<template lang="pug">
.container.ff-poppins.pb-4(:class="{ 'was-validated': formularioValidado }")
  .row.mt-2
    .col.mt-2.h6(style="text-align: justify; margin: 0 60px 0 -60px; width: 800px")
      p.h1.fw-bold(style="color: #34189a") Seguro de Vida
      .col-md-12.mt-5
      p(style="color: #6e7599") La solidez de una empresa como&#32;
        span.text-primary.fw-bold HIR Casa&#32;
        | se refleja, entre otras cosas, en la capacidad de brindar a sus clientes la tranquilidad de contar con el respaldo de una compañía de seguros perteneciente al mismo grupo.
      .col-md-12.mt-4
      p(style="color: #6e7599") Esta compañía ofrece a los clientes de&#32;
        span.text-primary.fw-bold HIR Casa&#32;
        | un seguro de vida.
      .col-md-12.mt-4
      p(style="color: #6e7599") Por el hecho de brindarnos su confianza y contratar su financiamiento con&#32;
        span.text-primary.fw-bold HIR Casa&#32;
        | ,&#32;
        span.fw-bold usted queda automáticamente cubierto con el seguro de vida&#32;
        | con&#32;
        span.text-primary.fw-bold HIR COMPAÑÍA DE SEGUROS, S.A. DE C.V.&#32;
      p.mt-5.fs-5.fw-bold(style="color: #34189a") Requisitos:
      p(style="color: #6e7599")
        span.fw-bold Cuestionario médico
        | , que será evaluado por la compañía de seguros para la autorización.
      p(style="color: #6e7599"): span.fw-bold Los requisitos médicos son obligatorios y la cobertura se encuentra condicionada al cumplimento oportuno de los mismos.
      p(style="color: #6e7599") De no cumplir con los requisitos médicos que se solicitan, de acuerdo con la Suma Asegurada, la cobertura del seguro será cancelada en forma inmediata. En los contratos bajo el esquema de coacreditado, las condiciones de cobertura y selección aplicarán para cada acreditado por separado.
      p(style="color: #6e7599")
        span.fw-bold Con el seguro de vida está cubierto hasta el monto del valor actual del contrato&#32;
        | (como integrante y adjudicatario)&#32;
        span.fw-bold o el saldo insoluto&#32;
        | (como adjudicado).
    .col-md-6.mt-3(style="margin-right: -50px")
      p.h2.fw-bold(style="color: #34189a") Mi Seguro
      .card.mt-2: .card-body
        .row: .col-12.rounded-pill.shadow
          c-c-timeline(:items="timeline.items" :progress="timeline.progress")
          //timeline(:items="timeline.items" :progress="timeline.progress")
        .row
          .col-md-12.mt-2(style="background-color: #5935df; padding: 10px 10px 0 10px")
            button(
              @click.prevent="cambiarPestania('Cliente')"
              :class="pestania == 'Cliente' ? '' : ''"
              style="background-color: #5935df; color: white; margin: 0 0 0 50px; width: 100px; height: 35px; font-weight: 600; border: none; border-radius: 5px 5px 0 0"
              :style="pestania == 'Cliente' ? 'background-color: white; color: #5935df;' : ''"
            ) Cliente
            button(
              v-if="cotitulares.length > 0 ? true : false"
              @click.prevent="cambiarPestania('Cotitular')"
              style="background-color: #5935df; color: white; margin-left: 30px; width: 100px; height: 35px; font-weight: 600; border: none; border-radius: 5px 5px 0 0"
              :style="pestania == 'Cotitular' ? 'background-color: white; color: #5935df;' : ''"
            ) Cotitular
        .row
          .col-md-4.mt-4: p.fw-bold(style="color: #34189a" v-if="pestania == 'Cotitular' ? true : false") Cotitulares
          .col-md-6.mt-4(style="margin: 10px 0")
            select.form-select(
              v-if="pestania == 'Cotitular' ? true : false"
              v-model="cotitular"
              style="width: 340px; border-radius: 15px; padding: 5px 10px 5px 10px"
            )
              option(v-for="(cotitular, index) in cotitulares" :key="index" :value="cotitular") {{ cotitular.nombreCompleto }}
        .row
          .col-md-4: p.fw-bold(style="color: #34189a") Coberturas
          .col-md-6: select.form-select(v-model="estatusTramite" style="width: 340px; border-radius: 15px; padding: 5px 10px 5px 10px")
            option(v-for="(estatus, index) in estatusTramites" :key="index" :value="estatus") {{ estatus?.nombreDictamen }}
          p.fw-bold.text-muted {{ coberturas() }}
          hr
          .col-md-12: p.fw-bold(style="color: #34189a") Contratos
          .col-md-12: p.text-muted {{ numerosContratos }}
          p(style="text-decoration: underline") * Aplica Restricciones
          hr
        .row
          .col-md-4.mt-2: p.text-muted Prima Mensual
          .col-md-4.mt-2: p.text-center(style="color: #34189a") {{ seguroVida.primaMensual }} %
          .col-md-4.mt-2: p.text-center(style="color: #34189a") {{ filtroDivisa(seguroVida.cantidadPrimaMensal) }}
        hr
        p.fw-bold(style="color: #34189a") Documentos
        //-a.text-decoration-none(:href="mostrarDocumentoAnexo(120)" target="_blank") Hola
        table.w-100: tbody
          tr(v-for="doc in documentos.filter((d) => d.disponible)")
            td(style="width: 60%") {{ doc.nombreDocumento }}
            td(style="width: 20%")
              a.text-decoration-none(:href="obtenerUrlDocumento(doc.metodo || '', doc.parametros)" target="_blank")
                span.fas.fa-eye.ms-2.text-decoration-none(style="color: #eb8113")
            td.text-danger(v-if="obtenerTextoVigencia(doc)" style="width: 20%") {{ obtenerTextoVigencia(doc) }}
        p.fw-bold(v-if="documentos.length == 0 ? true : false") No se han cargado Documentos.
        hr
        p.fw-bold(style="color: #34189a") Documentos Anexos
        table.w-100: tbody
          tr(v-for="(docAnexon, index) in documentoAnexo" :key="index")
            td(style="width: 60%") {{ docAnexon.nombreDocumento }}
            td(style="width: 20%")
              a.text-decoration-none(:href="mostrarDocumentoAnexo(docAnexon)" target="_blank")
                span.fas.fa-eye.ms-2.text-decoration-none(style="color: #eb8113")
        p.fw-bold(v-if="documentoAnexo.length == 0 ? true : false") No se han cargado Documentos Anexos.
</template>
<script lang="ts">
import { defineComponent } from "vue";
import CCTimeline from "../components/CCTimeline.vue";
import mixinFiltros from "@/mixins/filtros";
import mixinId from "@/mixins/componentId";
import api from "@/servicios/apiCliente";
import * as utilidades from "@/utilidades";
import { Core, Cliente } from "@/servicios/api";
import { DateTime } from "luxon";

export default defineComponent({
  mixins: [mixinFiltros, mixinId],
  components: { CCTimeline },
  data() {
    return {
      numerosContratos: "",
      pestania: "Cliente",
      estatusTramite: {
        tramiteSeguroId: 0,
        estatus: Core.Enums.EstatusTramiteSeguro.EN_TRAMITE
      } as Pick<
        {
          [k in keyof Cliente.Cliente.Seguro.TramiteSeguro]: NonNullable<Cliente.Cliente.Seguro.TramiteSeguro[k]>;
        },
        "tramiteSeguroId" | "estatus" | "folioRecepcion"
      > | null,
      contratos: [] as string[],
      estatusTramites: [] as Cliente.Cliente.Seguro.TramiteSeguro[],
      cotitulares: [] as Core.Models.PersonaFisica[],
      actor: 0,
      cotitular: {} as Core.Models.PersonaFisica | null,
      formularioValidado: false,
      timeline: {
        items: ["Solicitud", "Revisión", "Dictamen", "Certificado"],
        progress: 0
      },
      documentoAnexo: [] as Cliente.Cliente.Seguro.DocumentoSeguro[],
      seguroVida: {
        tipoActor: " ",
        detalleCobertura: "Detalle de tus Coberturas",
        primaMensual: 0,
        cantidadPrimaMensal: 0,
        primaActiva: true,
        primaCotitular: 2.5,
        cantidadCotitular: 250,
        cotitularActiva: false,
        beneficiarios: [] as any[],
        urlConsentimientoMedico:
          (import.meta.env.BASE_URL || "").replace(/\/$/g, "") +
          api.Cliente.Cliente.SeguroApi.ObtenerConsentimientoMedicoUrl
      },
      documentos: [] as Cliente.Cliente.Seguro.DocumentoSeguro[]
    };
  },
  watch: {
    cotitular: function (value: any) {
      let EntidadID = 0;
      if (this.cotitular != undefined || this.cotitular != null) {
        this.seguroVida.primaMensual = 0;
        this.seguroVida.cantidadPrimaMensal = 0;
        EntidadID = value.personaFisicaId;
      }
      api.Cliente.Cliente.SeguroApi.ObtenerTramiteSeguro(EntidadID).then((d) => {
        if (d.data.length > 0) {
          this.estatusTramites = d.data;
          this.estatusTramite = this.estatusTramites.length > 0 ? this.estatusTramites[0] : null;
        }
        this.estatusTramite = null;
      });
    },
    estatusTramite: function (value: any) {
      this.estatusTramite = value;
      if (this.estatusTramite == null) return;
      this.timeline.progress = 0;
      this.seguroVida.cantidadPrimaMensal = 0;
      this.seguroVida.primaMensual = 0;
      this.documentos = [];
      this.contratos = [];
      this.numerosContratos = "";
      // Obtener Linea de Vida Dictamen
      api.Cliente.Cliente.SeguroApi.ObtenerLineaVidaSeguro(this.estatusTramite.tramiteSeguroId).then((d) => {
        this.timeline.items = [];
        let items = [] as string[];
        d.data.etapas?.forEach(function (elemento, indice, array) {
          items.push(elemento.toString().replace(/_/g, " "));
        });
        this.timeline.items = items;
        this.timeline.progress = d.data.progreso;
      });
      //Obtener Prima Mensual
      api.Cliente.Cliente.SeguroApi.ObtenerPagoPrima(this.estatusTramite.tramiteSeguroId).then((resp) => {
        this.seguroVida.cantidadPrimaMensal = resp.data.valorPrima;
        this.seguroVida.primaMensual = resp.data.porcentajePrima;
      });
      //Obtener Documentos
      api.Cliente.Cliente.SeguroApi.ObtenerListaDocumentos(this.estatusTramite.tramiteSeguroId).then((d2) => {
        this.documentos = d2.data;
      });
      api.Cliente.Cliente.SeguroApi.ObtenerDocumentoAnexo(this.estatusTramite.tramiteSeguroId).then((respAnexo) => {
        this.documentoAnexo = respAnexo.data;
      });
      //Obtener Contratos
      api.Cliente.Cliente.SeguroApi.ContratosTramiteSeguro(this.estatusTramite.tramiteSeguroId).then((resp) => {
        for (let contrato of resp.data) {
          this.contratos.push(contrato.numeroCompleto!);
        }
        this.numerosContratos = this.contratos.join(", ");
      });
    }
  },
  methods: {
    cambiarPestania(value: any) {
      this.pestania = value;
      if (value == "Cotitular") {
        api.Cliente.Cliente.SeguroApi.ObtenerCotitulares().then((d) => {
          this.cotitulares = d.data;
          this.cotitular = this.cotitulares.length > 0 ? this.cotitulares[0] : null;
        });
      } else {
        api.Cliente.Cliente.SeguroApi.ObtenerTramiteSeguro(0).then((res) => {
          this.estatusTramites = res.data;
          this.estatusTramite = this.estatusTramites.length > 0 ? this.estatusTramites[0] : null;
        });
      }
    },
    cambiarEsatusTramite(index: number) {
      this.cotitular = this.cotitulares.length > 0 ? this.cotitulares[index] : null;
    },
    coberturas(): string {
      if (
        this.estatusTramite?.estatus == Core.Enums.EstatusTramiteSeguro.ACEPTADO_EN_SU_TOTALIDAD ||
        this.estatusTramite?.estatus == Core.Enums.EstatusTramiteSeguro.EXTRAPRIMADO
      )
        return "Vida - Invalidez - Desempleo";
      if (
        this.estatusTramite?.estatus == Core.Enums.EstatusTramiteSeguro.CANCELACIÓN ||
        this.estatusTramite?.estatus == Core.Enums.EstatusTramiteSeguro.CANCELADO_POR_CESIÓN_DERECHOS ||
        this.estatusTramite?.estatus == Core.Enums.EstatusTramiteSeguro.CESIÓN_DERECHOS ||
        this.estatusTramite?.estatus == Core.Enums.EstatusTramiteSeguro.ELIMINADO
      )
        return "N/A";
      if (
        this.estatusTramite?.estatus == Core.Enums.EstatusTramiteSeguro.EN_TRAMITE ||
        this.estatusTramite?.estatus == Core.Enums.EstatusTramiteSeguro.PENDIENTE ||
        this.estatusTramite?.estatus == Core.Enums.EstatusTramiteSeguro.PENDIENTE_DE_DICTAMEN ||
        this.estatusTramite?.estatus == Core.Enums.EstatusTramiteSeguro.RECONSIDERACIÓN_ASEGURADORA ||
        this.estatusTramite?.estatus == Core.Enums.EstatusTramiteSeguro.REQUIERE_INFORMACION_ADICIONAL
      )
        return "En Trámite";
      if (
        this.estatusTramite?.estatus == Core.Enums.EstatusTramiteSeguro.EXTRAPRIMADO_Y_RECHAZADO_EN_INVALIDEZ ||
        this.estatusTramite?.estatus == Core.Enums.EstatusTramiteSeguro.RECHAZADO_EN_INVALIDEZ
      )
        return "Vida - Desempleo";
      if (
        this.estatusTramite?.estatus ==
          Core.Enums.EstatusTramiteSeguro.EXTRAPRIMADO_Y_RECHAZADO_EN_INVALIDEZ_Y_SEG_DESEMPLEO ||
        this.estatusTramite?.estatus == Core.Enums.EstatusTramiteSeguro.RECHAZADO_EN_INVALIDEZ_Y_SEG_DESEMPLEO
      )
        return "Vida";
      if (
        this.estatusTramite?.estatus == Core.Enums.EstatusTramiteSeguro.EXTRAPRIMADO_Y_RECHAZADO_EN_SEG_DESEMPLEO ||
        this.estatusTramite?.estatus == Core.Enums.EstatusTramiteSeguro.RECHAZADO_EN_SEG_DESEMPLEO
      )
        return "Vida - Invalidez";
      if (this.estatusTramite?.estatus == Core.Enums.EstatusTramiteSeguro.RECHAZADO_EN_SU_TOTALIDAD) return "Rechazado";
      if (this.estatusTramite?.estatus == Core.Enums.EstatusTramiteSeguro.REQUIERE_EXAMEN_MEDICO)
        return "Examen Médico";
      return "";
    },
    obtenerUrlDocumento(metodo: string, parametros?: { [key: string]: string }): string {
      let parametrosString = "";
      if (parametros != undefined)
        parametrosString =
          "?" +
          Object.keys(parametros)
            .map((key) => key + "=" + encodeURIComponent(parametros[key]))
            .join("&");
      let base = (import.meta.env.BASE_URL || "").replace(/\/$/g, "");
      if (metodo == "ObtenerConsentimientoMedico")
        return base + api.Cliente.Cliente.SeguroApi.ObtenerConsentimientoMedicoUrl;
      return base + api.Cliente.Cliente.SeguroApi.ObtenerDocumentoUrl + parametrosString;
    },
    mostrarDocumentoAnexo(ID: any): string {
      let URL =
        (import.meta.env.BASE_URL || "").replace(/\/$/g, "") +
        api.Cliente.Cliente.SeguroApi.ObtenerArchivoAnexoUrl +
        "?token=";
      return URL + ID.metodo;
    },
    obtenerTextoVigencia(documento: Cliente.Cliente.Seguro.DocumentoSeguro): string {
      // Solo aplica para el pase médico en seguros en trámite
      if (documento.tipoDocumento != Core.Models.TipoDocumentoTramiteSeguro.PASE_MÉDICO) return "";
      if (!documento.disponible) return "";
      if (this.coberturas() != "En Trámite") return "";
      let diferencia = DateTime.now().diff(DateTime.fromISO(documento.fechaCarga!.substring(0, 19)), [
        "day",
        "hour"
      ]).days;
      if (diferencia > 30) return `Expirado. Contácte a su Asesor.`;
      if (diferencia >= 23)
        return `Expira en ${31 - diferencia} día${31 - diferencia > 1 ? "s" : ""}. Contácte a su Asesor`;
      return "";
    }
  },
  async mounted() {
    // Cotitulares del Cliente
    this.cotitulares = (await api.Cliente.Cliente.SeguroApi.ObtenerCotitulares()).data;
    //Obtener Lista de Dictamenes
    let res = await api.Cliente.Cliente.SeguroApi.ObtenerTramiteSeguro(0);
    this.estatusTramites = res.data;
    this.estatusTramite = this.estatusTramites.length > 0 ? this.estatusTramites[0] : null;
  }
});
</script>
