<template lang="pug">
.d-flex.flex-column.p-3.w-100
  .container.d-flex.justify-content-between
    .col-8.g-0.justify-content-start
      p#titulo.fs-4.text-secondary.fs-4 Checklist de Documentos
      p#subtitulo.font-weight-bold.text-primary {{  figura == "titular" ? 'Titular' : figura == "CoTitular" ? 'Cotitular' : figura == "GaranteHipotecario" ? "Garante Hipotecario" : figura == "ObligadoSolidario" ? "Obligado Solidario" : ""  }}
  <ChecklistCollapse />
</template>

<script lang="ts">
import ChecklistCollapse from "../../components/ChecklistCollapse.vue";
import { useRoute, useRouter } from "vue-router";
import { ref, watch } from "vue";

export default {
  components: { ChecklistCollapse },
  props: [],
  setup() {
    const route = useRoute();
    const router = useRouter();
    const figura = ref(route.params.figura);

    watch(route, () => {
      figura.value = route.params.figura;
    });
    return { route, router, figura };
  }
};
</script>

<style scoped>
#titulo,
#subtitulo {
  font-weight: 700;
}
</style>
