<template lang="pug">
.container(:class="{ 'was-validated': formularioValidado }"): .card: .card-body
  .row 
    .col-md-12: span *Campos obligatorios
    .col-md-12: label.form-label.fw-bold.h4.mt-3(style="color: #2f2d74") Información General
    .col-md-3
      label.form-label.fw-bold(style="color: #2f2d74; font-size: 14px") Fecha de ocurrido*:
      input.form-control(type="date" min="1900-01-01" :form="componentId + 'form'" :max="fechaIso" v-model="fechaSiniestro")
      .invalid-feedback Fecha invalida.
    .col-md-3
      label.form-label.fw-bold.h5(style="color: #2f2d74; font-size: 14px") Causa del siniestro *:
      input.form-control(type="text" pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+" v-model="danios.causaSiniestro")
      .invalid-feedback Causa del Siniestro invalida.
    .col-md-3
      label.form-label.fw-bold.h5(style="color: #2f2d74; font-size: 14px") ¿Conoce el monto estimado?:
      br
      .form-check.form-check-inline
        input.form-check-input(type="radio" :id="componentId + 'mostrarMonto'" :name="componentId + 'mostrarMonto'" :value="true" v-model="mostrarMonto")
        label.form-check-label(:for="componentId + 'mostrarMonto'" style="color: #2f2d74") Si
      .form-check.form-check-inline
        input.form-check-input(type="radio" :id="componentId + 'ocultarMonto'" :name="componentId + 'ocultarMonto'" :value="false" v-model="mostrarMonto")
        label.form-check-label(:for="componentId + 'ocultarMonto'" style="color: #2f2d74") No
    .col-md-3
      label.form-label.fw-bold.h5(style="color: #2f2d74; font-size: 14px") Monto estimado:
      hir-autonumeric.form-control(
        :form="componentId + 'form'"
        :readonly="!mostrarMonto"
        v-model="danios.montoEstimado"
        :configuracion="{ emptyInputBehavior: 'min', maximumValue: '99999999', minimumValue: '0', currencySymbol: '$ ' }"
      )
      .invalid-feedback Descripcion ocurrido invalido.
    .col-md-6.mt-2: label.form-label.fw-bold.h4.mt-3(style="color: #2f2d74") Domicilio del sinestro:
    .col-md-4
      label.form-label.fw-bold.h5(style="color: #2f2d74; font-size: 14px") Calle*:
      input.form-control(type="text" pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+" v-model="danios.calle")
      .invalid-feedback Calle invalida.
    .col-md-2
      label.form-label.fw-bold.h5(style="color: #2f2d74; font-size: 14px") No. Interior*:
      input.form-control(type="text" pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ0-9 ]+" v-model="danios.numInterior")
      .invalid-feedback No. Interior invalida.
    .col-md-2
      label.form-label.fw-bold.h5(style="color: #2f2d74; font-size: 14px") No. Exterior*:
      input.form-control(type="text" pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ0-9 ]+" v-model="danios.numExterior")
      .invalid-feedback No. Exterior invalida.
    .col-md-4
      label.form-label.fw-bold.h5(style="color: #2f2d74; font-size: 14px") C.P. *:
      input.form-control(type="text" pattern="[0-9]{5}" v-model="cp")
      .invalid-feedback Codigo Postal invalida.
    .col-md-4
      label.form-label.fw-bold.h5(style="color: #2f2d74; font-size: 14px") Colonia:
      select.form-select(v-model="Colonia")
        option(v-for="item in Colonias" :value="item" :key="item.coloniaId") {{ item.colonia }}
      .invalid-feedback Colonia invalida.
    .col-md-4 
      label.form-label.fw-bold.h5(style="color: #2f2d74; font-size: 14px") Municipio / Alcaldía:
      input.form-control(type="text" v-model="danios.alcaldiaMunicipio" readonly)
      .invalid-feedback Municipio / Alcaldía invalida.
    .col-md-4
      label.form-label.fw-bold.h5(style="color: #2f2d74; font-size: 14px") Estado:
      input.form-control(type="text" v-model="danios.estado" readonly)
      .invalid-feedback Estado invalida.
    .col-md-12: label.form-label.fw-bold.h4.mt-3(style="color: #2f2d74") Contacto que atenderá
    .col-md-4
      label.form-label.fw-bold.h5(style="color: #2f2d74; font-size: 14px") Nombre *:
      input.form-control(type="text" pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+" v-model="danios.nombre")
      .invalid-feedback Nombre invalida.
    .col-md-4
      label.form-label.fw-bold.h5(style="color: #2f2d74; font-size: 14px") Tel. Particular*:
      input.form-control(:form="componentId + 'form'" type="tel" pattern="^[0-9]{10}" v-model="danios.telefono")
      .invalid-feedback Teléfono a 10 dígitos.
    .col-md-4
      label.form-label.fw-bold.h5(style="color: #2f2d74; font-size: 14px") Correo electrónico particular*:
      input.form-control(
        :form="componentId + 'form'"
        type="email"
        v-model="danios.email"
        pattern="[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}"
      )
      .invalid-feedback Correo Electrónico Particular invalido.
    .col-md-6.mt-4
      label.form-label.fw-bold.h5(style="color: #2f2d74; font-size: 14px") Breve desrcripcion de lo ocurrido*:
      textarea.form-control(type="text" :form="componentId + 'form'" rows="3" cols="20" v-model="danios.descripcion")
      .invalid-feedback Bereve desrcripción de lo ocurrido invalido.
    .col-md-6.mt-4
      label.btn.btn-primary.text-white.mt-4(style="font-size: 14px")
        span.fas.fa-upload &#32;
        input(type="file" style="display: none" v-on:change="guardarDocuemento($event)" multiple="true")
        | Cargar Evidencia
    .col-md-12.mt-4: .row.justify-content-center
      .col-md-3: button.btn.bg-white.text-primary.border.border-primary Cancelar
      .col-md-3: button.btn.bg-white.text-primary.border.border-primary Llamar Asesor
      .col-md-3: button.btn.btn-primary.text-white(@click.prevent="guardarDatosSiniestros()") Enviar Información
    Spinner-Loader#spinner(name="spinner")

// Modal
.modal.fade(:id="componentId + 'Loading'" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="LoadingLabel" aria-hidden="true")
  .modal-dialog.modal-dialog-centered
    .modal-content
      .modal-body.d-flex.justify-content-center.align-items-center
        span.spinner-border.spinner-border-sm.text-primary(role="status" aria-hidden="true")
        |
        span.text-primary.p-2 Cargando información...
</template>
<script lang="ts" setup>
import { ref, onMounted, watch, nextTick } from "vue";
import * as utilidades from "@/utilidades";
import HirAutonumeric from "@/components/HirAutonumeric.vue";
import { Cliente, Core, EstadoRespuesta } from "@/servicios/api";
import api from "@/servicios/apiCliente";
import { useStore } from "@/store";
import * as bootstrap from "bootstrap";

//#endregion Data
const $store = useStore();
const formularioValidado = ref(false);
const componentId = ref(utilidades.generarId());
const fechaIso = new Date().toISOString().substring(0, 10);
const fechaSiniestro = ref("");
const siniestro = ref({ danios: {} as Cliente.Cliente.Siniestro.SiniestrosDañosPortalCliente });
const danios = ref({} as Cliente.Cliente.Siniestro.SiniestrosDañosPortalCliente);
const siniestroDanio = ref({} as Cliente.Cliente.Siniestro.ListaSiniestroconDocumentos);
const Colonias = ref({} as Cliente.Cliente.ObtenerColoniasBody[]);
const Colonia = ref({} as Cliente.Cliente.ObtenerColoniasBody);
const cp = ref(0);
const mostrarMonto = ref(false);
formularioValidado.value = true;
const mostrarSpinner = ref(false);

//#endregion
//#endregion Props
const props = defineProps({
  Danio: { type: Object as () => Cliente.Cliente.Siniestro.ListaSiniestroconDocumentos, default: null }
});
//#endregion
watch(mostrarMonto, val => {
  danios.value.mostrarMonto = val;
});
watch(Colonia, val => {
  danios.value.colonia = val.colonia;
});
watch(cp, async val => {
  Colonia.value.codigoPostal = val.toString();
  danios.value.cp = val;
  Colonias.value = [];
  Colonias.value = (await api.Cliente.Cliente.EscrituracionApi.ObtenerColonias((val || 0).toString())).data;
  if (Colonias.value.length > 0) {
    Colonia.value = Colonias.value[0] || {};
  }
  danios.value.colonia = Colonia.value.colonia;
  danios.value.estado = Colonia.value.estado;
  danios.value.alcaldiaMunicipio = Colonia.value.ciudad;
});

//methods

function abrirModal() {
  const miModal = document.getElementById(`${componentId.value}Loading`);
  const modal = new bootstrap.Modal(miModal);
  modal?.show();
}
function cerrarModal() {
  const miModal = document.getElementById(`${componentId.value}Loading`);
  const modal = bootstrap.Modal.getInstance(miModal);
  modal?.hide();
}

async function guardarDatosSiniestros() {
  // Obtenemos JSON deserealizado
  let div = document.getElementById("spinner") as HTMLDivElement;
  div.style.display = "block";
  if (!siniestroDanio.value.existe) {
    await CrearSiniestro();
  }
  let validador = (siniestroDanio.value.documentos?.length ?? 0) > 0;
  if (!validador) {
    div.style.display = "none";
    return;
  }
  let id = siniestroDanio.value.documentos![0].documentoSiniestroId!;
  let resp = await api.Cliente.Cliente.SiniestroApi.NotificarCambios(id, danios.value);
  siniestroDanio.value.danios = danios.value;
  siniestro.value.danios = siniestroDanio.value.danios;
  cp.value = siniestroDanio.value.danios.cp!;
  fechaSiniestro.value = new Date(danios.value.fechaOcurrido!).toISOString().substring(0, 10);
  div.style.display = "none";
  $store.agregarToast(resp.data);
}
async function cargarDatos() {
  let div = document.querySelector("#spinner") as HTMLDivElement;
  div.style.display = "none";
  siniestroDanio.value = props.Danio;
  danios.value = siniestroDanio.value.danios;
  cp.value = siniestroDanio.value.danios.cp!;
  fechaSiniestro.value = new Date(danios.value.fechaOcurrido!).toISOString().substring(0, 10);
  mostrarSpinner.value = false;
}
async function CrearSiniestro() {
  let resp = await api.Cliente.Cliente.SiniestroApi.CrearSiniestro(Core.Enums.TipoSinestro.DAÑOS);
  if (resp.data.estado == EstadoRespuesta.OK) {
    siniestroDanio.value = resp.data.data;
    guardarDatosSiniestros();
  }
  $store.agregarToast(resp.data);
}
async function guardarDocuemento(event: Event) {
  let div = document.getElementById("spinner") as HTMLDivElement;
  div.style.display = "block";
  abrirModal();
  if (!siniestroDanio.value.existe) {
    await CrearSiniestro();
  } else {
    cerrarModal();
  }
  let validador = (siniestroDanio.value.documentos?.length ?? 0) > 0;
  if (!validador) {
    div.style.display = "none";
    cerrarModal();
    return;
  }
  let files = (event.target as HTMLInputElement).files![0];
  let rutas = siniestroDanio.value.documentos![0].documentoSiniestroId!;
  let resp = await api.Cliente.Cliente.SiniestroApi.GuardarDocumentoSiniestro(files, rutas!);
  siniestroDanio.value = resp.data.data;
  guardarDatosSiniestros();
  $store.agregarToast(resp.data);
  div.style.display = "none";
  cerrarModal();
}

onMounted(async () => {
  await cargarDatos();
  await nextTick();
});
</script>
