export default {
  filtroDivisa(numero: number | undefined | null, currency: string = "MXN"): string {
    if (numero == undefined || numero == null) return "";
    return new Intl.NumberFormat("es-MX", { style: "currency", currency: currency }).format(numero);
  },
  formatoFecha(inputDate: string) {
    const fecha = new Date(inputDate);
    const dia = String(fecha.getDate()).padStart(2, '0');
    const mes = String(fecha.getMonth() + 1).padStart(2, '0');
    const anio = fecha.getFullYear();
    return `${dia}-${mes}-${anio}`;
  },
};
