<template lang="pug">
.container.ff-poppins(:class="{ 'was-validated': formularioValidado }")
  .row.mt-2(style="margin-top: -27px")
    .col-md-5.mt-2
      .p-3.fw-bold.text-secondary.text-white.fs-5(style="background: #5935df; border-radius: 8px 8px 0 0") LOGIN
      .card(style="border-radius: 0 0 8px 8px"): .card-body
        .row.justify-content-center
          .col-8.mt-4
            label.form-label.fw-bold.fs-5(style="color: #2f2d74") Correo Electrónico
            input.form-control(
              :form="componentId + 'form'"
              type="email"
              v-model="LoginUser.correo.value"
              required
              pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
              style="border-radius: 90px"
            )
            .invalid-feedback Correo Electrónico invalido.
          .col-8.mt-4
            label.form-label.fw-bold.fs-5(style="color: #2f2d74") Contraseña
            input.form-control(
              :form="componentId + 'form'"
              type="Password"
              v-model="LoginUser.password.value"
              required
              :pattern="patronPassword"
              style="border-radius: 90px"
            )
            .invalid-feedback Contraseña invalido.
          .col-8.text-center.mt-4
            router-link.fw-bold(:to="{ name: 'cliente-cliente-usuario-recuperacion' }" style="color: #ffa000") ¿Has Olvidado tu Contraseña?
          .col-8.text-center.mt-4
            form.text-center(ref="formDom" novalidate :id="componentId + 'form'" @submit.prevent="autentificarUsuario()"): button.btn.btn-primary.text-white.px-5.fw-bold(
              style="border-radius: 20px; background: #ffa000"
            ) Entrar
          .col-8.text-center.mt-4
            router-link.fw-bold(:to="{ name: 'cliente-cliente-usuario-crear' }" style="color: #ffa000") Soy Usuario Nuevo
    .col-md-6.mt-2.offset-md-1
      img.img-fluid.img-fluid(src="../assets/bg.png" alt="Responsive image")
</template>

<script lang="ts" setup>
import * as utilidades from "@/utilidades";
import { ref, reactive, toRefs, toRaw } from "vue";
import api from "@/servicios/apiCliente";
import { TipoBootstrap } from "@/interfaces";
import { EstadoRespuesta, Core } from "@/servicios/api";
import { useRouter } from "vue-router";
import Swal from "sweetalert2";
import { useStore } from "@/store";
import { useStore as useClienteClienteStore } from "../store";
const $router = useRouter();

//#region Data
const store = useClienteClienteStore();
const $store = useStore();
const patronPassword = "[^'\"\ ]+";
const formularioValidado = ref(false);
const reactiveLoginUser = reactive({
  numContrato: "",
  correo: "",
  password: "",
  estatus: Core.Enums.EstatusAlta.Activo
} as Core.Models.AltaUsuario);
const LoginUser = toRefs(reactiveLoginUser);
const contador = ref(0);
const formDom = ref(null as HTMLFormElement | null);
//#endregion

//#region Methods

async function autentificarUsuario() {
  contador.value += 1;
  formularioValidado.value = true;
  let valido = formDom.value!.checkValidity();
  if (!valido) {
    $store.agregarToast({
      tipo: TipoBootstrap.DANGER,
      body: "Llenar todos los campos correctamente."
    });
    return;
  }
  if (contador.value > 3) {
    if (contador.value > 4) return;
    LoginUser.estatus.value = Core.Enums.EstatusAlta.Recuperacion_Automatica;
    let resc = await api.Cliente.Cliente.HomeApi.SolicitudCambioContrasenia(toRaw(reactiveLoginUser));
    $store.agregarToast(resc.data);
    return;
  }
  const res = await api.Cliente.Cliente.HomeApi.Login({
    correo: LoginUser.correo?.value!,
    contrasenia: LoginUser.password?.value!
  });
  if (res.data.estado == EstadoRespuesta.OK) {
    gtag("event", "login");
    $router.push({ name: "cliente-cliente-home" });
    store.sesionIniciada = true;
    store.clienteAceptaTerminosCondicionesAvisoPrivacidad = res.data.aceptaTerminosCondicionesAvisoPrivacidad ?? false;
    sessionStorage.setItem("seguirMostrandoBannerCampania", "true");
  } else if (res.data.estado == EstadoRespuesta.INFO) {
    Swal.fire({
      icon: "error",
      showCloseButton: true,
      showConfirmButton: false,
      html: `
            <p class="fw-bold" style="color: #2f2d74; font-size: 14px;"> Estimado cliente:</p>
            <p class="fw-bold" style="color: #2f2d74; font-size: 14px;"> Por el momento no es posible ingresar a tu cuenta.</p>
            <p style="font-size: 12px"> Es importante que te comuniques por cualquiera de los siguientes medios para mayor información:</p>
            <table style="width:100%">
              <tr>
                <td style="width:50%">
                  <p style="font-size: 12px">Correo: <span class="fw-bold" style="color: #2f2d74; font-size: 12px;"> fperez@hircasa.com.mx</span></p>
                </td>
                <td style="width:50%">
                  <p style="font-size: 12px">Domicilio: <span class="fw-bold" style="color: #2f2d74; font-size: 12px;" colspan="2"> Desierto de los Leones No. 46, Nivel 1, Col San Ángel, Alvaro Obregón, CP 01000, Ciudad de México.</span></p>
                </td>
              </tr>
              <tr>
                <td style="width:50%">
                  <p style="font-size: 12px">Teléfono: <span class="fw-bold" style="color: #2f2d74; font-size: 12px;"> 555511 9910 ext. 6277</span></p>
                </td>
                <td></td>
              </tr>
              <tr>
                <td style="width:50%">
                  <p style="font-size: 12px">Whatsapp: <span class="fw-bold" style="color: #2f2d74; font-size: 12px;"> 557665 9885 Opción 3</span></p>
                </td>
                <td style="width:50%">
                  <p style="font-size: 12px">Horario de Atención: <span class="fw-bold" style="color: #2f2d74; font-size: 12px;"> Lunes a Viernes de 09:00 a 18:00 hrs.</span></p>
                </td>
              </tr>
            </table>
            `
    });
  } else {
    $store.agregarToast(res.data);
  }
}
//#endregion

const res2 = (await api.Cliente.Cliente.HomeApi.Ping()).data;
if (res2) $router.push({ name: "cliente-cliente-home" });
</script>
