<template lang="pug">
div
  .container.mb-5(:class="{ 'was-validated': formularioValidado }")
    .row.mt-2(style="margin-top: -27px")
      .col-md-12
        .d-inline-block(
          style="background: #374066; color: #ffffff; padding: 15px 26px; border-top-left-radius: 11px; border-top-right-radius: 11px; margin-left: 26px"
        ): b.text.secondary Mi Perfil
    .row
      .col-md-12.shadow(style="border-radius: 8px; padding: 0")
        div(style="background: #fff; padding: 16px 26px; border-bottom: #fff 1px solid")
          .row
            .col-2.col-sm-2.col-md-1.col-lg-1.text-center
              b.fas.fa-camera.mt-3
            .col-4.col-sm-4.col-md-6.col-lg-7
              b.form-label.text-muted Hola
              br
              b.form-label.mt-4(style="color: #000; font-size: 18px") {{ nombreCliente }}
            .col-4.col-sm-4.col-md-3.col-lg-2.text-center
              b.form-label.text-muted Registrado Desde
              br
              b.form-label {{ fechaCreacion }}
            .col-2.col-sm-2.col-md-2.col-lg-2.text-center
    .row.mt-2
      .col-md-12.shadow(style="border-radius: 8px; padding: 0")
        div(style="background: #eceff2; padding: 16px 26px; border-bottom: #95a7d6 1px solid")
          .row
            .col-md-2: b.form-label.text-secondary(style="font-size: 20px") Mis Datos
    .row
      .col-md-12.shadow(style="border-radius: 8px; padding: 0")
        div(style="background: #fff; padding: 16px 26px; border-bottom: #fff 1px solid")
          .row
            .col-md-12: .card.mb-2: .card-body(style="background: #eceff2; padding: 16px 26px; border: #95a7d6 1px solid")
              .row 
                .col-md-10
                  b.text-secondary(style="font-size: 22px") ¿Necesitas actualizar algún dato personal?
                  br
                  .col-12.col-sm-12.col-md-12.col-lg-12.mt-2
                    b.text-muted Envíanos un correo a&#32;
                    b.text-primary atencionaclientes@hircasa.com.mx o contáctanos al teléfono:&#32;
                      a(href="tel:525555119910,2") 55 5511 9910 opción 2
                .col-md-2: a.btn.btn-primary.text-center.text-white.mt-2(@click.prevent="editarInformacion()" v-if="puedeActualizarInformacion") Editar Información
            .col-12
              b.text-secondary(style="font-size: 18px") Domicilio
            .col-12.col-sm-4.col-md-4.col-lg-4
              label.form-label.text-muted(style="font-size: 18px") País:
              input.form-control(type="text" v-model="ubicacion.pais.descripcion" pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+" readonly)
              .invalid-feedback Pais invalido.
            .col-12.col-sm-4.col-md-4.col-lg-4
              label.form-label.text-muted(style="font-size: 18px") C.P.:
              input.form-control(type="text" v-model="CodigoPostal" pattern="[0-9]{5}" :readonly="!editarInformarPerfil" required)
              .invalid-feedback C.P. invalido.
            .col-12.col-sm-4.col-md-4.col-lg-8
              label.form-label.text-muted(style="font-size: 18px") Calle:
              input.form-control(
                type="text"
                :form="formId"
                v-model="ubicacion.calle"
                pattern="[a-z0-9A-ZñÑéÉáÁíÍóÓúÓ#.\\- ]+"
                required
                :readonly="!editarInformarPerfil"
              )
              .invalid-feedback Calle invalida.
            .col-12.col-sm-4.col-md-4.col-lg-4
              label.form-label.text-muted(style="font-size: 18px") Num. Interior y/o Exterior:
              input.form-control(type="text" v-model="ubicacion.numero" pattern="[a-zA-Z0-9ñÑéÉáÁíÍóÓúÓ#.\\- ]+" required :readonly="!editarInformarPerfil")
              .invalid-feedback Número invalido.
            .col-12.col-sm-4.col-md-4.col-lg-4
              label.form-label.text-muted(style="font-size: 18px") Colonia:
              select.form-select(v-model="Colonia" :disabled="!editarInformarPerfil")
                option(v-for="(col, index) in Colonias" :key="col.coloniaId" :value="col") {{ col.colonia }}
              //-input.form-control(type="text" v-model="ubicacion.coloniaNombre" pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+" :readonly="!editarInformarPerfil")
            .col-12.col-sm-4.col-md-4.col-lg-4
              label.form-label.text-muted(style="font-size: 18px") Alcaldía o Municipio:
              input.form-control(type="text" v-model="ubicacion.ciudadNombre" pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+" readonly)
              //-select.form-select(v-model="estado" :disabled="!editarInformarPerfil")
                option(v-for="item in estados" :key="item.coloniaId") {{ item.ciudad }}
            .col-12.col-sm-4.col-md-4.col-lg-4
              label.form-label.text-muted(style="font-size: 18px") Estado:
              input.form-control(type="text" v-model="ubicacion.estadoNombre" pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+" readonly)
            .col-12.mt-4
              b.text-secondary(style="font-size: 18px") Medios de Contacto:
            .col-12.col-sm-3.col-md-3.col-lg-3(v-if="editarInformarPerfil")
              label.form-label.text-muted(style="font-size: 18px") Seleccionar Lada:
              hir-select2(
                :from="componentId"
                :data="Paises.map(a => ({ id: a.paisId, text: (a.gentilicio || '').trim() || a.descripcion }))"
                v-model="LadaPais"
              )
            .col-12.col-sm-3.col-md-3.col-lg-3
              label.form-label.text-muted(style="font-size: 18px") Teléfono Particular:
              input.form-control(v-model="telParticular.valor" type="tel" pattern="^[0-9]{10}" :readonly="!editarInformarPerfil" required)
              .invalid-feedback Teléfono a 10 dígitos.
            .col-12.col-sm-3.col-md-3.col-lg-3
              label.form-label.text-muted(style="font-size: 18px") Celular:
              input.form-control(v-model="celParticular.valor" type="tel" pattern="^[0-9]{10}" :readonly="!editarInformarPerfil" required)
              .invalid-feedback Teléfono a 10 dígitos.
            .col-12.col-sm-3.col-md-3.col-lg-3
              label.form-label.text-muted(style="font-size: 18px") Correo personal:
              input.form-control(
                type="text"
                v-model="emailParticular.valor"
                pattern="[-a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}"
                :readonly="!editarInformarPerfil"
              )
            .col-12.col-sm-3.col-md-3.col-lg-3
              label.form-label.text-muted(style="font-size: 18px") Teléfono Oficina:
              input.form-control(type="text" pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+" v-model="telOficina.valor" readonly)
            .col-12.col-sm-3.col-md-3.col-lg-3
              label.form-label.text-muted(style="font-size: 18px") Celular Oficina:
              input.form-control(type="text" pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+" readonly)
            .col-12.col-sm-3.col-md-3.col-lg-3
              label.form-label.text-muted(style="font-size: 18px") Correo Oficina:
              input.form-control(type="text" v-model="email2.valor" pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+" readonly)
            .col-md-12: .row
              .col-md-8.mt-2
                b.text-secondary.mt-4(style="font-size: 18px" v-if="editarInformarPerfil") Para actualizar la información es requerida la carga de tu Identificación Oficial:
              .col-md-12.mt-2
                label.mt-2.btn.btn.btn-primary.text-white(v-if="editarInformarPerfil")
                  input(type="file" style="display: none" v-on:change="cargarIdentificacionOficial($event)" accept="application/pdf" multiple)
                  | Cargar Documentos
                | &#32;&#32;
                template(v-for="(item, i) in NombreArchivoCargado" :key="item")
                  a.btn.text-primary(
                    v-if="NombreArchivoCargado.length"
                    :href="ObtenerDocumento(item.toString())"
                    :title="'Documento- ' + item.toString() || ''"
                    target="_blank"
                  ) {{ "Ver Documento " + (i + 1) }} &#32;
              .col-md-2.mt-2: a.btn.bg-white.text-center.text-primary.mt-2.border.border-primary(@click.prevent="" v-if="editarInformarPerfil") Cancelar
              //-.col-md-2.mt-2: a.btn.btn-primary.text-center.text-white.mt-2(@click.prevent="actualizarDatosClientes()" v-if="editarInformarPerfil") Finalizar
              .col-md-2.mt-2: form.text-center(
                style="display: none"
                ref="formDom"
                novalidate
                :id="componentId + 'form'"
                @submit.prevent="actualizarDatosClientes()"
              ): button.btn.btn-primary.text-white Finalizar
            .col-12.col-sm-12.col-md-12.col-lg-12.mt-4(v-if="beneficiarios.length && !editarInformarPerfil")
              b.text-secondary(style="font-size: 18px") Beneficiarios:
            .col-12.col-sm-6.col-md-6.col-lg-6(v-for="(item, index) in beneficiarios" :key="item" v-if="!editarInformarPerfil")
              label.form-label.text-muted(style="font-size: 18px") Nombre:
              label.form-control {{ item }}
              //-{{item}}
              //-input.form-control(
                :form="componentId + 'form'"
                type="text"
                v-model="beneficiarios[index]"
                pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+"
                readonly
                )
              //-label.form-label.text-muted(style="font-size:18px;") Apellidos:
              //- input.form-control(
                :form="componentId + 'form'"
                type="text"
                pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+"
                readonly
                )
            .col-12.col-sm-12.col-md-12.col-lg-12
            .col-12.col-sm-12.col-md-12.col-lg-12.mt-4(v-if="!editarInformarPerfil")
              b.text-secondary(style="font-size: 18px") Si requieres cambiar la contraseña editar aquí:
            .col-12.col-sm-3.col-md-3.col-lg-3(v-if="!editarInformarPerfil")
              label.form-label.text-muted(style="font-size: 18px") Password Actual:
              input.form-control(
                :form="componentId + 'form'"
                type="Password"
                required
                v-model="actualizarCliente.passwordActual.value"
                pattern="[a-z0-9A-ZñÑéÉáÁíÍóÓúÓ ]+"
              )
              .invalid-feedback Contraseña No Valida.
            .col-12.col-sm-3.col-md-3.col-lg-3(v-if="!editarInformarPerfil")
              label.form-label.text-muted(style="font-size: 18px") Nueva Password:
              input.form-control(
                :form="componentId + 'form'"
                type="Password"
                required
                v-model="actualizarCliente.passwordNueva.value"
                pattern="[a-zA-Z0-9ñÑéÉáÁíÍóÓúÓ ]+"
              )
              .invalid-feedback Contraseña No Valida.
            .col-12.col-sm-3.col-md-3.col-lg-3(v-if="!editarInformarPerfil")
              label.form-label.text-muted(style="font-size: 18px") Confirmar Password:
              input.form-control(
                :form="componentId + 'form'"
                type="Password"
                required
                v-model="actualizarCliente.repPassword.value"
                pattern="[a-z0-9A-ZñÑéÉáÁíÍóÓúÓ ]+"
              )
              .invalid-feedback Contraseña No Valida.
            .col-12.col-sm-3.col-md-3.col-lg-3.mt-2.text-center(v-if="!editarInformarPerfil")
              br
              form.text-center(ref="formDom" novalidate :id="componentId + 'form'" @submit.prevent="actualizarPassword()"): button.btn.btn-primary.text-white Actualizar
              //-button.btn.btn-primary.text-light.fw-bold(type="submit" :form="componentId + 'form'" @click="actualizarPassword()") Actualizar
            Spinner-Loader#spinner(name="spinner" style="width: 78%; display: none")
</template>

<script lang="ts" setup>
import { toRefs, reactive, ref, toRaw, watch, computed, nextTick } from "vue";
import api from "@/servicios/apiCliente";
import { Cliente, Core, EstadoRespuesta } from "@/servicios/api";
import { TipoBootstrap } from "@/interfaces";
import * as utilidades from "@/utilidades";
import { useStore } from "@/store";
import $ from "jquery";
import { DateTime } from "luxon";
import Swal from "sweetalert2";
import SpinnerLoader from "../components/SpinnerLoader.vue";
//#region Data
const $store = useStore();
const formDom = ref(null as HTMLFormElement | null);
const componentId = utilidades.generarId();
const formularioValidado = ref(false);
const nombreCliente = ref("");
const beneficiarios = ref([] as string[]);
const fechaCreacion = ref("");
const reactiveActualizarCliente = reactive({
  clienteId: 0,
  passwordActual: "",
  passwordNueva: "",
  repPassword: "",
  estatus: Core.Enums.EstatusSesion.Recuperacion_Automatica
});
const actualizarCliente = toRefs(reactiveActualizarCliente);
const ubicacion = ref({} as Core.Models.UbicacionLocal);
const formId = componentId + "form";
const Colonias = ref([] as Cliente.Cliente.ObtenerColoniasBody[]);
const Colonia = ref({} as Cliente.Cliente.ObtenerColoniasBody);
const telParticular = ref(reactive({} as Core.Models.PuntoContacto));
const celParticular = ref(reactive({} as Core.Models.PuntoContacto));
const emailParticular = ref({} as Core.Models.PuntoContacto);
const telOficina = ref({} as Core.Models.PuntoContacto);
const email2 = ref({} as Core.Models.PuntoContacto);
const archivo = ref(reactive(null as any));
const fileName = ref(reactive("" as String));
const puedeActualizarInformacion = ref(false);
const puedeEditarInformacion = ref(false);
const CodigoPostal = ref("");
const Paises = ref([] as Core.Models.Pais[]);
const LadaPais = ref(154);
const NombreArchivoCargado = ref([] as String[]);
const flag = ref(true);
//#endregion
//#region WATCH
watch(CodigoPostal, async (val: any) => {
  ubicacion.value.codigoPostalNombre = val;
  Colonias.value = (
    await api.Cliente.Cliente.EscrituracionApi.ObtenerColonias(ubicacion.value.codigoPostalNombre!)
  ).data;
  ubicacion.value.ciudadNombre = (Colonias.value[0] || {}).ciudad;
  ubicacion.value.estadoNombre = (Colonias.value[0] || {}).estado;
  Colonia.value = Colonias.value[0] || {};
});
watch(LadaPais, (val: number) => {});
//#region
//#region Methods
function editarInformacion() {
  puedeEditarInformacion.value = true;
}
async function actualizarPassword() {
  let valido = formDom.value!.checkValidity();
  formularioValidado.value = true;
  if (!valido) {
    $store.agregarToast({
      tipo: TipoBootstrap.DANGER,
      body: "Llenar todos los campos correctamente."
    });
    return;
  }
  const res2 = await api.Cliente.Cliente.HomeApi.ActualizarContrasenia("", toRaw(reactiveActualizarCliente));
  $store.agregarToast(res2.data);
}
async function actualizarDatosClientes() {
  let valido = formDom.value!.checkValidity();
  formularioValidado.value = true;
  if (!valido) {
    $store.agregarToast({
      tipo: TipoBootstrap.DANGER,
      body: "Llenar todos los campos correctamente."
    });
    return;
  }
  NombreArchivoCargado.value = [];
  let div = document.getElementById("spinner") as HTMLDivElement;
  div.style.display = "block";
  ubicacion.value.coloniaId = Colonia.value.coloniaId;
  ubicacion.value.coloniaNombre = Colonia.value.colonia;
  ubicacion.value.estadoNombre = Colonia.value.estado;
  ubicacion.value.codigoPostalNombre = Colonia.value.codigoPostal;
  if (telParticular.value.valor === null) telParticular.value.valor = undefined;
  if (celParticular.value.valor === null) celParticular.value.valor = undefined;
  let rep = await api.Cliente.Cliente.HomeApi.ActualizarDatosCliente(
    telParticular.value.valor || "",
    celParticular.value.valor || "",
    emailParticular.value.valor || "",
    ubicacion.value.calle || "",
    ubicacion.value.numero || "",
    ubicacion.value.codigoPostalNombre || "",
    LadaPais.value,
    ubicacion.value.coloniaId as number
  );
  $store.agregarToast(rep.data);
  puedeEditarInformacion.value = false;
  cargarDatosClientes();
  div.style.display = "none";
}
function ObtenerDocumento(path: string): string {
  let URL =
    (import.meta.env.BASE_URL || "").replace(/\/$/g, "") + api.Cliente.Cliente.HomeApi.VerDocumentoUrl + "?ruta=";
  return URL + path;
}
async function cargarIdentificacionOficial(event: any) {
  archivo.value = event.target.files[0];
  let div = document.getElementById("spinner") as HTMLDivElement;
  div.style.display = "block";
  let resp = null;
  for (let i = 0; i < event.target.files.length; i++) {
    resp = await api.Cliente.Cliente.HomeApi.GuardarIdentificacion(event.target.files[i], flag.value);
    NombreArchivoCargado.value = resp.data.data as string[];
    $store.agregarToast(resp.data);
    flag.value = false;
  }
  div.style.display = "none";
}
async function cargarDatosClientes() {
  Paises.value = (await api.Cliente.Cliente.HomeApi.ListarPaises()).data;
  let res = (await api.Cliente.Cliente.HomeApi.ObtenerInformacionHome()).data;
  nombreCliente.value = res.nombreCompleto!;
  fechaCreacion.value = DateTime.fromISO(res.fechaCreacion.substring(0, 10)).toLocaleString({
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
  });
  ubicacion.value = res.ubicacion;
  telParticular.value = res.puntoContacto;
  celParticular.value = res.puntoContacto2;
  emailParticular.value = res.emailPersonal;
  beneficiarios.value = res.beneficiarios?.map(x => x.nombreCompleto) as string[];
  fileName.value = res.rutaIne!;
  puedeActualizarInformacion.value = res.puedeActualizarInformacion;
  puedeEditarInformacion.value = false;
  CodigoPostal.value = res.ubicacion.codigoPostalNombre!;
  flag.value = true;
  let div = document.querySelector("#spinner") as HTMLDivElement;
  div.style.display = "none";
}
//#endregion COMPUTED
const editarInformarPerfil = computed((): boolean => {
  return puedeEditarInformacion.value;
});
//#endregion
formularioValidado.value = true;
let res = (await api.Cliente.Cliente.HomeApi.ObtenerInformacionHome()).data;
nombreCliente.value = res.nombreCompleto!;
fechaCreacion.value = DateTime.fromISO(res.fechaCreacion.substring(0, 10)).toLocaleString({
  year: "numeric",
  month: "2-digit",
  day: "2-digit"
});
Paises.value = (await api.Cliente.Cliente.HomeApi.ListarPaises()).data;
ubicacion.value = res.ubicacion;
ubicacion.value.pais ??= {} as Core.Models.Pais;
telParticular.value = res.puntoContacto;
celParticular.value = res.puntoContacto2;
beneficiarios.value = res.beneficiarios?.map(x => x.nombreCompleto) as string[];
fileName.value = res.rutaIne!;
puedeActualizarInformacion.value = res.puedeActualizarInformacion;
puedeEditarInformacion.value = false;
CodigoPostal.value = res.ubicacion.codigoPostalNombre!;
flag.value = true;
</script>
