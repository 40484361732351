<template lang="pug">
.container.pt-2: .row: .col.ff-poppins
  h1.fw-bold Sitio en mantenimiento, intenta de nuevo en unos minutos...
</template>

<script lang="ts" setup>
import api from "@/servicios/apiCliente";
import { useRouter } from "vue-router";
const $router = useRouter();

let intervalo = undefined as undefined | number;

//#region Methods
async function probar() {
  try {
    await api.Cliente.Cliente.HomeApi.Ping();
    clearInterval(intervalo);
    $router.push({ name: "cliente-cliente-home" });
  } catch (_) {
    /* Verificación que ya esté arriba el sitio */
  }
}
//#endregion

intervalo = window.setInterval(() => probar(), 5000);
</script>
