<template lang="pug">
.carousel.slide.d-inline-block(data-ride="carousel" style="width: 100%; height: 300px; max-width: 100%" :id="componentId + 'banners'")
  .carousel-indicators(v-if="Imaganes.length > 1")
    button.active(
      v-for="(item, i) in Imaganes"
      type="button"
      :class="{ active: i == 0 }"
      :data-bs-target="'#' + componentId + 'banners'"
      :data-bs-slide-to="i"
    )
  .carousel-inner: .carousel-item(v-for="(item, i) in Imaganes" :class="{ active: i == 0 }")
    img.d-block.w-100.img-rounded(v-bind:src="item.toString()" width="600" height="300")
  template(v-if="Imaganes.length > 1")
    button.carousel-control-prev(type="button" :data-bs-target="'#' + componentId + 'banners'" data-bs-slide="prev")
      span.carousel-control-prev-icon
      span.visually-hidden Anterior
    button.carousel-control-next(type="button" :data-bs-target="'#' + componentId + 'banners'" data-bs-slide="next")
      span.carousel-control-next-icon
      span.visually-hidden Siguiente
</template>
<script lang="ts" setup>
import { ref, onMounted } from "vue";
import * as utilidades from "@/utilidades";
const props = defineProps({
  ImagenesProp: { type: Array, default: null }
});
const Imaganes = ref([] as string[]);
const componentId = utilidades.generarId();
onMounted(() => {
  Imaganes.value = props.ImagenesProp as string[];
});
</script>
