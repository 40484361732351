import { createApp } from "vue";
import router from "@/router/index";
import App from "./App.vue";
import HirSelect2 from "@/components/HirSelect2.vue";
import HirToast from "@/components/HirToast.vue";
import { useStore } from "@/store";
import { TipoBootstrap } from "./interfaces";
import { dom, library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { createPinia } from "pinia";
import "./registerServiceWorker";
import "@/sass/bootstrap-variables.sass";
import "bootstrap";
import type { Respuesta } from "./servicios/api";
import maska from "maska";
// Add all icons to the library so you can use it in your page
library.add(fas, far, fab);

gtag("config", import.meta.env.VITE_GA_ID, { send_page_view: false, transport_type: "beacon" });

const pinia = createPinia();
const app = createApp(App);
app.use(pinia);
app.use(router());
app.use(maska);

app.component("HirSelect2", HirSelect2);
app.component("HirToast", HirToast);

const reg = /^\s*at HirCasa/;

const store = useStore();

//eslint-disable-next-line
app.config.errorHandler = function (err: unknown, _vm, _info) {
  const resp = (err as any)?.response?.data as Respuesta;
  if (resp) {
    store.agregarToast({
      tipo: TipoBootstrap.DANGER,
      body: "<b>" + resp.mensaje + "</b><br>" + (resp.data as string).split("\r\n").filter(m => reg.test(m))
    });
    return;
  }
  const status = (err as any)?.response?.status as number;
  if (status == 401) {
    store.agregarToast({
      tipo: TipoBootstrap.DANGER,
      body: "<b>Usuario no identificado</b><br>" + (err as any).config.url
    });
    return;
  }
  if (status == 403) {
    store.agregarToast({
      tipo: TipoBootstrap.DANGER,
      body: "<b>Error de permisos</b><br>" + (err as any).config.url
    });
    return;
  }
  if (status >= 400 && status < 500) {
    store.agregarToast({
      tipo: TipoBootstrap.DANGER,
      body: "<b>" + (err as Error).message + "</b><br>" + (err as any).config.url
    });
    return;
  }
  store.agregarToast({
    tipo: TipoBootstrap.DANGER,
    body: (err as Error).message + "<br>" + (err as Error).stack?.replace(/</g, "&lt;").replace(/>/g, "&gt;")
  });
  if (import.meta.env.NODE_ENV != "production") console.log(err);
};

dom.watch();

app.mount("#app");
