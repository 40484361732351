import type { TipoBootstrap } from "@/interfaces";
import type { EstadoRespuesta, Respuesta } from "@/servicios/api";
import { defineStore } from "pinia";

export interface Toast {
  tipo: EstadoRespuesta | TipoBootstrap;
  body: string;
  delay?: number;
  id?: number;
}

export const useStore = defineStore("base", {
  state: () => ({
    toasts: [] as Toast[]
  }),
  actions: {
    agregarToast(toast: Toast | Respuesta) {
      if ("estado" in toast)
        toast = {
          body: toast.mensaje,
          tipo: toast.estado
        } as Toast;
      toast.id = Math.random();
      if (!toast.delay) toast.delay = 5000;
      this.toasts.push(toast);
    },
    removerToast(toastId: number) {
      const i = this.toasts.findIndex(t => t.id == toastId);
      if (i >= 0) this.toasts.splice(i, 1);
    }
  }
});
