import type VueRouter from "vue-router";
import AppAreaCliente from "../views/App.vue";
import EstudioSocioeconomico from "../views/EstudioSocioeconomico.vue";
import ObligadoSolidario from "../views/ObligadoSolidario.vue";
import Perfil from "../views/CCPerfil.vue";
import Login from "../views/CCLogin.vue";
import SeguroVida from "../views/SeguroVida.vue";
import Crear from "../views/CrearUsuario.vue";
import Recuperacion from "../views/CCRecuperacion.vue";
import RecuperacionCorreo from "../views/RecuperacionCorreo.vue";
import CCHome from "../views/CCHome.vue";
import CCFinanciamiento from "../views/CCFinanciamiento.vue";
import Adjudicaciones from "../views/CCAdjudicaciones.vue";
import Escrituracion from "../views/CCEscrituracion.vue";
import MaterialesApoyo from "../views/MaterialesApoyo.vue";
import AppOffline from "../views/AppOffline.vue";
import api from "@/servicios/apiCliente";
import ProgramaRecicla from "../views/ProgramaReclica.vue";
import Adjudiacion from "../views/MAAdjudicacion.vue";
import Liquidacion from "../views/MALiquidacion.vue";
import Titulacion from "../views/MATitulacion.vue";
import Seguros from "../views/MASeguros.vue";
import Bienvenida from "../views/MABienvenida.vue";
import Tramites from "../views/MATramites.vue";
import Liberacion from "../views/MALiberacion.vue";
import Preventa from "../views/MAPreventas.vue";
import Pagos from "../views/MAPagos.vue";
import SeguroDanios from "../views/SeguroDanios.vue";
import Siniestro from "../views/CCSiniestro.vue";
import Facturacion from "../views/CCFacturacion.vue";
import Domiciliacion from "../views/CCDomiciliacion.vue";
import ReporteSeguroVida from "../views/ReporteSeguroVida.vue";
import Guia from "../views/CCGuia.vue";
import Venta from "../views/MAVenta.vue";
import { useStore } from "../store";
import EstudioSocioeconomico2 from "../views/EstudioSocioeconomico/EstudioSocioeconomico2.vue";
import DatosGenerales from "../components/DatosGenerales.vue";
import Ingresos from "../components/Ingresos.vue";
import Gastos from "../components/Gastos.vue";
import Vendedor from "../components/Vendedor.vue";
import Patrimonio from "../components/Patrimonio.vue";
import ObservacionesGenerales from "../components/ObservacionesGenerales.vue";
import UsoFinanciamiento from "../components/UsoFinanciamiento.vue";
import FormularioLayout from "../layout/FormularioLayout.vue";
import ChecklistDocumentos from "../views/EstudioSocioeconomico/ChecklistDocumentos.vue";
import AnalisisAes from "../components/AnalisisAes.vue";
import FirmaAes from "../components/FirmaAes.vue";

export default () => {
  const urlExperiencia =  'https://experiencia.hircasa.com.mx/';
  const store = useStore();

  const guard = async (_to: any, _from: any, next: any) => {
    const res = (await api.Cliente.Cliente.HomeApi.Ping()).data;
    if (res != null) {
      store.sesionIniciada = true;
      store.clienteAceptaTerminosCondicionesAvisoPrivacidad = res.aceptaTerminosCondicionesAvisoPrivacidad ?? false;
      next();
    } else {
      next({ name: "cliente-cliente-login" });
    }
  };

  // Se extenderá
  const rutas: VueRouter.RouteRecordRaw[] = [
    {
      path: "",
      component: AppAreaCliente,
      children: [
        {
          path: "siniestro",
          component: Siniestro,
          name: "cliente-cliente-siniestro"
        },
        {
          // Se puede acceder sin sesión
          path: "estudio-socioeconomico-old",
          component: EstudioSocioeconomico
        },
        {
          // Se puede acceder sin sesión
          path: "figura-apoyo",
          component: ObligadoSolidario
        },
        {
          // Se puede acceder sin sesión
          path: "escrituracion",
          component: Escrituracion,
          name: "cliente-cliente-escrituracion"
        },
        {
          path: "siniestro-generales",
          component: Siniestro,
          name: "cliente-cliente-siniestro-generales",
          beforeEnter: guard
        },
        {
          // Se puede acceder desde Vista Materia de Apoyo
          path: "material-apoyo-adjudicacion",
          component: Adjudiacion,
          name: "cliente-cliente-material-apoyo-adjudicacion",
          beforeEnter: (to, from, next) => {
            window.open( urlExperiencia + 'adjudicacion-2/')
            next(false)
          }
        },
        {
          // Acceso sin sesion
          path: "material-apoyo-adjudicacion-flex",
          component: Adjudiacion,
          name: "cliente-cliente-material-apoyo-adjudicacion-flex",
          beforeEnter: (to, from, next) => {
            window.open( urlExperiencia + 'adjudicacion-flex/')
            next(false)
          }
        },
        {
          // Se puede acceder desde Vista Materia de Apoyo
          path: "material-apoyo-avaluos",
          component: Adjudiacion,
          name: "cliente-cliente-material-apoyo-avaluos",
          beforeEnter: (to, from, next) => {
            window.open( urlExperiencia + 'avaluos/')
            next(false)
          }
        },
        {
          // Acceso sin sesion
          path: "material-apoyo-avaluos-flex",
          component: Adjudiacion,
          name: "cliente-cliente-material-apoyo-avaluos-flex",
          beforeEnter: (to, from, next) => {
            window.open( urlExperiencia + 'avaluos-flex/')
            next(false)
          }
        },
        {
          // Se puede acceder desde Vista Materia de Apoyo
          path: "material-apoyo-remanentes",
          component: Adjudiacion,
          name: "cliente-cliente-material-apoyo-remanentes",
          beforeEnter: (to, from, next) => {
            window.open( urlExperiencia + 'remanentes-de-monto-adjudicado/')
            next(false)
          }
        },
        {
          // Acceso sin sesion
          path: "material-apoyo-remanentes-flex",
          component: Adjudiacion,
          name: "cliente-cliente-material-apoyo-remanentes-flex",
          beforeEnter: (to, from, next) => {
            window.open( urlExperiencia + 'remanentes-de-monto-adjudicado-flex/')
            next(false)
          }
        },
        {
          // Se puede acceder desde Vista Materia de Apoyo
          path: "material-apoyo-bienvenida",
          component: Bienvenida,
          name: "cliente-cliente-material-apoyo-bienvenida",
          beforeEnter: (to, from, next) => {
            window.open( urlExperiencia + 'bienvenida-bienvenido/')
            next(false)
          }
        },
        {
          // Se puede acceder desde Vista Materia de Apoyo
          path: "material-apoyo-bienvenida-flex",
          component: Bienvenida,
          name: "cliente-cliente-material-apoyo-bienvenida-flex",
          beforeEnter: (to, from, next) => {
            window.open( urlExperiencia + 'bienvenida-bienvenido-flex/')
            next(false)
          }
        },
        {
          // Se puede acceder desde Vista Materia de Apoyo
          path: "material-apoyo-factor-actualizacion",
          component: Bienvenida,
          name: "cliente-cliente-material-apoyo-factor-actualizacion",
          beforeEnter: (to, from, next) => {
            window.open( urlExperiencia + 'factor-de-actualizacion/ ')
            next(false)
          }
        },
        {
          // Se puede acceder desde Vista Materia de Apoyo
          path: "material-apoyo-factor-actualizacion-flex",
          component: Bienvenida,
          name: "cliente-cliente-material-apoyo-factor-actualizacion-flex",
          beforeEnter: (to, from, next) => {
            window.open( urlExperiencia + 'factor-de-actualizacion-flex/')
            next(false)
          }
        },
        {
          // Acceso sin Sesion
          path: "material-apoyo-bienvenida-portal",
          component: Bienvenida,
          name: "cliente-cliente-material-apoyo-bienvenida-portal"
        },
        {
          // Se puede acceder desde Vista Materia de Apoyo
          path: "material-apoyo-tramites",
          component: Tramites,
          name: "cliente-cliente-material-apoyo-tramites",
          beforeEnter: (to, from, next) => {
            window.open( urlExperiencia + 'tramites/')
            next(false)
          }
        },
        {
          // Acceso sin Sesion
          path: "material-apoyo-tramites-flex",
          component: Tramites,
          name: "cliente-cliente-material-apoyo-tramites-flex",
          beforeEnter: (to, from, next) => {
            window.open( urlExperiencia + 'tramites-flex/')
            next(false)
          }
        },
        {
          // Se puede acceder desde Vista Materia de Apoyo
          path: "material-apoyo-aclaraciones",
          component: Tramites,
          name: "cliente-cliente-material-apoyo-aclaraciones",
          beforeEnter: (to, from, next) => {
            window.open( urlExperiencia + 'aclaraciones/')
            next(false)
          }
        },
        {
          // Acceso sin Sesion
          path: "material-apoyo-aclaraciones-flex",
          component: Tramites,
          name: "cliente-cliente-material-apoyo-aclaraciones-flex",
          beforeEnter: (to, from, next) => {
            window.open( urlExperiencia + 'aclaraciones-flex/')
            next(false)
          }
        },
        {
          // Se puede acceder desde Vista Materia de Apoyo
          path: "material-apoyo-liberacion",
          component: Liberacion,
          name: "cliente-cliente-material-apoyo-liberacion",
          beforeEnter: (to, from, next) => {
            window.open( urlExperiencia + 'liberacion/')
            next(false)
          }
        },
        {
          // Acceso sin sesion
          path: "material-apoyo-liberacion-flex",
          component: Liberacion,
          name: "cliente-cliente-material-apoyo-liberacion-flex",
          beforeEnter: (to, from, next) => {
            window.open( urlExperiencia + 'liberacion-flex/')
            next(false)
          }
        },
        {
          // Se puede acceder desde Vista Materia de Apoyo
          path: "material-apoyo-preventas",
          component: Preventa,
          name: "cliente-cliente-material-apoyo-preventas",
          beforeEnter: (to, from, next) => {
            window.open( urlExperiencia + 'preventa/')
            next(false)
          }
        },
        {
          // Acceso sin Sesion
          path: "material-apoyo-preventas-flex",
          component: Preventa,
          name: "cliente-cliente-material-apoyo-preventas-flex",
          beforeEnter: (to, from, next) => {
            window.open( urlExperiencia + 'preventa-flex/')
            next(false)
          }
        },
        {
          // Se puede acceder desde Vista Materia de Apoyo
          path: "material-apoyo-pagos",
          component: Pagos,
          name: "cliente-cliente-material-apoyo-pagos",
          beforeEnter: (to, from, next) => {
            window.open( urlExperiencia + 'pagos-2/')
            next(false)
          }
        },
        {
          // Acceso sin Sesion
          path: "material-apoyo-pagos-flex",
          component: Pagos,
          name: "cliente-cliente-material-apoyo-pagos-flex",
          beforeEnter: (to, from, next) => {
            window.open( urlExperiencia + 'pagos-flex/ ')
            next(false)
          }
        },
        {
          // Se puede acceder desde Vista Materia de Apoyo
          path: "material-apoyo-descuentos",
          component: Pagos,
          name: "cliente-cliente-material-apoyo-descuentos",
          beforeEnter: (to, from, next) => {
            window.open( urlExperiencia + 'descuentos/')
            next(false)
          }
        },
        {
          // Acceso sin Sesion
          path: "material-apoyo-descuentos-flex",
          component: Pagos,
          name: "cliente-cliente-material-apoyo-descuentos-flex",
          beforeEnter: (to, from, next) => {
            window.open( urlExperiencia + 'descuentos-flex/')
            next(false)
          }
        },
        {
          // Se puede acceder desde Vista Materia de Apoyo
          path: "material-apoyo-domiciliacion",
          component: Pagos,
          name: "cliente-cliente-material-apoyo-domiciliacion",
          beforeEnter: (to, from, next) => {
            window.open( urlExperiencia + 'domiciliacion/')
            next(false)
          }
        },
        {
          // Acceso sin Sesion
          path: "material-apoyo-domiciliacion-flex",
          component: Pagos,
          name: "cliente-cliente-material-apoyo-domiciliacion-flex",
          beforeEnter: (to, from, next) => {
            window.open( urlExperiencia + 'domiciliacion-flex/')
            next(false)
          }
        },
        {
          // Se puede acceder desde Vista Materia de Apoyo
          path: "material-apoyo-Liquidacion",
          component: Liquidacion,
          name: "cliente-cliente-material-apoyo-liquidacion",
          beforeEnter: (to, from, next) => {
            window.open( urlExperiencia + 'liquidacion/')
            next(false)
          }
        },
        {
          // Acceso sin Sesion
          path: "material-apoyo-Liquidacion-flex",
          component: Liquidacion,
          name: "cliente-cliente-material-apoyo-liquidacion-flex",
          beforeEnter: (to, from, next) => {
            window.open( urlExperiencia + 'liquidacion-flex/')
            next(false)
          }
        },
        {
          // Se puede acceder desde Vista Materia de Apoyo
          path: "material-apoyo-titulacion",
          component: Titulacion,
          name: "cliente-cliente-material-apoyo-titulacion",
          beforeEnter: (to, from, next) => {
            window.open( urlExperiencia + 'titulacion/')
            next(false)
          }
        },
        {
          // Se puede acceder desde Vista Materia de Apoyo
          path: "material-apoyo-titulacion-flex",
          component: Titulacion,
          name: "cliente-cliente-material-apoyo-titulacion-flex",
          beforeEnter: (to, from, next) => {
            window.open( urlExperiencia + 'titulacion-flex/')
            next(false)
          }
        },
        {
          // Se puede acceder desde Vista Materia de Apoyo
          path: "material-apoyo-guia",
          component: Titulacion,
          name: "cliente-cliente-material-apoyo-guia",
          beforeEnter: (to, from, next) => {
            window.open( urlExperiencia + 'guia-de-adjudicacion/')
            next(false)
          }
        },
        {
          // Se puede acceder desde Vista Materia de Apoyo
          path: "material-apoyo-guia-flex",
          component: Titulacion,
          name: "cliente-cliente-material-apoyo-guia-flex",
          beforeEnter: (to, from, next) => {
            window.open( urlExperiencia + 'guiaadjudicacion-flex/')
            next(false)
          }
        },
        {
          // Se puede acceder desde Vista Materia de Apoyo
          path: "material-apoyo-avaluo",
          component: Titulacion,
          name: "cliente-cliente-material-apoyo-avaluo",
          beforeEnter: (to, from, next) => {
            window.open( urlExperiencia + 'bienvenida-bienvenido-flex/')
            next(false)
          }
        },
        {
          // Se puede acceder desde Vista Materia de Apoyo
          path: "material-apoyo-avaluo-flex",
          component: Titulacion,
          name: "cliente-cliente-material-apoyo-avaluo-flex",
          beforeEnter: (to, from, next) => {
            window.open( urlExperiencia + 'bienvenida-bienvenido-flex/')
            next(false)
          }
        },
        {
          // Se puede acceder desde Vista Materia de Apoyo
          path: "material-apoyo-firma",
          component: Titulacion,
          name: "cliente-cliente-material-apoyo-firma",
          beforeEnter: (to, from, next) => {
            window.open( urlExperiencia + 'firma-de-escritura/')
            next(false)
          }
        },
        {
          // Se puede acceder desde Vista Materia de Apoyo
          path: "material-apoyo-firma-flex",
          component: Titulacion,
          name: "cliente-cliente-material-apoyo-firma-flex",
          beforeEnter: (to, from, next) => {
            window.open( urlExperiencia + 'firma-de-escritura-flex/')
            next(false)
          }
        },
        {
          // Se puede acceder desde Vista Materia de Apoyo
          path: "material-apoyo-seguros",
          component: Seguros,
          name: "cliente-cliente-material-apoyo-seguros",
          beforeEnter: (to, from, next) => {
            window.open( urlExperiencia + 'seguros/')
            next(false)
          }
        },
        {
          // Acceso sin sesion
          path: "material-apoyo-seguros-flex",
          component: Seguros,
          name: "cliente-cliente-material-apoyo-seguros-flex",
          beforeEnter: (to, from, next) => {
            window.open( urlExperiencia + 'seguros-flex/')
            next(false)
          }
        },
        {
          // Se puede acceder desde Vista Materia de Apoyo
          path: "material-apoyo-contacto",
          component: Seguros,
          name: "cliente-cliente-material-apoyo-contacto",
          beforeEnter: (to, from, next) => {
            window.open( urlExperiencia + 'medios-de-contacto/')
            next(false)
          }
        },
        {
          // Acceso sin sesion
          path: "material-apoyo-contacto-flex",
          component: Seguros,
          name: "cliente-cliente-material-apoyo-contacto-flex",
          beforeEnter: (to, from, next) => {
            window.open( urlExperiencia + 'medios-de-contacto-flex/')
            next(false)
          }
        },
        {
          // Se puede acceder desde Vista Materia de Apoyo
          path: "material-apoyo-venta",
          component: Venta,
          name: "cliente-cliente-material-apoyo-venta",
          beforeEnter: (to, from, next) => {
            window.open( urlExperiencia + 'tips-para-tu-vendedor/')
            next(false)
          }
        },
        {
          // Acceso sin Sesion
          path: "material-apoyo-venta-flex",
          component: Venta,
          name: "cliente-cliente-material-apoyo-venta-flex",
          beforeEnter: (to, from, next) => {
            window.open( urlExperiencia + 'tips-flex/')
            next(false)
          }
        },
        {
          // Se puede acceder desde el modal
          path: "programa-recicla",
          component: ProgramaRecicla,
          name: "cliente-cliente-programa-recicla",
          beforeEnter: guard
        },
        {
          path: "financiamiento",
          component: CCFinanciamiento,
          name: "cliente-cliente-financiamiento",
          beforeEnter: guard
        },
        {
          path: "perfil",
          component: Perfil,
          name: "cliente-cliente-perfil",
          beforeEnter: guard
        },
        {
          path: "login",
          component: Login,
          name: "cliente-cliente-login"
        },
        {
          path: "usuario/crear",
          component: Crear,
          name: "cliente-cliente-usuario-crear"
        },
        {
          path: "usuario/recuperacion",
          component: Recuperacion,
          name: "cliente-cliente-usuario-recuperacion"
        },
        {
          path: "usuario/recuperacion-correo",
          component: RecuperacionCorreo,
          name: "cliente-cliente-usuario-recuperacion-2"
        },
        {
          // Solo con Sesion
          path: "home",
          component: CCHome,
          name: "cliente-cliente-home",
          beforeEnter: guard
        },
        {
          // Solo con Sesion
          path: "adjudicaciones",
          component: Adjudicaciones,
          name: "cliente-cliente-adjudicaciones",
          beforeEnter: guard
        },
        {
          // Solo con Sesion
          path: "estudio-socioeconomico",
          component: EstudioSocioeconomico2,
          name: "cliente-cliente-aes",
          redirect: "estudio-socioeconomico/titular/0/datos-generales",

          children: [
            {
              path: ":figura/:numero",
              component: FormularioLayout,
              name: "figura-layout",
              props: true,
              children: [
                {
                  path: "contenido-checklist",
                  component: ChecklistDocumentos,
                  name: "contenido-checklist",
                  props: true
                },
                {
                  path: "datos-generales",
                  component: DatosGenerales,
                  name: "datos-generales",
                  props: true
                },
                {
                  path: "analisis-aes",
                  component: AnalisisAes,
                  name: "analisis-aes",
                  props: true
                },
                {
                  path: "firma-aes",
                  component: FirmaAes,
                  name: "firma-aes",
                  props: true
                },
                {
                  path: "ingresos",
                  component: Ingresos,
                  name: "ingresos",
                  props: true
                },
                {
                  path: "gastos",
                  component: Gastos,
                  name: "gastos",
                  props: true
                },
                {
                  path: "patrimonio",
                  component: Patrimonio,
                  name: "patrimonio",
                  props: true
                },
                {
                  path: "vendedor",
                  component: Vendedor,
                  name: "vendedor",
                  props: true
                },

                {
                  path: "observaciones",
                  component: ObservacionesGenerales,
                  name: "observaciones",
                  props: true
                },
                {
                  path: "uso-financiamiento",
                  component: UsoFinanciamiento,
                  name: "uso-financiamiento",
                  props: true
                }
              ]
            }
          ]
        },
        {
          // Solo con Sesion
          path: "material-apoyo",
          component: MaterialesApoyo,
          name: "cliente-cliente-materiales-apoyo",
          beforeEnter: guard
        },
        {
          // cceso sin sesion
          path: "material-apoyo-portal",
          component: MaterialesApoyo,
          name: "cliente-cliente-materiales-apoyo-portal"
        },
        {
          // Acceso sin sesion
          path: "reporte-seguro-vida",
          component: ReporteSeguroVida,
          name: "cliente-cliente-reporte-seguro-vida"
        },
        {
          // Acceso sin Sesion
          path: "guia",
          component: Guia,
          name: "cliente-cliente-material-apoyo-guia",
          beforeEnter: (to, from, next) => {
            window.open( urlExperiencia + 'guia-de-adjudicacion/')
            next(false)
          }
        },
        {
          // Acceso sin Sesion
          path: "guia-flex",
          component: Guia,
          name: "cliente-cliente-material-apoyo-guia-flex",
          beforeEnter: (to, from, next) => {
            window.open( urlExperiencia + 'guiaadjudicacion-flex/')
            next(false)
          }
        },
        {
          // Solo con Sesion
          path: "seguro-vida",
          component: SeguroVida,
          name: "cliente-cliente-seguro-vida",
          beforeEnter: guard
        },
        {
          // Solo con Sesion
          path: "seguro-danios",
          component: SeguroDanios,
          name: "cliente-cliente-seguro-danios",
          beforeEnter: guard
        },
        {
          // Solo con Sesion
          path: "facturacion",
          component: Facturacion,
          name: "cliente-cliente-facturacion",
          beforeEnter: guard
        },
        {
          // Solo con Sesion
          path: "domiciliacion",
          component: Domiciliacion,
          name: "cliente-cliente-domiciliacion",
          beforeEnter: guard
        },
        {
          path: "app-offline",
          component: AppOffline,
          name: "app-offline"
        },
        {
          path: "",
          redirect: "home"
        },
        {
          path: ":pathMatch(.*)*",
          redirect: "home"
        }
      ]
    }
  ];

  return rutas;
};

