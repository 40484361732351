<template lang="pug">
#codigoModal.modal.fade(tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true")
  .modal-dialog.modal-dialog-centered.mw-80.w-75(role="document")
    .modal-content
      .modal-body
        .row
          .col-md-12: h4.m-2.font-weight-bold(style="font-size: 15px; text-align: right") 
            button.btn-close(aria-label="Close" type="button" v-on:click="cerrarModalCodigo()")
        .div(style="margin: 10px 35px 30px 35px")
          .row
            .col-md-12: h4.m-2.font-weight-bold
              h4 Ingresa el código que hemos enviado al {{ medioContacto.valor }}
        .div(style="margin: 0px 35px 40px 35px")
          .row
            input.form-control(v-model="code6" type="text" pattern="[0-9]{6}" maxlength="6" required)
        .div(style="margin: 10px 60px 40px 60px")
          .div(style="text-align: center")
            h6(v-show="validador == false" style="color: red") Código inválido. Intente de nuevo.
          .div(style="margin: 40px 0px 0px 0px; text-align: center")
            h4 ¿No recibiste el código?
            a.btn.btn-primary.text-white(type="button" v-on:click="mostrarModalCodigo()") Enviar nuevamente
#mediosContactoModal.modal.fade(tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true")
  .modal-dialog.modal-dialog-centered.mh-100(role="document")
    .modal-content
      .modal-body
        .row
          .col-md-12: h4.m-2.font-weight-bold(style="font-size: 15px; text-align: right") 
            button.btn-close(aria-label="Close" type="button" v-on:click="cerrarModalContactos()")
        .div(style="margin: 10px 35px 40px 35px")
          .row
            .col-md-12: h4.m-2.font-weight-bold
              h4(style="margin: 10px 0px 6px 0px") Para validar tu identidad te enviaremos un código de verificación.
              h4(style="margin: 10px 0px 6px 0px") ¿Por cuál medio prefieres recibirlo?
          .row(style="font-size: 15px")
            .col
              .div.d-flex.justify-content-start.flex-column.align-items-start
                .div(style="margin: 30px 0px 0px 0px")
                  .row: template(v-for="(medio, index) in props.MediosContactos" :key="index")
                    .col-md-12
                      input.form-check-input(type="radio" :name="'medioContacto' + index" :id="'medioContacto' + index" :value="medio" v-model="medioContacto")
                      label.form-check-label.ms-2(:for="'medioContacto' + index") {{ medio.valor }}
          .col
            .div(style="margin: 40px 0px 0px 0px; text-align: center")
              button.btn.btn-primary.text-white(v-on:click="mostrarModalCodigo()" v-if="medioContacto.valor != null") Confirmar
</template>
<script lang="ts" setup>
import * as bootstrap from "bootstrap";
import { ref, onMounted, watch } from "vue";
import api from "@/servicios/apiCliente";
import { Cliente, EstadoRespuesta } from "@/servicios/api";
interface MediosContacto {
  valor: string;
  tipo: number;
}
const medioContacto = ref({} as MediosContacto);
const code6 = ref("");
const validador = ref(true);
const emit = defineEmits<{
  (e: "pinvalidado", validacion: boolean): void;
}>();
//#endregion Props
const props = defineProps({
  MediosContactos: { type: Object as () => MediosContacto[], default: null }
});
//#endregion
watch(code6, async () => {
  var reg = /^(\d)*$/;
  if (!reg.test(code6.value)) code6.value = code6.value.substring(0, code6.value.length - 1);
  validador.value = true;
  console.log(code6.value);
  if (code6.value.length == 6) {
    var resp = await api.Cliente.Cliente.HomeApi.ValidarPIN(
      medioContacto.value.valor,
      medioContacto.value.tipo,
      code6.value
    );
    if (resp.data.estado == EstadoRespuesta.OK) cerrarModalCodigo();
    code6.value = "";
    validarPin(resp.data.estado == EstadoRespuesta.OK);
  }
});
//#endregion Methods
function validarPin(value: boolean) {
  emit("pinvalidado", value);
}
function cerrarModalContactos() {
  bootstrap.Modal.getOrCreateInstance(document.querySelector("#mediosContactoModal") as HTMLDivElement)?.hide();
}
async function mostrarModalCodigo() {
  bootstrap.Modal.getOrCreateInstance(document.querySelector("#mediosContactoModal") as HTMLDivElement)?.hide();
  let data = {} as Cliente.Cliente.Home.GenerarPINBody;
  data.medioContacto = medioContacto.value.tipo!;
  data.valor = medioContacto.value.valor!;
  data.ubicacion = "0,0";
  await api.Cliente.Cliente.HomeApi.GenerarPIN(data);
  bootstrap.Modal.getOrCreateInstance(document.querySelector("#codigoModal") as HTMLDivElement)?.show();
}
function cerrarModalCodigo() {
  bootstrap.Modal.getOrCreateInstance(document.querySelector("#codigoModal") as HTMLDivElement)?.hide();
}
//#endregion
onMounted(() => {
  medioContacto.value = {} as MediosContacto;
});
</script>
