<template lang="pug">
div
  .container.py-4
    .row
      .col-md-6.offset-md-3.bg-white.rounded-pill.shadow.ProgressTimeLine
        c-c-timeline(:items="timeline.items.value" :progress="timeline.progress.value")
      .col-md-6.offset-md-3.bg-white.shadow.ProgressCircular
        .row.text-center
          .col-3.d-flex.justify-content-start
            Progress.ProgressCircular(:radius="40" :strokeWidth="8" :value="barCircular.progreso.value" strokeColor="#EB8113")
              a(style="font-size: 14px") {{ barCircular.Etapa.value }} / 4
          .col-9.align-self-center
            p.text-left(style="font-size: 20px; font-weight: bold; color: #eb8113") {{ barCircular.Leyenda.value }}
    //-.row.mt-2(style="margin-top: -27px")
      .col-md-12
        .d-inline-block(
          style="background: #374066; color: #ffffff; padding: 15px 26px; border-top-left-radius: 11px; border-top-right-radius: 11px; margin-left: 26px"
        ) Escrituración
    .row.mt-2
      .col-md-12.shadow(style="border-radius: 8px; padding: 0")
        div(style="background: #eceff2; padding: 16px 26px; border-bottom: #95a7d6 1px solid")
          //- Integracion de Menu 
          nav.navbar.navbar-expand-lg.navbar-dark
            a.navbar-brand(
              style="background: #374066; color: #ffffff; padding: 8px 18px; border-top-left-radius: 11px; border-top-right-radius: 11px; margin-left: 26px"
            ) Escrituración
            button.navbar-toggler(
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            )
              span.fas.fa-bars(style="color: black")
            #navbarNav.collapse.navbar-collapse
              ul.navbar-nav.me-auto.mb-2.mb-lg-0
                li.nav-item
                  router-link.d-inline-block.pestania.text-decoration-none(:to="{ name: 'cliente-cliente-aes' }")
                    | Estudio Socioeconómico
                  span(v-bind:class="CheckProgreso" v-bind:style="CheckStyleProceso")
                    //-img(src="../assets/Interrogacion.svg")
                li.nav-item
                  a.d-inline-block.pestania.text-decoration-none(
                    href="#"
                    style="font-size: 14px"
                    v-if="(cliente.llavesObligadoSolidario.value || []).length + (cliente.llavesCotitular.value || []).length + (cliente.llavesGaranteHipotecario.value || []).length == 1"
                    @click.prevent="mostrarObligadoSolidario()"
                    :class="{ active: pestania == 'obligado-solidario' }"
                  )
                    | Figura Apoyo
                    //-| Obligado Solidario o Cotitular
                    //-img(src="../assets/Interrogacion.svg")
                  .dropdown.d-inline-block(
                    v-if="(cliente.llavesObligadoSolidario.value || []).length + (cliente.llavesCotitular.value || []).length + (cliente.llavesGaranteHipotecario.value || []).length > 1"
                  )
                    button.btn.dropdown-toggle.pestania.d-inline-block(data-bs-toggle="dropdown" style="font-size: 14px") Figuras Apoyo
                    span(v-bind:class="CheckProgreso" v-bind:style="CheckStyleProceso")
                    ul.dropdown-menu
                      li(v-for="(llave, i) in cliente.llavesObligadoSolidario.value")
                        a.dropdown-item(href="#" @click.prevent="mostrarObligadoSolidario(llave, 'Obligado Solidario ' + (i + 1))") Obligado Solidario {{ i + 1 }}
                      li(v-for="(llave, i) in cliente.llavesCotitular.value")
                        a.dropdown-item(href="#" @click.prevent="mostrarObligadoSolidario(llave, 'Cotitular ' + (i + 1))") Cotitular {{ i + 1 }}
                      li(v-for="(llave, i) in cliente.llavesGaranteHipotecario.value")
                        a.dropdown-item(href="#" @click.prevent="mostrarObligadoSolidario(llave, 'Garante Hipotecario ' + (i + 1))") Garante Hipotecario {{ i + 1 }}
                li.nav-item
                  a.d-inline-block.pestania.text-decoration-none(
                    href="#"
                    @click.prevent="pestania = 'autorizacion-consulta-buro-credito'"
                    :class="{ active: pestania == 'autorizacion-consulta-buro-credito' }"
                    style="font-size: 14px"
                  )
                    | Consulta de Buró de Crédito
                  span.fas.fa-question-circle(style="color: #eb8113")
                    //-img(src="../assets/Interrogacion.svg")
                //-li(class="nav-item")
                  .d-inline-block.pestania(style="font-size:15px;") 
                    | Asesoría
                    span.fas.fa-question-circle(style="color:#EB8113;")
                    //-img(src="../assets/Interrogacion.svg")
                li.nav-item
                  a.d-inline-block.pestania.text-decoration-none(
                    href="#"
                    style="font-size: 14px"
                    @click.prevent="pestania = 'checklist-documentos'"
                    :class="{ active: pestania == 'checklist-documentos' }"
                  )
                    | Checklist
                  span.fas.fa-question-circle(style="color: #eb8113")
                    //-img(src="../assets/Interrogacion.svg")
          //-Fin de Menu
        div
          estudio-socioeconomico(v-if="pestania == 'estudio-socioeconomico'" :mostrar-header-footer="false")
          obligado-solidario(
            v-if="pestania == 'obligado-solidario'"
            :mostrar-header-footer="false"
            :llave="cliente.llaveActual.value"
            :header="cliente.headerActual.value"
          )
          .p-4(v-if="pestania == 'autorizacion-consulta-buro-credito'")
            iframe.w-100.mb-4(
              :src="api.Cliente.Cliente.EscrituracionApi.ObtenerArchivoAutorizacionConsultaBuroCreditoUrl + '?id=' + $router.currentRoute.value.query['estudioId']"
              style="height: 400px"
              v-if="cliente.autorizacionConsultaBuroCredito.value"
            )
            .row
              .d-inline-block.rounded-pill.border.bg-white.p-3.col-md-4.offset-md-4(style="border-color: #94a3af")
                | Entregado
                input.form-check-input.float-end(type="radio" @click.prevent="" :checked="cliente.autorizacionConsultaBuroCredito.value")
          div(v-if="pestania == 'checklist-documentos'" style="padding: 30px")
            lista-documentos(:cliente="toRaw(reactiveCliente)")
            .mt-4.row
              .d-inline-block.rounded-pill.border.bg-white.p-3.col-md-4.offset-md-4.text-center(style="border-color: #94a3af; color: #94a3af")
                | Documentos Entregados&#32;
                span.fw-bold {{ clienteTotalDocumentos }}
                | &#32;de&#32;
                span.fw-bold {{ clienteDocumentosEntregados }}

// Modal
.modal.fade(
  :id="'modalEscrituracion-' + modalId"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
)
  .modal-dialog.modal-dialog-centered
    .modal-content
      .modal-body.text-secondary
        | Este módulo se ha actualizado a una nueva versión. ¡Favor de contactar a su asesor para mayor información!
      .modal-footer
        router-link(:to="{ name: 'cliente-cliente-home' }")
          button.btn.btn-primary.text-white(type="button" @click="cerrarModal") Inicio
</template>

<script lang="ts" setup>
import CCTimeline from "../components/CCTimeline.vue";
import EstudioSocioeconomico from "./EstudioSocioeconomico.vue";
import ObligadoSolidario from "./ObligadoSolidario.vue";
import api from "@/servicios/apiCliente";
import Progress from "@/components/EasyCircularProgress.vue";
import ListaDocumentos from "./EstudioSocioeconomico/ListaDocumentos.vue";
import type { Toast } from "@/store";
import { TipoBootstrap } from "@/interfaces";
import { reactive, toRefs, ref, computed, nextTick, toRaw } from "vue";
import { useStore } from "@/store";
import type { Core } from "@/servicios/api";
import type { ClienteDocumentos2 } from "../composables/EstudioSocioeconomico";
import { useRouter } from "vue-router";
import * as utilidades from "@/utilidades";
import { onMounted, onBeforeMount } from "vue";
import * as bootstrap from "bootstrap";

//#region Data
const $store = useStore();
const $router = useRouter();
const timeline = toRefs(
  reactive({
    items: ["Definición del proyecto", "Integración de Expediente", "Análisis", "Firma"],
    progress: 0
  })
);
const barCircular = toRefs(
  reactive({
    progreso: 25,
    Etapa: 1,
    Leyenda: "Definición del proyecto"
  })
);
const pestania = ref("checklist-documentos");
const reactiveCliente = reactive({
  nombreCompleto: "",
  estudioSocioeconomicoId: 0,
  estudioSocioeconomicoBloqueado: false,
  autorizacionConsultaBuroCredito: false,
  documentos: [] as Core.Models.EstudioSocioeconomicoDocumento[],
  documentosOS: [] as Core.Models.EstudioSocioeconomicoDocumento[][],
  documentosConyuge: [] as Core.Models.EstudioSocioeconomicoDocumento[],
  documentosCotitular: [] as Core.Models.EstudioSocioeconomicoDocumento[][],
  documentosVendedor: [] as Core.Models.EstudioSocioeconomicoDocumento[],
  documentosGaranteHipotecario: [] as Core.Models.EstudioSocioeconomicoDocumento[],
  documentosConyugeVendedor: [] as Core.Models.EstudioSocioeconomicoDocumento[],
  llavesObligadoSolidario: [] as string[],
  llavesCotitular: [] as string[],
  llavesGaranteHipotecario: [] as string[],
  llaveActual: "",
  headerActual: ""
} as ClienteDocumentos2);
const cliente = toRefs(reactiveCliente);
const modalId = utilidades.generarId();

//#endregion

//#region Computed
const clienteDocumentosEntregados = computed(
  (): number =>
    cliente.documentos.value.length +
    cliente.documentosConyuge.value.length +
    cliente.documentosVendedor.value.length +
    cliente.documentosGaranteHipotecario.value.length +
    cliente.documentosOS.value.flat().length +
    cliente.documentosCotitular.value.flat().length
);
const clienteTotalDocumentos = computed(
  (): number =>
    cliente.documentos.value.filter(e => e.guardado).length +
    cliente.documentosConyuge.value.filter(e => e.guardado).length +
    cliente.documentosVendedor.value.filter(e => e.guardado).length +
    cliente.documentosGaranteHipotecario.value.filter(e => e.guardado).length +
    cliente.documentosOS.value.flat().filter(e => e.guardado).length +
    cliente.documentosCotitular.value.flat().filter(e => e.guardado).length
);
const CheckProgreso = computed((): string => "fas fa-circle");
const CheckStyleProceso = computed((): string => (timeline.progress.value >= 33.33 ? "color:green;" : "color:red;"));
//#endregion

//#region Methods

function abrirModal() {
  const miModal = document.getElementById(`modalEscrituracion-${modalId}`);
  const modal = bootstrap.Modal.getInstance(miModal);
  if (modal) {
    modal?.show();
  } else {
    const nuevoModal = new bootstrap.Modal(miModal);
    nuevoModal?.show();
  }
}

function cerrarModal() {
  const miModal = document.getElementById(`modalEscrituracion-${modalId}`);
  const modal = bootstrap.Modal.getInstance(miModal);
  modal?.hide();
}

async function mostrarObligadoSolidario(llave?: string, header?: string) {
  if (pestania.value == "obligado-solidario") {
    pestania.value = "";
    await nextTick();
  }
  if (llave == undefined) {
    llave = cliente.llavesObligadoSolidario.value[0] || cliente.llavesCotitular.value[0];
    if (cliente.llavesObligadoSolidario.value.length) header = "Obligado Solidario";
    else header = cliente.llavesCotitular.value.length ? "Cotitular" : "Garante Hipotecario";
  }
  pestania.value = "obligado-solidario";
  cliente.llaveActual.value = llave;
  cliente.headerActual.value = header ?? "";
}

function cargarBarraCircular() {
  if (timeline.progress.value <= 33.33 && timeline.progress.value > 0) {
    barCircular.Etapa.value = 2;
    barCircular.Leyenda.value = "Integración de Expediente";
  } else if (timeline.progress.value <= 66.66 && timeline.progress.value > 33.33) {
    barCircular.Etapa.value = 3;
    barCircular.Leyenda.value = "Análisis";
  } else if (timeline.progress.value > 66.66) {
    barCircular.Etapa.value = 4;
    barCircular.Leyenda.value = "Firma";
  }
  barCircular.progreso.value = timeline.progress.value == 0 ? 25 : timeline.progress.value;
}
//#endregion

let escrituracion = $router.currentRoute.value.query["estudioId"] as string;

if (escrituracion == undefined) escrituracion = "0";

let progreso = await api.Cliente.Cliente.EscrituracionApi.ObtenerProgresoEscrituracion(escrituracion);

if (!progreso.data.estudioSocioeconomicoId) {
  $store.agregarToast({
    tipo: TipoBootstrap.DANGER,
    body: "El link proporcionado no es válido, verifica e intenta nuevamente."
  } as Toast);
  $router.replace({ name: "cliente-cliente-login" });
} else {
  cliente.nombreCompleto.value = progreso.data.nombreCompletoCliente || "";
  cliente.estudioSocioeconomicoId.value = progreso.data.estudioSocioeconomicoId;
  timeline.progress.value = progreso.data.progreso;
  cliente.estudioSocioeconomicoBloqueado.value = progreso.data.estudioSocioeconomicoBloqueado;
  cliente.autorizacionConsultaBuroCredito.value = progreso.data.autorizacionConsultaBuroCredito;
  cliente.documentos.value = progreso.data.documentos!;
  cliente.documentosOS.value = progreso.data.documentosObligadoSolidario!;
  cliente.documentosConyuge.value = progreso.data.documentosConyuge!;
  cliente.documentosCotitular.value = progreso.data.documentosCotitular!;
  cliente.documentosVendedor.value = progreso.data.documentosVendedor!;
  cliente.documentosConyugeVendedor.value = progreso.data.documentosConyugeVendedor!;
  cliente.documentosGaranteHipotecario.value = progreso.data.documentosGaranteHipotecario!;
  cliente.llavesObligadoSolidario.value = progreso.data.llavesObligadoSolidario!;
  cliente.llavesCotitular.value = progreso.data.llavesCotitular!;
  cliente.llavesGaranteHipotecario.value = progreso.data.llavesGaranteHipotecario!;
  if (cliente.estudioSocioeconomicoBloqueado.value) pestania.value = "autorizacion-consulta-buro-credito";
  if (timeline.progress.value >= 33.33) pestania.value = "checklist-documentos";
  cargarBarraCircular();
}

onMounted(() => {
  console.log("se monto la APP");
  abrirModal();
});

onBeforeMount(() => {});
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1920px) {
  .ProgressTimeLine {
    display: block;
  }
  .ProgressCircular {
    display: none;
  }
}
@media screen and (max-width: 800px) {
  .ProgressTimeLine {
    display: block;
  }
  .ProgressCircular {
    display: none;
  }
}
@media screen and (max-width: 500px) {
  .ProgressTimeLine {
    display: none;
  }
  .ProgressCircular {
    display: block;
  }
}

div .pestania:not(:last-child) {
  margin-right: 12px;
}

.pestania {
  font-weight: bold;
  color: #374066;
  padding: 9px 30px;
  border-radius: 90px;

  > img {
    margin-left: 8px;
  }
}

.contenedor-Documentos {
  border: 2px dotted #bdbdbd;
  margin-top: 1rem;
  margin-left: 0.1rem;
  width: 100%;
}
.area-drag {
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1rem;
}
.area-drag .icon {
  font-size: 2.5rem;
  color: #eb8113;
}
.area-lista-archivos {
  align-items: center;
  padding-top: 1rem;
}

.area-lista-archivos p {
  color: #eb8113;
  opacity: 0.7;
}

.textoDrag {
  color: #828282;
}
.buscarDrag {
  color: #eb8113;
  text-decoration: underline;
  cursor: pointer;
}
.archivoSubido {
  list-style: none;
  color: #eb8113;
  font-weight: bold;
}
.iconoArchivo {
  padding: 0.3rem;
  color: rgb(201, 67, 67);
  font-size: 14px;
}
.iconoEliminarArchivo {
  color: rgba(95, 95, 95, 0.699);
  margin-left: 2.2rem;
  font-size: 12px;
  cursor: pointer;
}
.pointer {
  cursor: pointer;
}
.textHir1 {
  color: #eb8113;
  font-weight: bold;
}
</style>

