import type { Cliente, Core } from "@/servicios/api";
import type { ToRefs } from "vue";
import { toRefs, reactive } from "vue";

export function mapear( //nosonar
  esOrigen: Cliente.Cliente.EstudioSocioeconomico | Cliente.Cliente.ObligadoSolidario,
  esDestino: ToRefs<Cliente.Cliente.EstudioSocioeconomico | Cliente.Cliente.ObligadoSolidario>
) {
  const ks = Object.keys(esOrigen);

  for (const k of ks) {
    if (k in esDestino && !/^fecha.+/g.test(k)) (esDestino as any)[k].value = (esOrigen as any)[k];
  }

  for (const fecha of ks.filter((k) => /^fecha.+/g.test(k))) {
    if (fecha in esDestino) {
      const v = (esOrigen as any)[fecha] as string;
      if ((v ?? "").length) (esDestino as any)[fecha].value = v.substring(0, 10);
    }
  }
}

export function limpiarFechas(es: ToRefs<Cliente.Cliente.EstudioSocioeconomico | Cliente.Cliente.ObligadoSolidario>) {
  if (es.fechaNacimiento?.value == "") es.fechaNacimiento.value = undefined;
  if ((es as ToRefs<Cliente.Cliente.EstudioSocioeconomico>).fechaNacimientoConyuge) {
    const esES = es as ToRefs<Cliente.Cliente.EstudioSocioeconomico>;
    if (esES.fechaNacimientoConyuge?.value == "") esES.fechaNacimientoConyuge.value = undefined;
    if (esES.fechaNacimientoGaranteHipotecario?.value == "") esES.fechaNacimientoGaranteHipotecario.value = undefined;
    if (esES.fechaNacimientoVendedor?.value == "") esES.fechaNacimientoVendedor.value = undefined;
  }
}

export interface IngresoGeneral {
  monto: number;
  concepto: string;
  esSocio: boolean | undefined;
}

export interface IngresoNomina {
  empresa: string;
  actividad: string;
  telefono: string;
  extension: string;
  correo: string;
  puesto: string;
  otroPuesto: string;
  antiguedad: number;
  nombreJefe: string;
  telefonoJefe: string;
  extensionJefe: string;
  sueldoBruto: number;
  isrImss: number;
  sueldoNeto: number;
}

export interface DocumentoComprobacionIngresos {
  nombre: string;
  requerido: boolean;
}

export interface ClienteDocumentos {
  documentos: Core.Models.EstudioSocioeconomicoDocumento[];
  documentosOS: Core.Models.EstudioSocioeconomicoDocumento[][];
  documentosConyuge: Core.Models.EstudioSocioeconomicoDocumento[];
  documentosCotitular: Core.Models.EstudioSocioeconomicoDocumento[][];
  documentosVendedor: Core.Models.EstudioSocioeconomicoDocumento[];
  documentosGaranteHipotecario: Core.Models.EstudioSocioeconomicoDocumento[];
  documentosConyugeVendedor: Core.Models.EstudioSocioeconomicoDocumento[];
}

export interface ClienteDocumentos2 extends ClienteDocumentos {
  nombreCompleto: string;
  estudioSocioeconomicoId: number;
  estudioSocioeconomicoBloqueado: boolean;
  autorizacionConsultaBuroCredito: boolean;
  llavesObligadoSolidario: string[];
  llavesCotitular: string[];
  llavesGaranteHipotecario: string[];
  llaveActual: string;
  headerActual: string;
}

export function generarObjetoIngresos() {
  return toRefs(
    reactive({
      cantidadNomina: 0,
      nomina: [] as IngresoNomina[],
      numeroNominaActual: 0,
      cantidadNegocioPropio: 0,
      negocioPropio: [] as IngresoGeneral[],
      cantidadActividadEmpresarial: 0,
      actividadEmpresarial: [] as IngresoGeneral[],
      cantidadPension: 0,
      pension: [] as IngresoGeneral[],
      cantidadArrendamiento: 0,
      arrendamiento: [] as IngresoGeneral[],
      cantidadConcesion: 0,
      concesion: [] as IngresoGeneral[],
      cantidadOtro: 0,
      otro: [] as IngresoGeneral[],
      sumaTotal: 0,
      sueldoNetoTotal: 0,
      documentosNomina: undefined as DocumentoComprobacionIngresos[] | undefined,
      documentosActividadEmpresarial: undefined as DocumentoComprobacionIngresos[] | undefined,
      documentosPension: undefined as DocumentoComprobacionIngresos[] | undefined,
      documentosNegocioPropio: undefined as DocumentoComprobacionIngresos[] | undefined,
      documentosArrendamiento: undefined as DocumentoComprobacionIngresos[] | undefined,
      documentosConcesion: undefined as DocumentoComprobacionIngresos[] | undefined,
      documentosOtro: undefined as DocumentoComprobacionIngresos[] | undefined
    })
  );
}

export type ComprobacionIngresos = ReturnType<typeof generarObjetoIngresos>;

export function asignarIngresos(ingresos: string | undefined, esComprobacionIngresos: ComprobacionIngresos) {
  if (!(ingresos ?? "").length) return;
  try {
    const ing = JSON.parse(ingresos!) as string[];
    const ingresosGuardados = ing.map((elem) => JSON.parse(elem) as (IngresoNomina | IngresoGeneral)[]);
    esComprobacionIngresos.cantidadNomina.value = (ingresosGuardados[0] as IngresoNomina[]).length;
    esComprobacionIngresos.nomina.value = ingresosGuardados[0] as IngresoNomina[];
    esComprobacionIngresos.cantidadNegocioPropio.value = ingresosGuardados[1].length;
    esComprobacionIngresos.negocioPropio.value = ingresosGuardados[1] as IngresoGeneral[];
    esComprobacionIngresos.cantidadPension.value = ingresosGuardados[2].length;
    esComprobacionIngresos.pension.value = ingresosGuardados[2] as IngresoGeneral[];
    esComprobacionIngresos.cantidadActividadEmpresarial.value = ingresosGuardados[3].length;
    esComprobacionIngresos.actividadEmpresarial.value = ingresosGuardados[3] as IngresoGeneral[];
    esComprobacionIngresos.cantidadArrendamiento.value = ingresosGuardados[4].length;
    esComprobacionIngresos.arrendamiento.value = ingresosGuardados[4] as IngresoGeneral[];
    esComprobacionIngresos.cantidadConcesion.value = ingresosGuardados[5].length;
    esComprobacionIngresos.concesion.value = ingresosGuardados[5] as IngresoGeneral[];
    esComprobacionIngresos.cantidadOtro.value = ingresosGuardados[6].length;
    esComprobacionIngresos.otro.value = ingresosGuardados[6] as IngresoGeneral[];
  } catch {
    /* Prevenir detener funcionamiento por formato de guardado de ingresos antiguo */
  }
}

export interface EstudioSocioeconomico extends Cliente.Cliente.EstudioSocioeconomico {
  estudioSocioeconomicoId: string;
  ocupacionActualArray: number[];
}

export interface EstudioSocioeconomicoObligadoSolidario extends Cliente.Cliente.ObligadoSolidario {
  ocupacionActualArray: number[];
}
