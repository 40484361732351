import { defineStore } from "pinia";

export const useStore = defineStore("clienteCliente", {
  state: () => ({
    clienteId: 0 as number,
    nombrePilaCliente: "",
    nombrePilaApellidoPaternoCliente: "",
    porLiquidar:false, //CORE-0777
    nombreCompletoCliente: "",
    mostrarEscrituracion: false,
    mostrarSeguro: false,
    titulo: "",
    sesionIniciada: false,
    clienteAceptaTerminosCondicionesAvisoPrivacidad: false,
    modalLoginId: ""
  })
});

