<template lang="pug">
.container.mb-3: .row
  .col-8
    .d-inline-block(
      style="background: #492fe5; color: #ffffff; padding: 15px 26px; border-top-left-radius: 11px; border-top-right-radius: 11px; margin-left: 26px"
    ): b.text.secondary Adjudicaciones
    .bg-white.shadow(style="border-radius: 8px; padding: 0")
      .card.mb-2: .card-body(style="background: #492fe5; padding: 16px 26px; border-bottom: #95a7d6 1px solid")
        .row
          .col-6
            input.form-control.mt-4(:form="componentId + 'form'" type="search" v-model="filtroContrato" placeholder="Busqueda por Nombre")
          .col-2
          .col-4
            select.form-select.mt-4(:form="componentId + 'form'" v-model="Mes")
              option(disabled selected) Seleccione Mes Adjudicación
              option(v-for="mes in meses" :value="mes") {{ mes.texto }}
      .p-2: table.w-100.bg-white.table(ref="tablaDom")
        thead: tr
          td Grupo
          td Cliente
          td Contrato
          td Adjudicado
          td Adjudicación
          td Cuotas
        tbody
  .col-4
    .row
      .col-12
        .mt-4.card: .card-body
          .row
            .col-12
              .row: .col.text-center.fw-bold.fs-5(style="color: #2f2d74") Publicación del Mes
            .col-12
              .centrador: a(href="https://www.reforma.com/libre/online07/aplicacionei/Pagina.html?seccion=adjudicadoshir&fecha=20211024" target="_blank")
                img.imagen(src="../assets/publicidadmes.svg")
      .col-12
        .mt-4.card: .card-body
          .row
            .col-12
              .row: .col.text-center.fw-bold.fs-5(style="color: #2f2d74") Calendario de Eventos
            .col-12
              .row
                .col-4
                  .calendar_date
                    .calendar_month
                      | ENERO
                    .calendar_day
                      | 21
                .col-4
                  .calendar_date
                    .calendar_month
                      | FEBRERO
                    .calendar_day
                      | 18
                .col-4
                  .calendar_date
                    .calendar_month
                      | MARZO
                    .calendar_day
                      | 18
                .col-4
                  .calendar_date
                    .calendar_month
                      | ABRIL
                    .calendar_day
                      | 15
                .col-4
                  .calendar_date
                    .calendar_month
                      | MAYO
                    .calendar_day
                      | 20
                .col-4
                  .calendar_date
                    .calendar_month
                      | JUNIO
                    .calendar_day
                      | 17
                .col-4
                  .calendar_date
                    .calendar_month
                      | JULIO
                    .calendar_day
                      | 15
                .col-4
                  .calendar_date
                    .calendar_month
                      | AGOSTO
                    .calendar_day
                      | 19
                .col-4
                  .calendar_date
                    .calendar_month
                      | SEPTIEMBRE
                    .calendar_day
                      | 19
                .col-4
                  .calendar_date
                    .calendar_month
                      | OCTUBRE
                    .calendar_day
                      | 21
                .col-4
                  .calendar_date
                    .calendar_month
                      | NOVIEMBRE
                    .calendar_day
                      | 18
                .col-4
                  .calendar_date
                    .calendar_month
                      | DICIEMBRE
                    .calendar_day
                      | 16
</template>

<script lang="ts" setup>
import * as utilidades from "@/utilidades";
import filtros from "@/composables/filtros";
import { watch, ref, onMounted, nextTick } from "vue";
import api from "@/servicios/apiCliente";
import $ from "jquery";
import "@/datatables";
import * as luxon from "luxon";

const componentId = utilidades.generarId();
const filtroContrato = ref("");
const Mes = ref({ anio: new Date().getFullYear(), mes: new Date().getMonth() + 1 });
const meses = [] as { mes: number; anio: number; texto: string }[];
const tablaDom = ref(null as HTMLTableElement | null);
let tabla: DataTables.Api;

watch(filtroContrato, (val) => {
  filtroContrato.value = val.toUpperCase();
  tabla.search(val).draw();
});

async function obtenerAdjudicaciones() {
  let data = await api.Cliente.Cliente.AdjudicacionApi.ObtenerAdjudicacionesMes(Mes.value.anio, Mes.value.mes);
  tabla.clear();
  tabla.rows.add(data.data);
  tabla.draw();
  tabla.columns.adjust().draw();
}

for (let i = 0; i < 13; i++) {
  let fecha = luxon.DateTime.now().minus({ months: i });
  meses.push({ mes: fecha.month, anio: fecha.year, texto: fecha.toLocaleString({ month: "long", year: "numeric" }) });
}

Mes.value = meses[0];

watch(Mes, async () => {
  filtroContrato.value = "";
  await obtenerAdjudicaciones();
});

onMounted(async () => {
  await nextTick();
  tabla = $(tablaDom.value!).DataTable({
    columns: [
      { data: "numeroGrupo" },
      { data: "nombreCompletoCliente" },
      { data: "numeroCompleto" },
      { data: "montoContratado", render: (data2: number) => filtros.filtroDivisa(data2), className: "text-end" },
      { data: "tipoAdjudicacion", render: (data2: string) => data2.replace(/_/g, " ") },
      { data: "numeroCuotaPagadaIngresoListaEspera" }
    ],
    dom: "lrtip"
  });
  await obtenerAdjudicaciones();
});
</script>

<style lang="scss" scoped>
.calendar_date {
  width: 74px;
  height: 74px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}
.calendar_month {
  width: 74px;
  height: 24px;
  font-family: Poppins, Arial, Helvetica, sans-serif;
  background-color: #ffa000;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
}
.calendar_day {
  font-family: Poppins, Arial, Helvetica, sans-serif;
  background-color: #eef3f6;
  color: #354052;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  height: 50px;
}
.centrador {
  position: relative;
  width: 400px;
  height: 400px;
}
.imagen {
  position: absolute;
  width: 300px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.header {
  background: url(../assets/Header.jpg);
  height: 107px;
  width: 100%;
  background-repeat: no-repeat;
  margin-bottom: 27px;
}

div .pestania:not(:last-child) {
  margin-right: 12px;
}

.pestania {
  font-weight: bold;
  color: #374066;
  padding: 9px 30px;
  border-radius: 90px;

  > img {
    margin-left: 8px;
  }
}

.contenedor-Documentos {
  border: 2px dotted #bdbdbd;
  margin-top: 1rem;
  margin-left: 0.1rem;
  width: 100%;
}
.area-drag {
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1rem;
}
.area-drag .icon {
  font-size: 2.5rem;
  color: #eb8113;
}
.area-lista-archivos {
  align-items: center;
  padding-top: 1rem;
}

.area-lista-archivos p {
  color: #eb8113;
  opacity: 0.7;
}

.textoDrag {
  color: #828282;
}
.buscarDrag {
  color: #eb8113;
  text-decoration: underline;
  cursor: pointer;
}
.archivoSubido {
  list-style: none;
  color: #eb8113;
  font-weight: bold;
}
.iconoArchivo {
  padding: 0.3rem;
  color: rgb(201, 67, 67);
  font-size: 14px;
}
.iconoEliminarArchivo {
  color: rgba(95, 95, 95, 0.699);
  margin-left: 2.2rem;
  font-size: 12px;
  cursor: pointer;
}
.pointer {
  cursor: pointer;
}
.textHir1 {
  color: #eb8113;
  font-weight: bold;
}
</style>
