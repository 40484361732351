const simboloPesos = amount => {
  const exp = /(\d)(?=(\d{3})+(?!\d))/g;
  const rep = "$1,";
  let arr = amount.toString().split(".");
  arr[0] = arr[0].replace(exp, rep);
  amount = arr[1] ? "$" + arr.join(".") : "$" + arr[0];
  return amount;
};

export default simboloPesos;
