import * as VueRouter from "vue-router";
import ClienteAplicacion from "@/Aplicaciones/Cliente/router/index";
import RouterViewGenerico from "@/views/RouterViewGenerico.vue";

export let instancia: VueRouter.Router = null!;

export default () => {
  const routes: VueRouter.RouteRecordRaw[] = [
    {
      path: "/",
      name: "Home",
      component: RouterViewGenerico,
      children: ClienteAplicacion()
    }
  ];

  const router = VueRouter.createRouter({
    history: VueRouter.createWebHistory(import.meta.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
      return { top: 0, behavior: "smooth" };
    }
  });

  router.onError(err => {
    if (import.meta.env.NODE_ENV != "production") console.log(err);
  });

  // Ver: https://developers.google.com/tag-platform/gtagjs/reference?hl=es

  router.afterEach((to, from, failure) => {
    if (!VueRouter.isNavigationFailure(failure)) {
      gtag("event", "page_view", {
        page_location: window.location.toString(),
        user_agent: navigator.userAgent,
        page_title: to.name
      });
    }
  });
  instancia = router;
  return router;
};
