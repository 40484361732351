<template lang="pug">
.container.ff-poppins.py-3
  .row
    .col-md-12.text-center(v-if="banners.length")
      //- Galeria HirCasa
      .carousel.slide.d-inline-block(data-ride="carousel" style="width: 600px; height: 250px; max-width: 100%" :id="componentId + 'banners'")
        .carousel-indicators(v-if="banners.length > 1")
          button.active(
            v-for="(item, i) in banners"
            type="button"
            :class="{ active: i == 0 }"
            :data-bs-target="'#' + componentId + 'banners'"
            :data-bs-slide-to="i"
          )
        .carousel-inner: .carousel-item(v-for="(item, i) in banners" :class="{ active: i == 0 }")
          a(:href="item.linkUrl" target="_blank")
            img.d-block.w-100.img-rounded(alt="Slide" :src="item.bannerUrl" width="600" height="250")
        template(v-if="banners.length > 1")
          button.carousel-control-prev(type="button" :data-bs-target="'#' + componentId + 'banners'" data-bs-slide="prev")
            span.carousel-control-prev-icon
            span.visually-hidden Anterior
          button.carousel-control-next(type="button" :data-bs-target="'#' + componentId + 'banners'" data-bs-slide="next")
            span.carousel-control-next-icon
            span.visually-hidden Siguiente
    .col-md-6
      p.fs-2.fw-bold(style="color: #2f2d74") Hola,&#32;
        span.text-primary {{ $store.nombrePilaCliente }}
      p.fs-5(style="color: #6e7599") Este es el Portal Cliente donde tienes acceso a tu&#32;
        span.text-primary.fw-bold información general
        | , a tus&#32;
        span.text-primary.fw-bold estados de cuenta
        | &#32;y a otras&#32;
        span.text-primary.fw-bold funcionalidades
        | &#32;que estamos desarrollando para tu comodidad.
      .text-center: img.border.border-1.shadow(src="/cliente/cliente/img/home-banner-2024.png" style="border-radius: 1rem; max-width: 100%")
      .row: .col-md-12.mt-2: .card: .card-body(style="background: #492fe5; padding: 16px 26px; border-bottom: #95a7d6 1px solid; border-radius: 8px 8px 8px 8px")
        .row
          .col-md-12.mt-2.d-flex.justify-content-center: select.form-select.rounded-pill(:form="componentId + 'form'" v-model="contratoSeleccionado")
            option(v-for="item in listaContratos" :value="item") {{ item.numeroContrato }}
          .col-md-12.mt-2.d-flex.justify-content-center: a.text-decoration-none.btn.text-primary.bg-white.border.border-3.border-primary.rounded-pill.fw-600(
            :href="linkDescargaComplemento"
            @click="gt('event', 'descargar', { event_category: 'complemento-estado-cuenta', event_label: 'home', value: 1 })"
            target="_blank"
          ) Estado de Cuenta
            i.fa-solid.fa-download.ms-2
          .col-md-12.mt-2.justify-content-center: p.text-center.text-white.fw-600 PAGUE ANTES DEL <br/> {{ fechaApagar }}
          .col-md-12.mt-1.justify-content-center: p.text-center.text-white.fw-bold.fs-1 {{ filtros.filtroDivisa(totalPagar) }}
          //- .col-md-12.d-flex.justify-content-center: button.btn.btn-primary.text-white.px-5.rounded-pill.fw-600 Pagar
          .col-md-12.mt-2.justify-content-center.text-center.text-white.fw-600(v-if="referencia != 'error' ? true : false") REFERENCIA ÚNICA: <span class="fw-bold fs-5">{{ referencia }}</span>
          hr.my-3.mx-1(style="color: #fff; height: 2px; max-width: calc(100% - 1rem)")
          .col-md-12.mt-2.justify-content-center: .row
          .col-md-4.justify-content-center: router-link.text-decoration-none(:to="{ name: 'cliente-cliente-domiciliacion' }")
            img(src="../assets/i_domiciliar.svg" style="height: 30px")
            button.text-white.btn.btn-link Domiciliación
          .col-md-4.justify-content-center: router-link.text-decoration-none(:to="{ name: 'cliente-cliente-facturacion' }")
            img(src="../assets/i_facturacion.svg" style="height: 30px")
            button.text-white.btn.btn-link Facturación
          .col-md-4.justify-content-center
            img(src="../assets/i_aclaraciones.svg" style="height: 30px")
            button.text-white.btn.btn-link(@click.prevent="mostrarModalAclaraciones()") Aclaraciones
            //- Modal de Aclaraciones
            .modal.fade(ref="aclaracionesModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true")
              .modal-dialog.modal-dialog-centered.mw-100.w-75(role="document")
                .modal-content
                  .modal-header
                    h3#aclaracionesModalLabel.modal-title.m-2(style="color: #34189a; font-weight: bold") Mis Aclaraciones
                    button(
                      @click.prevent="cerrarModalAclaraciones()"
                      style="float: right; width: 45px; height: 45px; margin: 20px 20px; background-color: transparent; border: none"
                    )
                      i.fas.fa-times.fa-2x.text-primary 
                  .modal-body
                    .row(v-if="mostrarOpcionAclaracion")
                      p.fw-bold(align="left" style="margin: 0 30px") Selecciona la opción deseada
                      .row
                        .col-md-12.mt-2(v-for="(aclaracion, i) in aclaraciones" :key="i")
                          button#DP.fw-bold(
                            @click.prevent="seleccionarAclaracion(aclaracion)"
                            style="width: 100%; background-color: #492fe5; color: white; border: none; text-align: left; padding: 7px 20px; border-radius: 7px"
                            :style="aclaracion.tipoAclaracionId == crearAclaracion.tipoAclaracionId ? 'background-color: #eb8113' : ''"
                          ) {{ aclaracion.nombre }}
                    .row(v-if="mostrarDescripcion")
                      p.fw-bold(align="left" style="margin: 20px 0 0 30px") Descríbenos brevemente la situación
                      div(style="width: 100%; margin: 10px 0 0 30px"): textarea#mensaje(
                        rows="3"
                        cols="50"
                        style="border-radius: 10px"
                        maxlength="250"
                        v-model="descripcion"
                      )
                      div(style="width: 100%; margin-left: 30px"): p#contador.text-left {{ descripcion.length }}/250
                      .text-white(style="background-color: red; margin-left: 45px; width: 90%" v-if="avisoDescripcion") Agrega una descripción.
                      .text-white(style="background-color: red; margin-left: 45px; width: 90%" v-if="mensajeTamanioArchivo") Tamaño de archivo NO Permitido.
                      .text-white(style="background-color: red; margin-left: 45px; width: 90%" v-if="mensajeTipoArchivo") Tipo de archivo NO Permitido.
                      div(style="margin: 10px 20px")
                        p.fw-bold(align="left" style="margin: 0") Adjunta documentos de evidencia
                        p.fw-bold(align="left" style="color: #c3c1c1; font-size: 14px") Puedes adjuntar archivos JPG, PDF, PNG con un peso hasta de 10mb
                        input#file.archivo(
                          type="file"
                          multiple
                          style="background-color: grey"
                          draggable="true"
                          accept=".pdf,.png,.jpg"
                          hidden
                          v-on:change="obtenerArchivo($event)"
                        )
                        label(
                          draggable="true"
                          for="file"
                          style="background-color: #e0e7ea; width: 60%; padding-top: 10px; border-radius: 10px; border: 2px solid #c2c8cb; margin-left: 150px"
                        )
                          i.fas.fa-paperclip.text-primary
                          p.fw-bold.text-muted Arrastra los archivos aquí o&#32;
                            p(style="display: inline; text-decoration: underline; color: #eb8113") Búscalos
                        #nombres.row(v-for="(file, id) in files" :key="id" style="text-align: center; margin: 10px 0 0 0")
                          .col-md-2
                          .col-md-2
                            span
                              a#eliminar(v-on:click="eliminarArchivo(id)"): i.fas.fa-trash-alt
                          .col-md-5
                            a.nombre.fw-bold(:href="visualizarArchivo(file)" target="_blank" v-if="motsrarNombreArchivo == false ? true : false") {{ file.name }}
                    button.mt-2.text-white.fs-5.fw-bold.btn.btn-primary(@click="estado += 1" @click.prevent="estadoBoton()") Enviar
                    .row(style="margin: 0 80px 0 80px" v-if="mostrarFolio")
                      .row(style="margin-top: 80px; text-align: center")
                        p.fs-3.fw-bold Aclaraciones
                      .row(style="text-align: center")
                        p.fw-bold Hemos recibido correctamente tu caso.
                      .row(style="background-color: #492fe5; color: white; width: 400px; margin-left: 110px; border-radius: 8px")
                        p.fs-5(style="margin-top: 20px; text-align: center") El folio de tu aclaración es:
                        p.fw-bold.fs-3(style="text-align: center") {{ folio.aclaracionId }}
                      .row(style="margin: 40px 87px 0 87px; width: 450px")
                        p En breve uno de nuestros Asesores te contactará para darle seguimiento al caso.
                      button.text-white.fs-5.fw-bold.btn.btn-primary(@click.prevent="cerrarModalAclaraciones()") Cerrar
    .col-md-6.text-end 
      img(src="../assets/img_main_pc.png" style="width: calc(100% - 1rem)")
    .col-md-12.mt-4: .d-flex.flex-row
      router-link.d-block.flex-fill.text-decoration-none.mx-2(:to="{ name: 'cliente-cliente-financiamiento' }"): .card.h-100.justify-content-center.shadow(
        style="border-radius: 1rem"
      ): .card-body.flex-grow-0
        .row.justify-content-md-center
          .col-md-12.justify-content-center(style="text-align: center"): img(src="../assets/Menu/icon_mifinanciamiento.svg" style="height: 100px")
          .col-md-12.mt-3
          .col-md-12.justify-content-center(style="text-align: center"): p.h6.fw-bold(style="color: #34189a") MI FINANCIAMIENTO
          .col-md-12.mt-2
          .col-md-12.justify-content-center(style="text-align: center"): p.h6.fw-500.text-muted Información de tus contratos
          .col-md-12.mt-2
      .flex-fill.mx-2: .card.h-100.justify-content-center.shadow(style="border-radius: 1rem"): .card-body.flex-grow-0
        .row.justify-content-md-center
          .col-md-12.justify-content-center(style="text-align: center"): .dropdown.d-inline-block
            a.btn.btn-outline-white.dropdown-toggle.bg-white.fw-bold.h6(
              data-bs-toggle="dropdown"
              style="border-radius: 90px; color: #34189a; font-weight: 600"
            )
              img(src="../assets/Menu/icon_seguros.svg" style="height: 100px")
              | <br>SEGUROS
            ul.dropdown-menu
              li: a.dropdown-item(v-for="(periodo, index) in seguros" :key="index" href="#" @click.prevent="seleccionarSeguro(periodo.path)") {{ periodo.Name }}
          .col-md-12.justify-content-center(style="text-align: center"): p.h6.fw-500.text-muted Tus coberturas y tu reporte de siniestro
          .col-md-12.mt-3 
      router-link.flex-fill.text-decoration-none.d-block.mx-2(v-if="$store.mostrarEscrituracion" :to="{ name: 'cliente-cliente-aes' }"): .card.h-100.justify-content-center.shadow(
        style="border-radius: 1rem"
      ): .card-body.flex-grow-0
        .row.justify-content-md-center
          .col-md-12.justify-content-center(style="text-align: center"): img(src="../assets/Menu/icon_titulacion.svg" style="height: 100px")
          .col-md-12.mt-2
          .col-md-12.justify-content-center(style="text-align: center"): p.h6.fw-bold(style="color: #34189a") TITULACIÓN
          .col-md-12.mt-2
          .col-md-12.justify-content-center(style="text-align: center"): p.h6.fw-500.text-muted La información de tu proceso de titulación.
          .col-md-12.mt-2
      router-link.flex-fill.text-decoration-none.d-block.mx-2(:to="{ name: 'cliente-cliente-perfil' }"): .card.h-100.justify-content-center.shadow(
        style="border-radius: 1rem"
      ): .card-body.flex-grow-0
        .row.justify-content-md-center
          .col-md-12.justify-content-center(style="text-align: center"): img(src="../assets/Menu/i_miperfil.svg" style="height: 100px")
          .col-md-12.mt-2
          .col-md-12.justify-content-center(style="text-align: center"): p.h6.fw-bold(style="color: #34189a") MI PERFIL
          .col-md-12.mt-2
          .col-md-12.justify-content-center(style="text-align: center"): p.h6.fw-500.text-muted La información de tu cuenta.
          .col-md-12.mt-2
      .flex-fill.mx-2
        router-link.d-block.text-decoration-none.mb-2(:to="{ name: 'cliente-cliente-adjudicaciones' }"): .card.shadow(style="border-radius: 1rem"): .card-body
          .d-flex.flex-row.align-items-center
            img.me-3(src="../assets/Menu/icon_adjudicaciones.svg" style="height: 100px")
            div
              p.h6.fw-bold(style="color: #34189a") Adjudicaciones
              p.h6.fw-500.text-muted.text-nowrap Conoce la lista de clientes que han adjudicado.
        router-link.d-block.text-decoration-none.mt-2(:to="{ name: 'cliente-cliente-materiales-apoyo' }"): .card.shadow(style="border-radius: 1rem"): .card-body
          .d-flex.flex-row.align-items-center
            img.me-3(src="../assets/Menu/icon_materiales_apoyo.svg" style="height: 100px")
            div
              p.h6.fw-bold(style="color: #34189a") MATERIAL DE APOYO
              p.h6.fw-500.text-muted.text-nowrap Inducción al Financiamiento&#32;
                span.text-primary HIR Casa
  .modal(ref="modalAceptarTCAP")
    .modal-dialog
      .modal-content
        .modal-body
          h5 Acepto el <a href="https://hircasa.com.mx/aviso-privacidad/" target="_blank">Aviso de Privacidad</a> y <a href="https://hircasa.com.mx/terminos-y-condiciones/" target="_blank">Términos y Condiciones</a>
        .modal-footer
          button.btn.btn-primary(type="button" @click="aceptarTCAP()") Aceptar


  
.modal(ref="bannerModalCampanias")
    .modal-dialog
      .modal-content
        .modal-body
        img.me-3(src="../assets/PagosApp_PORTAL-CLIENTE.png" style="width: 100%")
        .modal-footer
          button.btn.btn-secondary.text-white(type="button" @click="noMostrarModalBannerCampanias()") No volver a mostrar
          



</template>

<style lang="sass">
.botones-secciones
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
  border-radius: 20px
a:hover img
  filter: opacity(0.5)
.modal-overlay
  position: absolute
  top: 0
  left: 0
  bottom: 0
  right: 0
  z-index: 100
  height: 160%
  width: 100%
  background: rgba(0,0,0,0.4)
#eliminar:hover
  cursor: pointer
</style>

<script lang="ts" setup>
import * as utilidades from "@/utilidades";
import filtros from "@/composables/filtros";
import api from "@/servicios/apiCliente";
import { useStore } from "../store";
import Swal from "sweetalert2";
import { Cliente, Core } from "@/servicios/api";
import * as bootstrap from "bootstrap";
import { ref, watch, onBeforeUnmount, onMounted, nextTick } from "vue";
import { onBeforeRouteUpdate, useRouter } from "vue-router";

interface MenuSeguros {
  path: string;
  Name: string;
}

const $router = useRouter();
const $store = useStore();
const seguros = ref([] as MenuSeguros[]);
const estado = ref(0);
const enlaceHirContigo = ref("")
const descripcion = ref("");
const seleccionado = ref([] as any);
const aclaraciones = ref([] as any);
const tipoAclaracion = ref([] as any | undefined);
const mostrarModal = ref(false as boolean | undefined);
const mostrarDescripcion = ref(false as boolean | undefined);
const mostrarOpcionAclaracion = ref(true as boolean | undefined);
const mostrarFolio = ref(false as boolean | undefined);
const motsrarNombreArchivo = ref(true as boolean | undefined);
const folio = ref({} as any);
const crearAclaracion = ref({} as Core.Models.Aclaracion);
const aclaracionCliente = ref({} as Core.Models.AclaracionUsuario);
const avisoAclaracion = ref(false as boolean | undefined);
const avisoDescripcion = ref(false as boolean | undefined);
const mensajeTamanioArchivo = ref(false as boolean | undefined);
const mensajeTipoArchivo = ref(false as boolean | undefined);
const files = ref([] as File[]);
const fechaApagar = ref();
const totalPagar = ref(0 as number | undefined);
const tieneComplementoEstadoCuenta = ref(undefined as boolean | undefined);
const linkDescargaComplemento = ref("" as string | undefined);
const referencia = ref("error" as string | undefined);
const pagosVencidos = ref({} as any);
const componentId = ref(utilidades.generarId());
const Contratos = ref({} as any);
const banners = ref([] as Cliente.Models.BannerCampania[]);
const carouselBanners = ref(null as null | bootstrap.Carousel);
const listaContratos = ref([] as Cliente.Financiamiento.InformacionFinanciamientoContrato[]);
const contratoSeleccionado = ref({} as Cliente.Financiamiento.InformacionFinanciamientoContrato);
const modalAceptarTCAP = ref(null as HTMLDivElement | null);
const aclaracionesModal = ref(null as HTMLDivElement | null);
const bannerModalCampanias = ref(null as HTMLDivElement | null);

watch(contratoSeleccionado, val => {
  totalPagar.value = val.totalPagar!;
  linkDescargaComplemento.value = val.linkEstadoCuentaContrato.find(d => d != null);
});

function cerrarModal() {
  const miModal = document.getElementById(`modalLogin-${$store.modalLoginId}`);
  const modal = bootstrap.Modal.getInstance(miModal);
  modal?.hide();
}

async function seleccionarSeguro(periodo: string) {
  await $router.push({ name: periodo });
}
function irProgramaRecicla() {
  $router.push({ name: "cliente-cliente-programa-recicla" });
}
function seleccionarAclaracion(index: any) {
  crearAclaracion.value.tipoAclaracionId = index.tipoAclaracionId;
  crearAclaracion.value.tipoAclaracion = index;
}
async function estadoBoton() {
  if (estado.value == 1) {
    if (crearAclaracion.value.tipoAclaracionId == 0) {
      mostrarDescripcion.value = false;
      estado.value = 0;
      avisoAclaracion.value = true;
    } else {
      mostrarDescripcion.value = true;
      avisoAclaracion.value = false;
    }
  } else if (estado.value == 2) {
    if (descripcion.value.length > 0) {
      avisoDescripcion.value = false;
      mensajeTamanioArchivo.value = false;
      mostrarOpcionAclaracion.value = false;
      mostrarFolio.value = true;
      crearAclaracion.value.detalle = descripcion.value;
      var aclaracionGuardada = (await api.Cliente.Cliente.AclaracionApi.GuardarAclaracion(crearAclaracion.value)).data;
      folio.value = aclaracionGuardada.data;
      aclaracionCliente.value.aclaracionId = folio.value.aclaracionId;
      aclaracionCliente.value.aclaracion = folio.value.aclaracion;
      var archivos = [] as string[];
      for (let f of files.value) {
        var archivoGuardado = await api.Cliente.Cliente.AclaracionApi.GuardarArchivo(f, folio.value.ruta!);
        archivos.push(archivoGuardado.data);
      }
      await api.Cliente.Cliente.AclaracionApi.CrearZip(archivos.toString());
      await api.Cliente.Cliente.AclaracionApi.GuardarUsuarioAclaracion(aclaracionCliente.value);
    } else {
      if (descripcion.value.length <= 0) avisoDescripcion.value = true;
      estado.value = 1;
    }
  }
}
function obtenerArchivo(event: any) {
  // Utilizamos jQuery para actualizar los nombres de los archivos
  // Obtenemos el arreglo de archivos que haya adjuntado el cliente
  let file = event.target.files;
  let info;
  let obtenerExtension;
  // Validamos si existen archivos asjuntos para mostrar su nombre
  if (file.length <= 0) motsrarNombreArchivo.value = true;
  else motsrarNombreArchivo.value = false;
  // Realizamos validaciones a los archivos adjuntos
  for (let f of file) {
    info = f.type;
    if (f.size > 10 * 1024 * 1024) mensajeTamanioArchivo.value = true;
    obtenerExtension = info.split("/");
    if (obtenerExtension[1] == "pdf" || obtenerExtension[1] == "png" || obtenerExtension[1] == "jpg")
      mensajeTipoArchivo.value = false;
    else mensajeTipoArchivo.value = true;
    files.value.push(f);
  }
  crearAclaracion.value.archivo = true;
}
function visualizarArchivo(archivo: Blob) {
  return URL.createObjectURL(archivo);
}
function eliminarArchivo(indice: number) {
  files.value.splice(indice, 1);
}
async function aceptarTCAP() {
  await api.Cliente.Cliente.HomeApi.AceptarTerminosCondicionesAvisoPrivacidad();
  bootstrap.Modal.getOrCreateInstance(modalAceptarTCAP.value!).hide();
}
function mostrarModalAclaraciones() {
  api.Cliente.Cliente.HomeApi.ObtenerAdeudoVencidos().then(() => {
    let mostrarPopup = false;
    let mensaje = "";
    if (
      (pagosVencidos.value.esIntegrante || pagosVencidos.value.esAdjudicatario) &&
      pagosVencidos.value.cuotasVencidas > 0 &&
      pagosVencidos.value.cuotasVencidas <= 3.5
    ) {
      mostrarPopup = true;
      mensaje = "Da clic en opción 1 seguido de la opción A.";
    } else if (
      (pagosVencidos.value.esAdjudicatario && pagosVencidos.value.cuotasVencidas >= 3.51) ||
      (pagosVencidos.value.esAdjudicado && pagosVencidos.value.cuotasVencidas >= 0.01)
    ) {
      mostrarPopup = true;
      mensaje = "Da clic en opción 3.";
    }

    if (mostrarPopup) {
      Swal.fire({
        html:
          `<p class="fs-2 fw-bold">Estimado cliente:</p></br>
                 <p class="h6">Por el momento tu(s) cobertura(s) en Seguro de Vida y Daños no se encuentran vigentes.</p></br>
                 <p class="h6 fw-bold">Te sugerimos verificarlo.</p></br>
                 <div class="card">
                  <div class="card-body" style="background-color: #eceff2">
                    <a href="` +
          pagosVencidos.value.contactoWhatsApp +
          `" target="_blank" class="btn btn-primary text-white">Contáctanos vía WhatsApp</a></br>
                <div style="border-right: solid; width: 50%; height:20px"></div>
                <p class="h6">` +
          mensaje +
          `</p></br>
                  </div>
                </div>`,
        showCloseButton: true,
        showConfirmButton: false
      });
    } else {
      mostrarModal.value = true;
      bootstrap.Modal.getOrCreateInstance(aclaracionesModal.value!).show();
    }
  });
}
function cerrarModalAclaraciones() {
  bootstrap.Modal.getOrCreateInstance(aclaracionesModal.value!).hide();
  mostrarModal.value = false;
  mostrarDescripcion.value = false;
  for (let index = 0; index < seleccionado.value.length; index++) {
    seleccionado.value[index] = false;
  }
  for (let index = 0; index < tipoAclaracion.value.length; index++) {
    tipoAclaracion.value[index] = undefined;
  }
  crearAclaracion.value.tipoAclaracionId = 0;
  estado.value = 0;
  mostrarOpcionAclaracion.value = true;
  mostrarFolio.value = false;
  descripcion.value = "";
  avisoAclaracion.value = false;
  avisoDescripcion.value = false;
  crearAclaracion.value.archivo = false;
  mensajeTipoArchivo.value = false;
  mensajeTamanioArchivo.value = false;
  motsrarNombreArchivo.value = true;
  files.value = [];
}


const showBannerCompanias = () => {
  const seguirMostrandoBannerCampania = sessionStorage.getItem("seguirMostrandoBannerCampania");
   if (seguirMostrandoBannerCampania == "true") {
      bootstrap.Modal.getOrCreateInstance(bannerModalCampanias.value!, {
        backdrop: "static"
    }).show();
  }
}
const noMostrarModalBannerCampanias = () => {
  sessionStorage.setItem("seguirMostrandoBannerCampania", "false");
  bootstrap.Modal.getOrCreateInstance(bannerModalCampanias.value!).hide();
}


const gt = gtag;

onMounted(async () => {
  //** Camapaña para mostrar banner de descarga de app nueva */
  showBannerCompanias();
  //** Comentar o eliminar para quitar modal banner  */
 
  seguros.value = [];
  seguros.value.push({ path: "cliente-cliente-seguro-vida", Name: "Vida" });
  seguros.value.push({ path: "cliente-cliente-seguro-danios", Name: "Daños" });
  seguros.value.push({ path: "cliente-cliente-siniestro-generales", Name: "Siniestros" });
  //obtenemos los contratos activos
  let respContratos = await api.Cliente.Cliente.FinanciamientoApi.ObtenerContratos(1);
  listaContratos.value = respContratos.data;
  let resp = await api.Cliente.Cliente.ProgramaReclicaApi.ObtenerClienteProgramaRecicla();
  let _cliente = resp.data.data;
  let invitacionProgramaReciclaMostrado = Boolean(window.sessionStorage.getItem("invitacionProgramaReciclaMostrado"));
  if (!_cliente.programaRecicla && !invitacionProgramaReciclaMostrado) {
    window.sessionStorage.setItem("invitacionProgramaReciclaMostrado", "true");
    Swal.fire({
      position: "top-end",
      backdrop: "rgba(0,0,0,0)",
      background: "#8CC63E",
      html: `<div class='text-white ff-poppins'>
          En <b style='color: #2F2D74'>HIR CASA</b> estamos <b>comprometidos con nuestro medio ambiente</b>, por lo que te invitamos a ser parte de este compromiso:
          apóyanos <b>activando el envío de tu estado de cuenta digital</b>.</div>`,
      showCloseButton: true,
      closeButtonHtml: "<i class='fas fa-times' style='color: #fff; font-size: 20px'></i>",
      showCancelButton: false,
      focusConfirm: true,
      confirmButtonText: "Sí, deseo unirme a la campaña",
      confirmButtonColor: "#FFA000",
      timer: 15000,
      toast: true,
      width: "35%"
    }).then(resp2 => {
      if (resp2.isConfirmed) {
        irProgramaRecicla();
      }
    });
  }
  /*
      Seguros S5Q4 HU-7244-PAGOSVENCIDOS
    */
  pagosVencidos.value = await (await api.Cliente.Cliente.HomeApi.ObtenerAdeudoVencidos()).data.data;
  if (pagosVencidos.value.tienePagosVencidos.length > 0) {
    Swal.fire({
      html: `<p class="fs-2 fw-bold">Estimado cliente:</p></br>
             <p class="h6 fw-bold">Presentas mensualidades atrasadas de tu financiamiento realiza tu pago y ponte al corriente.</p></br>
             <p class="h6">Recuerda que por el momento no tienes la cobertura de tu seguro de vida o daños.</p></br>
             <a class="btn btn-primary text-white" href="#" target="_blank"> Pagar`,
      showCloseButton: true,
      showConfirmButton: false
    });
  }
  let res = await api.Cliente.Cliente.HomeApi.ObtenerInformacionHome();  
  const data = `${_cliente.clienteId}&organizacion=True`;  
  Contratos.value = res.data.contrato;
  banners.value = res.data.banners ?? [];
  $store.mostrarEscrituracion = res.data.mostrarEscrituracion;
  $store.porLiquidar = res.data.porLiquidar;
  await nextTick();
  if (banners.value.length)
    carouselBanners.value = new bootstrap.Carousel(document.querySelector("#" + componentId.value + "banners")!);

  api.Cliente.Cliente.FinanciamientoApi.ObtenerInformacion().then(a => {
    let informacionFinanciamiento = a.data;
    if (informacionFinanciamiento.linkDescargaComplemento) {
      totalPagar.value = informacionFinanciamiento.totalPagar;
      fechaApagar.value = new Intl.DateTimeFormat("es-MX", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric"
      }).format(new Date(informacionFinanciamiento.fechaVigencia));
      tieneComplementoEstadoCuenta.value = informacionFinanciamiento.tieneComplementoEstadoCuenta;
      linkDescargaComplemento.value = informacionFinanciamiento.linkDescargaComplemento;
      referencia.value = informacionFinanciamiento.referenciaUnica;
    }
  });
  seleccionado.value.length = 5;
  for (let index = 0; index < seleccionado.value.length; index++) {
    seleccionado.value[index] = false;
  }
  let respA = await api.Cliente.Cliente.AclaracionApi.ListarAclaraciones();
  aclaraciones.value = respA.data;

  if (!$store.clienteAceptaTerminosCondicionesAvisoPrivacidad) {
    bootstrap.Modal.getOrCreateInstance(modalAceptarTCAP.value!, {
      backdrop: "static"
    }).show();
  }
  cerrarModal();

});

onBeforeUnmount(() => {
  carouselBanners.value?.dispose();
});
</script>

