<template lang="pug">
.container.ff-poppins.pt-3
  .row
    .col-md-12: .Principal(style="height: 500px; margin: 0 auto")
      .col-md-5(style="padding: 230px 0 0 40px"): .card.mb-2(style="border-radius: 25px; border: none"): .card-body.bg-primary(style="border-radius: 25px")
        p.text-white.fw-bold.fs-2(style="padding: 20px 0 0 10px") Titulación
        p.text-white.fw-bold.fs-5(style="padding: 0 0 0 10px; margin-top: -5px") ¡FELICIDADES!
        p.text-white.fw-bold.fs-5(style="padding: 0 0 0 10px; margin-top: -15px") ¡Has adjudicado tus contratos!
    .col-md-12: .mt-4.card(style="border-radius: 15px"): .card-body(style="border-radius: 15px"): .d-flex
      .flex-fill
        p.fs-2.fw-bold(style="color: #2f2d74") Conoce toda la información de titulación
        p.fs-5.mt-4(style="color: #2f2d74") Estas muy cerca de concretar tu sueño, por ello hemos preparado este vídeo con toda la información para llevar a cabo tu proceso de titulación.
        p.fs-5.mt-4(style="color: #2f2d74") Recuerda que si deseas asesoría específica, puedes contactarnos.
      div <iframe width="560" height="315" src="https://www.youtube.com/embed/ct4RgHo0NbA" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    .row(style="background-color: white; margin: 70px auto 0 auto; width: 90%; border-radius: 20px")
      .col-md-4
        img(src="../assets/img_domiciliar.png" style="width: 450px; height: 450px; border-radius: 250px; padding: 50px 25px")
      .col-md-1
      .col-md-7
        .row(style="margin-top: 40px")
          .col-md-3(style="background-color: rgb(235, 129, 19); width: 40px; height: 40px; border-radius: 20px")
            p.text-center.fw-bold.text-white(style="padding-top: 6px") 1
          .col-md-9
            p.fw-bold(style="margin-top: 5px") Identifica el proyecto que vas a realizar
          .row
            .col-md-12
              .row(style="margin: 10px 0px 5px 20px")
                .col-md-3.justify-content-center.text-center
                  img(src="../assets/i_comprar.svg")
                  p.fw-bold(style="margin-top: 10px") Comprar
                .col-md-3.justify-content-center.text-center
                  img(src="../assets/i_construir.svg")
                  p.fw-bold(style="margin-top: 10px") Construir
                .col-md-3.justify-content-center.text-center
                  img(src="../assets/i_remodelar.svg")
                  p.fw-bold(style="margin-top: 10px") Remodelar
        .row(style="margin-top: 20px")
          .col-md-3(style="background-color: rgb(235, 129, 19); width: 40px; height: 40px; border-radius: 20px")
            p.text-center.fw-bold.text-white(style="padding-top: 6px") 2
          .col-md-9
            p.fw-bold(style="margin-top: 5px") Consulta la&#32;
              a.text-primary(style="text-decoration: underline" :href="URL" target="_blank") Guía de adjudicación&#32;
              | para conocer los requisitos puntuales de tu proyecto.
        .row(style="margin-top: 30px")
          .col-md-3(style="background-color: rgb(235, 129, 19); width: 40px; height: 40px; border-radius: 20px")
            p.text-center.fw-bold.text-white(style="padding-top: 6px") 3
          .col-md-9
            p.fw-bold(style="margin-top: 5px") Cumplidos y actualizando los requisitos, estaremos listos para iniciar tu trámite con la Notaría, una vez finalizado el proceso de análisis, podemos programar tu firma de escritura.
    .row(style="background-color: rgb(89, 53, 223); margin: 20px auto; width: 90%; border-radius: 30px")
      .row(style="text-align: center; margin: 20px 0 20px 0")
        p.fw-bold.text-white ¿Alguna duda con tu proceso? Contáctanos
      .row(style="padding: 0 0 20px 120px")
        .col-md-4
          .row
            .col-md-3(style="background-color: rgb(235, 129, 19); width: 60px; height: 60px; border-radius: 30px")
              i.fab.fa-whatsapp.fa-3x(style="color: white; margin: 5px 0 0 -2px") 
            .col-md-5
              p.fw-bold.text-white(style="margin-top: 7px") 55.7665.9885
              p.fw-bold.text-white(style="margin-top: -15px") opción 2
        .col-md-4
          .row
            .col-md-3(style="background-color: rgb(235, 129, 19); width: 60px; height: 60px; border-radius: 30px")
              i.fas.fa-phone-alt.fa-2x(style="color: white; margin: 12px 0 0 3px") 
            .col-md-5
              p.fw-bold.text-white(style="margin-top: 20px") 55.5511.9910
        .col-md-4
          .row
            .col-md-3(style="background-color: rgb(235, 129, 19); width: 60px; height: 60px; border-radius: 30px")
              i.far.fa-envelope.fa-2x(style="color: white; margin: 13px 0 0 3px") 
            .col-md-5
              p.fw-bold.text-white(style="margin-top: 20px") titulacion@hircasa.com.mx
    .row(style="text-align: center; margin: 30px 0")
      p.fw-bold.fs-2 ¡Estás a un paso de vivir tu sueño!
</template>

<style scoped>
.Principal {
  background-image: url("../assets/img_titulacion_principal.jpg");
  width: 100%;
}
</style>
<script lang="ts" setup>
import { ref, reactive, toRefs, onMounted } from "vue";
import api from "@/servicios/apiCliente";
const URL = ref((import.meta.env.BASE_URL || "").replace(/\/$/g, ""));
onMounted(async () => {
  URL.value += api.Cliente.Cliente.HomeApi.ObtenerArchivoPublicoUrl + "?ruta=guia-adjudicatario.pdf";
});
</script>
