<template lang="pug">
.timeline
  .progress(:style="{ width: progress + '%' }")
  .items
    .item(v-for="(item, i) in items" :key="item" :class="{ active: (100 / ((items || { length: 0 }).length - 1)) * i - progress <= 0.01 }")
      .content {{ item }}
</template>

<style lang="scss" scoped>
.timeline {
  margin: 50px 60px;
  height: 4px;
  background-color: #6e7599;

  .progress {
    height: 100%;
    background-color: #eb8113;
  }

  .items {
    margin-left: -10px;
    margin-right: -10px;
    margin-top: -12px;
    display: flex;
    justify-content: space-between;

    .item {
      position: relative;

      &.active {
        &::before {
          background-color: #eb8113;
        }

        .content {
          font-weight: bold;
          color: #eb8113;

          &::before {
            content: "";
            width: 10px;
            height: 10px;
            background-color: #fff;
            display: block;
            position: absolute;
            top: -10px;
            left: 50%;
            transform: translateX(-50%);
            border-radius: 100%;
          }
        }
      }

      &::before {
        content: "";
        width: 20px;
        height: 20px;
        background-color: #6e7599;
        display: block;
        border-radius: 100%;
      }

      .content {
        position: absolute;
        top: 15px;
        left: 50%;
        transform: translateX(-50%);
        // background-color: #ddd;
        width: 100px;
        padding: 5px 10px;
        border-radius: 5px;
        text-align: center;
        font-size: 75%;
      }
    }
  }
}
</style>

<script lang="ts" setup>
const props = defineProps({
  items: Array as () => string[],
  progress: Number
});

const items = props.items!;
</script>
