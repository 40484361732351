<template lang="pug">
.container.ff-poppins.pt-3: .row
  .col-md-12: .Principal(style="height: 500px")
    .row.justify-content-md-start
      .col-md-12.mt-4
      .col-md-12.mt-4
      .col-md-12.mt-4
      .col-md-12.mt-4
      .col-md-12.mt-4
      .col-md-12.mt-4
      .col-md-12.mt-4
      .col-md-12.mt-4
      .col-md-12.mt-4
      .col-md-12.mt-4
      .col-md-4.m-4: .card.mb-2(style="border-radius: 25px"): .card-body.bg-primary(style="border-radius: 25px")
        p.HirH1.text-white.text-center.fw-bold Pagos
        p.text-white.HirH5(style="text-align: justify") ¡Hola! Queremos hacerte más sencilla la realización de tus pagos, te contamos como hacerlo.
  .col-md-12: .mt-4.card(style="border-radius: 15px"): .card-body(style="border-radius: 15px"): .row
    .col-md-7
      p.HirH2.fw-bold.text-center Conoce toda la información de pagos
      p.text-center.text.text-muted Hemos preparado este video con toda la información como dónde puedes realizar tus pagos, manejo de la referencia única y mucho más.
      p.text-center.text.text-muted Recuerda que si deseas asesoría especifica puedes contactarnos.
    .col-md-5
      iframe(width="100%" height="100%" src="https://www.youtube.com/embed/pcgzz_DhdCM")
  .col-md-12: p.HirH2.fw-bold.text-center.mt-4 Te invitamos a Domiciliar
  .col-md-12: p.HirH3.fw-bold.text-center.mt-4 Continúa con tus actividades sin perder más tiempo en trámites, asegura la puntualidad en tus pagos y olvídate de hacer filas en el banco. Pagos con cargo automático a tu cuenta.
  .col-md-12: .mt-4.card(style="border-radius: 15px; background-color: rgb(89, 53, 223)"): .card-body(style="border-radius: 15px"): .row
    .col-md-4.m-4: img(src="../assets/img_domiciliar.png" style="margin: 10px auto; display: block")
    .col-md-6: .row.ps-5
      .col-md-12: .row
        .col-md-1: .Circulo: p.text-center.text-white 1
        .col-md-11: p.HirC.text-white Formato domiciliación.
      .col-md-12: .row
        .col-md-1: .Circulo: p.text-center.text-white 2
        .col-md-11: p.HirC.text-white Estado de cuenta no mayor a 3 meses con cuenta interbancaria visible.
      .col-md-12: .row
        .col-md-1: .Circulo: p.text-center.text-white 3
        .col-md-11: p.HirC.text-white Identificación oficial vigente.
      .col-md-12: .row
        .col-md-1: .Circulo: p.text-center.text-white 4
        .col-md-11: .row
          .col-md-12: p.HirC.text-white Agenda cita o envía tus documentos.
          .col-md-12
            p.HirC.text-white Número telefónico:&#32;
              span.text-white.fw-bold 55 5511 9910 Opc. 2
          .col-md-12
            p.HirC.text-white WhatsApp:&#32;
              span.text-white.fw-bold 55 7665 9885 (Solo mensajes).
          .col-md-12
            p.HirC.text-white Correo Electrónico:&#32;
              span.text-white.fw-bold atencionaclientes@hircasa.com.mx
  .col-md-12: .mt-4.card(style="border-radius: 15px"): .card-body(style="border-radius: 15px"): .row
    .col-md-12: p.HirH2.fw-bold.text-center Formas de Pago
    .col-md-12
      .row.justify-content-md-center
        .col-md-4: img(src="../assets/i_pago_01.svg" style="margin: 10px auto; display: block")
        .col-md-4: img.mt-4(src="../assets/i_pago_02.svg" style="margin: 10px auto; display: block")
        .col-md-4: img.mt-4(src="../assets/i_pago_03.svg" style="margin: 10px auto; display: block")
      .row.justify-content-md-center
        .col-md-4
          p.HirH4.fw-bold.text-center Transferencia Electrónica
          p.HirP.mt-4(style="text-align: justify; text-justify: inter-word") Por medio del App o Sitio Web de tu banca; como pago de servicio, pago a terceros SPEI.
        .col-md-4
          p.HirH4.fw-bold.text-center Establecimiento
          p.HirP.mt-4(style="text-align: justify; text-justify: inter-word") Realiza tu pago en 7 Eleven, Chedraui y Telecom,&#32;
            span.text-muted.fw-bold USANDO EL CONVENIO DE BANORTE&#32;
            | (aplica costo adicional según el establecimiento)
        .col-md-4
          p.HirH4.fw-bold.text-center Efectivo o Cheque
          p.HirP(style="text-align: justify; text-justify: inter-word") Depositando en la sucursal de tu preferencia, Citibanamex, Banorte, Scotiabank y Santander.
            | &#32;En el caso de BBVA podrás realizar el pago a través de practicaja.

  .col-md-12: p.HirH2.fw-bold.text-center.mt-4 Tipos de Pagos
  .col-md-12: .mt-4.card(style="border-radius: 15px; background-color: rgb(89, 53, 223)"): .card-body(style="border-radius: 15px")
    .row.justify-content-md-center
      .col-md-4: img(src="../assets/i_pagopuntual.svg" style="margin: 10px auto; display: block")
      .col-md-4: img(src="../assets/i_pagoanticipado.svg" style="margin: 10px auto; display: block")
      .col-md-4: img(src="../assets/i_pagomoroso.svg" style="margin: 10px auto; display: block")
    .row.justify-content-md-center
      .col-md-4
        p.HirH4.fw-bold.text-center.mt-4(style="color: white") Pago Puntual
        p.HirP(style="color: white; text-align: justify; text-justify: inter-word") Para mantener un buen historial, efectúa tu pago desde el día 15 del mes corriente, hasta el primer día hábil del siguiente mes.
      .col-md-4
        p.HirH4.fw-bold.text-center.mt-4(style="color: white") Pago anticipado
        p.HirP(style="color: white; text-align: justify; text-justify: inter-word") Una vez cubierta tu cuota mensual puedes anticipar pagos en el momento que lo desees. Recuerda que la cuota será de un valor menor.
        p.HirP(style="color: white; text-align: justify; text-justify: inter-word") Esto te ayudará a que el tiempo para obtener tu adjudicación y terminar tu financiamiento se reduzca.
      .col-md-4
        p.HirH4.fw-bold.text-center.mt-4(style="color: white") Pago Moroso
        p.HirP(style="color: white; text-align: justify; text-justify: inter-word") ¡IMPORTANTE! Si no efectúas tu pago para la fecha límite, entrarás en morosidad y o tendrás la cobertura de tus seguros.
  .col-md-12: .mt-4.card(style="border-radius: 15px"): .card-body(style="border-radius: 15px"): .row
    .col-md-4.m-4: img(src="../assets/img_edo_cuenta.png" style="margin: 10px auto; display: block")
    .col-md-6: .row
      .col-md-12: p.HirH3.fw-bold Estado de Cuenta
      .col-md-12
        p.HirP.text-primary(style="text-align: justify; text-justify: inter-word") HIR Casa&#32;
          span.text-muted emite de forma mensual tu estado de cuenta y un complemento. Puedes recibirlo por&#32;
          span.text-muted.fw-bold email o de forma física&#32;
          span.text-muted en el domicilio registrado.
      .col-md-12: p.text-muted.HirP Solicitarlo a través de&#32;
        span.text-muted.fw-bold WhatsApp 55 7665 9885, en www.hircasa.com.mx o llamando al 55 5511 9910 Opc. 2
      .col-md-12: .row
        .col-md-4.m-auto: .mt-4.card(style="border-radius: 15px; background-color: #fd7e14"): a.card-body.text-decoration-none(
          style="border-radius: 15px"
          href="#"
          @click.prevent="mostrarPopUp()"
        ): .row
          .col-md-12: img(src="../assets/leer_edo_cuenta.svg" style="margin: 10px auto; display: block")
          .col-md-12: p.fs-6.fw-bold.text-center.mt-4(style="color: white") Aprende a leer tu estado de cuenta
          //- Modal de Estados de Cuentas
        .col-md-4.m-auto: .mt-4.card(style="border-radius: 15px; background-color: #fd7e14"): router-link.text-decoration-none.card-body(
          :to="{ name: 'cliente-cliente-financiamiento' }"
          style="border-radius: 15px"
        ): .row
          .col-md-12: img(src="../assets/consultar_edo_cuenta.svg" style="margin: 10px auto; display: block")
          .col-md-12: p.fs-6.fw-bold.text-center.mt-4(style="color: white") Consulta tu ultimó estado de cuenta.
        .col-md-4.m-auto: .mt-4.card(style="border-radius: 15px; background-color: #198754"): router-link.text-decoration-none.card-body(
          :to="{ name: 'cliente-cliente-programa-recicla' }"
          style="border-radius: 15px"
        ): .row
          .col-md-12: img(src="../assets/recicla.svg" style="margin: 10px auto; display: block")
          .col-md-12: p.fw-bold.text-center.mt-4(style="color: white; font-size: 10px") Comprometidos con el medio ambiente puedes sumarte a nuestro programa recicla.
  #estadoCuentaModal.modal.fade(tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true")
    .modal-dialog.modal-dialog-centered.mw-100.w-75(role="document")
      .modal-content
        .modal-body
          p.m-2.text-center.h2(style="color: #34189a; font-weight: bold") ¿Como leer tu Estado de Cuenta?
          .row.justify-content-md-center: .col-md-7: Slider-Carousel(
            :ImagenesProp="['img/slider/01.jpg', 'img/slider/02.jpg', 'img/slider/03.jpg', 'img/slider/04.jpg', 'img/slider/05.jpg', 'img/slider/06.jpg', 'img/slider/07.jpg', 'img/slider/08.jpg', 'img/slider/09.jpg', 'img/slider/10.jpg', 'img/slider/11.jpg', 'img/slider/12.jpg', 'img/slider/13.jpg', 'img/slider/14.jpg', 'img/slider/15.jpg']"
          )
        .modal-footer
          button.btn.btn-primary.text-white.fw-bold(data-dismiss="modal" @click="ocultarPopUp()") Cerrar
</template>
<style scoped>
.Principal {
  background-image: url("../assets/img_pagos_principal.jpg");
  width: 100%;
}

.Circulo {
  height: 25px;
  width: 25px;
  background-color: #fd7e14;
  border-radius: 50%;
  display: block;
}

.HirH1 {
  font-size: 40px;
  color: #2f2d74;
}

.HirH2 {
  font-size: 36px;
  color: #2f2d74;
}

.HirH3 {
  font-size: 28px;
  color: #2f2d74;
}

.HirH4 {
  font-size: 24px;
  color: #2f2d74;
}

.HirH5 {
  font-size: 18px;
  color: #2f2d74;
}

.HirH6 {
  font-size: 14px;
  color: #2f2d74;
}

.HirP {
  font-size: 16px;
  color: #2f2d74;
}

.HirC {
  font-size: 20px;
  color: white;
}
</style>
<script lang="ts" setup>
import { ref, onMounted } from "vue";
import api from "@/servicios/apiCliente";
import * as bootstrap from "bootstrap";
import SliderCarousel from "../components/SliderCarousel.vue";
const URL = ref("");
let estadoCuentaDescuentos = null as null | bootstrap.Modal;
function ocultarPopUp() {
  estadoCuentaDescuentos?.hide();
}
function mostrarPopUp() {
  estadoCuentaDescuentos?.show();
}
onMounted(async () => {
  URL.value =
    (import.meta.env.BASE_URL || "").replace(/\/$/g, "") +
    api.Cliente.Cliente.HomeApi.ObtenerArchivoPublicoUrl +
    "?archivo=guia_adjudicatario.pdf";
  estadoCuentaDescuentos = new bootstrap.Modal(document.querySelector("#estadoCuentaModal") as HTMLDivElement);
});
</script>
