<template lang="pug">
.container.ff-poppins.pt-3: .row
  .col-md-6
    p.fs-2.fw-bold(style="color: #2f2d74") Materiales de Apoyo
    .row: .col-md-12: input.form-control.mt-4(type="search" v-model="filtrarMaterialApoyo" placeholder="Busqueda de Material de Apoyo")
    p.text-muted.mt-4(v-if="resultadoBusqueda.length == 0") Puedes encontrar toda la documentación de apoyo de tu proceso en &#32;
      span.text-primary.fw-bold HIR Casa.
      | &#32; Cualquier duda que persista puedes consultarla directamente con nosotros a través de nuestro&#32;
      span.text-primary.fw-bold Chat
      | &#32; o &#32;
      span.text-primary.fw-bold WhatsApp.
    .row(v-if="resultadoBusqueda.length > 0")
      .col-md-12.mt-2.text-center.fw-bold.fs-6(
        v-for="(resp, i) in resultadoBusqueda"
        :key="resp"
        :style="crearStyle(resp.tipo)"
        :class="crearClass(resp.tipo)"
        @click.prevent="crearRuta(resp)"
      ): p.text-center.fw-bold(
        style="color: #2f2d74"
      ) {{ resp.texto }}
  .col-md-6.text-end: img(src="../assets/img_main_pc.png" style="height: 340px")
  .bg-white.mt-3.botones-secciones.pt-3.mb-4
    .row: .col.text-center.fw-bold.fs-5(style="color: #2f2d74") Ayuda por Temas
    .row.justify-content-center.mt-3.mb-3
      template(v-for="(item, i) in menuMaterialApoyo" :key="i")
        .col-2(:id="i == 106 ? 'margin-to-left' : ''" :style="[i == 109 ? { transform: 'translateX(50%)' } : {}]")
          router-link.text-decoration-none(:to="{ name: item.path }")
            img.rounded.mx-auto.d-block.mb-2(:src="item.icon")
            p.text-center.fw-bold(style="color: #2f2d74") {{ item.nombre }}
</template>
<script lang="ts" setup>
import { ref, watch, onMounted } from "vue";
import api from "@/servicios/apiCliente";
import { useRouter } from "vue-router";
import type { Cliente } from "@/servicios/api";
interface MaterialApoyo {
  texto: string;
  tipo: number;
  ruta: string;
}
//#region Data
const $router = useRouter();
const filtrarMaterialApoyo = ref("");
const resultadoBusqueda = ref([] as any[]);
const materialApoyo = ref([] as MaterialApoyo[]);
const titulos = ref([] as any[]);
const menuMaterialApoyo = ref([] as Cliente.Cliente.MaterialApoyo[]);
const arrayCopy = ref([] as any[]);

//#endregion

//#region Methods
function crearStyle(value: number): string {
  if (value == 0) return "color: #2f2d74";
  if (value == 3) return "color: #c8c8c8";
  return "";
}
function crearClass(value: number): string {
  if (value == 1) return "text-primary";
  if (value == 2) return "text-muted";
  return "";
}
function cargarElementos() {
  materialApoyo.value = [];
  //- Titulos Menu
  materialApoyo.value.push({ texto: "Bienvenida", tipo: 0, ruta: "cliente-cliente-material-apoyo-bienvenida" });
  materialApoyo.value.push({ texto: "Introducción", tipo: 1, ruta: "cliente-cliente-home" });
  materialApoyo.value.push({
    texto: "Bullets relevantes de HIR CASA Link a Conócenos Página web",
    tipo: 1,
    ruta: "cliente-cliente-home"
  });
  materialApoyo.value.push({
    texto: "¿Cómo Funciona?",
    tipo: 1,
    ruta: "cliente-cliente-home"
  });
  materialApoyo.value.push({
    texto: "Roadmap",
    tipo: 1,
    ruta: "cliente-cliente-home"
  });
  materialApoyo.value.push({
    texto: "Acciones Generales",
    tipo: 0,
    ruta: "cliente-cliente-home"
  });
  materialApoyo.value.push({
    texto: "Cambio de Password",
    tipo: 0,
    ruta: "cliente-cliente-home"
  });
  materialApoyo.value.push({
    texto: "Actualización de Datos (Mi Perfil) Formas de Pago Cesión de Derechos",
    tipo: 2,
    ruta: "cliente-cliente-home"
  });
  materialApoyo.value.push({
    texto: "Contacto Asesor",
    tipo: 1,
    ruta: "cliente-cliente-home"
  });
  //- Mi Pagos
  materialApoyo.value.push({
    texto: "Pagos",
    tipo: 0,
    ruta: "cliente-cliente-material-apoyo-pagos"
  });
  materialApoyo.value.push({
    texto: "Inducción(¿Por qué es importante el pago?)",
    tipo: 1,
    ruta: "cliente-cliente-material-apoyo-pagos"
  });
  materialApoyo.value.push({
    texto: "Descripción General Referente a pago (Aclaraciones y Consultas)",
    tipo: 2,
    ruta: "cliente-cliente-material-apoyo-pagos"
  });
  materialApoyo.value.push({
    texto: "Descripción de Estado de Cuenta",
    tipo: 1,
    ruta: "cliente-cliente-material-apoyo-pagos"
  });
  materialApoyo.value.push({
    texto: "Métodos de Pago",
    tipo: 1,
    ruta: "cliente-cliente-material-apoyo-pagos"
  });
  materialApoyo.value.push({
    texto: "Pago por referencia Única Contrato por Contrato",
    tipo: 2,
    ruta: "cliente-cliente-material-apoyo-pagos"
  });
  materialApoyo.value.push({
    texto: "¿Dónde puedo hacer el pago?",
    tipo: 1,
    ruta: "cliente-cliente-material-apoyo-pagos"
  });
  materialApoyo.value.push({
    texto: "Ventanilla Bancaria",
    tipo: 2,
    ruta: "cliente-cliente-material-apoyo-pagos"
  });
  materialApoyo.value.push({
    texto: "Transferencia",
    tipo: 2,
    ruta: "cliente-cliente-material-apoyo-pagos"
  });
  materialApoyo.value.push({
    texto: "Cheque",
    tipo: 2,
    ruta: "cliente-cliente-material-apoyo-pagos"
  });
  materialApoyo.value.push({
    texto: "Corresponsal",
    tipo: 2,
    ruta: "cliente-cliente-material-apoyo-pagos"
  });
  materialApoyo.value.push({
    texto: "Domiciliación",
    tipo: 2,
    ruta: "cliente-cliente-material-apoyo-pagos"
  });
  //- Mi Tramite
  materialApoyo.value.push({
    texto: "Tramites",
    tipo: 0,
    ruta: "cliente-cliente-home"
  });
  materialApoyo.value.push({
    texto: "Inducción",
    tipo: 1,
    ruta: "cliente-cliente-home"
  });
  materialApoyo.value.push({
    texto: "Descripción General",
    tipo: 2,
    ruta: "cliente-cliente-home"
  });
  materialApoyo.value.push({
    texto: "Tipos de Trámites",
    tipo: 1,
    ruta: "cliente-cliente-home"
  });
  materialApoyo.value.push({
    texto: "Cambio de Domicilio",
    tipo: 2,
    ruta: "cliente-cliente-home"
  });
  materialApoyo.value.push({
    texto: "Cambio de Datos de Contacto",
    tipo: 2,
    ruta: "cliente-cliente-home"
  });
  materialApoyo.value.push({
    texto: "Cambio de Beneficiarios",
    tipo: 2,
    ruta: "cliente-cliente-home"
  });
  materialApoyo.value.push({
    texto: "Fusión",
    tipo: 2,
    ruta: "cliente-cliente-home"
  });
  materialApoyo.value.push({
    texto: "Sesión de Derechos",
    tipo: 2,
    ruta: "cliente-cliente-home"
  });
  materialApoyo.value.push({
    texto: "Incremento de Monto Contratado",
    tipo: 2,
    ruta: "cliente-cliente-home"
  });
  materialApoyo.value.push({
    texto: "Disminución de Monto",
    tipo: 2,
    ruta: "cliente-cliente-home"
  });
  materialApoyo.value.push({
    texto: "Liquidación Anticipada",
    tipo: 2,
    ruta: "cliente-cliente-material-apoyo-liquidacion"
  });
  materialApoyo.value.push({
    texto: "Cancelación",
    tipo: 2,
    ruta: "cliente-cliente-home"
  });
  materialApoyo.value.push({
    texto: "Domiciliación",
    tipo: 2,
    ruta: "cliente-cliente-home"
  });
  materialApoyo.value.push({
    texto: "Seguro de Vida",
    tipo: 2,
    ruta: "cliente-cliente-home"
  });
  materialApoyo.value.push({
    texto: "Seguro de Daños",
    tipo: 2,
    ruta: "cliente-cliente-home"
  });
  materialApoyo.value.push({
    texto: "Aclaraciones",
    tipo: 2,
    ruta: "cliente-cliente-home"
  });
  //- Mi Adjudicacion
  materialApoyo.value.push({ texto: "Adjudicación", tipo: 0, ruta: "cliente-cliente-material-apoyo-Adjudiacion" });
  materialApoyo.value.push({
    texto: "Inducción Adjudicación",
    tipo: 1,
    ruta: "cliente-cliente-material-apoyo-Adjudiacion"
  });
  materialApoyo.value.push({
    texto: "¿Qué es una Adjudicación?",
    tipo: 2,
    ruta: "cliente-cliente-material-apoyo-Adjudiacion"
  });
  materialApoyo.value.push({
    texto: "Formas de Adjudicación",
    tipo: 1,
    ruta: "cliente-cliente-material-apoyo-Adjudiacion"
  });
  materialApoyo.value.push({ texto: "Natural", tipo: 2, ruta: "cliente-cliente-material-apoyo-Adjudiacion" });
  materialApoyo.value.push({ texto: "Obligatoria", tipo: 2, ruta: "cliente-cliente-material-apoyo-Adjudiacion" });
  materialApoyo.value.push({ texto: "Lista de Espera", tipo: 2, ruta: "cliente-cliente-material-apoyo-Adjudiacion" });
  materialApoyo.value.push({
    texto: "Guia de Adjudicatario",
    tipo: 1,
    ruta: "cliente-cliente-material-apoyo-Adjudiacion"
  });
  materialApoyo.value.push({
    texto: "Complemento Cuota de Inscripción",
    tipo: 1,
    ruta: "cliente-cliente-material-apoyo-Adjudiacion"
  });
  materialApoyo.value.push({
    texto: "Rechazo de Adjudicación",
    tipo: 1,
    ruta: "cliente-cliente-material-apoyo-Adjudiacion"
  });
  //- Mi Escrituracion
  materialApoyo.value.push({ texto: "Escrituración", tipo: 0, ruta: "cliente-cliente-home" });
  materialApoyo.value.push({ texto: "Inducción", tipo: 1, ruta: "cliente-cliente-home" });
  materialApoyo.value.push({ texto: "¿Qué es Escrituración?", tipo: 2, ruta: "cliente-cliente-home" });
  materialApoyo.value.push({ texto: "Requisitos para Escriturar", tipo: 1, ruta: "cliente-cliente-home" });
  materialApoyo.value.push({ texto: "Actualización de ES", tipo: 2, ruta: "cliente-cliente-home" });
  materialApoyo.value.push({ texto: "Infografía de Proceso de titulación", tipo: 3, ruta: "cliente-cliente-home" });
  materialApoyo.value.push({ texto: "Consulta de Buró de Crédito", tipo: 2, ruta: "cliente-cliente-home" });
  materialApoyo.value.push({ texto: "Trámite del Avalúo", tipo: 2, ruta: "cliente-cliente-home" });
  materialApoyo.value.push({ texto: "Infografía de proceso", tipo: 3, ruta: "cliente-cliente-home" });
  materialApoyo.value.push({ texto: "Catálogo de Notarias", tipo: 1, ruta: "cliente-cliente-home" });
  //- Mi Seguro
  materialApoyo.value.push({ texto: "Seguros", tipo: 0, ruta: "cliente-cliente-home" });
  materialApoyo.value.push({ texto: "Inducción", tipo: 1, ruta: "cliente-cliente-home" });
  materialApoyo.value.push({ texto: "¿Por qué necesito un seguro?", tipo: 2, ruta: "cliente-cliente-home" });
  materialApoyo.value.push({ texto: "Tipos de Seguro", tipo: 1, ruta: "cliente-cliente-home" });
  materialApoyo.value.push({ texto: "Descripción e Infografia", tipo: 3, ruta: "cliente-cliente-home" });
  materialApoyo.value.push({
    texto: "*Proceso de Reclamo de Seguro (Siniestros)",
    tipo: 3,
    ruta: "cliente-cliente-home"
  });
  materialApoyo.value.push({ texto: "Daños", tipo: 2, ruta: "cliente-cliente-home" });
  materialApoyo.value.push({ texto: "Descripción e Infografia", tipo: 3, ruta: "cliente-cliente-home" });
  materialApoyo.value.push({
    texto: "*Proceso de Reclamo de Seguro (Siniestros)",
    tipo: 3,
    ruta: "cliente-cliente-home"
  });
}
function crearRuta(value: any) {
  $router.push({ name: value.ruta });
}
//#endregion

//#region Watchers
watch(filtrarMaterialApoyo, value => {
  filtrarMaterialApoyo.value = value.toUpperCase();
  cargarElementos();
  if (
    filtrarMaterialApoyo.value == "" ||
    filtrarMaterialApoyo.value == undefined ||
    filtrarMaterialApoyo.value == null
  ) {
    resultadoBusqueda.value = [];
    return;
  }
  resultadoBusqueda.value = materialApoyo.value.filter(item => {
    return item.texto?.toUpperCase().includes(filtrarMaterialApoyo.value) && item.tipo != 0;
  });
});
watch(resultadoBusqueda, value => {
  resultadoBusqueda.value = value;
});
//#endregion

onMounted(async () => {
  cargarElementos();
  menuMaterialApoyo.value = (await api.Cliente.Cliente.HomeApi.ListarMaterialApoyo()).data;
  arrayCopy.value = [...menuMaterialApoyo.value];  
});
</script>

<style scoped>
#margin-to-left {
  margin-left: 200px !important;
}
img.rounded {
  height: 105px;
}
</style>
