<template lang="pug">
.container.ff-poppins(:class="{ 'was-validated': formularioValidado }")
  .row.mt-2(style="margin-top: -27px")
    .col-md-5.mt-2.mb-2
      .p-3.fw-bold.text-secondary.text-white.fs-5(style="background: #5935df; border-radius: 8px 8px 0 0") Reportar Siniestro de vida
      .card(style="border-radius: 0 0 8px 8px"): .card-body
        .row.justify-content-left
          .col-12.mt-4
            label.form-label.fw-bold.fs-5(style="color: #2f2d74") Nombre del beneficiario.
            input.form-control(
              :form="componentId + 'form'"
              type="text"
              v-model="CuerpoCorreo.nombreBeneficiario.value"
              required
              pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+"
              style="border-radius: 90px"
            )
            .invalid-feedback Campo requerido.
          .col-12.mt-4
            label.form-label.fw-bold.fs-5(style="color: #2f2d74") Correo Electrónico
            input.form-control(
              :form="componentId + 'form'"
              type="email"
              v-model="CuerpoCorreo.correo.value"
              required
              pattern="[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}"
              style="border-radius: 90px"
            )
            .invalid-feedback Correo Electrónico invalido.
          .col-12.mt-4
            label.form-label.fw-bold.fs-5(style="color: #2f2d74") Nombre completo del titular
            input.form-control(
              :form="componentId + 'form'"
              type="text"
              v-model="CuerpoCorreo.nombreTitular.value"
              required
              pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+"
              style="border-radius: 90px"
            )
            .invalid-feedback Campo requerido.
          .col-12.mt-4
            .row
              label.form-label.fw-bold.fs-5(style="color: #2f2d74") Teléfonos de contacto beneficiario
              .col-6
                input.form-control(
                  :form="componentId + 'form'"
                  type="tel"
                  v-model="CuerpoCorreo.telefono1.value"
                  required
                  pattern="[0-9]{10}"
                  style="border-radius: 90px"
                )
                .invalid-feedback Número teléfonico invalido.
              .col-6
                input.form-control(
                  :form="componentId + 'form'"
                  type="tel"
                  v-model="CuerpoCorreo.telefono2.value"
                  pattern="[0-9]{10}"
                  style="border-radius: 90px"
                )
          .col-12.text-center.mt-4
            form.text-center(ref="formDom" novalidate :id="componentId + 'form'" @submit.prevent="EnviarCorreoSinietro()"): button.btn.btn-primary.text-white.px-5.fw-bold(
              style="border-radius: 20px; background: #ffa000"
            ) Enviar
          .col-12.text-center.mt-4
            router-link.fw-bold(:to="{ name: 'cliente-cliente-login' }" style="color: #ffa000") Regresar
    .col-md-5.mt-2.offset-md-1
      .card(style="border-radius: 0 0 0px 0px"): .card-body
        .div.justify-content-between.px-2(style="")
          .row
            .col-12
              label.form-label.fw-bold.fs-5(style="color: #2f2d74") Contactar al Área de Seguros.
              p(style="color: #ffa000") 
                b Atención de Seguros (Experiencia al Cliente)
          .row
            .col-7
              p(style="color: #2f2d74; font-size: 12px") 
                b Tel: +52 55 5511 9910 ext 6158 y 6161
              p(style="color: #2f2d74; font-size: 12px") 
                b Whatsapp: +52 55 7665 9885
              p(style="color: #2f2d74; font-size: 12px") 
                b Correo: atencionseguros@hircasa.com.mx
            .col-5.d-flex.align-items-end.p-2.mb-2
              img.img-fluid.img-fluid(src="../../../assets/LogoColor2.png" style="height: 60px")
</template>

<script lang="ts" setup>
import * as utilidades from "@/utilidades";
import { ref, reactive, toRefs, toRaw } from "vue";
import api from "@/servicios/apiCliente";
import { Cliente } from "@/servicios/api";
import { useRouter } from "vue-router";
const $router = useRouter();

//#region Data
const componentId = utilidades.generarId();
const formularioValidado = ref(false);
const formDom = ref(null as null | HTMLFormElement);

//Creación de un objeto EnviarCorreoSiniestroVidaBody
const reactiveCorreoSiniestro = reactive({
  nombreBeneficiario: "",
  correo: "",
  nombreTitular: "",
  telefono1: "",
  telefono2: ""
} as Cliente.Cliente.Siniestro.EnviarCorreoSiniestroVidaBody);
const CuerpoCorreo = toRefs(reactiveCorreoSiniestro);
//#endregion

//#region Methods
function EnviarCorreoSinietro() {
  formularioValidado.value = true;
  if (formDom.value!.checkValidity()) {
    var res = api.Cliente.Cliente.SiniestroApi.EnviarCorreoSiniestroVida(toRaw(reactiveCorreoSiniestro));
    $router.push("cliente-cliente-login");
  }
}
//#endregion)
</script>
