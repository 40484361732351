<template lang="pug">
.d-flex.flex-column.px-3.py-3.justify-content-start.g-0.w-100
  HeaderForm
  General(ref="FormularioG")
  .d-flex.justify-content-between.mt-5
    button.btn.btn-outline-primary(style="width: 100px" @click="anterior" :disabled="store.datos_figura[store.getFigura].estudioBloqueado")
      | Anterior
    button.btn.btn-primary.text-white(style="width: 100px" @click="siguiente" :disabled="store.datos_figura[store.getFigura].estudioBloqueado")
      | Siguiente
</template>
<script lang="ts">
import { defineAsyncComponent, onMounted, ref, watch, onUpdated } from "vue";
import { useRouter } from "vue-router";
import { useStoreTitular } from "../storeTitular";
import { useStore } from "@/store";
import { TipoBootstrap } from "@/interfaces";

export default {
  components: {
    General: defineAsyncComponent(() => import("../components/Ingresos/General.vue")),
    HeaderForm: defineAsyncComponent(() => import("./HeaderForm.vue"))
  },
  props: ["figura"],
  setup() {
    const router = useRouter(); //Navegar hacia un lugar
    const store = useStoreTitular();
    const $store = useStore();
    const FormularioG = ref();
    /**Métodos  */

    const siguiente = () => {
      FormularioG.value.validar();
      //Validar ruta para determinar el camino correcto segun la figura

      if (store.$state.formulario_ingresos.completado) {
        store.guardarFigura(store.$state.datos_figura[store.figuraActual]);
        if (store.tieneSesion) router.push({ name: "gastos" });
        else router.push({ name: "gastos", query: { operacionId: store.operacionTitulacionId } });
      } else {
        $store.agregarToast({
          tipo: TipoBootstrap.DANGER,
          body: "Llenar todos los campos correctamente.",
          mensaje: "Texto"
        });
      }
    };

    return {
      siguiente,
      anterior: () => {
        if (store.tieneSesion) router.push({ name: "datos-generales" });
        else router.push({ name: "datos-generales", query: { operacionId: store.operacionTitulacionId } });
      },
      FormularioG,
      store
    };
  }
};
</script>

<style>
.form-label {
  font-size: 14px;
  font-weight: 600;
}
</style>
