<template lang="pug">
.card: .card-body
  .row
    .col-md-12: p.fw-bold.h3(style="color: #2f2d74") Cargar Documentación
    .col-md-8.mt-2: p(style="color: #2f2d74") Copia de acta de nacimiento
    .col-md-2: label.btn.btn-primary.text-white
      span.fas.fa-upload &#32;
      input(type="file" style="display: none" v-on:change="guardarDocuemento($event, 0)")
      | Cargar
    .col-md-2: label.gb-white.text-muted(v-if="mostrarEnlace(0)")
      span.fas.fa-eye &#32;
      a.text-muted.text-decoration-none(:href="mostrarDocumento(0)" target="_blank") Ver
    .col-md-8.mt-2: p(style="color: #2f2d74") Copia de comprobante de domicilio
    .col-md-2: label.btn.btn-primary.text-white
      span.fas.fa-upload &#32;
      input(type="file" style="display: none" v-on:change="guardarDocuemento($event, 1)")
      | Cargar
    .col-md-2: label.gb-white.text-muted(v-if="mostrarEnlace(1)")
      span.fas.fa-eye &#32;
      a.text-muted.text-decoration-none(:href="mostrarDocumento(1)" target="_blank") Ver
    .col-md-8.mt-2: p(style="color: #2f2d74") Copia de INE
    .col-md-2: label.btn.btn-primary.text-white
      span.fas.fa-upload &#32;
      input(type="file" style="display: none" v-on:change="guardarDocuemento($event, 2)")
      | Cargar
    .col-md-2: label.gb-white.text-muted(v-if="mostrarEnlace(2)")
      span.fas.fa-eye &#32;
      a.text-muted.text-decoration-none(:href="mostrarDocumento(2)" target="_blank") Ver
    .col-md-8.mt-2: p(style="color: #2f2d74") Cálculo de finiquito con firma o sello de la institución
    .col-md-2: label.btn.btn-primary.text-white
      span.fas.fa-upload &#32;
      input(type="file" style="display: none" v-on:change="guardarDocuemento($event, 3)")
      | Cargar
    .col-md-2: label.gb-white.text-muted(v-if="mostrarEnlace(3)")
      span.fas.fa-eye &#32;
      a.text-muted.text-decoration-none(:href="mostrarDocumento(3)" target="_blank") Ver
    .col-md-8.mt-2: p(style="color: #2f2d74") Contrato laboral
    .col-md-2: label.btn.btn-primary.text-white
      span.fas.fa-upload &#32;
      input(type="file" style="display: none" v-on:change="guardarDocuemento($event, 4)")
      | Cargar
    .col-md-2: label.gb-white.text-muted(v-if="mostrarEnlace(4)")
      span.fas.fa-eye &#32;
      a.text-muted.text-decoration-none(:href="mostrarDocumento(4)" target="_blank") Ver
    .col-md-8.mt-2: p(style="color: #2f2d74") 3 recibos de nómina anteriores a la fecha que perdió el empleo
    .col-md-2: label.btn.btn-primary.text-white
      span.fas.fa-upload &#32;
      input(type="file" style="display: none" v-on:change="guardarDocuemento($event, 5)")
      | Cargar
    .col-md-2: label.gb-white.text-muted(v-if="mostrarEnlace(5)")
      span.fas.fa-eye &#32;
      a.text-muted.text-decoration-none(:href="mostrarDocumento(5)" target="_blank") Ver
    .col-md-8.mt-2: p(style="color: #2f2d74") Baja de seguro
    .col-md-2: label.btn.btn-primary.text-white
      span.fas.fa-upload &#32;
      input(type="file" style="display: none" v-on:change="guardarDocuemento($event, 6)")
      | Cargar
    .col-md-2: label.gb-white.text-muted(v-if="mostrarEnlace(6)")
      span.fas.fa-eye &#32;
      a.text-muted.text-decoration-none(:href="mostrarDocumento(6)" target="_blank") Ver
    .col-md-8.mt-2: p(style="color: #2f2d74") Carta indicando el motivo del despido injustificado
    .col-md-2: label.btn.btn-primary.text-white
      span.fas.fa-upload &#32;
      input(type="file" style="display: none" v-on:change="guardarDocuemento($event, 7)")
      | Cargar
    .col-md-2: label.gb-white.text-muted(v-if="mostrarEnlace(7)")
      span.fas.fa-eye &#32;
      a.text-muted.text-decoration-none(:href="mostrarDocumento(7)" target="_blank") Ver
    .col-md-8.mt-2: a.text-primary.text-decoration-underline(href="cliente/cliente/pdf/formato/siniestro/desempleo.pdf" target="_blank") Formato de reclamación de siniestro
    .col-md-2: label.btn.btn-primary.text-white
      span.fas.fa-upload &#32;
      input(type="file" style="display: none" v-on:change="guardarDocuemento($event, 8)")
      | Cargar
    .col-md-2: label.gb-white.text-muted(v-if="mostrarEnlace(8)")
      span.fas.fa-eye &#32;
      a.text-muted.text-decoration-none(:href="mostrarDocumento(8)" target="_blank") Ver
    .col-md-12.mt-2
      .form-check.form-check-inline
        input.form-check-input(type="checkbox" :id="componentId + 'muerteAccidental'" :name="componentId + 'muerteAccidental'" :value="1" v-model="opcionales")
        label.form-check-label(:for="componentId + 'muerteAccidental'" style="color: #2f2d74") ¿Es empleado de gobierno?
    .col-md-8.mt-2: p(style="color: #2f2d74" v-if="empleadoGobierno") Hoja Única de Servicio y/o Constancia Nombramiento (Especifique la entrega del puesto)
    .col-md-2(v-if="empleadoGobierno"): label.btn.btn-primary.text-white
      span.fas.fa-upload &#32;
      input(type="file" style="display: none" v-on:change="guardarDocuemento($event, 9)")
      | Cargar
    .col-md-2: label.gb-white.text-muted(v-if="mostrarEnlace(9)")
      span.fas.fa-eye &#32;
      a.text-muted.text-decoration-none(:href="mostrarDocumento(9)" target="_blank") Ver
    .col-md-8.mt-2: p(style="color: #2f2d74" v-if="empleadoGobierno") Estado de cuenta bancario que refleje el pago referido
    .col-md-2(v-if="empleadoGobierno"): label.btn.btn-primary.text-white
      span.fas.fa-upload &#32;
      input(type="file" style="display: none" v-on:change="guardarDocuemento($event, 10)")
      | Cargar
    .col-md-2: label.gb-white.text-muted(v-if="mostrarEnlace(10)")
      span.fas.fa-eye &#32;
      a.text-muted.text-decoration-none(:href="mostrarDocumento(10)" target="_blank") Ver
    .col-md-12.mt-4: .row.justify-content-center
      .col-md-3: button.btn.bg-white.text-primary.border.border-primary Cancelar
      .col-md-3: button.btn.bg-white.text-primary.border.border-primary Llamar Asesor
      .col-md-3: button.btn.btn-primary.text-white(@click.prevent="enviarNotificacion()") Enviar Información
    Spinner-Loader#spinner(name="spinner")
</template>
<script lang="ts" setup>
import { ref, onMounted, watch, nextTick } from "vue";
import * as utilidades from "@/utilidades";
import api from "@/servicios/apiCliente";
import { useStore } from "@/store";
import { Cliente, Core, EstadoRespuesta } from "@/servicios/api";
const $store = useStore();
const componentId = utilidades.generarId();
const empleadoGobierno = ref(false);
const opcionales = ref([] as number[]);
const documentosVida = ref([] as Cliente.Cliente.Siniestro.ListaDocumentos[]);
//#endregion Props
interface Props {
  Siniestro: Cliente.Cliente.Siniestro.ListaDocumentos[];
}
const props = withDefaults(defineProps<Props>(), {
  Siniestro: () => []
});

watch(opcionales, () => {
  empleadoGobierno.value = opcionales.value.includes(1) || opcionales.value.includes(2);
});
function mostrarDocumento(index: number) {
  if (!documentosVida.value.length) return "";
  let nombre = documentosVida.value[index].nombreDocumento;
  return (
    (import.meta.env.BASE_URL || "").replace(/\/$/g, "") +
    api.Cliente.Cliente.SiniestroApi.ObtenerDocumentosSiniestrosUrl +
    "?ruta=" +
    nombre
  );
}
function mostrarEnlace(index: number) {
  if (!documentosVida.value.length) return false;
  return documentosVida.value[index].nombreDocumento != null;
}
function mostrarOpcionales() {
  opcionales.value = [];
  empleadoGobierno.value = false;
  let doc9 = false;
  let doc10 = false;
  if (!documentosVida.value.length) return;
  if (documentosVida.value[9].nombreDocumento != null) {
    opcionales.value.push(1);
    doc9 = true;
  }
  if (documentosVida.value[10].nombreDocumento != null) {
    opcionales.value.push(2);
    doc10 = true;
  }
  empleadoGobierno.value = doc9 || doc10;
}
async function guardarDocuemento(event: Event, index: number) {
  let div = document.querySelector("#spinner") as HTMLDivElement;
  div.style.display = "block";
  if (!documentosVida.value.length) {
    await CrearSiniestro();
  }
  let file = (event.target as HTMLInputElement).files![0] as File;
  let DocumentoSiniestro = documentosVida.value[index];
  let resp = await api.Cliente.Cliente.SiniestroApi.GuardarDocumentoSiniestro(
    file,
    DocumentoSiniestro.documentoSiniestroId!
  );
  documentosVida.value = resp.data.data.documentos!;
  mostrarOpcionales();
  div.style.display = "none";
  $store.agregarToast(resp.data);
}
async function enviarNotificacion() {
  let div = document.querySelector("#spinner") as HTMLDivElement;
  div.style.display = "block";
  if (!documentosVida.value.length) {
    await CrearSiniestro();
  }
  let resp = await api.Cliente.Cliente.SiniestroApi.NotificarCambios(
    documentosVida.value[0].documentoSiniestroId!,
    {} as Cliente.Cliente.Siniestro.SiniestrosDañosPortalCliente
  );
  div.style.display = "none";
  $store.agregarToast(resp.data);
}
async function CrearSiniestro() {
  let resp = await api.Cliente.Cliente.SiniestroApi.CrearSiniestro(Core.Enums.TipoSinestro.DESEMPLEO_1A_MENSUALIDAD);
  if (resp.data.estado == EstadoRespuesta.OK) {
    let data = (resp.data.data as Cliente.Cliente.Siniestro.ListaSiniestroconDocumentos)!.documentos!;
    documentosVida.value = data;
  }
  $store.agregarToast(resp.data);
}
onMounted(async () => {
  documentosVida.value = props.Siniestro;
  mostrarOpcionales();
  let div = document.querySelector("#spinner") as HTMLDivElement;
  div.style.display = "none";
  await nextTick();
});
</script>
