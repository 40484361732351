<template lang="pug">
.div.w-100.d-flex.justify-content-center.align-items-center.h-100(v-if="store.cargandoDatosFiguraDeApoyo")
  .contenedor-central-formulario.p-4
    .spinner-border(role="status")
div(v-else)
  .container-fluid
    h3.titulacion Mi proceso de Titulación
    .card.mb-5.shadow-sm
      .row
        .col-12.d-flex.justify-content-center.stepper-container
          ul.stepper
            li(
              v-for="(etapa, index) in objEtapas"
              :key="etapa"
              :class="[{ active: etapa.id == etapaTitulacionActiva, llenarLinea: (index == indexPasoActual && index != indexPasoActual) || index < indexPasoActual, llenarPunto: etapa.index == indexPasoActual || etapa.index < indexPasoActual }]"
              @click="obtenerURL"
            )
              router-link(:to="{ name: etapa.componente, params: { figura: 'titular', numero: '0' }, query: { operacionId: operacion } }") {{ etapa.texto }}
          .asesor
          p.text-secondary(v-if="false") Tu asesor es
            button.btn.btn-outline-primary(type="button")
              i.fa-solid.fa-headset
              span {{ nombreAsesor.data }}
          button.btn.btn-outline-primary(v-if="!store.tieneSesion" type="button" @click="store.segundoToken(store.operacionTitulacionId)")
            span Actualizar
      section
        .izquierdo(:class="isVisible ? 'anchoIzquierdo' : ''")
          #sidebar.seccion-navegacion.flex-shrink-0
            ul.list-unstyled.lista_sidebar
              li
                .seccion-interna.d-flex.justify-content-between.align-items-center.mb-2
                  button.btn.btn-toggle.d-inline-flex.align-items-center.rounded.border-0.collapsed(
                    :data-bs-toggle="route.name !== 'contenido-checklist' ? 'collapse' : ''"
                    data-bs-target="#home-collapse"
                    aria-expanded="false"
                    @click="route.name == 'contenido-checklist' ? mostrarDocumentosChecklistTitular() : ''"
                  )
                    span Titular
                  div(v-if="store.datos_figura[0].estudioBloqueado && route.name !== 'contenido-checklist'")
                    i.fa-solid.fa-circle-check.text-success.me-2
                #home-collapse.collapse.bg-secondary-hir(:class="route.name !== 'contenido-checklist' ? 'show' : ''")
                  ul.btn-toggle-nav.small.lista__sidebar.py-2
                    li.d-flex.justify-content-between
                      router-link(:to="{ name: 'datos-generales', params: { figura: 'titular', numero: '0' }, query: { operacionId: operacion } }") Datos generales
                      div(v-if="false")
                        i.fa-solid.fa-circle-check.text-success.me-2
                    li.d-flex.justify-content-between(hidden)
                      router-link(:to="{ name: 'ingresos', params: { figura: 'titular', numero: '0' }, query: { operacionId: operacion } }") Ingresos
                      div(v-if="false")
                        i.fa-solid.fa-circle-check.text-success.me-2
                    li.d-flex.justify-content-between
                      router-link(:to="{ name: 'gastos', params: { figura: 'titular', numero: '0' }, query: { operacionId: operacion } }") Gastos
                      div(v-if="false")
                        i.fa-solid.fa-circle-check.text-success.me-2
                    li.d-flex.justify-content-between
                      router-link(:to="{ name: 'patrimonio', params: { figura: 'titular', numero: '0' }, query: { operacionId: operacion } }") Patrimonio
                      div(v-if="false")
                        i.fa-solid.fa-circle-check.text-success.me-2
                    li.d-flex.justify-content-between
                      router-link(:to="{ name: 'uso-financiamiento', params: { figura: 'titular', numero: '0' }, query: { operacionId: operacion } }") Uso de financiamiento
                      div(v-if="false")
                        i.fa-solid.fa-circle-check.text-success.me-2
                    li.d-flex.justify-content-between
                      router-link(:to="{ name: 'vendedor', params: { figura: 'titular', numero: '0' }, query: { operacionId: operacion } }") Vendedor
                      div(v-if="false")
                        i.fa-solid.fa-circle-check.text-success.me-2
                    li.d-flex.justify-content-between
                      router-link(:to="{ name: 'observaciones', params: { figura: 'titular', numero: '0' }, query: { operacionId: operacion } }") Observaciones generales
                      div(v-if="false")
                        i.fa-solid.fa-circle-check.text-success.me-2
              li(
                v-for="(item, index) in store.getDatosFigura"
                @click="route.name == 'contenido-checklist' ? mostrarDocumentosChecklistFiguras(item.figura, item.numero) : ''"
              )
                .seccion-interna.d-flex.justify-content-between.align-items-center.mb-2(v-if="item.figura !== undefined")
                  button.btn.btn-toggle.d-inline-flex.align-items-center.rounded.border-0.collapsed(
                    :data-bs-toggle="route.name !== 'contenido-checklist' ? 'collapse' : ''"
                    :data-bs-target="'#' + item.figura + item.numero"
                    aria-expanded="false"
                  )
                    span {{  item.figura == "CoTitular" ? 'Cotitular' : item.figura == "GaranteHipotecario" ? "Garante Hipotecario" : item.figura == "ObligadoSolidario" ? "Obligado Solidario" : ""  }}
                  div(v-if="store.datos_figura[index].estudioBloqueado")
                    i.fa-solid.fa-circle-check.text-success.me-2
                  i.fa-solid.fa-circle-check.text-success.me-2(v-if="false")
                .collapse.bg-secondary-hir(
                  :id="item.figura + item.numero"
                  :class="route.params.figura == item.figura && route.params.numero == item.numero && route.name !== 'contenido-checklist' ? 'show' : ''"
                )
                  ul.btn-toggle-nav.small.lista__sidebar.py-2
                    li.d-flex.justify-content-between
                      router-link(
                        :to="{ name: 'datos-generales', params: { figura: '' + item.figura, numero: '' + item.numero }, query: { operacionId: operacion } }"
                      ) Datos generales
                    li.d-flex.justify-content-between
                      router-link(
                        :to="{ name: 'ingresos', params: { figura: '' + item.figura, numero: '' + item.numero }, query: { operacionId: operacion } }"
                      ) Ingresos
                    li.d-flex.justify-content-between
                      router-link(:to="{ name: 'gastos', params: { figura: '' + item.figura, numero: '' + item.numero }, query: { operacionId: operacion } }") Gastos
                    li.d-flex.justify-content-between
                      router-link(
                        :to="{ name: 'patrimonio', params: { figura: '' + item.figura, numero: '' + item.numero }, query: { operacionId: operacion } }"
                      ) Patrimonio
                    li.d-flex.justify-content-between
                      router-link(
                        :to="{ name: 'observaciones', params: { figura: '' + item.figura, numero: '' + item.numero }, query: { operacionId: operacion } }"
                      ) Observaciones generales
          router-view
            //- **** CHAT ****      
        .centro(v-if="false")
          button.btn.btn-primary.bg-secondary(@click="esconder")
            i.fa-solid.fa-message
            p Seguimiento
            i#flecha.fa-solid.fa-arrow-right
        .derecho(:class="isVisible ? 'block' : 'hide'")
          #conversacion.conversacion
            .asesor-chat
              p.nombre {{ nombreAsesor.data }} - <span>Asesor</span>
              p.mensaje-chat Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              p.horario 16/02/2021 16:30 hrs
            .usuario(v-for="mensaje in historialMensajes" :key="mensaje")
              p.nombre Tú
              p.mensaje-chat {{ formatearTexto(mensaje.mensaje) }}
              p.horario {{ formatearFecha(mensaje.fecha) }} hrs

          .mensaje
            p Escribe tu mensaje
            textarea(type="textarea" placeholder="Mensaje" v-model="mensajeDeUsuario" @keyup.enter="guardarMensaje")
            button.btn.btn-primary(type="button" @click="guardarMensaje")
              img(src="@/Aplicaciones/Cliente/Areas/Cliente/assets/logo_enviar.png")
</template>

<!-- *** VUE *** -->
<script lang="ts" setup>
import api from "@/servicios/apiCliente";
import { ref, computed, reactive, watch, defineComponent, nextTick, onMounted } from "vue";
import { Core, Cliente } from "@/servicios/api";
import { useStoreDocumentos } from "../../storeDocumentos";
import { useStoreTitular } from "../../storeTitular";
import { useStore } from "../../store";
import { useRoute, useRouter } from "vue-router";

import { storeToRefs } from "pinia";

const store = useStoreTitular();
const storeDocumentos = useStoreDocumentos();
const clienteStore = useStore();
const { formulario_datos_generales } = storeToRefs(store);
const { clienteId } = storeToRefs(clienteStore);
const route = useRoute();
const router = useRouter();
// store.$state.datos_figura[store.$state.figuraActual].clienteId = clienteId;
// store.ObtenerDatosDeFigura();
const asignarFiguraInicial = () => {
  store.figuraActual = route.params.numero;
};
const operacion = ref("");
let etapaTitulacion = "";

if (route.query.operacionId) {
  //se ejecuta si no hay sesion
  store.tieneSesion = false;
  asignarFiguraInicial();
  store.operacionTitulacionId = route.query.operacionId;
  operacion.value = store.operacionTitulacionId;
  store.segundoToken(route.query.operacionId);
  const { data } = await api.Cliente.Cliente.OperacionTitulacionApi.ObtenerEtapaOperacionTitulacion(operacion.value);
  etapaTitulacion = [];
  etapaTitulacion.push(data);
} else {
  //se ejecuta si existe sesion
  operacion.value = "";
  router.replace({ query: {} });
  asignarFiguraInicial();
  store.ObtenerOperacionesAtivasSesion();

  const { data } = await api.Cliente.Cliente.OperacionTitulacionApi.ObtenerOperacionesActivas();
  etapaTitulacion = data;
}
const isVisible = ref(false);
let historialMensajes = ref([]);
const nombreAsesor = ref("");
let horaDelMensaje = ref("");
let mensajeDeUsuario = ref("");
// Objeto para validaciones
const dataCotitular = reactive({
  cpDomicilioParticular: "",
  cpDomicilioTrabajo: "",
  telefonoParticular: "",
  telefonoCelular: "",
  dependientesEconomicos: "",
  nombre: "",
  profesion: "",
  sueldoNominalBruto: ""
});
const checkBoxesState = reactive({
  checkbox1: false,
  checkbox2: false
});

const banderaChecks = ref("");

const etapaTitulacionActiva = ref("");

const indexPasoActual = ref(1);

/*Metodos*/

onMounted(() => {
  obtenerURL();
  llenarStepper();
});

function autoScrollInicio() {
  const theElement = document.getElementById("conversacion");
  setTimeout(() => {
    theElement.scrollTop = theElement.scrollHeight - theElement.clientHeight;
    const arrayTest = document.querySelectorAll(".usuario");
    arrayTest[arrayTest.length - 1].classList.toggle("popup");
  }, 100);
}

function mostrarDocumentosChecklistTitular() {
  router.push({
    name: "contenido-checklist",
    params: { figura: "titular", numero: "0" },
    query: { operacionId: operacion.value }
  });
  storeDocumentos.consumirDocumentos();
}

function mostrarDocumentosChecklistFiguras(figura, numero) {
  router.push({
    name: "contenido-checklist",
    params: { figura: figura, numero: numero },
    query: { operacionId: operacion.value }
  });
}

//Se ejecuta cuando el DOM esta listo
// onMounted(() => {
//   setTimeout(() => {
//     autoScrollInicio();
//   }, 200);
// });

function esconder() {
  isVisible.value = !isVisible.value;
  document.getElementById("flecha")?.classList.toggle("fa-arrow-right");
  document.getElementById("flecha")?.classList.toggle("fa-arrow-left");
}

//Poner en Pascal Case los nombre

// API
// let operacionesActivas = await api.Cliente.Cliente.OperacionTitulacionApi.ObtenerOperacionesActivas();
// const operacionTitulacionId = "PI1aEtyhsyGiPcFvLeLP4Q==";

async function guardarMensaje() {
  if (mensajeDeUsuario.value.length === 0) return;
  try {
    //objeto para mandar a API
    let mensajeUser = {
      operacionTitulacionId: operacionTitulacionId,
      mensaje: mensajeDeUsuario.value
    };

    //limpiando el textarea
    await api.Cliente.Cliente.OperacionTitulacionMensajeApi.GuardarMensaje(mensajeUser);
    mensajeDeUsuario.value = "";
    obetenerMensaje();
    //scroll del chat hasta abajo
    autoScrollInicio();
    //clase popUp

    console.log("Exito!");
    console.log("Exito!");
  } catch (error) {
    console.log(error);
  }
}

// nombreAsesor.value = await api.Cliente.Cliente.OperacionTitulacionApi.ObtenerNombreAsesorTitulacion(
//   operacionTitulacionId
// );

// async function obetenerMensaje() {
//   let respMensaje = await api.Cliente.Cliente.OperacionTitulacionMensajeApi.ObtenerMensajes(operacionTitulacionId);

//   historialMensajes.value = respMensaje.data.reverse();
// }

// obetenerMensaje();

function formatearFecha(horario) {
  return new Intl.DateTimeFormat("es-ES", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit"
  })
    .format(new Date(horario))
    .replace(",", "");
}

function formatearTexto(textoChat) {
  return textoChat;
}

const etapas = Core.Models.EtapaOperacionTitulacion;

// *** rellenar Stepper ***

const objEtapas = [
  {
    id: etapas.IDENTIFICACION_DEL_PROYECTO,
    texto: "Definición del proyecto",
    componente: "datos-generales",
    index: 0
  },
  {
    id: etapas.INTEGRACION_DE_EXPEDIENTE,
    texto: "Integración de Expediente",
    componente: "contenido-checklist",
    index: 1
  },
  { id: etapas.ANÁLISIS, texto: "Análisis", componente: "analisis-aes", index: 2 },
  { id: etapas.FIRMA, texto: "Firma", componente: "firma-aes", index: 3 }
];

const llenarStepper = _ => {
  if (etapaTitulacion[0].etapaTitulacion === "FIRMA") {
    indexPasoActual.value = 3;
  } else if (etapaTitulacion[0].etapaTitulacion === "ANÁLISIS") {
    indexPasoActual.value = 2;
  } else if (etapaTitulacion[0].etapaTitulacion === "INTEGRACION_DE_EXPEDIENTE") {
    indexPasoActual.value = 1;
  } else if (etapaTitulacion[0].etapaTitulacion === "IDENTIFICACION_DEL_PROYECTO") {
    indexPasoActual.value = 0;
  } else {
    indexPasoActual.value = null;
  }
};

const obtenerURL = () => {
  const url = new URL(window.location.href).toString();
  if (url.includes(objEtapas[0].componente)) {
    etapaTitulacionActiva.value = objEtapas[0].id;
  } else if (url.includes(objEtapas[1].componente)) {
    etapaTitulacionActiva.value = objEtapas[1].id;
  } else if (url.includes(objEtapas[2].componente)) {
    etapaTitulacionActiva.value = objEtapas[2].id;
  } else if (url.includes(objEtapas[3].componente)) {
    etapaTitulacionActiva.value = objEtapas[3].id;
  } else {
    etapaTitulacionActiva.value = null;
  }
};

// *** Validacion ***

const onlyNumber = $event => {
  // console.log("Cp: ", dataCotitular.cpDomicilioParticular.length);
  let keyCode = $event.keyCode ? $event.keyCode : $event.which;
  if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
    // 46 is dot
    $event.preventDefault();
  }
};
const onlyAlpha = $event => {
  console.log($event);

  if (!(/[a-z]/i.test($event.key) || $event.keyCode == 32)) {
    $event.preventDefault();
  }
};

const maxLength = ($event, property, max) => {
  if (property.length > max) {
    $event.preventDefault();
  } else {
    onlyNumber($event);
  }
};

const check = ($event, boxNumber) => {
  console.log(checkBoxesState.property);

  checkBoxesState.checkbox1 = checkBoxesState.checkbox1 ? false : true;
  // checkBoxesState.boxNumber = checkBoxesState.boxNumber ? false : true;
};

const toCurrency = (number, property) => {
  const exp = /(\d)(?=(\d{3})+(?!\d))/g;
  const rep = "$1,";
  let arr = number.toString().split(".");
  arr[0] = arr[0].replace(exp, rep);
  number = arr[1] ? "$" + arr.join(".") : "$" + arr[0];
  dataCotitular.property = number;
  console.log(dataCotitular.property);
};
</script>

<!-- *** SCSS *** -->
<style scoped>
.llenarLinea:after,
.llenarPunto:before {
  background: #ffa000 !important;
}

.titulacion {
  color: #2f2d73;
  font-size: 36px;
  font-weight: 700;
  padding: 5% 0 1% 0;
}
.stepper-container {
  padding: 3%;
  width: 100%;
  max-height: 140px;
}
.stepper {
  width: 100%;
  padding-left: 19%;
}

ul {
  color: #a1b5d8;
  font-size: 13px;
}
.active {
  color: #ffa000;
  font-weight: 700;
}
.stepper li {
  display: block;
  float: left;
  list-style-type: none;
  position: relative;
  text-align: center;
  width: 22%;
}
/* DOTS */
.stepper li:before {
  background: #a1b5d8;
  border-radius: 50%;
  content: "";
  display: block;
  height: 14px;
  margin: 0 auto 10px auto;
  text-align: center;
  width: 14px;
}
.active:before {
  background: #ffa000 !important;
  bottom: 5px !important;
  height: 24px !important;
  position: relative;
  width: 24px !important;
  z-index: 1;
}

/* LINEA */
.stepper li:not(:last-child):after {
  background-color: #a1b5d8;
  content: "";
  height: 2px;
  left: 54%;
  position: absolute;
  top: 6px;
  width: 92%;
}

/* BUTTON */
.asesor {
  text-align: center;
}

.asesor-chat p,
.usuario p {
  color: #2f2d73;
  margin: 0;
  font-size: 12px;
}
.asesor button {
  border-radius: 16px;
  border: 2px solid #ffa000;
  color: #ffa000;
  font-weight: 700;
  width: 204px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.asesor .btn span {
  margin-left: 9px;
}

.asesor .btn svg {
  font-size: 18px;
}
/* BODY */
.card {
  background: #ffffff;
  /* box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.22); */
  /* max-height: 1351px; */
}
/**SideBar de navegación de formulario  */
#sidebar {
  border-right: 1px solid #a1b5d8;
  min-width: 200px;
  margin-right: 10px;
  width: 20%;
  color: #2f2d74 !important;
}
.seccion-interna .fa-circle-check {
  font-size: 18px;
}

.lista_sidebar > li:not(:first-child) {
  border-bottom: 1px solid #a1b5d8;
}
.lista__sidebar {
  list-style-type: none;
}
.lista__sidebar > li {
  padding: 3px 0;
}

/* LADO IZQUIERDO */
section {
  border-top: 1px solid #a1b5d8;
  display: flex;
  justify-content: space-between;
  min-height: 460px;
}

.izquierdo {
  display: flex;
  padding: 0;
  width: 100%;
}

.izquierdo h1 {
  color: #2f2d74;
  font-weight: 700;
  font-size: 24px;
  width: 100%;
}
/* INPUTS */
#row-flex {
  display: flex;
  align-items: flex-start;
}

#row-flex .form-check {
  margin-top: 8px;
}

.form-select {
  border-radius: 8px;
  height: 44px;
}

.row {
  padding: 1% 0% 1% 0%;
}

label.container {
  color: #2f2d74;
  font-size: 14px;
  padding-left: 0;
}

.input-container.col {
  padding-left: 0;
}

.input-title {
  color: #2f2d74;
  font-weight: 700;
  font-size: 14px;
  padding: 2% 0% 2% 0;
}

input[type="text"],
input[type="date"],
input[type="email"],
input[type="number"] {
  border-radius: 8px;
  width: 100%;
  color: #2f2d74;
  padding: 3%;
}

.h5.text-secondary {
  padding-left: 0;
}

.radio_si,
.radio_no {
  cursor: pointer;
}

.radio_no {
  margin-left: 15%;
}

*:focus {
  outline: none;
}

.centro {
  width: auto;
}
.centro button {
  align-items: center;
  color: white;
  display: flex;
  flex-direction: column;
  height: 400px;
  justify-content: space-around;
  position: relative;
  top: 5%;
  width: 75px;
  border-radius: 8px 0px 0px 8px;
  transition: all 0.2s ease-in;
  z-index: 100 !important;
}

.centro button:hover {
  transform: scale(1.05);
}

.centro .btn svg {
  font-size: 22px !important;
}
.centro .btn p {
  transform: rotate(-90deg) !important;
}

.centro .btn p {
  color: white;
  font-size: 18px;
  width: 150px;
}

.btn-check:focus,
.btn-primary,
.btn-primary:focus,
.btn:focus {
  border: none !important;
  box-shadow: none !important;
}

#siguiente {
  background: #ffa000 !important;
  border-radius: 15px;
  color: white;
  padding: 0.5% 2% 0.5% 2%;
}

/* CHAT */
.conversacion {
  padding: 4%;
  overflow: scroll;
  max-height: 300px;
}
.derecho {
  width: 30%;
  border-left: 2px solid #a1b5d8;
  border-bottom: 0;
  z-index: 100 !important;
  background: white;
}
.asesor-chat {
  text-align: left !important;
  padding: 0;
  margin: 0% 15% 10% 0;
}
.usuario {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 10% 0;
}
.popup {
  animation: scale-display 0.8s;
}
.asesor-chat span,
.usuario span {
  font-size: 10px;
}
p.horario {
  font-weight: 400;
  font-size: 10px;
  color: #8ba7b7;
}
.mensaje-chat {
  color: #6e7599 !important;
  font-weight: 400 !important;
  background: #eceff2;
  border-radius: 8px;
  padding: 3%;
}

/* Ocultar las ventana del chat */
.block {
  display: block;
  animation: scale-display 0.6s;
}

.hide {
  display: none;
}

.anchoIzquierdo {
  width: 70% !important;
  min-height: 460px;
}

.conversacion {
  height: 70%;
  overflow-x: hidden;
}

.mensaje {
  border-top: 2px solid #a1b5d8;
  height: 30%;
  padding: 3%;
}
.mensaje p {
  font-weight: 500;
  font-size: 13px;
  margin: 0;
}
.mensaje textarea {
  background: #eceff2;
  border: none;
  font-family: "Poppins";
  height: 60%;
  padding-bottom: 20%;
  width: 100%;
  font-size: 12px;
  border-radius: 5px;
  resize: none;
  overflow: hidden;
}

.mensaje button {
  border: 0;
  float: right;
  background: none;
  padding: 0;
}
.mensaje button img {
  width: 24px;
  transition: all 0.3s ease-in-out;
}
.mensaje button img:hover {
  transform: scale(1.3);
}

@keyframes scale-display {
  0% {
    opacity: 0;
    transform: scale(0);
    -webkit-transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
span {
  color: #2f2d73;
  font-weight: bold;
}
a {
  font-weight: 500;
  color: #2f2d73;
  text-decoration: none;
}
a.router-link-exact-active {
  color: #ffa000;
  font-weight: bold;
}
/* 
@keyframes scale-display--reversed {
  0% {
    display: inline-flex;
    opacity: 1;
    transform: scale(1);
    -webkit-transform: scale(1);
  }
  99% {
    display: inline-flex;
    opacity: 0;
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  100% {
    display: none;
    opacity: 0;
    transform: scale(0);
    -webkit-transform: scale(0);
  }
} */

/*
Sección comentada por orden de Angel

.bloque {
  background: #eceff2;
  border-radius: 8px;
  color: #2f2d74;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  padding: 2% 2% 6% 2%;
  text-align: center;
  width: 30%;
}

.bloques-container {
  display: flex;
  justify-content: space-between;
  margin-top: 5%;
}
*/

.bg-secondary-hir {
  background-color: #f5f8fc;
}
</style>

