import { Cliente, Configuration, EstadoRespuesta, IdentityApi } from "./api";
import axios from "axios";
import type { AxiosResponse } from "axios";
import { instancia as router } from "@/router";
import useAxiosStore from "@/store/storeAxios";

let token = "";
let usuario = "";
let contrasenia = "";

const axiosApi = axios.create();

function buscarReferencia(objeto: any, ref: string): any {
  if (objeto != undefined && objeto != null && objeto.$id == ref) return objeto;
  if (objeto == undefined || objeto == null) return undefined;
  for (const k in objeto) {
    if (!(objeto[k] instanceof Object)) continue;
    const br = buscarReferencia(objeto[k], ref);
    if (br != undefined) return br;
  }
  return undefined;
}

function resolverRefsArray(objeto: any, objetoGlobal: any) {
  for (let k = 0; k < objeto.length; k++) {
    if (objeto[k] == null || objeto[k] == undefined) continue;
    const ks = Object.keys(objeto[k]);
    if (ks.length == 1 && ks[0] == "$ref") objeto[k] = buscarReferencia(objetoGlobal, objeto[k].$ref);
    else if (objeto[k] instanceof Object) objeto[k] = resolverRefs(objeto[k], objetoGlobal);
  }
}

function resolverRefsObjeto(objeto: any, objetoGlobal: any) {
  for (const k in objeto) {
    if (objeto[k] == null || objeto[k] == undefined) continue;
    const ks = Object.keys(objeto[k]);
    if (ks.length == 1 && ks[0] == "$ref") objeto[k] = buscarReferencia(objetoGlobal, objeto[k].$ref);
    else if (objeto[k] instanceof Object) objeto[k] = resolverRefs(objeto[k], objetoGlobal);
  }
}

function resolverRefs(objeto: any, objetoGlobal: any) {
  if (!(objeto instanceof Object)) return objeto;
  if (Array.isArray(objeto)) resolverRefsArray(objeto, objetoGlobal);
  else resolverRefsObjeto(objeto, objetoGlobal);
  return objeto;
}

function removerIds(pila: any[], objeto: any): any {
  if (objeto == null || objeto == undefined || !(objeto instanceof Object)) return objeto;
  if ("$id" in objeto) delete objeto.$id;
  for (const k in objeto) {
    if (!(objeto[k] instanceof Object)) continue;
    if (pila.includes(objeto[k])) continue;
    if (!("$values" in objeto[k])) pila.push(objeto[k]);
    objeto[k] = removerIds(pila, objeto[k]);
  }
  if ("$values" in objeto) return objeto.$values;
  return objeto;
}

// Add a request interceptor
axiosApi.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    const axiosStore = useAxiosStore();
    axiosStore.contadorRequestResponse++;
    axiosStore.detectarCambiosRequestResponse = !axiosStore.detectarCambiosRequestResponse;

    return config;
  },
  function (error) {
    // Do something with request error
    const axiosStore = useAxiosStore();
    axiosStore.contadorRequestResponse--;
    axiosStore.detectarCambiosRequestResponse = !axiosStore.detectarCambiosRequestResponse;
    return Promise.reject(error);
  }
);

axiosApi.interceptors.response.use(
  async (respuesta: AxiosResponse<any>) => {
    const axiosStore = useAxiosStore();
    axiosStore.contadorRequestResponse--;
    axiosStore.detectarCambiosRequestResponse = !axiosStore.detectarCambiosRequestResponse;

    //#region Renovar automáticamente JWT
    if ("Token-Expired" in respuesta.headers) {
      await api.IdentityApi.Login({
        contrasenia,
        usuario
      });
      respuesta.config.headers!["Authorization"] = (<(name: string) => string>configuracion.accessToken)(
        "Authorization"
      );
      respuesta = await axiosApi.request(respuesta.config);
    }
    //#endregion

    const pila: any[] = [];
    if (respuesta.data) {
      respuesta.data = resolverRefs(respuesta.data, respuesta.data);
      respuesta.data = removerIds(pila, respuesta.data);
    }
    return respuesta;
  },
  error => {
    const axiosStore = useAxiosStore();
    axiosStore.contadorRequestResponse--;
    axiosStore.detectarCambiosRequestResponse = !axiosStore.detectarCambiosRequestResponse;
    if (error === undefined) return Promise.reject();
    if (error?.response?.status == 503 && router.currentRoute.value.name != "app-offline") {
      router.push({ name: "app-offline" });
    }
    return Promise.reject(error);
  }
);

const configuracion = new Configuration({
  accessToken: () => token,
  basePath: (import.meta.env.BASE_URL || "").replace(/\/$/g, "")
});

// AGREGAR AQUÍ LOS DEMÁS CONTROLADORES DE LA MISMA FORMA COMO ESTÁN DECLARADOS LOS DEMÁS:
const api = {
  IdentityApi: new IdentityApi(configuracion, undefined, axiosApi),
  Cliente: {
    Cliente: {
      EscrituracionApi: new Cliente.Cliente.EscrituracionApi(configuracion, undefined, axiosApi),
      FinanciamientoApi: new Cliente.Cliente.FinanciamientoApi(configuracion, undefined, axiosApi),
      AdjudicacionApi: new Cliente.Cliente.AdjudicacionApi(configuracion, undefined, axiosApi),
      HomeApi: new Cliente.Cliente.HomeApi(configuracion, undefined, axiosApi),
      MaterialApoyo: new Cliente.Cliente.MaterialApoyoApi(configuracion, undefined, axiosApi),
      ProgramaReclicaApi: new Cliente.Cliente.ProgramaReciclaApi(configuracion, undefined, axiosApi),
      SeguroApi: new Cliente.Cliente.SeguroApi(configuracion, undefined, axiosApi),
      AclaracionApi: new Cliente.Cliente.AtencionClienteApi(configuracion, undefined, axiosApi),
      SiniestroApi: new Cliente.Cliente.SiniestroApi(configuracion, undefined, axiosApi),
      FacturacionApi: new Cliente.Cliente.FacturacionApi(configuracion, undefined, axiosApi),
      DomiciliacionApi: new Cliente.Cliente.DomiciliacionApi(configuracion, undefined, axiosApi),
      RegimenFiscalApi: new Cliente.Cliente.RegimenFiscalApi(configuracion, undefined, axiosApi),
      ClienteApi: new Cliente.Cliente.ClienteApi(configuracion, undefined, axiosApi),
      OperacionTitulacionApi: new Cliente.Cliente.OperacionTitulacionApi(configuracion, undefined, axiosApi),
      GiroIngresoApi: new Cliente.Cliente.GiroIngresoApi(configuracion, undefined, axiosApi),
      ProfesionApi: new Cliente.Cliente.ProfesionApi(configuracion, undefined, axiosApi),
      ColoniaApi: new Cliente.Cliente.ColoniaApi(configuracion, undefined, axiosApi),
      EstudioSocioEconomicoApi: new Cliente.Cliente.EstudioSocioeconomicoApi(configuracion, undefined, axiosApi)
    }
  }
};

const loginOriginal = api.IdentityApi.Login;

api.IdentityApi.Login = async (loginBody: Identity.LoginBody, options?: any) => {
  const r = await loginOriginal.call(api.IdentityApi, loginBody, options);
  if (r.data.estado == EstadoRespuesta.OK) {
    token = r.data.data;
    usuario = loginBody.usuario;
    contrasenia = loginBody.contrasenia;
  }
  return r;
};

export default api;

