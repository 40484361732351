<template lang="pug">
.container.ff-poppins.pt-3
  .row
    .col-md-6
      p.fs-2.fw-bold(style="color: #2f2d74") ¿ Qué es el programa Recicla ?
      p(style="color: #6e7599; text-align: justify; text-justify: inter-word") Estamos en una constante transformación y entrando de lleno a una era digitalizada, la cual nos exige no solo estar actualizados en temas tecnológicos, sino también, nos exige ser una empresa congruente con nuestros valores; uno de ellos sin duda es el respeto por el cuidado del medio ambiente.<br>Desde hace más de 10 años tenemos el compromiso como Empresa Socialmente Responsable de protegerlo y fomentar acciones que permitan disminuir nuestro impacto ambiental. Es por esto, que nace el programa RECICLA HIR Casa con acciones desde reducción de agua, reciclaje de contenedores, tapas, reuso de materiales, así como el uso consciente y responsable de los elementos impresos. Te invitamos a unir a nuestro programa RECICLA HIR Casa; donde te invitamos a que decidas rechazar el uso de recibo físico que contamine, a migrar un estado de cuenta electrónico que beneficie al medio ambiente.
      p.text-center(style="color: #ffa000") ¡La sustentabilidad hoy, en la era digital ha dejado de ser una opción y se ha convertido en una prioridad para salvar al mundo!
      p.text-center(style="color: #2f2d74") ¡Migremos a la lo digital y salvemos al planeta! Activa tu estado de cuenta digital
      .row: .col-md-12: .card.mb-2(style="border-radius: 20px"): .card-body(style="background-color: #8cc63e; border-radius: 20px")
        .row
          .col-md-12
            b: p.mt-2(style="color: #ffffff; font-size: 20px") Sabías que...
          .col-md-12 
            p.fs-6.mt-2(style="color: #ffffff") La media del consumo anual de papel es de 48 kg por persona. Cerca del 67% de los documentos que se imprimen son evitables
    .col-md-6.text-end: img(src="../assets/img_recicla.png" style="height: 340px")
    .col-md-12.m-4
      .card.mb-2(style="border-radius: 20px; box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px"): .card-body(style="border-radius: 20px")
        .row: .col-md-12: p.fw-bold.text-center.h2(style="color: #2f2d74") La decisión es tuya, activa tu estado de cuenta digital y contribuye al cuidado del medio ambiente.
        .row.justify-content-center
          .col-md-4
            br
            br
          .col-md-4
            .d-grid.gap-2: .form-check
              input#flexCheckDefault.form-check-input.mx-auto(type="checkbox" v-model="Cliente.programaRecicla")
              span &nbsp;&nbsp;
              span.form-check-label.mx-auto(for="flexCheckDefault"): u(style="color: #2f2d74") Acepto los Términos y Condiciones
              br
              br
          .col-md-4
        .row.justify-content-center
          .col-md-4
          .col-md-4
            .d-grid.gap-2: button.btn.btn-lg.text-white(v-on:click="ActivarProgramaRecicla" style="background-color: #ffa000") Sí, deseo unirme a la campaña
          .col-md-4
    .col-md-12.m-2
      p.fs-2.fw-bold.h2(style="color: #2f2d74") Conoce otras iniciativas sustentables de&#32;
        span.text-primary HIR CASA
    .col-md-12.m-1
      .row
        .col-3.m-2
          img(src="../assets/img_recicla.png" style="height: 340px")
          p.fw-bold(style="color: #2f2d74") ¿Cómo separar y reciclar la basura en tu hogar nos favorece a todos?
          p.text-muted La basura es un contaminante que requiere de atención especial, y depende de nosotros poder ayudar a que ésta la
          .d-grid.gap-2: a.btn.btn-outline-primary(href="https://hircasa.com.mx/reciclar-en-tu-hogar/" target="_blank") Continuar leyendo
        .col-3.m-2
          img(src="../assets/img_recicla.png" style="height: 340px")
          p.fw-bold(style="color: #2f2d74") ¿Cómo llevar un estilo de vida sustentable en 2021?
          p.text-muted A lo largo de la vida hemos intentado satisfacer nuestras necesidades a costa de la destrucción ecológica, llevando al borde
          .d-grid.gap-2: a.btn.btn-outline-primary(href="https://hircasa.com.mx/estilo-vida-sustentable/" target="_blank") Continuar leyendo
</template>
<script lang="ts">
import { defineComponent } from "vue";
import mixinFiltros from "@/mixins/filtros";
import mixinId from "@/mixins/componentId";
import api from "@/servicios/apiCliente";
import Swal from "sweetalert2";
import { TipoBootstrap } from "@/interfaces";
import { useStore } from "@/store";
import { mapStores } from "pinia";
export default defineComponent({
  mixins: [mixinFiltros, mixinId],
  data() {
    return {
      Cliente: {
        programaRecicla: false
      } as any
    };
  },
  computed: {
    ...mapStores(useStore)
  },
  methods: {
    async ActivarProgramaRecicla() {
      // PopUp Login
      Swal.fire({
        title: "Para activar esta función debe ingresar su contraseña actual:",
        input: "password",
        inputAttributes: {
          autocapitalize: "on"
        },
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Confirmar",
        confirmButtonColor: "#ffa000",
        showLoaderOnConfirm: true,
        preConfirm: async (login) => {
          if (!login || login.length == 0) {
            this.baseStore.agregarToast({ tipo: TipoBootstrap.DANGER, body: "Campo Requerido" });
            return;
          }
          let r = await api.Cliente.Cliente.ProgramaReclicaApi.LoginConSesion(login);
          this.baseStore.agregarToast({ tipo: r.data.estado, body: r.data.mensaje! });
          if (r.data.estado == "OK") {
            let data = await api.Cliente.Cliente.ProgramaReclicaApi.ActivarProgramaRecicla(
              this.Cliente.programaRecicla
            );
            this.baseStore.agregarToast({ tipo: data.data.estado, body: data.data.mensaje! });
          }
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    }
  },
  async mounted() {
    let r = await api.Cliente.Cliente.ProgramaReclicaApi.ObtenerClienteProgramaRecicla();
    this.Cliente.programaRecicla = r.data.data.programaRecicla;
  }
});
</script>
