<template>
  <input type="text" @change="actualizarValor()" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AutoNumeric from "@/lib/autonumeric";

export default defineComponent({
  data() {
    return {
      instancia: null as any
    };
  },
  methods: {
    actualizarValor() {
      this.$emit("update:modelValue", this.instancia.getNumber());
    }
  },
  watch: {
    modelValue(value) {
      this.instancia.set(value);
    },
    configuracion(valor) {
      if (this.instancia) this.instancia.set(this.instancia.getNumericString(), valor);
    }
  },
  props: {
    configuracion: {
      type: Object as any,
      default: null
    },
    modelValue: {
      type: [Number, String]
    }
  },
  mounted() {
    let valor = this.modelValue as number;
    if (isNaN(valor)) valor = 0;
    if (this.configuracion) this.instancia = new AutoNumeric(this.$el, valor, this.configuracion);
    else this.instancia = new AutoNumeric(this.$el, valor, null);
  },
  beforeUnmount() {
    let au = this.instancia;
    if (au) au.remove();
  }
});
</script>
