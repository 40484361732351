import * as VueRouter from "vue-router";
import RutasAreaCliente from "../Areas/Cliente/router/index";
import RouterViewGenerico from "@/views/RouterViewGenerico.vue";

export default () => {
  return [
    {
      path: "",
      component: RouterViewGenerico,
      children: RutasAreaCliente()
    }
  ] as VueRouter.RouteRecordRaw[];
};
