<template lang="pug">
.container.ff-poppins.pb-4(:class="{ 'was-validated': formularioValidado }")
  .row: .col-md-12
    generar-pin(:MediosContactos="mediosContactos" ref="GeneradorPin" @pin-validado="validarPin")
  .row
    .col-md-12: h1.fw-bold(style="color: #2f2d74") Financiamiento
    .col-md-12: h3.fw-bold(style="color: #2f2d74") Facturación
    .col-md-12: p.fw-bold.text-muted ¿Qué necesito para poder tener activo mi servicio de facturación?
  .row: .col-md-12.m-2: ol
    li.fw-bold.text-muted Identificación Oficial Vigente
    li.fw-bold.text-muted Cédula fiscal
    li.fw-bold.text-muted Genera, imprime y carga tu formato de Facturación
  .row: .col-md-12 
    .card: .card-body(v-if="mostrarDescargaFactura == false")
      Spinner-Loader#spinner(name="spinner" style="width: 98%; display: none")
      .col-md-12: p.fw-bold(style="color: #2f2d74") Proporciona los siguientes datos
      .col-md-12: p.fw-bold(style="color: #2f2d74") Contratos a facturar
      .col-md-12: p.fw-bold(style="color: #2f2d74") Marcar solo los contratos a los cuales se solicitará la facturación.
      .row: .col-md-12
        .row: .col-md-2(v-for="(contrato, index) in contratos" :key="index"): .form-check.form-check-inline
          input.form-check-input(type="checkbox" :value="contrato.contratoId" :id="'contrato' + index" v-model="contratoIdFacturar" checked)
          label.form-check-label(:for="'contrato' + index") {{ contrato.numeroContrato }}
        .row
          .col-md-4
            label.m-2.font-weight-bold Nombre(s) *
            input.form-control(type="text" v-model="datosFacturacion.nombre" pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+" required)
          .col-md-4
            label.m-2.font-weight-bold Apellido Paterno *
            input.form-control(type="text" v-model="datosFacturacion.apellidoPaterno" pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+" required)
          .col-md-4
            label.m-2.font-weight-bold Apellido Materno *
            input.form-control(type="text" v-model="datosFacturacion.apellidoMaterno" pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+" required)
          .col-md-4
            label.m-2.font-weight-bold RFC con homoclave (12 dígitos) *
            input.form-control(
              type="text"
              v-model="datosFacturacion.rfc"
              pattern="[a-zA-Z,Ñ,&]{3,4}[0-9]{2}[0-1][0-9][0-3][0-9][a-zA-Z,0-9]?[a-zA-Z,0-9]?[0-9,a-zA-Z]?"
              minlength="12"
              maxlength="13"
              required
            )
          .col-md-4
            label.m-2.font-weight-bold Regimen Fiscal *
            select.form-select(v-model="datosFacturacion.regimenFiscalId")
              option(v-for="r in regimenesFiscales" :value="r.regimenFiscalId") {{ r.nombreRegimen }}
        .row.mt-4
          .col-md-12: h6.fw-bold(style="color: #2f2d74") Domicilio Fiscal
          .col-md-4
            label.m-2.font-weight-bold Calle *
            input.form-control(type="text" v-model="datosFacturacion.calle" required)
          .col-md-4
            label.m-2.font-weight-bold No. Interior / Exterior *
            input.form-control(type="text" v-model="datosFacturacion.numero" required)
          .col-md-4
            label.m-2.font-weight-bold CP *
            input.form-control(type="text" v-model="cp" minlength="5" maxlength="5" pattern="^[0-9]{5}" required)
          .col-md-4
            label.m-2.font-weight-bold Colonia *
            select.form-select(v-model="coloniaId")
              option(v-for="(col, i) in colonias" :value="col.coloniaId" :key="i") {{ col.colonia }}
          .col-md-4
            label.m-2.font-weight-bold Colonia *
            input.form-control(type="text" v-model="ciudad" readonly)
          .col-md-4
            label.m-2.font-weight-bold Estado *
            input.form-control(type="text" v-model="estado" readonly)
          .col-md-4
            label.m-2.font-weight-bold Telefono particular *
            input.form-control(type="text" v-model="datosFacturacion.telefonoParticular" minlength="10" maxlength="10" pattern="^[0-9]{10}" required)
          .col-md-4
            label.m-2.font-weight-bold Telefono celular *
            input.form-control(type="text" v-model="datosFacturacion.telefonoCelular" minlength="10" maxlength="10" pattern="^[0-9]{10}" required)
      .row
        .col-md-6.mt-4
          label.m-2.font-weight-bold Identificación Oficial *
          input#Edotercero(type="file" v-on:change="obtenerArchivo($event)" hidden)
          label(for="Edotercero" style="background-color: rgb(224, 231, 234); border-radius: 10px; padding: 5px 20px") Arrastra tus archivos aqui o Búscalos
          br
          a.fw-bold.text-primary(
            :href="visualizarArchivo(fileIdentificacion)"
            target="_blank"
            v-if="mostrarFileIdentificacion"
            :id="componentId + 'identificacion'"
          ) {{ fileIdentificacion.name }}
        .col-md-6.mt-4
          label.m-2.font-weight-bold Cedula de Identificación Fiscal *
          input#Cedula(type="file" v-on:change="obtenerArchivoCedula($event)" hidden)
          label(for="Cedula" style="background-color: rgb(224, 231, 234); border-radius: 10px; padding: 5px 20px") Arrastra tus archivos aqui o Búscalos
          br
          a.fw-bold.text-primary(:href="visualizarArchivoCedula(fileCedula)" target="_blank" v-if="mostrarFileCedula" :id="componentId + 'cedula'") {{ fileCedula.name }}
        .col-md-12.mt-2: span.text-muted El (la) que suscribe, como cliente de Bienes Programados, S.A. de C.V., manifiesto:
        .col-md-12.mt-2: span.text-muted Que Bienes Programados S.A de C.V.(en lo sucesivo HIRCASA), con domicilio en Avenida Desierto de los Leones número 46, nivel 1, colonia San Ángel, Alcaldía Álvaro Obregón, Ciudad de México, C.P. 01000, me ha puesto a la vista el Aviso de Privacidad que tiene establecido conforme a la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, y me ha informado que puedo consultarlo en la sitio www.hircasa.com.mx, mismo que he leído y comprendido, por lo que otorgo mi consentimiento libre de vicios para que HIRCASA recabe, utilice, conserve y/o transfiera mis datos personales sensibles, manifestando tácitamente mi conformidad para que HIRCASA pueda allegarse de más información a través de otras fuentes o personas.
      .row 
        .col-md-3 
        //-.col-md-3: a.btn.bg-white.text-center.text-primary.mt-2.border.border-primary No Aceptar
        .col-md-3: router-link.text-decoration-none(:to="{ name: 'cliente-cliente-home' }")
          p.btn.bg-white.text-center.text-primary.mt-2.border.border-primary No Aceptar
        .col-md-3: a.btn.btn-primary.text-white.mt-2(@click.prevent="guardarFacturacion()" v-if="mostrarFileIdentificacion && mostrarFileCedula") Aceptar
    .card: .card-body(v-if="mostrarDescargaFactura == true")
      .row
        .col-md-12: p.text-center.fw-bold(style="color: #2f2d74") Tu trámite de Facturación se ha concluido correctamente.
        .col-md-4
        .col-md-4: a.btn.btn-primary.text-white(:href="urlFactura" target="_blank") Descarga tu comprobante de formato de facturación.
        .col-md-4
        .col-md-12: p.text-center.fw-bold(style="color: #2f2d74") ¿Hay algun error en tu formato?
        .col-md-4
        .col-md-4: a.text-decoration-none.text-center.fw-bold.text-primary(href="#" @click.prevent="recargarPantalla()") Presiona aqui para generar otro formato.
        .col-md-4
</template>
<script lang="ts" setup>
import { nextTick, onMounted, reactive, ref, watch } from "vue";
import * as bootstrap from "bootstrap";
import { useStore } from "@/store";
import { TipoBootstrap } from "@/interfaces";
import filtros from "@/composables/filtros";
import api from "@/servicios/apiCliente";
import { Cliente, Core, EstadoRespuesta } from "@/servicios/api";
import * as utilidades from "@/utilidades";
import GenerarPin from "../components/GeneradorPin.vue";
import SpinnerLoader from "../components/SpinnerLoader.vue";
interface MediosContacto {
  valor: string;
  tipo: number;
}
//- Variables del programa
const $store = useStore();
const mediosContactos = ref([] as MediosContacto[]);
const formularioValidado = ref(false);
const componentId = utilidades.generarId();
const contratos = ref([] as Cliente.Financiamiento.InformacionFinanciamientoContrato[]);
const contratoIdFacturar = ref([] as number[]);
const datosFacturacion = ref({} as Cliente.Cliente.FacturacionBody);
const regimenesFiscales = ref([] as Core.Models.RegimenFiscal[]);
const regimenFiscal = ref(0);
const cp = ref("0");
const colonias = ref([] as Cliente.Cliente.ObtenerColoniasBody[]);
const coloniaId = ref(0);
const ciudad = ref("");
const estado = ref("");
const fileIdentificacion = ref({} as File);
const mostrarFileIdentificacion = ref(false);
const fileCedula = ref({} as File);
const mostrarFileCedula = ref(false);
const mostrarDescargaFactura = ref(false);

const urlFactura = ref("");
// #region Metodos Emits
function recargarPantalla() {
  window.location.reload();
}
async function validarPin(value: boolean) {
  bootstrap.Modal.getOrCreateInstance(document.querySelector("#codigoModal") as HTMLDivElement)?.hide();
  let div = document.getElementById("spinner") as HTMLDivElement;
  div.style.display = "block";
  if (value) {
    const data = contratos.value.filter(x => contratoIdFacturar.value.includes(x.contratoId!));
    datosFacturacion.value.contrato.contratoId = data.map(x => x.contratoId!);
    datosFacturacion.value.contrato.numeroContrato = data.map(x => x.numeroContrato!);
    let respuesta = await api.Cliente.Cliente.FacturacionApi.GuardarDatosFacturacion(datosFacturacion.value);
    mostrarDescargaFactura.value = respuesta.data.estado === EstadoRespuesta.OK;
    if (respuesta.data.estado == EstadoRespuesta.OK) {
      let notificacion = await api.Cliente.Cliente.FacturacionApi.NotificarUsuarios();
      $store.agregarToast(notificacion.data);
      if (mostrarFileIdentificacion.value) {
        let respIdentificaion = await api.Cliente.Cliente.FacturacionApi.GuardarDocumentoFacturacion(
          fileIdentificacion.value,
          Core.Enums.TipoDocumentoDomiciliacion.Identificacion_Oficial_Cliente
        );
        $store.agregarToast(respIdentificaion.data);
      }
      if (mostrarFileCedula.value) {
        let respCedula = await api.Cliente.Cliente.FacturacionApi.GuardarDocumentoFacturacion(
          fileIdentificacion.value,
          Core.Enums.TipoDocumentoDomiciliacion.Estado_Cuenta_Cliente
        );
        $store.agregarToast(respCedula.data);
      }
    }
    $store.agregarToast(respuesta.data);
  }
  div.style.display = "none";
}
// #endregion
// #region Eventos Observadores
watch(cp, async value => {
  colonias.value = (await api.Cliente.Cliente.EscrituracionApi.ObtenerColonias(value || "")).data;
  if (colonias.value.length) {
    coloniaId.value = colonias.value[0]!.coloniaId!;
  }
});
watch(coloniaId, value => {
  datosFacturacion.value.colonia = colonias.value.find(e => e.coloniaId == value)!;
  ciudad.value = colonias.value.find(e => e.coloniaId == value)?.ciudad!;
  estado.value = colonias.value.find(e => e.coloniaId == value)?.estado!;
});
// #endregion
// #region Metodos
function guardarFacturacion() {
  bootstrap.Modal.getOrCreateInstance(document.querySelector("#mediosContactoModal") as HTMLDivElement)?.show();
}
function obtenerArchivo(event: any) {
  let file = event.target.files[0];
  fileIdentificacion.value = file;
  mostrarFileIdentificacion.value = true;
}
function obtenerArchivoCedula(event: any) {
  let file = event.target.files[0];
  fileCedula.value = file;
  mostrarFileCedula.value = true;
}
function visualizarArchivo(archivo: Blob) {
  let leer = URL.createObjectURL(archivo);
  return leer;
}
function visualizarArchivoCedula(archivo: Blob) {
  let leer = URL.createObjectURL(archivo);
  return leer;
}
// #endregion

api.Cliente.Cliente.RegimenFiscalApi.Obtener().then(respuesta => {
  regimenesFiscales.value = respuesta.data;
});

// Recuperar el régimen fiscal
Promise.all([
  api.Cliente.Cliente.ClienteApi.ObtenerPersonaFisica(),
  api.Cliente.Cliente.ClienteApi.ObtenerPersonaMoral()
]).then(personas => {
  if (personas[0].data) datosFacturacion.value.regimenFiscalId = personas[0].data.regimenFiscalId;
  else if (personas[1].data) datosFacturacion.value.regimenFiscalId = personas[1].data.regimenFiscalId;
});

onMounted(async () => {
  let div = document.getElementById("spinner") as HTMLDivElement;
  div.style.display = "block";
  formularioValidado.value = true;
  mostrarDescargaFactura.value = false;
  datosFacturacion.value = (await api.Cliente.Cliente.FacturacionApi.ObtenerDatosFacturacion()).data;
  contratos.value = (await api.Cliente.Cliente.FinanciamientoApi.ObtenerContratos(1)).data;
  cp.value = datosFacturacion.value.cp!;
  //- Filtramos los contratosID
  contratoIdFacturar.value = contratos.value.map(e => {
    return e.contratoId;
  });
  mostrarFileCedula.value = false;
  mostrarFileIdentificacion.value = false;
  //Consultamos los datos del medios de contatcos del cliente
  let informacionCliente = (await api.Cliente.Cliente.HomeApi.ObtenerInformacion()).data;
  mediosContactos.value = [];
  if (informacionCliente.cliente.telefono3 != null)
    mediosContactos.value.push({ valor: informacionCliente.cliente.telefono3!, tipo: 0 });
  if (informacionCliente.cliente.email1 != null)
    mediosContactos.value.push({ valor: informacionCliente.cliente.email1!, tipo: 1 });
  urlFactura.value =
    (import.meta.env.BASE_URL || "").replace(/\/$/g, "") + api.Cliente.Cliente.FacturacionApi.ObtenerPDFFacturacionUrl;
  div.style.display = "none";
  await nextTick();
});
</script>
