<template lang="pug">
.container.ff-poppins.pt-3: .row
  .col-md-12: .Principal(style="height: 550px")
    .row.justify-content-md-start
      .col-md-4.m-4: .card.mb-2(style="border-radius: 25px"): .card-body.bg-primary(style="border-radius: 25px")
        p.text-white.text-center.fw-bold.HirH1 Liquidación de Contratos
  .col-md-12: .mt-4.card(style="border-radius: 15px"): .card-body.p-5(style="border-radius: 15px"): .d-flex
    .flex-fill
      p.fs-2.fw-bold(style="color: #2f2d74") ¿Estás a punto de cubrir la totalidad de las cuotas de tu financiamiento?
      p.fs-5.mt-4(style="color: #2f2d74") En este video encontrarás todas las recomendaciones para que liquides tus contratos de forma anticipada y disfrutes el inmueble de tus sueños
    div <iframe width="560" height="315" src="https://www.youtube.com/embed/DU_MMyBKRKk" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  .col-md-12.px-5: p.HirH2.text-center.mt-4 Si deseas liquidar tu(s) contrato(s) de forma anticipada, ponemos a tu disposición las siguientes <strong>recomendaciones</strong>:
  .col-md-12: .mt-4.card(style="border-radius: 15px"): .card-body(style="border-radius: 15px"): .row
    .col-md-3: img(src="../assets/i_liquidacion_01.svg" style="margin: 10px auto; display: block")
    .col-md-3: img(src="../assets/i_liquidacion_02.svg" style="margin: 10px auto; display: block")
    .col-md-3: img(src="../assets/i_liquidacion_03.svg" style="margin: 10px auto; display: block")
    .col-md-3: img(src="../assets/i_liquidacion_04.svg" style="margin: 10px auto; display: block")
    .col-md-3: p.HirH4.fw-bold.text-center Contáctanos
    .col-md-3: p.HirH4.fw-bold.text-center Efectúa tu Pago
    .col-md-3: p.HirH4.fw-bold.text-center Pago confirmado
    .col-md-3: p.HirH4.fw-bold.text-center Liberación de Hipoteca
    .col-md-3
      p.HirP.text-muted(style="text-align: justify; text-justify: inter-word") Solicita la emisión de tu cálculo para la liquidación anticipada.
      p.HirP.text-muted(style="text-align: justify; text-justify: inter-word") WhatsApp: 55 7665 9885
      p.HirP.text-muted(style="text-align: justify; text-justify: inter-word") 55 5511 9910 Opc. 0 - 2.
      p.HirP.text-muted(style="text-align: justify; text-justify: inter-word") atencionaclientes@hircasa.com.mx
    .col-md-3: p.HirP.text-muted(style="text-align: justify; text-justify: inter-word") En el cálculo de la liquidación podrás visualizar el monto y los datos bancarios para pagar.
    .col-md-3: p.HirP.text-muted(style="text-align: justify; text-justify: inter-word") Una vez reflejado el pago total de las cuotas, puedes dar inicio al trámite de liberación de hipoteca.
    .col-md-3
      p.HirP.text-muted(style="text-align: justify; text-justify: inter-word") Para la emisión de tu carta de liberación debes proporcionarnos los siguientes datos:
      ol(type="1")
        li: p.HirP.text-muted(style="text-align: justify; text-justify: inter-word") Número de Notaría.
        li: p.HirP.text-muted(style="text-align: justify; text-justify: inter-word") Localidad de inmueble.
        li: p.HirP.text-muted(style="text-align: justify; text-justify: inter-word") Nombre del Notario.
  .col-md-12: .row
    .col-md-6: .mt-4.card(style="border-radius: 15px; background-color: rgb(89, 53, 223)"): .card-body(style="border-radius: 15px")
      p.HirH2.fw-bold.text-center.text-white Liberación de hipoteca
      p.HirH4.text-white(style="text-align: justify; text-justify: inter-word") En&#32;
        span.text-primary.fw-bold HIR Casa&#32;
        | estamos contentos porque hemos llegado juntos a la etapa final de tu financiamiento.
      p.HirH4.text-white(style="text-align: justify; text-justify: inter-word") Te sugerimos tomar en cuenta los siguientes puntos para la cancelación de tu hipoteca:
      ol.list-group(start="1" type="1")
        li.list-group-item.active.text-white.HirC(style="border-radius: 15px") 1.- Cotiza tu trámite.
        li.list-group-item.mt-2.text-white.HirC(style="border-radius: 15px; background-color: transparent; border-style: none") 2.- Proporciona tus datos.
        li.list-group-item.mt-2.text-white.HirC(style="border-radius: 15px; background-color: transparent; border-style: none") 3.- Seguimiento con notaría.
        li.list-group-item.mt-2.text-white.HirC(style="border-radius: 15px; background-color: transparent; border-style: none") 4.- Entrega
    .col-md-6: .mt-4.card(style="border-radius: 15px"): .card-body(style="border-radius: 15px"): .row
      .col-md-12.mt-2
      .col-md-12: img(src="../assets/i_cotizatramite.svg" style="margin: 10px auto; display: block" @click.prevent="mostrarPopUp()")
      .col-md-12.mt-4
      //- Modal de Estados de Cuentas
      #NotariaModal.modal.fade(tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true")
        .modal-dialog.modal-dialog-centered.mw-100.w-75(role="document")
          .modal-content
            .modal-body
              p.m-2.text-center.h2(style="color: #34189a; font-weight: bold") Notarias
              .row.justify-content-md-center: .col-md-7: Slider-Carousel(
                :ImagenesProp="['img/notarias/01_notaria.png', 'img/notarias/02_notaria.png', 'img/notarias/03_notaria.png', 'img/notarias/04_notaria.png', 'img/notarias/05_notaria.png', 'img/notarias/06_notaria.png', 'img/notarias/07_notaria.png', 'img/notarias/08_notaria.png', 'img/notarias/09_notaria.png', 'img/notarias/10_notaria.png']"
              )
            .modal-footer
              button.btn.btn-primary.text-white.fw-bold(data-dismiss="modal" @click="ocultarPopUp()") Cerrar
      .col-md-12.mt-4
      .col-md-12: p.text-center.text-muted.HirC Investiga con la Notaria de tu preferencia el costo del trámite de liberación.
      .col-md-12.mt-4
      .col-md-12.mt-4
      .col-md-12: p.text-center.text-muted.HirC En algunas localidades podemos recomendarte Notarias con las cuales tenemos convenio.
      .col-md-12.mt-4
  .col-md-12: br
</template>
<script lang="ts" setup>
import { onMounted } from "vue";
import * as bootstrap from "bootstrap";
let notarias = null as null | bootstrap.Modal;
function ocultarPopUp() {
  notarias?.hide();
}
function mostrarPopUp() {
  notarias?.show();
}
onMounted(async () => {
  notarias = new bootstrap.Modal(document.querySelector("#NotariaModal") as HTMLDivElement);
});
</script>
<style scoped>
.Principal {
  background-image: url("../assets/img_liquidacion_principal.jpg");
  width: 100%;
}
.HirH1 {
  font-size: 40px;
  color: #2f2d74;
}
.HirH2 {
  font-size: 36px;
  color: #2f2d74;
}
.HirH3 {
  font-size: 28px;
  color: #2f2d74;
}
.HirH4 {
  font-size: 24px;
  color: #2f2d74;
}
.HirH5 {
  font-size: 18px;
  color: #2f2d74;
}
.HirH6 {
  font-size: 14px;
  color: #2f2d74;
}
.HirP {
  font-size: 16px;
  color: #2f2d74;
}
.HirC {
  font-size: 20px;
  color: white;
}
</style>
