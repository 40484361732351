import type { ComponentOptionsMixin } from "vue";

export default {
  methods: {
    filtroDivisa(numero: number, currency: string = "MXN"): string {
      if (numero == undefined || numero == null) return "";
      return new Intl.NumberFormat("es-MX", { style: "currency", currency: currency }).format(numero);
    }
  }
} as ComponentOptionsMixin;
