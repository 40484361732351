<template lang="pug">
.d-flex.flex-column.p-3.w-100.justify-content-start.g-0
  HeaderForm
  ObservacionesGenerales(ref="formOG")
  // Button trigger modal

  .d-flex.justify-content-between.mt-5
    button.btn.btn-outline-primary(style="width: 100px" @click="anterior" :disabled="store.datos_figura[store.getFigura].estudioBloqueado")
      | Anterior
    button.btn.btn-primary.text-white(type="button" style="width: 100px" @click="siguiente" :disabled="store.datos_figura[store.getFigura].estudioBloqueado")
      | Guardar y enviar
    button.btn.btn-primary(v-show="false" type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop" style="width: 100px" ref="modalPdf")
      //- | Mostrat modal
    #staticBackdrop.modal.fade(data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true")
      .modal-dialog
        .modal-content
          .modal-header
            h5#staticBackdropLabel.modal-title 
              | Estas a punto de confirmar que todos tus datos han sido capturados
            button.btn-close(type="button" data-bs-dismiss="modal" aria-label="Close" @click="aceptoElUsuario" value="false")
          .modal-footer
            button.btn.btn-secondary(type="button" data-bs-dismiss="modal" @click="aceptoElUsuario" value="false") Cerrar
            button.btn.btn-primary(type="button" @click="aceptoElUsuario" value="true" data-bs-dismiss="modal" aria-label="Close") Acepto
</template>
<script lang="ts">
import { defineAsyncComponent, ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStoreTitular } from "../storeTitular";
import { useStore } from "@/store";
import { TipoBootstrap } from "@/interfaces";

export default {
  components: {
    ObservacionesGenerales: defineAsyncComponent(() => import("./Observaciones/ObservacionesGenerales.vue")),
    HeaderForm: defineAsyncComponent(() => import("./HeaderForm.vue"))
  },
  props: ["figura"],
  setup(props) {
    //variables
    const router = useRouter(); //Navegar hacia un lugar
    const formOG = ref();
    const store = useStoreTitular();
    const $store = useStore();
    const route = useRoute(); //Navegar hacia un lugar
    const muestraPopup = ref(false);
    const modalPdf = ref();

    //metodos
    const aceptoElUsuario = e => {
      const respUsuario = e.target.value;

      if (respUsuario == "true") {
        store.datos_figura[store.getFigura].estudioBloqueado = true;
        store.guardarFigura(store.$state.datos_figura[store.figuraActual]);
      } else {
        store.datos_figura[store.getFigura].estudioBloqueado = false;
      }
    };

    const siguiente = () => {
      formOG.value.validar();

      //Validar ruta para determinar el camino correcto segun la figura

      if (store.$state.formulario_observaciones.completado) {
        store.guardarFigura(store.$state.datos_figura[store.figuraActual]);
        $store.agregarToast({
          tipo: TipoBootstrap.SUCCESS,
          body: "Formulario guardado correctamente.",
          mensaje: "Texto"
        });
        // store.titularCompletado();
        muestraPopup.value = true;
        modalPdf.value.click();

        //Se muestra el PDF de la figura en cuestión
      } else {
        $store.agregarToast({
          tipo: TipoBootstrap.DANGER,
          body: "Llenar todos los campos correctamente.",
          mensaje: "Texto"
        });
        muestraPopup.value = false;
      }
    };
    const anterior = () => {
      if (props.figura != "titular") {
        if (store.tieneSesion) router.push({ name: "patrimonio" });
        else router.push({ name: "patrimonio", query: { operacionId: store.operacionTitulacionId } });
      } else {
        if (store.tieneSesion) router.push({ name: "vendedor" });
        else router.push({ name: "vendedor", query: { operacionId: store.operacionTitulacionId } });
      }
    };

    return {
      siguiente,
      anterior,
      formOG,
      store,
      aceptoElUsuario,
      muestraPopup,
      modalPdf
    };
  }
};
</script>

<style scoped>
.form-label {
  font-size: 14px;
  font-weight: 600;
}
</style>
