<template lang="pug">
.footer-principal: .container.ff-poppins
  .row
    .col-3
      img(src="../../../assets/logoblanco.png" style="margin-right: calc(40px + 0.75rem); width: 190px")
    .col-1
      .d-inline-block.align-middle(style="width: calc(100% - 280px - 0.75rem)")
        .col: p: router-link.fw-bold.text-decoration-none.text-white(:to="{ name: 'cliente-cliente-home' }") Inicio
        .col(v-if="mostrarMenu"): p: router-link.fw-bold.text-decoration-none.text-white(:to="{ name: 'cliente-cliente-perfil' }") Mi Perfil
        .col(v-if="mostrarMenu && mostrarTodoSobreMiFinanciamiento"): p: router-link.fw-bold.text-decoration-none.text-white(
          :to="{ name: 'cliente-cliente-home' }"
        ) Todo Sobre mi Financiamiento
    .col-2.ps-5
      .col(v-if="mostrarMenu"): p: router-link.fw-bold.text-decoration-none.text-white(:to="{ name: 'cliente-cliente-financiamiento' }") Financiamiento
      .col(v-if="mostrarMenu && clienteClienteStore.mostrarEscrituracion"): p: router-link.fw-bold.text-decoration-none.text-white(
        :to="{ name: 'cliente-cliente-escrituracion' }"
      ) Escrituración
      .col(v-if="mostrarMaterialApoyo"): p: router-link.fw-bold.text-decoration-none.text-white(:to="{ name: 'cliente-cliente-materiales-apoyo' }") Material de Apoyo
    .col-2
      .col(v-if="false"): p: router-link.fw-bold.text-decoration-none.text-white(:to="{ name: 'cliente-cliente-home' }") Mapa de Sitio
      .col: p: a.fw-bold.text-decoration-none.text-white(href="https://hircasa.com.mx") Portal <span class="text-primary">HIR Casa</span>
    .col-4
      p
        a.text-decoration-none.text-white(href="#" @click.prevent=""): span.fa-stack(style="font-size: 137.5%")
          i.fas.fa-circle.fa-stack-2x
          i.fab.fa-facebook-f.fa-stack-1x.fa-inverse(style="color: #2f2d74")
        a.text-decoration-none.text-white(href="#" @click.prevent=""): span.fa-stack(style="font-size: 137.5%")
          em.fas.fa-circle.fa-stack-2x
          em.fab.fa-twitter.fa-stack-1x.fa-inverse(style="color: #2f2d74")
        a.text-decoration-none.text-white(href="#" @click.prevent=""): span.fa-stack(style="font-size: 137.5%")
          em.fas.fa-circle.fa-stack-2x
          em.fab.fa-instagram.fa-stack-1x.fa-inverse(style="color: #2f2d74")
        a.text-decoration-none.text-white(href="#" @click.prevent=""): span.fa-stack(style="font-size: 137.5%")
          em.fas.fa-circle.fa-stack-2x
          em.fab.fa-linkedin-in.fa-stack-1x.fa-inverse(style="color: #2f2d74")
        a.text-decoration-none.text-white(href="#" @click.prevent=""): span.fa-stack(style="font-size: 137.5%")
          em.fas.fa-circle.fa-stack-2x
          em.fab.fa-youtube.fa-stack-1x.fa-inverse(style="color: #2f2d74")
        a.text-decoration-none.text-white(href="#" @click.prevent=""): span.fa-stack(style="font-size: 137.5%")
          em.fas.fa-circle.fa-stack-2x
          em.fab.fa-whatsapp.fa-stack-1x.fa-inverse(style="color: #2f2d74")

    hr.my-3(style="background: #a1b5d8; height: 1px")

  .row.text-white
    .col-3
      p Av. Desierto de los Leones No. 46, Nivel 1, Col. San Ángel, Álvaro Obregón, C.P. 01000, Ciudad de México.
      a.btn.btn-outline-light.fw-bold.text-white.text-decoration-none(href="https://goo.gl/maps/EDePb6fTgEWJZgsJ8" target="_blank")
        em.fas.fa-map-marker-alt.me-2
        | Abrir en Google Maps
    .col-3
      p Horario de Atención:
      p.fw-bold
        em.fas.fa-clock.me-2
        | Lun - Vie: 09:00 - 18:00
      p: a.fw-bold.text-decoration-none.text-white(href="tel:+525555119910")
        em.fas.fa-phone-alt.me-2
        | 55 5511 9910
      p: a.fw-bold.text-decoration-none.text-white(href="tel:+528004472272")
        em.fas.fa-phone-alt.me-2
        | 800 hircasa (447 2272)
      p: a.fw-bold.text-white(href="mailto:atencionaclientes@hircasa.com.mx")
        em.fas.fa-envelope.me-2
        | atencionaclientes@hircasa.com.mx
    .col-2
      p: a.text-white.text-decoration-none(href="https://hircasa.com.mx/aviso-privacidad/" target="_blank") Aviso de Privacidad
      p: a.text-white.text-decoration-none(href="https://hircasa.com.mx/aviso-legal/" target="_blank") Términos y Condiciones
    .col-4
      p Certificaciones
      img(src="../../../assets/certificaciones.png" style="width: 100%")
</template>

<style lang="sass" scope>
.footer-principal
  background: #2F2D74
  padding-top: 40px
  padding-bottom: 0.75rem
  font-size: 14px
.col
  width: max-content
</style>

<script lang="ts">
import { defineComponent } from "vue";
import { useStore } from "../store";
import { mapStores } from "pinia";

export default defineComponent({
  data() {
    return {
      mostrarTodoSobreMiFinanciamiento:
        import.meta.env.VITE_CLIENTE_CLIENTE_MOSTRAR_TODO_SOBRE_MI_FINANCIAMIENTO == "1",
      mostrarMaterialApoyo: import.meta.env.VITE_CLIENTE_CLIENTE_MOSTRAR_MATERIAL_DE_APOYO == "1"
    };
  },
  props: {
    mostrarMenu: { type: Boolean, default: false }
  },
  computed: {
    ...mapStores(useStore)
  }
});
</script>
