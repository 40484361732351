<template lang="pug">
div
  div(style="background-color: #f3f1ee"): .container(:class="{ 'was-validated': formularioValidado }")
    .row.pt-4
      .col-md-6: .card: .card-body
        .row
          .col-6
            b.form-label Nacionalidad:
            hir-select2(
              :form="componentId + 'form'"
              :data="catalogos.paises.value.map(a => ({ id: a.paisId, text: (a.gentilicio ?? '').trim() || a.descripcion }))"
              v-model="estudioSocioeconomico.paisNacionalidadId.value"
            )
          .col-6
            b.form-label Fecha
            br
            span.text-primary.fw-bold {{ fechaActual }}
      .col-md-6.col-sm-12: .card: .card-body
        .row
          .col-4.text-center
            b.form-label Ingresos
            br
            span.text-primary.fw-bold {{ filtroDivisa(utilidades.ifNaN(estudioSocioeconomico.sueldoNominalNeto.value)) }}
          .col-4.text-center
            b.form-label Egresos
            br
            span.text-primary.fw-bold {{ filtroDivisa(estudioSocioeconomicoTotalEgresos) }}
          .col-4.text-center
            b.form-label Remanente
            br
            span.text-primary.fw-bold {{ filtroDivisa(utilidades.ifNaN((estudioSocioeconomico.sueldoNominalNeto.value ?? 0) - estudioSocioeconomicoTotalEgresos)) }}
    .row: .col.pt-4.pb-4
      .accordion.mb-4.shadow(:id="componentId + 'acordion'")
        .accordion-item
          h2.accordion-header: button.accordion-button.fw-bold(data-bs-toggle="collapse" :data-bs-target="'#' + componentId + 'servicioContratar'") Servicio a Contratar
          .accordion-collapse.collapse.show(:id="componentId + 'servicioContratar'" :data-bs-parent="'#' + componentId + 'acordion'"): .accordion-body: .row
            .col-md-4
              label.form-label Servicio a Contratar (Proyecto)
              select.form-select(:form="componentId + 'form'" v-model="estudioSocioeconomico.servicioContratar.value")
                option(disabled selected) Seleccione...
                option(:value="1") Casa nueva
                option(:value="2") Casa usada
                option(:value="3") Departamento nuevo
                option(:value="4") Departamento usado
                option(:value="5") Local comercial
                option(:value="6") Terreno
                option(:value="7") Remodelar
                option(:value="8") Construcción
                option(:value="9") Departamento o Casa usado en Condominio
                option(:value="10") Departamento o Casa nuevo en Condominio
            .col-md-4
              label.form-label Destino
              select.form-select(:form="componentId + 'form'" v-model="estudioSocioeconomico.destino.value")
                option(disabled selected) Seleccione...
                option(:value="1") Uso habitacional
                option(:value="2") Comercio
            .col-md-4
              label.form-label Valor del proyecto (En moneda nacional)
              hir-autonumeric.form-control(
                :form="componentId + 'form'"
                v-model="estudioSocioeconomico.valorProyecto.value"
                :configuracion="{ emptyInputBehavior: 'min', maximumValue: '99999999', minimumValue: '0', currencySymbol: '$ ' }"
              )
              .invalid-feedback Valor del proyecto (En moneda nacional) inválido.
            .col-md-4
              label.form-label.mt-1 Contratos involucrados
              textarea.form-control(:form="componentId + 'form'" row="3" v-model="estudioSocioeconomico.contratosInvolucrados.value")
              .invalid-feedback Contratos involucrados invalido.
            .col-md-4
              label.form-label.mt-1 Procedimiento de Adjudicación
              select.form-select(:form="componentId + 'form'" v-model="estudioSocioeconomico.procedimientoAdjudicacion.value")
                option(v-for="Procedimiento in catalogos.procedimientoAdjudicacion.value" :value="Procedimiento") {{ Procedimiento.replace(/_/g, " ") }}
            .text-center.mt-4
              button.btn.btn-primary.text-light.fw-bold(
                type="submit"
                :form="componentId + 'form'"
                v-on:click="estudioSocioeconomico.notificacionAscesor.value = false"
              ) Guardar
      .accordion.shadow.mb-4(:id="componentId + 'acordion2'")
        .accordion-item
          h2.accordion-header: button.accordion-button.collapsed.fw-bold(data-bs-toggle="collapse" :data-bs-target="'#' + componentId + 'datosCliente'") Datos del Cliente
          .accordion-collapse.collapse(:id="componentId + 'datosCliente'" :data-bs-parent="'#' + componentId + 'acordion2'"): .accordion-body
            .row
              .col-md-3
                label.form-label Nombre:
                input.form-control(
                  :form="componentId + 'form'"
                  v-model="estudioSocioeconomico.nombre.value"
                  type="text"
                  pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+"
                  readonly
                )
                .invalid-feedback Nombre invalido.
              .col-md-3
                label.form-label Apellido Paterno:
                input.form-control(
                  :form="componentId + 'form'"
                  v-model="estudioSocioeconomico.apellidoPaterno.value"
                  type="text"
                  pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+"
                  readonly
                )
                .invalid-feedback Apellido Paterno invalido.
              .col-md-3
                label.form-label Apellido Materno :
                input.form-control(
                  :form="componentId + 'form'"
                  v-model="estudioSocioeconomico.apellidoMaterno.value"
                  type="text"
                  pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+"
                  readonly
                )
                .invalid-feedback Apellido Materno invalido.
              .col-md-3
                label.form-label Fecha de nacimiento:
                input.form-control(
                  :form="componentId + 'form'"
                  v-model="estudioSocioeconomico.fechaNacimiento.value"
                  type="date"
                  readonly
                  min="1900-01-01"
                  :max="fechaIso"
                )
                .invalid-feedback Fecha de nacimiento invalido.
            .row
              .col-md-3
                label.form-label RFC:
                input.form-control(
                  :form="componentId + 'form'"
                  v-model="estudioSocioeconomico.rfc.value"
                  type="text"
                  pattern="[a-zA-Z,Ñ,&]{3,4}[0-9]{2}[0-1][0-9][0-3][0-9][a-zA-Z,0-9]?[a-zA-Z,0-9]?[0-9,a-zA-Z]?"
                  readonly
                )
                .invalid-feedback RFC invalido.
              .col-md-3
                label.form-label Calle:
                input.form-control(:form="componentId + 'form'" v-model="estudioSocioeconomico.calle.value" type="text" readonly)
              .col-md-3
                label.form-label No. Exterior / Interior:
                input.form-control(:form="componentId + 'form'" v-model="estudioSocioeconomico.noExterior.value" type="text" readonly)
              .col-md-3
                label.form-label C.P.:
                input.form-control(:form="componentId + 'form'" v-model="estudioSocioeconomico.codigoPostal.value" type="text" pattern="[0-9]{5}" readonly)
                .invalid-feedback Colocar un código postal valido.
            .row
              .col-md-3
                label.form-label Colonia:
                select.form-select(
                  v-model="estudioSocioeconomico.coloniaId.value"
                  :disabled="Boolean(!catalogos.colonias.value.length || estudioSocioeconomicoOriginal.coloniaId)"
                )
                  option(v-for="colonia in catalogos.colonias.value" :value="colonia.coloniaId") {{ colonia.colonia }}
              .col-md-3
                label.form-label Municipio / Alcaldía:
                input.form-control(type="text" readonly :value="(catalogos.colonias.value[0] || {}).ciudad")
              .col-md-3
                label.form-label Estado:
                input.form-control(readonly :value="(catalogos.colonias.value[0] || {}).estado")
              .col-md-3
                label.form-label Tel. Particular:
                input.form-control(
                  :form="componentId + 'form'"
                  v-model="estudioSocioeconomico.telefonoParticular.value"
                  type="tel"
                  pattern="^[0-9]{10}"
                  :readonly="(estudioSocioeconomicoOriginal.telefonoParticular || '') != ''"
                )
                .invalid-feedback Teléfono a 10 dígitos.
            .row
              .col-md-3
                label.form-label Tel. Celular:
                input.form-control(
                  :form="componentId + 'form'"
                  v-model="estudioSocioeconomico.telefonoCelular.value"
                  type="tel"
                  pattern="^[0-9]{10}"
                  :readonly="(estudioSocioeconomicoOriginal.telefonoCelular || '') != ''"
                )
                .invalid-feedback Teléfono a 10 dígitos.
              .col-md-3
                label.form-label Correo Electrónico Particular:
                input.form-control(
                  :form="componentId + 'form'"
                  v-model="estudioSocioeconomico.emailParticular.value"
                  type="email"
                  pattern="[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}"
                  :readonly="(estudioSocioeconomicoOriginal.emailParticular || '') != ''"
                )
                .invalid-feedback Correo Electrónico Particular invalido.
              .col-md-6
                label.form-label Medio preferido de contacto:
                br
                .form-check.form-check-inline
                  input.form-check-input(type="checkbox" value="0" v-model="medioPreferidoContactoJSON")
                  label.form-check-label Whatsapp
                .form-check.form-check-inline
                  input.form-check-input(type="checkbox" value="1" v-model="medioPreferidoContactoJSON")
                  label.form-check-label Teléfono
                .form-check.form-check-inline
                  input.form-check-input(type="checkbox" value="2" v-model="medioPreferidoContactoJSON" checked)
                  label.form-check-label Celular
                .form-check.form-check-inline
                  input.form-check-input(type="checkbox" value="3" v-model="medioPreferidoContactoJSON")
                  label.form-check-label Correo electrónico
            .row
              .col-md-3
                label.form-label Estado Civil:
                select.form-select(v-model="estudioSocioeconomico.estadoCivil.value")
                  option(v-for="estado in catalogos.estadoCivil.value" :value="estado") {{ estado.replace(/_/g, " ") }}
              .col-md-3
                label.form-label Regimen Matrimonial:
                select.form-select(v-model="estudioSocioeconomico.regimenMatrimonial.value" :disabled="ActivarDesactivarRegimenMatrimonial")
                  option(v-for="regimen in catalogos.regimenMatrimonial.value" :value="regimen") {{ regimen.replace(/_/g, " ") }}
              //- .col-md-3
                label.form-label Nombre del Cónyuge:
                input.form-control(:form="componentId + 'form'" v-model="estudioSocioeconomico.nombreConyuge" type="text" pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+")
                .invalid-feedback Nombre del Cónyuge invalido.
              .col-md-3(v-if="mostrarOcultarDatosConyuge")
                label.form-label ¿Trabaja su Cónyuge?
                div
                  .form-check.form-check-inline
                    input.form-check-input(:id="componentId + 'SiTrabaja'" type="radio" v-model="estudioSocioeconomico.trabajaConyuge.value" :value="true")
                    label.form-check-label(:for="componentId + 'SiTrabaja'") Si
                  .form-check.form-check-inline
                    input.form-check-input(:id="componentId + 'NoTrabaja'" type="radio" v-model="estudioSocioeconomico.trabajaConyuge.value" :value="false")
                    label.form-check-label(:for="componentId + 'NoTrabaja'") No
              //- .col-md-3
                label.form-label Fecha de Nacimiento del Cónyuge:
                input.form-control(v-model="estudioSocioeconomico.fechaNacimientoConyuge" type="date" min="1900-01-01" :max="fechaIso")
                .invalid-feedback Fecha de Nacimiento del Cónyuge invalido.
              .col-md-3
                label.form-label Dependientes Económicos:
                input.form-control(
                  :form="componentId + 'form'"
                  v-model="estudioSocioeconomico.dependientesEconomicos.value"
                  type="number"
                  min="0"
                  max="99"
                  step="1"
                )
                .invalid-feedback Dependientes Económicos invalido.
              .col-md-3
                label.form-label ¿Cliente radica en extranjero?
                br
                .form-check.form-check-inline
                  input.form-check-input(
                    type="radio"
                    :name="componentId + 'TipoRadicaExtranjero'"
                    :id="componentId + 'TipoRadicaExtranjero0'"
                    :value="true"
                    v-model="estudioSocioeconomico.clienteRadicaExtranjero.value"
                  )
                  label.form-check-label(:for="componentId + 'TipoRadicaExtranjero0'") Si
                .form-check.form-check-inline
                  input.form-check-input(
                    type="radio"
                    :name="componentId + 'TipoRadicaExtranjero'"
                    :id="componentId + 'TipoRadicaExtranjero1'"
                    :value="false"
                    v-model="estudioSocioeconomico.clienteRadicaExtranjero.value"
                  )
                  label.form-check-label(:for="componentId + 'TipoRadicaExtranjero1'") No
              .col-md-3
                label.form-label ¿Tiene doble nacionalidad?
                br
                .form-check.form-check-inline
                  input.form-check-input(
                    type="radio"
                    :name="componentId + 'TipoDobleNacionalidad'"
                    :id="componentId + 'TipoDobleNacionalidad0'"
                    :value="true"
                    v-model="estudioSocioeconomico.clienteDobleNacionalidad.value"
                  )
                  label.form-check-label(:for="componentId + 'TipoDobleNacionalidad0'") Si
                .form-check.form-check-inline
                  input.form-check-input(
                    type="radio"
                    :name="componentId + 'TipoDobleNacionalidad'"
                    :id="componentId + 'TipoDobleNacionalidad1'"
                    :value="false"
                    v-model="estudioSocioeconomico.clienteDobleNacionalidad.value"
                  )
                  label.form-check-label(:for="componentId + 'TipoDobleNacionalidad1'") No
              .col-md-3
                label.form-label ¿Ingresos vienen de extranjero?
                br
                .form-check.form-check-inline
                  input.form-check-input(
                    type="radio"
                    :name="componentId + 'TipoIngresosExtranjero'"
                    :id="componentId + 'TipoIngresosExtranjero0'"
                    :value="true"
                    v-model="estudioSocioeconomico.ingresosExtranjeros.value"
                  )
                  label.form-check-label(:for="componentId + 'TipoIngresosExtranjero0'") Si
                .form-check.form-check-inline
                  input.form-check-input(
                    type="radio"
                    :name="componentId + 'TipoIngresosExtranjero'"
                    :id="componentId + 'TipoIngresosExtranjero1'"
                    :value="false"
                    v-model="estudioSocioeconomico.ingresosExtranjeros.value"
                  )
                  label.form-check-label(:for="componentId + 'TipoIngresosExtranjero1'") No
              .text-center.mt-4
                button.btn.btn-primary.text-light.fw-bold(
                  type="submit"
                  :form="componentId + 'form'"
                  v-on:click="estudioSocioeconomico.notificacionAscesor.value = false"
                ) Guardar
              //- .col-md-3
                label.form-label Nacionalidad del Cónyuge:
                select2(
                  :form="componentId + 'form'"
                  :data="catalogos.paises.map((a) => ({ id: a.paisId, text: a.gentilicio.trim() || a.descripcion }))"
                  v-model="estudioSocioeconomico.paisNacionalidadIdConyuge"
                )
      .accordion.shadow.mb-4(:id="componentId + 'acordion4'" v-if="mostrarOcultarDatosConyuge")
        .accordion-item
          h2.accordion-header: button.accordion-button.collapsed.fw-bold(data-bs-toggle="collapse" :data-bs-target="'#' + componentId + 'DatosConyuge'") Datos del Cónyuge
          .accordion-collapse.collapse(:id="componentId + 'DatosConyuge'" :data-bs-parent="'#' + componentId + 'acordion4'"): .accordion-body
            .row
              .col-md-4
                label.form-label Nombre del Cónyuge:
                input.form-control(
                  :form="componentId + 'form'"
                  v-model="estudioSocioeconomico.nombreConyuge.value"
                  type="text"
                  pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+"
                )
                .invalid-feedback Nombre del Cónyuge invalido.
              .col-md-4
                label.form-label Nacionalidad del Cónyuge:
                hir-select2(
                  :form="componentId + 'form'"
                  :data="catalogos.paises.value.map(a => ({ id: a.paisId, text: (a.gentilicio ?? '').trim() || a.descripcion }))"
                  v-model="estudioSocioeconomico.paisNacionalidadIdConyuge.value"
                )
              .col-md-4(v-if="estudioSocioeconomico.trabajaConyuge")
                label.form-label Actividad del Cónyuge:
                input.form-control(
                  :form="componentId + 'form'"
                  v-model="estudioSocioeconomico.actividadConyuge.value"
                  type="text"
                  pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+"
                )
                //- select.form-select(v-model="estudioSocioeconomico.actividadConyuge")
                  option Asalariado
                  option No asalariado
                  option Profesionista independiente
              .col-md-4(v-if="estudioSocioeconomico.trabajaConyuge")
                label.form-label Total de Ingresos del Cónyuge:
                //- input.form-control(:form="componentId + 'form'" v-model="estudioSocioeconomico.totalIngresosConyuge" type="text" pattern="^[0-9]+([.][0-9]+)?")
                  .invalid-feedback Ingreso invalido.
                hir-autonumeric.form-control(
                  :form="formId"
                  v-model="estudioSocioeconomico.totalIngresosConyuge.value"
                  :configuracion="{ emptyInputBehavior: 'min', maximumValue: '99999999', minimumValue: '0', currencySymbol: '$ ' }"
                )
              .col-md-4(v-if="estudioSocioeconomico.trabajaConyuge")
                label.form-label Giro de la Empresa del Cónyuge:
                input.form-control(:form="componentId + 'form'" v-model="estudioSocioeconomico.giroConyuge.value" type="text" pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+")
                //- select.form-select(v-model="estudioSocioeconomico.giroConyuge")
                  option Alimentos
                  option Automotriz
                  option Financiera
                  option Informática
                  option Otro
              //- .col-md-4
                label.form-label RFC del Cónyuge:
                input.form-control(
                  :form="componentId + 'form'"
                  v-model="estudioSocioeconomico.rfcConyuge"
                  type="text"
                  pattern="[a-zA-Z,Ñ,&]{3,4}[0-9]{2}[0-1][0-9][0-3][0-9][a-zA-Z,0-9]?[a-zA-Z,0-9]?[0-9,a-zA-Z]?"
                )
                .invalid-feedback RFC del Cónyuge invalido.
              .col-md-4(v-if="estudioSocioeconomico.trabajaConyuge")
                label.form-label Antigüedad Laboral del Cónyuge (Meses):
                input.form-control(
                  type="number"
                  v-model="estudioSocioeconomico.antiguedadLaboralConyuge.value"
                  pattern="^[0-9]{1,2}"
                  min="0"
                  max="99"
                  step="1any"
                )
                .invalid-feedback Antigüedad Laboral del Cónyuge (Meses) invalida.
              .col-md-4(v-if="estudioSocioeconomico.trabajaConyuge")
                label.form-label RFC del Cónyuge:
                input.form-control(
                  :form="componentId + 'form'"
                  v-model="estudioSocioeconomico.rfcConyuge.value"
                  type="text"
                  pattern="[a-zA-Z,Ñ,&]{3,4}[0-9]{2}[0-1][0-9][0-3][0-9][a-zA-Z,0-9]?[a-zA-Z,0-9]?[0-9,a-zA-Z]?"
                )
                .invalid-feedback RFC invalido.
              .col-md-4(v-if="estudioSocioeconomico.trabajaConyuge")
                label.form-label Fecha de Nacimiento del Conyuge:
                input.form-control(
                  :form="componentId + 'form'"
                  v-model="estudioSocioeconomico.fechaNacimientoConyuge.value"
                  type="date"
                  min="1900-01-01"
                  :max="fechaIso"
                )
                .invalid-feedback Fecha de nacimiento inválida.
              .text-center.mt-4
                button.btn.btn-primary.text-light.fw-bold(
                  type="submit"
                  :form="componentId + 'form'"
                  v-on:click="estudioSocioeconomico.notificacionAscesor.value = false"
                ) Guardar
      informacion-laboral(
        :estudio-socioeconomico="estudioSocioeconomico"
        :es-comprobacion-ingresos="esComprobacionIngresos"
        :comp-id="componentId"
        :catalogos="catalogos"
        @guardar="guardarEstudioSocioeconomico()"
      )
      .accordion.shadow.mb-4(:id="componentId + 'acordionPatromonio'")
        .accordion-item
          h2.accordion-header: button.accordion-button.collapsed.fw-bold(data-bs-toggle="collapse" :data-bs-target="'#' + componentId + 'Patrimonio'") Patrimonio
          .accordion-collapse.collapse(:id="componentId + 'Patrimonio'" :data-bs-parent="'#' + componentId + 'acordionPatromonio'"): .accordion-body
            .row
              .col-md-5
                label.form-label Inmuebles Descripción
                textarea.form-control(row="3" v-model="estudioSocioeconomico.inmuebleDescripcion.value")
              .col-md-4
                label.form-label Inmuebles Valor Aproximado
                hir-autonumeric.form-control(
                  :form="formId"
                  v-model="estudioSocioeconomico.inmuebleValorAprox.value"
                  :configuracion="{ emptyInputBehavior: 'min', maximumValue: '99999999', minimumValue: '0', currencySymbol: '$ ' }"
                )
              .col-md-3
                label.form-label ¿Inmueble Esta Gravado?
                br
                .form-check.form-check-inline
                  input.form-check-input(
                    type="radio"
                    :name="componentId + 'TipoInmuebleGravado'"
                    :id="componentId + 'TipoInmuebleGravado0'"
                    :value="true"
                    v-model="estudioSocioeconomico.inmuebleGravado.value"
                  )
                  label.form-check-label(:for="componentId + 'TipoInmuebleGravado0'") Si
                .form-check.form-check-inline
                  input.form-check-input(
                    type="radio"
                    :name="componentId + 'TipoInmuebleGravado'"
                    :id="componentId + 'TipoInmuebleGravado1'"
                    :value="false"
                    v-model="estudioSocioeconomico.inmuebleGravado.value"
                  )
                  label.form-check-label(:for="componentId + 'TipoInmuebleGravado1'") No
              .col-md-5
                label.form-label Automoviles Descripción
                textarea.form-control(row="3" v-model="estudioSocioeconomico.automovilDescripcion.value")
              .col-md-4
                label.form-label Automoviles Valor Aproximado
                hir-autonumeric.form-control(
                  :form="formId"
                  v-model="estudioSocioeconomico.automovilValorAprox.value"
                  :configuracion="{ emptyInputBehavior: 'min', maximumValue: '99999999', minimumValue: '0', currencySymbol: '$ ' }"
                )
              .col-md-3
                label.form-label Automoviles Esta Gravado?
                br
                .form-check.form-check-inline
                  input.form-check-input(
                    type="radio"
                    :name="componentId + 'TipoAutomovilGravado'"
                    :id="componentId + 'TipoAutomovilGravado0'"
                    :value="true"
                    v-model="estudioSocioeconomico.automovilGravado.value"
                  )
                  label.form-check-label(:for="componentId + 'TipoAutomovilGravado0'") Si
                .form-check.form-check-inline
                  input.form-check-input(
                    type="radio"
                    :name="componentId + 'TipoAutomovilGravado'"
                    :id="componentId + 'TipoAutomovilGravado1'"
                    :value="false"
                    v-model="estudioSocioeconomico.automovilGravado.value"
                  )
                  label.form-check-label(:for="componentId + 'TipoAutomovilGravado1'") No
              .text-center.mt-4
                button.btn.btn-primary.text-light.fw-bold(
                  type="submit"
                  :form="componentId + 'form'"
                  v-on:click="estudioSocioeconomico.notificacionAscesor.value = false"
                ) Guardar
      .accordion.shadow.mb-4(:id="componentId + 'acordion6'")
        .accordion-item
          h2.accordion-header: button.accordion-button.collapsed.fw-bold(data-bs-toggle="collapse" :data-bs-target="'#' + componentId + 'gastos'") Gastos (En moneda nacional)
          .accordion-collapse.collapse(:id="componentId + 'gastos'" :data-bs-parent="'#' + componentId + 'acordion6'"): .accordion-body
            .row
              .col-md-3
                label.form-label Pago Mensual de Créditos:
                //-input.form-control(
                  :form="componentId + 'form'"
                  v-model="estudioSocioeconomico.pagoMensualCreditos"
                  type="text"
                  pattern="^[0-9]+([.][0-9]+)?"
                  min="0"
                  max="999999999"
                  step="any")
                hir-autonumeric.form-control(
                  :form="formId"
                  v-model="estudioSocioeconomico.pagoMensualCreditos.value"
                  :configuracion="{ emptyInputBehavior: 'min', maximumValue: '99999999', minimumValue: '0', currencySymbol: '$ ' }"
                )
                .invalid-feedback Pago Mensual de Créditos invalido.
              .col-md-3
                label.form-label Tarjeta de Crédito:
                //- input.form-control(
                  :form="componentId + 'form'"
                  v-model="estudioSocioeconomico.tarjetaCredito"
                  type="text"
                  pattern="^[0-9]+([.][0-9]+)?"
                  min="0"
                  max="999999999"
                  step="any")
                hir-autonumeric.form-control(
                  :form="formId"
                  v-model="estudioSocioeconomico.tarjetaCredito.value"
                  :configuracion="{ emptyInputBehavior: 'min', maximumValue: '99999999', minimumValue: '0', currencySymbol: '$ ' }"
                )
              .col-md-3
                label.form-label Renta:
                //-input.form-control(
                  :form="componentId + 'form'"
                  v-model="estudioSocioeconomico.renta"
                  type="text"
                  pattern="^[0-9]+([.][0-9]+)?"
                  min="0"
                  max="999999999"
                  step="any")
                hir-autonumeric.form-control(
                  :form="formId"
                  v-model="estudioSocioeconomico.renta.value"
                  :configuracion="{ emptyInputBehavior: 'min', maximumValue: '99999999', minimumValue: '0', currencySymbol: '$ ' }"
                )
              .col-md-3
                label.form-label Colegiaturas:
                //-input.form-control(
                  :form="componentId + 'form'"
                  v-model="estudioSocioeconomico.colegiaturas"
                  type="text"
                  pattern="^[0-9]+([.][0-9]+)?"
                  min="0"
                  max="999999999"
                  step="any")
                hir-autonumeric.form-control(
                  :form="formId"
                  v-model="estudioSocioeconomico.colegiaturas.value"
                  :configuracion="{ emptyInputBehavior: 'min', maximumValue: '99999999', minimumValue: '0', currencySymbol: '$ ' }"
                )
            .row
              .col-md-3
                label.form-label Gasto Familiar:
                //-input.form-control(
                  :form="componentId + 'form'"
                  v-model="estudioSocioeconomico.gastoFamiliar"
                  type="text"
                  pattern="^[0-9]+([.][0-9]+)?"
                  min="0"
                  max="999999999"
                  step="any")
                hir-autonumeric.form-control(
                  :form="formId"
                  v-model="estudioSocioeconomico.gastoFamiliar.value"
                  :configuracion="{ emptyInputBehavior: 'min', maximumValue: '99999999', minimumValue: '0', currencySymbol: '$ ' }"
                )
              .col-md-3
                label.form-label Otros Egresos:
                //- input.form-control(
                  :form="componentId + 'form'"
                  v-model="estudioSocioeconomico.otrosEgresos"
                  type="text"
                  pattern="^[0-9]+([.][0-9]+)?"
                  min="0"
                  max="999999999"
                  step="any")
                hir-autonumeric.form-control(
                  :form="formId"
                  v-model="estudioSocioeconomico.otrosEgresos.value"
                  :configuracion="{ emptyInputBehavior: 'min', maximumValue: '99999999', minimumValue: '0', currencySymbol: '$ ' }"
                )
              .col-md-3
                label.form-label Cuotas (pagos) a Hir Casa
                hir-autonumeric.form-control(
                  :form="formId"
                  v-model="estudioSocioeconomico.cuotas.value"
                  :configuracion="{ emptyInputBehavior: 'min', maximumValue: '99999999', minimumValue: '0', currencySymbol: '$ ' }"
                )
              .col-md-3
                label.form-label Total Egresos:
                //- input.form-control(v-model="estudioSocioeconomicoTotalEgresos" readonly)
                hir-autonumeric.form-control(
                  :form="formId"
                  readonly
                  v-model="estudioSocioeconomicoTotalEgresos"
                  :configuracion="{ emptyInputBehavior: 'min', maximumValue: '99999999', minimumValue: '0', currencySymbol: '$ ' }"
                )
              .col-md-3
                label.form-label ¿Puede comprobar ingresos?
                br
                .form-check.form-check-inline
                  input.form-check-input(
                    type="radio"
                    :name="componentId + 'TipoComprobarIngresos'"
                    :id="componentId + 'TipoComprobarIngresos0'"
                    :value="true"
                    v-model="estudioSocioeconomico.comprobarIngresos.value"
                  )
                  label.form-check-label(:for="componentId + 'TipoRadicaExtranjero0'") Si
                .form-check.form-check-inline
                  input.form-check-input(
                    type="radio"
                    :name="componentId + 'TipoComprobarIngresos'"
                    :id="componentId + 'TipoComprobarIngresos1'"
                    :value="false"
                    v-model="estudioSocioeconomico.comprobarIngresos.value"
                  )
                  label.form-check-label(:for="componentId + 'TipoComprobarIngresos1'") No
              .text-center.mt-4
                button.btn.btn-primary.text-light.fw-bold(
                  type="submit"
                  :form="componentId + 'form'"
                  v-on:click="estudioSocioeconomico.notificacionAscesor.value = false"
                ) Guardar
      .accordion.shadow.mb-4(:id="componentId + 'acordion5'")
        .accordion-item
          h2.accordion-header: button.accordion-button.collapsed.fw-bold(data-bs-toggle="collapse" :data-bs-target="'#' + componentId + 'viviendaActual'") Vivienda Actual
          .accordion-collapse.collapse(:id="componentId + 'viviendaActual'" :data-bs-parent="'#' + componentId + 'acordion5'"): .accordion-body
            .row
              .col-md-4
                label.form-label Actualmente su Vivienda es:
                select.form-select(v-model="estudioSocioeconomico.tipoViviendaActual.value")
                  option(:value="0") Propio
                  option(:value="2") Familiar
                  option(:value="3") Hipotecada
                  option(:value="1") Rentada
              .col-md-4
                label.form-label Nombre del Arrendador:
                input.form-control(
                  :form="componentId + 'form'"
                  v-model="estudioSocioeconomico.nombreArrendador.value"
                  type="text"
                  pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+"
                  :required="requeridoViviendaActual"
                )
                .invalid-feedback Nombre del Arrendador invalido.
              .col-md-4
                label.form-label Teléfono del Arrendador:
                input.form-control(
                  :form="componentId + 'form'"
                  v-model="estudioSocioeconomico.telefonoArrendador.value"
                  type="tel"
                  pattern="[0-9]{10}"
                  :required="requeridoViviendaActual"
                )
                .invalid-feedback Teléfono a 10 dígitos.
              .text-center.mt-4
                button.btn.btn-primary.text-light.fw-bold(
                  type="submit"
                  :form="componentId + 'form'"
                  v-on:click="estudioSocioeconomico.notificacionAscesor.value = false"
                ) Guardar
      //- WIP
      //- .accordion.shadow.mb-4(:id="this.componentId + 'acordion9'")
        .accordion-item
          h2.accordion-header: button.accordion-button.collapsed.fw-bold(data-bs-toggle="collapse" :data-bs-target="'#' + this.componentId + 'propiedades'") Propiedades
          .accordion-collapse.collapse(:id="this.componentId + 'propiedades'" :data-bs-parent="'#' + this.componentId + 'acordion9'"): .accordion-body
            .row
              .col-md-3
                label.form-label ¿Puede comprobar los ingresos que declaró?:
                input.form-control(v-model="estudioSocioeconomico.pagoMensualCreditos")
              .col-md-3
                label.form-label Si posee inmuebles, declárelos aquí:
                input.form-control(v-model="estudioSocioeconomico.tarjetaCredito")
              .col-md-3
                label.form-label Valor aproximado de los inmuebles que posee:
                input.form-control(v-model="estudioSocioeconomico.renta")
              .col-md-3
                label.form-label Valor de los inmuebles hipotecados:
                input.form-control(v-model="estudioSocioeconomico.colegiaturas")
            .row
              .col-md-3
                label.form-label Si posee automóviles declárelos aquí:
                input.form-control(v-model="estudioSocioeconomico.pagoMensualCreditos")
              .col-md-3
                label.form-label Valor apróximado de los automóviles:
                input.form-control(v-model="estudioSocioeconomico.tarjetaCredito")
      .accordion.shadow.mb-4(:id="componentId + 'acordion7'")
        .accordion-item
          h2.accordion-header: button.accordion-button.collapsed.fw-bold(data-bs-toggle="collapse" :data-bs-target="'#' + componentId + 'referencias'") Referencias
          .accordion-collapse.collapse(:id="componentId + 'referencias'" :data-bs-parent="'#' + componentId + 'acordion7'"): .accordion-body
            .row
              .col-md-3
                label.form-label Nombre de Referencia Familiar:
                input.form-control(v-model="estudioSocioeconomico.nombreRefFamiliar.value" type="text" pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+")
                .invalid-feedback Nombre de una Referencia Familiar invalido.
              .col-md-3
                label.form-label Teléfono de Referencia Familiar:
                input.form-control(v-model="estudioSocioeconomico.telefonoRefFamiliar.value" type="tel" pattern="[0-9]{10}")
                .invalid-feedback Teléfono a 10 dígitos.
              .col-md-6
                label.form-label Direccion Referencia Familiar:
                input.form-control(
                  :form="componentId + 'form'"
                  type="text"
                  pattern="[0-9a-zA-ZñÑéÉáÁíÍóÓúÓ#. ]+"
                  v-model="estudioSocioeconomico.direccionREfFamiliar.value"
                )
                .invalid-feedback Dirección Familiar invalida.
            .row
              .col-md-3
                label.form-label Nombre de Referencia Laboral:
                input.form-control(v-model="estudioSocioeconomico.nombreRefLaboral.value" type="text" pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+")
                .invalid-feedback Nombre de una Referencia invalido.
              .col-md-3
                label.form-label Teléfono de Referencia Laboral:
                input.form-control(v-model="estudioSocioeconomico.telefonoRefLaboral.value" type="tel" pattern="[0-9]{10}")
                .invalid-feedback Teléfono a 10 dígitos.
              .col-md-6
                label.form-label Dirección Referencia Laboral:
                input.form-control(
                  :form="componentId + 'form'"
                  type="text"
                  pattern="[0-9a-zA-ZñÑéÉáÁíÍóÓúÓ#. ]+"
                  v-model="estudioSocioeconomico.direccionREfLaboral.value"
                )
                .invalid-feedback Dirección invalida.
            .row
              .col-md-3
                //-offset-md-2
                label.form-label Nombre de Referencia Contacto:
                input.form-control(v-model="estudioSocioeconomico.nombreRefContacto.value" type="text" pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+")
                .invalid-feedback Nombre de una Referencia invalido.
              .col-md-3
                label.form-label Teléfono de Referencia Contacto:
                input.form-control(v-model="estudioSocioeconomico.telefonoRefContacto.value" type="tel" pattern="[0-9]{10}")
                .invalid-feedback Teléfono a 10 dígitos.
              .col-md-6
                label.form-label Dirección Referencia Contacto:
                input.form-control(
                  :form="componentId + 'form'"
                  type="text"
                  pattern="[0-9a-zA-ZñÑéÉáÁíÍóÓúÓ#. ]+"
                  v-model="estudioSocioeconomico.direccionREfContacto.value"
                )
                .invalid-feedback Dirección invalida.
            .text-center.mt-4
              button.btn.btn-primary.text-light.fw-bold(
                type="submit"
                :form="componentId + 'form'"
                v-on:click="estudioSocioeconomico.notificacionAscesor.value = false"
              ) Guardar
      .accordion.shadow.mb-4(:id="componentId + 'acordionVendedor'")
        .accordion-item
          h2.accordion-header: button.accordion-button.collapsed.fw-bold(data-bs-toggle="collapse" :data-bs-target="'#' + componentId + 'vendedor'") Vendedor
          .accordion-collapse.collapse(:id="componentId + 'vendedor'" :data-bs-parent="'#' + componentId + 'acordionVendedor'"): .accordion-body
            .row
              .col-md-3
                label.form-label Nombre:
                input.form-control(
                  :form="componentId + 'form'"
                  v-model="estudioSocioeconomico.nombreVendedor.value"
                  type="text"
                  pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+"
                )
                .invalid-feedback Nombre invalido.
              .col-md-3
                label.form-label Número de teléfono de celular:
                input.form-control(:form="componentId + 'form'" v-model="estudioSocioeconomico.telefonoVendedor.value" type="tel" pattern="[0-9]{10}")
                .invalid-feedback Teléfono a 10 dígitos.
              .col-md-3
                label.form-label Número de teléfono partícular:
                input.form-control(:form="componentId + 'form'" v-model="estudioSocioeconomico.celularVendedor.value" type="tel" pattern="[0-9]{10}")
                .invalid-feedback Teléfono a 10 dígitos.
              .col-md-3
                label.form-label Fecha de nacimiento:
                input.form-control(
                  :form="componentId + 'form'"
                  v-model="estudioSocioeconomico.fechaNacimientoVendedor.value"
                  type="date"
                  min="1900-01-01"
                  :max="fechaIso"
                )
                .invalid-feedback Fecha de nacimiento inválida.
            .row
              .col-md-3
                label.form-label Estado civil:
                select.form-select(v-model="estudioSocioeconomico.estadoCivilVendedor.value")
                  option(v-for="estado in catalogos.estadoCivil.value" :value="estado") {{ estado.replace(/_/g, " ") }}
              .col-md-3
                label.form-label Régimen matrimonial:
                select.form-select(v-model="estudioSocioeconomico.regimenMatrimonialVendedor.value" :disabled="regimenMatrimonial")
                  option(v-for="regimen in catalogos.regimenMatrimonial.value" :value="regimen") {{ regimen.replace(/_/g, " ") }}
              .col-md-3
                label.form-label Correo electrónico personal:
                input.form-control(
                  :form="componentId + 'form'"
                  v-model="estudioSocioeconomico.emailVendedor.value"
                  type="email"
                  pattern="[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}"
                )
                .invalid-feedback Correo electrónico personal invalido.
              .col-md-3
                label.form-label Correo electrónico laboral:
                input.form-control(
                  :form="componentId + 'form'"
                  v-model="estudioSocioeconomico.emailLaboralVendedor.value"
                  type="email"
                  pattern="[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}"
                )
                .invalid-feedback Correo electrónico laboral invalido.
            .row
              .col-md-3
                b.form-label Nacionalidad:
                hir-select2(
                  :form="componentId + 'form'"
                  :data="catalogos.paises.value.map(a => ({ id: a.paisId, text: (a.gentilicio || '').trim() || a.descripcion }))"
                  v-model="estudioSocioeconomico.paisNacionalidadIdVendedor.value"
                )
            .text-center
              button.btn.btn-primary.text-light.fw-bold.mt-4(
                type="submit"
                :form="componentId + 'form'"
                v-on:click="estudioSocioeconomico.notificacionAscesor.value = false"
              ) Guardar
      //- WIP
      //- .accordion.shadow.mb-4(:id="this.componentId + 'acordion8'")
        .accordion-item
          h2.accordion-header: button.accordion-button.collapsed.fw-bold(
            data-bs-toggle="collapse"
            :data-bs-target="'#' + this.componentId + 'informacionAdicional'"
          ) Información Adicional
          .accordion-collapse.collapse(:id="this.componentId + 'informacionAdicional'" :data-bs-parent="'#' + this.componentId + 'acordion8'"): .accordion-body
            .row
              .col-md-4
                label.form-label ¿Es Aval u Obligado Solidario en algún Préstamo?:
                input.form-control
              .col-md-4
                label.form-label ¿Se ha Declarado en Moratoria o Suspensión de Pagos alguna vez?
                input.form-control
              .col-md-4
                label.form-label ¿Ha sido alguna vez demandado por Incumplimiento de sus obligaciones financieras?
                input.form-control
            .row
              .col-md-4
                label.form-label Nombre del Vendedor:
                input.form-control(v-model="estudioSocioeconomico.nombreVendedor")
              .col-md-4
                label.form-label Teléfono del Vendedor:
                input.form-control(v-model="estudioSocioeconomico.telefonoVendedor")
              .col-md-4
                label.form-label Mail del Vendedor:
                input.form-control(v-model="estudioSocioeconomico.emailVendedor")
      //-form.text-center(ref="form" novalidate :id="componentId + 'form'"): button.btn.btn-success(@click.prevent="guardarEstudioSocioeconomico()") Guardar
      //- .accordion.shadow.mb-4(:id="this.componentId + 'acordion9'")
        .accordion-item
          h2.accordion-header: button.accordion-button.collapsed(data-bs-toggle="collapse" :data-bs-target="'#' + this.componentId + 'proyecto'") Proyecto
          .accordion-collapse.collapse(:id="this.componentId + 'proyecto'" :data-bs-parent="'#' + this.componentId + 'acordion9'"): .accordion-body
            .row
              .col-md-4
                label.form-label Proyecto:
                select.form-select(v-model="estudioSocioeconomico.proyecto")
                  option(disabled select) Seleccione...
                  option Casa nueva
                  option Casa usada
                  option Departamento nuevo
                  option Departamento usado
                  option Local comercial
                  option Terreno
              .col-md-4
                label.form-label Monto Adjudicado:
                input.form-control(v-model="estudioSocioeconomico.montoAdjudicado")
              .col-md-4
                label.form-label Score BC:
                input.form-control(v-model="estudioSocioeconomico.scoreBc")
      //- Hipotecario'") 
        .accordion-item
          h2.accordion-header: button.accordion-button.collapsed.fw-bold(
            data-bs-toggle="collapse"
            :data-bs-target="'#' + this.componentId + 'GaranteHipotecario'"
          ) Garante Hipotecario
          .accordion-collapse.collapse(:id="this.componentId + 'GaranteHipotecario'" :data-bs-parent="'#' + this.componentId + 'acordionGaranteHipotecario'"): .accordion-body
            .row
              .col-md-3
                label.form-label Nombre:
                input.form-control(
                  :form="componentId + 'form'"
                  v-model="estudioSocioeconomico.nombreGaranteHipotecario"
                  type="text"
                  pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+"
                )
                .invalid-feedback Nombre invalido.
              .col-md-3
                label.form-label Número de teléfono de celular:
                input.form-control(:form="componentId + 'form'" v-model="estudioSocioeconomico.telefonoGaranteHipotecario" type="tel" pattern="[0-9]{10}")
                .invalid-feedback Número de teléfono de celular invalido.
              .col-md-3
                label.form-label Número de teléfono partícular:
                input.form-control(:form="componentId + 'form'" v-model="estudioSocioeconomico.celularGaranteHipotecario" type="tel" pattern="[0-9]{10}")
                .invalid-feedback Número de teléfono partícular invalido.
              .col-md-3
                label.form-label Fecha de nacimiento:
                input.form-control(
                  :form="componentId + 'form'"
                  v-model="estudioSocioeconomico.fechaNacimientoGaranteHipotecario"
                  type="date"
                  min="1900-01-01"
                  :max="fechaIso"
                )
                .invalid-feedback Fecha de nacimiento inválida.
            .row
              .col-md-3
                label.form-label Estado civil:
                select.form-select(v-model="estudioSocioeconomico.estadoCivilGaranteHipotecario")
                  option(v-for="estado in catalogos.estadoCivil" :value="estado") {{ estado.replace(/_/g, ' ') }}
              .col-md-3
                label.form-label Régimen matrimonial:
                select.form-select(v-model="estudioSocioeconomico.regimenMatrimonialGaranteHipotecario" :disabled="regimenMatrimonialGH")
                  option(v-for="regimen in catalogos.regimenMatrimonial" :value="regimen") {{ regimen.replace(/_/g, ' ') }}
              .col-md-3
                label.form-label Correo electrónico personal:
                input.form-control(
                  :form="componentId + 'form'"
                  v-model="estudioSocioeconomico.emailGaranteHipotecario"
                  type="email"
                  pattern="[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}"
                )
                .invalid-feedback Correo electrónico personal invalido.
              .col-md-3
                label.form-label Correo electrónico laboral:
                input.form-control(
                  :form="componentId + 'form'"
                  v-model="estudioSocioeconomico.emailLaboralGaranteHipotecario"
                  type="email"
                  pattern="[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}"
                )
                .invalid-feedback Correo electrónico laboral invalido.
            .row
              .col-md-3
                label.form-label Nacionalidad:
                select2(
                  :form="componentId + 'form'"
                  :data="catalogos.paises.map((a) => ({ id: a.paisId, text: a.gentilicio.trim() || a.descripcion }))"
                  v-model="estudioSocioeconomico.paisNacionalidadIdGaranteHipotecario"
                )
      form.text-center(ref="form" novalidate :id="componentId + 'form'" @submit.prevent="guardarEstudioSocioeconomico()"): button.btn.btn-success(
        v-on:click="estudioSocioeconomico.notificacionAscesor.value = true"
      ) Enviar Datos
</template>

<script lang="ts" setup>
import HirAutonumeric from "@/components/HirAutonumeric.vue";
import InformacionLaboral from "./EstudioSocioeconomico/InformacionLaboral.vue";
import filtros from "@/composables/filtros";
import * as utilidades from "@/utilidades";
import { limpiarFechas as limpiarFechasES, mapear as mapearES } from "../composables/EstudioSocioeconomico";
import { generarComprobacionIngresos } from "../composables/ComprobacionIngresos";
import type { IngresoGeneral, IngresoNomina } from "../composables/ComprobacionIngresos";
import { useStore as useClienteClienteStore } from "../store";
import { useStore } from "@/store";
import { useRouter } from "vue-router";

const filtroDivisa = filtros.filtroDivisa;

const form = ref(null as HTMLFormElement | null);
const store = useClienteClienteStore();
const $router = useRouter();
const $store = useStore();

//#region Data
const esComprobacionIngresos = toRefs(reactive(generarComprobacionIngresos()));
provide("ComprobacionIngresos", esComprobacionIngresos);

const componentId = utilidades.generarId();
const formId = componentId + "form";
const fechaActual = new Intl.DateTimeFormat("es-MX", {
  day: "2-digit",
  month: "2-digit",
  year: "numeric"
} as any).format(new Date());
const fechaIso = new Date().toISOString().substring(0, 10);
const catalogos = toRefs(
  reactive({
    estadoCivil: Core.Models.EstadoCivil,
    regimenMatrimonial: Core.Models.RegimenMatrimonial,
    procedimientoAdjudicacion: Core.Models.ProcedimientoAdjudicacion,
    profesiones: [] as Core.Models.Profesion[],
    colonias: [] as Cliente.Cliente.ObtenerColoniasBody[],
    paises: [] as Core.Models.Pais[]
  })
);
const formularioValidado = ref(false);
const estudioSocioeconomicoOriginal = {
  coloniaId: 0,
  telefonoParticular: "" as string | undefined,
  telefonoCelular: "" as string | undefined,
  emailParticular: "" as string | undefined
};
const medioPreferidoContactoJSON = ref([] as number[]);
const reactiveEstudioSocioeconomico = reactive({
  estudioSocioeconomicoId: "",
  clienteId: 0,
  servicioContratar: 0,
  destino: 0,
  valorProyecto: 0,
  nombre: "",
  apellidoPaterno: "",
  apellidoMaterno: "",
  fechaNacimiento: undefined as string | undefined,
  rfc: "",
  rfcConyuge: "",
  calle: "",
  noExterior: "",
  codigoPostal: "",
  coloniaId: 0,
  telefonoParticular: "" as string | undefined,
  telefonoCelular: "" as string | undefined,
  emailParticular: "" as string | undefined,
  estadoCivil: Core.Models.EstadoCivil.SOLTERO,
  regimenMatrimonial: undefined as Core.Models.RegimenMatrimonial | undefined,
  dependientesEconomicos: 0,
  nombreConyuge: "",
  fechaNacimientoConyuge: undefined as string | undefined,
  paisNacionalidadIdConyuge: undefined as number | undefined,
  profesion: 0 as number | undefined,
  ocupacionActual: undefined as string | undefined,
  ocupacionActualArray: [] as number[],
  laboralOtro: "",
  empresaLabora: "",
  actividadEmpresa: "",
  telefonoEmpresa: "",
  extensionTelefonicaTrabajo: "",
  emailTrabajo: "",
  puestoDesempenia: "",
  otroPuesto: "",
  antiguedadLaboralMeses: 0,
  nombreJefeInmediato: "",
  telefonoJefeInmediato: "",
  extensionJefeInmediato: "",
  sueldoNominalBruto: 0,
  isrImss: 0,
  sueldoNominalNeto: 0.0,
  honorarios: 0,
  gratificaciones: 0,
  rentas: 0,
  comisiones: 0,
  otrosIngresos: 0,
  totalIngresos: 0,
  totalIngresosConyuge: 0,
  actividadConyuge: "",
  giroConyuge: "",
  antiguedadLaboralConyuge: 0,
  tipoViviendaActual: -1,
  nombreArrendador: "",
  telefonoArrendador: "",
  pagoMensualCreditos: 0,
  tarjetaCredito: 0,
  renta: 0,
  colegiaturas: 0,
  gastoFamiliar: 0,
  otrosEgresos: 0,
  totalEgresos: 0,
  nombreRefFamiliar: undefined as string | undefined,
  telefonoRefFamiliar: "",
  nombreRefLaboral: "",
  telefonoRefLaboral: "",
  nombreRefContacto: "",
  telefonoRefContacto: "",
  esAvalObligadoSolidario: false,
  esDeclaradoMoratoriaSuspensionPagos: false,
  esDemandadoIncumplimientoObligacionesFinancieras: false,
  nombreVendedor: "",
  telefonoVendedor: "",
  celularVendedor: "",
  emailVendedor: "",
  emailLaboralVendedor: "",
  fechaNacimientoVendedor: undefined as string | undefined,
  estadoCivilVendedor: undefined as Core.Models.EstadoCivil | undefined,
  regimenMatrimonialVendedor: undefined as Core.Models.RegimenMatrimonial | undefined,
  paisNacionalidadIdVendedor: undefined as number | undefined,
  proyecto: "",
  montoAdjudicado: 0,
  scoreBC: 0,
  medioPreferidoContacto: "",
  paisNacionalidadId: 0 as number,
  nombreGaranteHipotecario: "",
  telefonoGaranteHipotecario: "",
  celularGaranteHipotecario: "",
  fechaNacimientoGaranteHipotecario: undefined as string | undefined,
  emailGaranteHipotecario: "" as string | undefined,
  emailLaboralGaranteHipotecario: "" as string | undefined,
  estadoCivilGaranteHipotecario: undefined as Core.Models.EstadoCivil | undefined,
  regimenMatrimonialGaranteHipotecario: undefined as Core.Models.RegimenMatrimonial | undefined,
  paisNacionalidadIdGaranteHipotecario: undefined as number | undefined,
  ingresos: "",
  trabajaConyuge: false,
  notificacionAscesor: false,
  version: 0,
  contratosInvolucrados: undefined as string | undefined,
  procedimientoAdjudicacion: undefined as Core.Models.ProcedimientoAdjudicacion | undefined,
  cuotas: undefined as number | undefined,
  comprobarIngresos: undefined as boolean | undefined,
  direccionREfFamiliar: undefined as string | undefined,
  direccionREfLaboral: undefined as string | undefined,
  direccionREfContacto: undefined as string | undefined,
  clienteRadicaExtranjero: undefined as boolean | undefined,
  clienteDobleNacionalidad: undefined as boolean | undefined,
  ingresosExtranjeros: undefined as boolean | undefined,
  inmuebleDescripcion: undefined as string | undefined,
  inmuebleValorAprox: undefined as number | undefined,
  inmuebleGravado: undefined as boolean | undefined,
  automovilDescripcion: undefined as string | undefined,
  automovilValorAprox: undefined as number | undefined,
  automovilGravado: undefined as boolean | undefined
});
const estudioSocioeconomico = toRefs(reactiveEstudioSocioeconomico);
let esCargado = false;
//#endregion

//#region Computed
const estudioSocioeconomicoTotalIngresos = computed((): number => {
  let es = estudioSocioeconomico;
  return (
    (es.honorarios.value ?? 0) +
    (es.gratificaciones.value ?? 0) +
    (es.rentas.value ?? 0) +
    (es.comisiones.value ?? 0) +
    (es.otrosIngresos.value ?? 0) +
    (es.sueldoNominalNeto.value ?? 0)
  );
});
const estudioSocioeconomicoTotalEgresos = computed((): number => {
  let es = estudioSocioeconomico;
  return (
    (es.pagoMensualCreditos.value ?? 0) +
    (es.tarjetaCredito.value ?? 0) +
    (es.renta.value ?? 0) +
    (es.colegiaturas.value ?? 0) +
    (es.gastoFamiliar.value ?? 0) +
    (es.otrosEgresos.value ?? 0) +
    (es.cuotas.value ?? 0)
  );
});
const ActivarDesactivarRegimenMatrimonial = computed((): boolean => {
  let es = estudioSocioeconomico;
  if (es.estadoCivil?.value == "CASADO") return false;
  else {
    es.regimenMatrimonial.value = undefined;
    return true;
  }
});
const mostrarOcultarDatosConyuge = computed((): boolean => {
  let es = estudioSocioeconomico;
  if (es.estadoCivil?.value == "CASADO") {
    return true;
  } else {
    es.regimenMatrimonial.value = undefined;
    return false;
  }
});
const requeridoViviendaActual = computed((): boolean => {
  let es = estudioSocioeconomico;
  if (es.tipoViviendaActual?.value == 1) return true;
  else return false;
});
const regimenMatrimonial = computed((): boolean => {
  let es = estudioSocioeconomico;
  if (es.estadoCivilVendedor?.value == "CASADO") return false;
  else {
    es.regimenMatrimonialVendedor.value = undefined;
    return true;
  }
});
//#endregion

//#region Watch
watch(
  estudioSocioeconomico.codigoPostal,
  _.debounce(val => {
    if (val) api.Cliente.Cliente.EscrituracionApi.ObtenerColonias(val).then(v => (catalogos.colonias.value = v.data));
    else catalogos.colonias.value = [];
  }, 300)
);

watch(
  Object.values(esComprobacionIngresos),
  () => {
    if (esCargado) calcularIngresos();
  },
  { deep: true }
);
//#endregion

//#region Methods
async function guardarEstudioSocioeconomico() {
  let valido = (form.value as HTMLFormElement).checkValidity();
  estudioSocioeconomico.medioPreferidoContacto.value = medioPreferidoContactoJSON.value.join(",");
  formularioValidado.value = true;
  if (!valido) {
    $store.agregarToast({
      tipo: TipoBootstrap.DANGER,
      body: "Llenar todos los campos correctamente."
    });
    return;
  }
  if (estudioSocioeconomico.notificacionAscesor) {
    Swal.fire({
      title: "<p class='text-primary fw-bold'>Tus datos serán enviados.</p>",
      html: "Gracias por enviar los datos de tu estudio socioeconómico, por favor envía un correo a tu asesor para notificarlo del cierre y continuar con la revisión y posterior emisión de los documentos necesarios en el trámite.<b/>",
      icon: "warning",
      showCancelButton: true,
      customClass: {
        confirmButton: "btn btn-primary text-white fw-bold",
        cancelButton: "btn text-white"
      },
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar"
    }).then(async (result: any) => {
      if (result.isConfirmed) {
        await enviarEstudioSocioEconomico();
      }
    });
  } else {
    await enviarEstudioSocioEconomico();
  }
}

function calcularIngresos() {
  let es = estudioSocioeconomico;
  let ci = esComprobacionIngresos;
  let aIngresos: string[] = [];
  verficarConceptos(ci.nomina.value, 1, ci.cantidadNomina.value, aIngresos);
  verficarConceptos(ci.negocioPropio.value, 2, ci.cantidadNegocioPropio.value, aIngresos);
  verficarConceptos(ci.pension.value, 3, ci.cantidadPension.value, aIngresos);
  verficarConceptos(ci.actividadEmpresarial.value, 4, ci.cantidadActividadEmpresarial.value, aIngresos);
  verficarConceptos(ci.arrendamiento.value, 5, ci.cantidadArrendamiento.value, aIngresos);
  verficarConceptos(ci.concesion.value, 6, ci.cantidadConcesion.value, aIngresos);
  verficarConceptos(ci.otro.value, 7, ci.cantidadOtro.value, aIngresos);
  es.ingresos.value = JSON.stringify(aIngresos);
  /*Sumatoria de Conceptos*/
  sumarTodosConceptos();
}

function limpiarIngresos() {
  for (let nomina of esComprobacionIngresos.nomina.value) {
    nomina.empresa = utilidades.limpiarString(nomina.empresa);
    nomina.telefono = utilidades.limpiarString(nomina.telefono);
    nomina.extension = utilidades.limpiarString(nomina.extension);
    nomina.correo = utilidades.limpiarString(nomina.correo);
    nomina.puesto = utilidades.limpiarString(nomina.puesto);
    nomina.otroPuesto = utilidades.limpiarString(nomina.otroPuesto);
    nomina.nombreJefe = utilidades.limpiarString(nomina.nombreJefe);
    nomina.telefonoJefe = utilidades.limpiarString(nomina.telefonoJefe);
    nomina.extensionJefe = utilidades.limpiarString(nomina.extensionJefe);
    nomina.actividad = utilidades.limpiarString(nomina.actividad);
  }
  for (let ingresoGeneral of [
    esComprobacionIngresos.actividadEmpresarial.value,
    esComprobacionIngresos.arrendamiento.value,
    esComprobacionIngresos.concesion.value,
    esComprobacionIngresos.negocioPropio.value,
    esComprobacionIngresos.otro.value,
    esComprobacionIngresos.pension.value
  ])
    for (let general of ingresoGeneral) {
      general.concepto = utilidades.limpiarString(general.concepto);
    }
}

async function enviarEstudioSocioEconomico() {
  let es = estudioSocioeconomico;
  es.estudioSocioeconomicoId.value ??= $router.currentRoute.value.query["estudioId"] as string;
  es.totalIngresos.value = estudioSocioeconomicoTotalIngresos.value;
  es.totalEgresos.value = estudioSocioeconomicoTotalEgresos.value;
  //-Modifcaciones para obtener sumar conceptos y nominas
  //-this.sumarDatos(this.esComprobacionIngresos.actividadEmpresarial);
  /*JCreacion de Array JSON Estructurado de Ingresos*/
  limpiarIngresos();
  calcularIngresos();
  /*Accciones para Guardar Datos*/
  es.ocupacionActual.value = (es.ocupacionActualArray.value ?? []).join(",");
  limpiarFechasES(estudioSocioeconomico);
  let estudio = toRaw(reactiveEstudioSocioeconomico) as Cliente.Cliente.EstudioSocioeconomico;
  let r = await api.Cliente.Cliente.EscrituracionApi.GuardarEstudioSocioeconomico(estudio);
  $store.agregarToast({ tipo: r.data.estado, body: r.data.mensaje! });
}

function sumarDatos(value: IngresoGeneral[]): number {
  if (!value) return 0;
  let suma = 0;
  suma = value.reduce((prev: number, curr: any) => prev + curr.monto, 0);
  return Number(suma);
}

function sumarTodosConceptos() {
  estudioSocioeconomico.dependientesEconomicos.value = Number.parseInt(
    (estudioSocioeconomico.dependientesEconomicos.value ?? "0").toString()
  );
  estudioSocioeconomico.antiguedadLaboralConyuge.value = Number.parseInt(
    (estudioSocioeconomico.antiguedadLaboralConyuge.value ?? "0").toString()
  );
  estudioSocioeconomico.rentas.value = sumarDatos(esComprobacionIngresos.arrendamiento.value);
  estudioSocioeconomico.otrosIngresos.value = sumarDatos(esComprobacionIngresos.otro.value);
  estudioSocioeconomico.comisiones.value = sumarDatos(esComprobacionIngresos.concesion.value);
  estudioSocioeconomico.sueldoNominalNeto.value =
    (esComprobacionIngresos.sueldoNetoTotal?.value ?? 0) +
    sumarDatos(esComprobacionIngresos.pension?.value) +
    sumarDatos(esComprobacionIngresos.negocioPropio?.value) +
    sumarDatos(esComprobacionIngresos.actividadEmpresarial?.value) +
    estudioSocioeconomico.rentas.value +
    estudioSocioeconomico.otrosIngresos.value +
    estudioSocioeconomico.comisiones.value;
}

function verficarConceptos(
  arrayAdd: (IngresoNomina | IngresoGeneral)[],
  valueCurrent: number,
  cantidadCurrent: number,
  aIngresos: string[]
): void {
  if (estudioSocioeconomico.ocupacionActualArray.value.includes(valueCurrent)) {
    let arregloLimpio = [];
    for (let i = 0; i < cantidadCurrent; i++) {
      arregloLimpio.push(arrayAdd[i]);
    }
    aIngresos.push(JSON.stringify(arregloLimpio));
  } else {
    arrayAdd = [];
    aIngresos.push(JSON.stringify(arrayAdd));
  }
}
//#endregion

onMounted(async () => {
  store.titulo = "Estudio Socioeconómico";
  catalogos.profesiones.value = (await api.Cliente.Cliente.EscrituracionApi.ObtenerProfesiones()).data;
  catalogos.paises.value = (await api.Cliente.Cliente.EscrituracionApi.ObtenerPaises()).data;

  let cliente = $router.currentRoute.value.query["estudioId"] as string;
  if (cliente == undefined) cliente = "0";

  let d = await api.Cliente.Cliente.EscrituracionApi.ObtenerDatosCliente(cliente, false);
  formularioValidado.value = true;
  mapearES(d.data, estudioSocioeconomico);
  estudioSocioeconomico.ocupacionActualArray.value = [];
  //- SEGUROS S4Q4 2021
  estudioSocioeconomico.contratosInvolucrados.value = d.data.contratosInvolucrados;
  estudioSocioeconomico.procedimientoAdjudicacion.value = d.data.procedimientoAdjudicacion;
  estudioSocioeconomico.cuotas.value = d.data.cuotas;
  estudioSocioeconomico.comprobarIngresos.value = d.data.comprobarIngresos;
  estudioSocioeconomico.direccionREfFamiliar.value = d.data.direccionREfFamiliar;
  estudioSocioeconomico.direccionREfLaboral.value = d.data.direccionREfLaboral;
  estudioSocioeconomico.direccionREfContacto.value = d.data.direccionREfContacto;
  estudioSocioeconomico.clienteRadicaExtranjero.value = d.data.clienteRadicaExtranjero;
  estudioSocioeconomico.clienteDobleNacionalidad.value = d.data.clienteDobleNacionalidad;
  estudioSocioeconomico.ingresosExtranjeros.value = d.data.ingresosExtranjeros;
  estudioSocioeconomico.inmuebleDescripcion.value = d.data.inmuebleDescripcion;
  estudioSocioeconomico.inmuebleValorAprox.value = d.data.inmuebleValorAprox;
  estudioSocioeconomico.inmuebleGravado.value = d.data.inmuebleGravado;
  estudioSocioeconomico.automovilDescripcion.value = d.data.automovilDescripcion;
  estudioSocioeconomico.automovilValorAprox.value = d.data.automovilValorAprox;
  estudioSocioeconomico.automovilGravado.value = d.data.automovilGravado;
  estudioSocioeconomico.estudioSocioeconomicoId.value = d.data.estudioSocioeconomicoId ?? "";
  //- Cambios Conyuge
  if (d.data.ocupacionActual != null && d.data.ocupacionActual != "")
    estudioSocioeconomico.ocupacionActualArray.value = d.data.ocupacionActual.split(",").map(a => Number(a));
  if (d.data.ingresos) {
    try {
      let ing = JSON.parse(d.data.ingresos) as string[];
      let ingresosGuardados = ing.map(elem => JSON.parse(elem) as (IngresoNomina | IngresoGeneral)[]);
      esComprobacionIngresos.cantidadNomina.value = (ingresosGuardados[0] as IngresoNomina[]).length;
      esComprobacionIngresos.nomina.value = ingresosGuardados[0] as IngresoNomina[];
      esComprobacionIngresos.cantidadNegocioPropio.value = ingresosGuardados[1].length;
      esComprobacionIngresos.negocioPropio.value = ingresosGuardados[1] as IngresoGeneral[];
      esComprobacionIngresos.cantidadPension.value = ingresosGuardados[2].length;
      esComprobacionIngresos.pension.value = ingresosGuardados[2] as IngresoGeneral[];
      esComprobacionIngresos.cantidadActividadEmpresarial.value = ingresosGuardados[3].length;
      esComprobacionIngresos.actividadEmpresarial.value = ingresosGuardados[3] as IngresoGeneral[];
      esComprobacionIngresos.cantidadArrendamiento.value = ingresosGuardados[4].length;
      esComprobacionIngresos.arrendamiento.value = ingresosGuardados[4] as IngresoGeneral[];
      esComprobacionIngresos.cantidadConcesion.value = ingresosGuardados[5].length;
      esComprobacionIngresos.concesion.value = ingresosGuardados[5] as IngresoGeneral[];
      esComprobacionIngresos.cantidadOtro.value = ingresosGuardados[6].length;
      esComprobacionIngresos.otro.value = ingresosGuardados[6] as IngresoGeneral[];
      nextTick(
        () =>
          (estudioSocioeconomico.sueldoNominalNeto.value = Number.parseFloat(
            (d.data.sueldoNominalNeto ?? "0").toString()
          ))
      );
    } catch {
      /* Prevenir detener funcionamiento por formato de guardado de ingresos antiguo */
    }
  }
  if (d.data.medioPreferidoContacto != null)
    medioPreferidoContactoJSON.value = d.data.medioPreferidoContacto.split(",").map(a => Number(a));
  else medioPreferidoContactoJSON.value = ["2"].map(a => Number(a));
  esCargado = true;
});
</script>

<script lang="ts">
import { Cliente, Core } from "@/servicios/api";
import { provide, computed, watch, ref, toRefs, onMounted, reactive, toRaw, nextTick, defineComponent } from "vue";
import _ from "lodash";
import { TipoBootstrap } from "@/interfaces";
import api from "@/servicios/apiCliente";
import Swal from "sweetalert2";

export default defineComponent({});

export interface EstudioSocioeconomico extends Cliente.Cliente.EstudioSocioeconomico {
  estudioSocioeconomicoId: string;
  ocupacionActualArray: number[];
}
</script>

<style src="sweetalert2/dist/sweetalert2.min.css"></style>

<style lang="scss">
.accordion-item {
  background-color: #ffffff;
}

body {
  min-height: 100vh;
  background-color: #f5f9fc !important;

  .documentos-requeridos {
    .form-check-input:disabled ~ label {
      color: #6f6f6f;
      opacity: 1;
    }
  }
}
</style>
