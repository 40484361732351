<template lang="pug">
form.w-100(:class="{ 'was-validated': formularioValidado }" ref="formulario")
  .d-flex.p-3.flex-column.w-100.justify-content-between
    .div
      .row.w-100
        HeaderForm
        .col-4.input-container
          label.form-label.text-secondary Pago mensual a préstamos <br>
          .input-group.mb-3
            span.input-group-text $
            HirAutonumeric.form-control(
              required
              type="text"
              @keypress="onlyNumbers"
              v-model="store.datos_figura[store.getFigura].pagoMensualPrestamo"
              :configuracion="{ emptyInputBehavior: 'min', maximumValue: '15000000', minimumValue: '0', currencySymbol: '' }"
              :disabled="store.datos_figura[store.getFigura].estudioBloqueado"
            )
          small.text-secondary (hipotecario, automóvil, personal, laboral etc.)
        .col-4.input-container
          label.form-label.text-secondary Pago mensual a tarjetas de crédito
          .input-group.mb-3
            span.input-group-text $
            HirAutonumeric.form-control(
              required
              type="text"
              @keypress="onlyNumbers"
              v-model="store.datos_figura[store.getFigura].pagoTarjetaCredito"
              :configuracion="{ emptyInputBehavior: 'min', maximumValue: '15000000', minimumValue: '0', currencySymbol: '' }"
              :disabled="store.datos_figura[store.getFigura].estudioBloqueado"
            ) 
        .col-4.input-container
          label.form-label.text-secondary Pago mensual de renta
          .input-group.mb-3
            span.input-group-text $
            HirAutonumeric.form-control(
              required
              type="text"
              @keypress="onlyNumbers"
              v-model="store.datos_figura[store.getFigura].pagoRenta"
              :configuracion="{ emptyInputBehavior: 'min', maximumValue: '15000000', minimumValue: '0', currencySymbol: '' }"
              :disabled="store.datos_figura[store.getFigura].estudioBloqueado"
            ) 

      .row.w-100.mt-3
        .col-4.input-container
          label.form-label.text-secondary Gasto mensual colegiaturas
          .input-group.mb-3
            span.input-group-text $
            HirAutonumeric.form-control(
              required
              type="text"
              v-model="store.datos_figura[store.getFigura].pagoColegiaturas"
              :configuracion="{ emptyInputBehavior: 'min', maximumValue: '15000000', minimumValue: '0', currencySymbol: '' }"
              :disabled="store.datos_figura[store.getFigura].estudioBloqueado"
            ) 
        .col-4.input-container
          label.form-label.text-secondary Gasto familiar mensual
          .input-group.mb-3
            span.input-group-text $
            HirAutonumeric.form-control(
              required
              type="text"
              v-model="store.datos_figura[store.getFigura].pagoGastoFamiliar"
              :configuracion="{ emptyInputBehavior: 'min', maximumValue: '15000000', minimumValue: '0', currencySymbol: '' }"
              :disabled="store.datos_figura[store.getFigura].estudioBloqueado"
            ) 
        .col-4.input-container
          label.form-label.text-secondary Otros gastos
          .input-group.mb-3
            span.input-group-text $
            HirAutonumeric.form-control(
              required
              type="text"
              v-model="store.datos_figura[store.getFigura].otrosEgresos"
              :configuracion="{ emptyInputBehavior: '0', maximumValue: '15000000', minimumValue: '0', currencySymbol: '' }"
              :disabled="store.datos_figura[store.getFigura].estudioBloqueado"
            ) 
      .row.w-100.mt-3
        .col-4.input-container(v-if="store.getFigura == 0")
          label.form-label.text-secondary Mensualidad HIR Casa
          .input-group.mb-3
            span.input-group-text $
            HirAutonumeric.form-control(
              required
              type="text"
              v-model="store.datos_figura[store.getFigura].mensualidadHirCasa"
              :configuracion="{ emptyInputBehavior: 'min', maximumValue: '15000000', minimumValue: '0', currencySymbol: '' }"
              :disabled="store.datos_figura[store.getFigura].estudioBloqueado"
            ) 
        .col-4.input-container
          label.form-label.text-secondary Total gastos
          .input-group.mb-3
            span.input-group-text $
            HirAutonumeric.form-control(
              disabled
              required
              type="text"
              v-model="totalGastos"
              :configuracion="{ emptyInputBehavior: 'min', maximumValue: '15000000', minimumValue: '0', currencySymbol: '' }"
            ) 

    .d-flex.justify-content-between.pt-5
      button.btn.btn-outline-primary(style="width: 100px" @click="anterior" :disabled="store.datos_figura[store.getFigura].estudioBloqueado")
        | Anterior
      button.btn.btn-primary.text-white(style="width: 100px" @click="siguiente" :disabled="store.datos_figura[store.getFigura].estudioBloqueado")
        | Siguiente
</template>

<script lang="ts">
import simboloPesos from "../helpers/simboloPesos";
import { ref, defineAsyncComponent, computed, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStoreTitular } from "../storeTitular";
import { useStore } from "@/store";
import { TipoBootstrap } from "@/interfaces";
import onlyNumbers from "../helpers/onlyNumbers";

export default {
  components: {
    HeaderForm: defineAsyncComponent(() => import("./HeaderForm.vue")),
    HirAutonumeric: defineAsyncComponent(() => import("@/components/HirAutonumeric.vue"))
  },
  setup() {
    //Variables
    const router = useRouter(); //Navegar hacia un lugar

    const route = useRoute(); //Navegar hacia un lugar

    const formulario = ref("");
    const formularioValidado = ref(false);
    const store = useStoreTitular();
    const $store = useStore();
    const totalGastos = computed(() => {
      const valores = [
        store.datos_figura[store.getFigura].pagoMensualPrestamo || 0,
        store.datos_figura[store.getFigura].pagoTarjetaCredito || 0,
        store.datos_figura[store.getFigura].pagoRenta || 0,
        store.datos_figura[store.getFigura].pagoColegiaturas || 0,
        store.datos_figura[store.getFigura].pagoGastoFamiliar || 0,
        store.datos_figura[store.getFigura].otrosEgresos || 0,
        store.datos_figura[store.getFigura].mensualidadHirCasa || 0
      ];
      return valores.reduce((a, b) => a + b, 0);
    });

    //metodos
    const validar = () => {
      let valido = formulario.value.checkValidity();
      formularioValidado.value = true;
      if (valido) {
        store.$state.formulario_gastos.completado = true;
      } else {
        store.$state.formulario_gastos.completado = false;
        return;
      }
    };
    const siguiente = () => {
      //Validar ruta para determinar el camino correcto segun la figura
      validar();

      if (store.$state.formulario_gastos) {
        store.guardarFigura(store.$state.datos_figura[store.figuraActual]);
        if (store.tieneSesion) router.push({ name: "patrimonio" });
        else router.push({ name: "patrimonio", query: { operacionId: store.operacionTitulacionId } });
        // store.titularCompletado();
      } else {
        $store.agregarToast({
          tipo: TipoBootstrap.DANGER,
          body: "Llenar todos los campos correctamente.",
          mensaje: "Texto"
        });
        return;
      }
    };
    watch(
      () => route,
      () => {
        formularioValidado.value = false;
        store.asignarValoresGastos();
      },
      { deep: true }
    );
    return {
      store,
      simboloPesos,
      siguiente,
      validar,
      totalGastos,
      formularioValidado,
      onlyNumbers,
      formulario,
      anterior: () => {
        if (store.tieneSesion) router.push({ name: "ingresos" });
        else router.push({ name: "ingresos", query: { operacionId: store.operacionTitulacionId } });
      }
    };
  }
};
</script>

<style scoped>
small {
  font-size: 10px;
}
.form-label {
  font-size: 14px;
  font-weight: 600;
}
.input-group input {
  z-index: 0 !important;
}
</style>
