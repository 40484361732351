/**
 * Función que regresa un nuevo arreglo los distintos elementos de un arreglo con base en un comparador opcional
 * @param arr Arreglo
 * @param comparer Función de comparación, debe de regresar true en caso de que los elemento sean iguales
 */
export function arrayDistinct<T>(arr: T[], comparer: ((a: T, b: T) => boolean) | null | undefined): T[] {
  if (comparer == null || comparer == undefined) comparer = (a: T, b: T) => a == b;
  const arrDiff: T[] = [];
  let encontrado = false;
  for (const i of arr) {
    encontrado = false;
    for (const j of arrDiff) {
      encontrado = comparer(i, j);
      if (encontrado) break;
    }
    if (!encontrado) arrDiff.push(i);
  }
  return arrDiff;
}
export function descargarBlob(archivo: Blob, nombre: string): void {
  const aHtml = document.createElement("a");
  aHtml.download = nombre;
  document.body.appendChild(aHtml);
  aHtml.style.display = "none";
  const url = window.URL.createObjectURL(archivo);
  aHtml.href = url;
  aHtml.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(aHtml);
}

export function ifNaN(valor: any, valorIfNaN: any = 0) {
  if (isNaN(valor)) return valorIfNaN;
  return valor;
}

export function generarId() {
  return "a" + Math.random().toString(36).substring(7) + Math.random().toString(36).substring(7);
}

export function limpiarString(texto: string) {
  return (texto ?? "")
    .toUpperCase()
    .trim()
    .replace(/ {2,}/g, " ")
    .replace(/\n{2,}/g, "\n")
    .replace(/(^\n)|(\n$)/, "");
}

export const patronAlfaNum = "[a-zA-Z0-9áéíóúÜüÁÉÍÓÚñÑ]+";
export const patronAlfaNumEspacio = "[a-zA-Z0-9áéíóúÜüÁÉÍÓÚñÑ ]+";
