<template lang="pug">
form(:class="{ 'was-validated': formularioValidado }" ref="formulario")
  .d-flex.flex-column.p-3.w-100.justify-content-between.g-0
    HeaderForm
    .div
      .row.w-100.mt-3
        .col-4
          label.form-label.text-secondary ¿Su vendedor es persona física o moral?
          //- select.form-select.form-select(aria-label=".form-select-sm example" v-model="tipo_vendedor")
          //-   option(v-for="opcion in opciones" :key="opcion.value" v-bind:value="opcion.value") 
          //-     | {{ opcion.texto }}
          .container.g-1
            .form-check.form-check-inline
              input#fisica.form-check-input(
                :disabled="store.datos_figura[store.getFigura].estudioBloqueado"
                name="tipo"
                type="radio"
                v-bind:value="true"
                v-model="store.datos_figura[store.getFigura].vendedorEsPersonaFisica"
                required
                @change="asignarVendedor"
              )
              label.form-check-label(for="fisica") Física
            .form-check.form-check-inline
              input#moral.form-check-input(
                :disabled="store.datos_figura[store.getFigura].estudioBloqueado"
                name="tipo"
                type="radio"
                v-bind:value="false"
                v-model="store.datos_figura[store.getFigura].vendedorEsPersonaFisica"
                required
                @change="asignarVendedor"
              )
              label.form-check-label(for="moral") Moral
        template(v-if="store.datos_figura[store.getFigura].vendedorEsPersonaFisica")
          .row.py-3
            .col-4
              label.form-label.text-secondary Nombre del vendedor(es)
              input.form-control(
                :disabled="store.datos_figura[store.getFigura].estudioBloqueado"
                required
                type="text"
                v-model="store.datos_figura[store.getFigura].nombreVendedor"
                @keypress="onlyAlfa"
              )
            .col-4
              label.form-label.text-secondary Apellido paterno
              input.form-control(
                :disabled="store.datos_figura[store.getFigura].estudioBloqueado"
                type="text"
                @keypress="onlyAlfa"
                v-model="store.datos_figura[store.getFigura].apellidoPaternoVendedor"
              )
            .col-4
              label.form-label.text-secondary Apellido materno
              input.form-control(
                :disabled="store.datos_figura[store.getFigura].estudioBloqueado"
                type="text"
                @keypress="onlyAlfa"
                v-model="store.datos_figura[store.getFigura].apellidoMaternoVendedor"
              )
          .row
            .col-4
              label.form-label.text-secondary Número de teléfono particular
              input.form-control(
                :disabled="store.datos_figura[store.getFigura].estudioBloqueado"
                required
                type="text"
                v-model="store.datos_figura[store.getFigura].telefonoVendedor"
                @keypress="onlyNumbers"
                maxlength="10"
                minlength="10"
              )
            .col-4
              label.form-label.text-secondary Correo electrónico personal
              input.form-control(
                :disabled="store.datos_figura[store.getFigura].estudioBloqueado"
                required
                type="email"
                v-model="store.datos_figura[store.getFigura].correoVendedor"
                pattern="[Aa-Zz0-9._%+-]+@[Aa-zZ0-9.-]+\.[Aa-zZ]{2,4}$"
              )
          .col-4.mt-3
            label.form-label.text-secondary Estado civil
            select.form-select.form-select(
              :disabled="store.datos_figura[store.getFigura].estudioBloqueado"
              aria-label=".form-select-sm example"
              v-model="store.datos_figura[store.getFigura].estadoCivilVendedor"
              required
              @change.prevent="validarEstadoCivilRegimenMatrimonial"
            )
              option(v-for="estado in catalogoEstadoCivil" :key="estado.id" v-bind:value="estado.id")
                | {{ estado.texto }}
          .col-4.mt-3(v-if="esCasado")
            label.form-label.text-secondary Régimen matrimonial
            select.form-select.form-select(
              :disabled="store.datos_figura[store.getFigura].estudioBloqueado"
              aria-label=".form-select-sm example"
              v-model="store.datos_figura[store.getFigura].regimenMatrimonialVendedor"
              required
              @change.prevent="validarEstadoCivilRegimenMatrimonial"
            )
              option(v-for="regimen in catalogoRegimenMatrimonial" :key="regimen.id" v-bind:value="regimen.id")
                | {{ regimen.texto }}
          .row(v-if="esCasado")
            .col-4.mt-3
              label.form-label.text-secondary Nombre del cónyuge
              input.form-control(
                :disabled="store.datos_figura[store.getFigura].estudioBloqueado"
                required
                type="text"
                @keypress="onlyAlfa"
                v-model="store.datos_figura[store.getFigura].nombreConyugeVendedor"
              )
            .col-4.mt-3
              label.form-label.text-secondary Apellido paterno
              input.form-control(
                :disabled="store.datos_figura[store.getFigura].estudioBloqueado"
                type="text"
                @keypress="onlyAlfa"
                v-model="store.datos_figura[store.getFigura].apellidoPaternoConyugeVendedor"
              )
            .col-4.mt-3
              label.form-label.text-secondary Apellido materno
              input.form-control(
                :disabled="store.datos_figura[store.getFigura].estudioBloqueado"
                type="text"
                @keypress="onlyAlfa"
                v-model="store.datos_figura[store.getFigura].apellidoMaternoConyugeVendedor"
              )
        template(v-if="!store.datos_figura[store.getFigura].vendedorEsPersonaFisica")
          .col-4
            label.form-label.text-secondary Nombre del desarrollador
            input.form-control(
              :disabled="store.datos_figura[store.getFigura].estudioBloqueado"
              required
              type="text"
              v-model="store.datos_figura[store.getFigura].nombreDesarrollo"
            )
          .col-4
            label.form-label.text-secondary Nombre del contacto del desarrollador
            input.form-control(
              :disabled="store.datos_figura[store.getFigura].estudioBloqueado"
              required
              type="text"
              @keypress="onlyAlfa"
              maxlength="10"
              v-model="store.datos_figura[store.getFigura].nombreContactoDesarrollo"
            )
          .col-4.mt-3
            label.form-label.text-secondary Teléfono
            input.form-control(
              :disabled="store.datos_figura[store.getFigura].estudioBloqueado"
              required
              type="text"
              @keypress="onlyNumbers"
              maxlength="10"
              minlength="10"
              v-model="store.datos_figura[store.getFigura].telefonoDesarrollo"
            )
          .col-4.mt-3
            label.form-label.text-secondary Correo
            input.form-control(
              :disabled="store.datos_figura[store.getFigura].estudioBloqueado"
              required
              type="email"
              v-model="store.datos_figura[store.getFigura].correoDesarrollo"
            )
    .d-flex.justify-content-between.mt-5
      button.btn.btn-outline-primary(style="width: 100px" @click="anterior")
        | Anterior
      button.btn.btn-primary.text-white(style="width: 100px" @click="siguiente")
        | Siguiente
</template>

<script>
import { defineAsyncComponent, onMounted, ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStoreTitular } from "../storeTitular";
import onlyAlfa from "../helpers/onlyAlfa";
import onlyNumbers from "../helpers/onlyNumbers";
import { storeToRefs } from "pinia";
import { useStore } from "@/store";
import { TipoBootstrap } from "@/interfaces";
import { Core, Cliente } from "@/servicios/api";
import api from "@/servicios/apiCliente";

export default {
  components: {
    UsoDeFinanciamiento: defineAsyncComponent(() => import("./UsoDeFinanciamiento/UsoDeFinanciamiento.vue")),
    HeaderForm: defineAsyncComponent(() => import("./HeaderForm.vue"))
  },
  props: ["figura"],
  setup() {
    //variables
    const router = useRouter(); //Navegar hacia un lugar
    const vendedor_fisica = ref(true);
    const tipo_vendedor = ref("fisica");
    const formulario = ref("");
    const formularioValidado = ref(false);
    const store = useStoreTitular();
    const $store = useStore();
    const estadoCivil = Core.Models.EstadoCivil;
    const regimenMatrimonial = Core.Models.RegimenMatrimonial;
    const catalogoEstadoCivil = ref([{}]);
    const catalogoRegimenMatrimonial = ref([{}]);
    const route = useRoute(); //Navegar hacia un lugar
    let esCasado = ref(false);
    let esCasadoEnSociedadConyugal = ref(false);

    const opciones = ref([
      {
        texto: "Física",
        value: "fisica"
      },
      {
        texto: "Moral",
        value: "moral"
      }
    ]);

    /** Variables de store  */
    const { datos_figura } = storeToRefs(store);

    //Metodos
    const validarEstadoCivilRegimenMatrimonial = () => {
      if (store.datos_figura[store.getFigura].estadoCivilVendedor == "CASADO") {
        esCasado.value = true;
      } else {
        esCasado.value = false;
        store.datos_figura[store.getFigura].nombreConyugeVendedor = "";
        store.datos_figura[store.getFigura].apellidoPaternoConyugeVendedor = "";
        store.datos_figura[store.getFigura].apellidoMaternoConyugeVendedor = "";
      }
    };

    /** Métodos */
    onMounted(() => {
      validarEstadoCivilRegimenMatrimonial();
    });
    watch(route, () => {
      if (route.params.figura == undefined) return;
      validarEstadoCivilRegimenMatrimonial();
    });
    const validar = () => {
      let valido = formulario.value.checkValidity();
      formularioValidado.value = true;
      if (valido) {
        store.$state.formulario_vendedor.completado = true;
      } else {
        store.$state.formulario_vendedor.completado = false;
        return;
      }
    };

    const CargarCatalogoEstadoCivil = () => {
      catalogoEstadoCivil.value = [
        { id: estadoCivil.SOLTERO, texto: "Soltero" },
        { id: estadoCivil.CASADO, texto: "Casado" },
        { id: estadoCivil.VIUDO, texto: "Viudo" },
        { id: estadoCivil.DIVORCIADO, texto: "Divorciado" },
        { id: estadoCivil.UNIÓN_LIBRE, texto: "Unión libre " }
      ];
    };
    const CargarCatalogoRegimenMatrimonial = () => {
      catalogoRegimenMatrimonial.value = [
        { id: regimenMatrimonial.SOCIEDAD_CONYUGAL, texto: "Sociedad Conyugal" },
        { id: regimenMatrimonial.SEPARACIÓN_DE_BIENES, texto: "Separación De Bienes" }
      ];
    };

    CargarCatalogoEstadoCivil();
    CargarCatalogoRegimenMatrimonial();
    // single ref
    // watch(datos_figura[store.getFigura].value, datos => {
    //   if (datos[store.getDataFigura].vendedorEsPersonaFisica == true) vendedor_fisica.value = true;
    //   else vendedor_fisica.value = false;
    // });

    const asignarVendedor = () => {
      if (store.$state.datos_figura[store.getFigura].vendedorEsPersonaFisica) {
        store.datos_figura[store.getFigura].nombreDesarrollo = "";
        store.datos_figura[store.getFigura].nombreContactoDesarrollo = "";
        store.datos_figura[store.getFigura].telefonoDesarrollo = "";
        store.datos_figura[store.getFigura].correoDesarrollo = "";
      } else {
        store.datos_figura[store.getFigura].nombreVendedor = "";
        store.datos_figura[store.getFigura].apellidoPaternoVendedor = "";
        store.datos_figura[store.getFigura].apellidoMaternoVendedor = "";
        store.datos_figura[store.getFigura].telefonoVendedor = "";
        store.datos_figura[store.getFigura].correoVendedor = "";
        store.datos_figura[store.getFigura].estadoCivilVendedor = null;
        store.datos_figura[store.getFigura].regimenMatrimonialVendedor = null;
        store.datos_figura[store.getFigura].nombreConyugeVendedor = "";
        store.datos_figura[store.getFigura].apellidoPaternoConyugeVendedor = "";
        store.datos_figura[store.getFigura].apellidoMaternoConyugeVendedor = "";
      }
    };
    const siguiente = () => {
      validar();

      if (store.$state.formulario_vendedor.completado) {
        store.guardarFigura(store.$state.datos_figura[store.figuraActual]);
        if (store.tieneSesion) router.push({ name: "observaciones" });
        else router.push({ name: "observaciones", query: { operacionId: store.operacionTitulacionId } });
      } else {
        $store.agregarToast({
          tipo: TipoBootstrap.DANGER,
          body: "Llenar todos los campos correctamente.",
          mensaje: "Texto"
        });
      }
    };
    watch(
      () => route,
      () => {
        formularioValidado.value = false;
      },
      { deep: true }
    );
    return {
      tipo_vendedor,
      onlyNumbers,
      validar,
      onlyAlfa,
      formulario,
      vendedor_fisica,
      formularioValidado,
      store,
      opciones,
      siguiente,
      validarEstadoCivilRegimenMatrimonial,
      esCasado,
      esCasadoEnSociedadConyugal,
      anterior: () => {
        if (store.tieneSesion) router.push({ name: "uso-financiamiento" });
        else router.push({ name: "uso-financiamiento", query: { operacionId: store.operacionTitulacionId } });
      },
      catalogoEstadoCivil,
      catalogoRegimenMatrimonial,
      asignarVendedor
    };
  }
};
</script>

<style scoped>
.form-label {
  font-size: 14px;
  font-weight: 600;
}
</style>
