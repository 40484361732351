import { defineStore, createPinia, setActivePinia } from "pinia";

const pinia = createPinia();
setActivePinia(pinia);

const useAxiosStore = defineStore("axiosStore", {
  state: () => ({
    detectarCambiosRequestResponse: false,
    contadorRequestResponse: 0
  })
});

export default useAxiosStore;

