<template lang="pug">
.d-flex.flex-column.p-3.w-100.justify-content-start.g-0
  HeaderForm
  PatrimonioGeneral(ref="formPG")
  .d-flex.justify-content-between.mt-5
    button.btn.btn-outline-primary(style="width: 100px" @click="anterior" :disabled="store.datos_figura[store.getFigura].estudioBloqueado")
      | Anterior
    button.btn.btn-primary.text-white(style="width: 100px" @click="siguiente" :disabled="store.datos_figura[store.getFigura].estudioBloqueado")
      | Siguiente
</template>
<script lang="ts">
import { defineAsyncComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { useStoreTitular } from "../storeTitular";
import { useStore } from "@/store";
import { TipoBootstrap } from "@/interfaces";

export default {
  components: {
    PatrimonioGeneral: defineAsyncComponent(() => import("./Patrimonio/PatrimonioGeneral.vue")),
    HeaderForm: defineAsyncComponent(() => import("./HeaderForm.vue"))
  },
  props: ["figura"],
  setup(props, ctx) {
    //variables
    const router = useRouter(); //Navegar hacia un lugar
    const formPG = ref();
    const store = useStoreTitular();
    const $store = useStore();

    //metodos
    const siguiente = () => {
      formPG.value.validar();

      //Validar ruta para determinar el camino correcto segun la figura
      if (store.$state.formulario_patrimonio.completado) {
        if (props.figura != "titular") {
          store.guardarFigura(store.$state.datos_figura[store.figuraActual]);
          if (store.tieneSesion) router.push({ name: "observaciones" });
          else router.push({ name: "observaciones", query: { operacionId: store.operacionTitulacionId } });
        } else {
          store.guardarFigura(store.$state.datos_figura[store.figuraActual]);
          if (store.tieneSesion) router.push({ name: "uso-financiamiento" });
          else router.push({ name: "uso-financiamiento", query: { operacionId: store.operacionTitulacionId } });
        }
      } else {
        $store.agregarToast({
          tipo: TipoBootstrap.DANGER,
          body: "Llenar todos los campos correctamente.",
          mensaje: "Texto"
        });
      }
    };
    const anterior = () => {
      if (store.tieneSesion) router.push({ name: "gastos" });
      else router.push({ name: "gastos", query: { operacionId: store.operacionTitulacionId } });
    };
    return {
      siguiente,
      anterior,
      formPG,
      store
    };
  }
};
</script>

<style scoped>
.form-label {
  font-size: 14px;
  font-weight: 600;
}
</style>
