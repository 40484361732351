<template lang="pug">
.container.ff-poppins
  .row.mt-2
    .col-md-12: label.form-label.fw-bold.h1(style="color: #2f2d74") {{ tipoSeguro }}
    .col-md-4: label.form-label.fw-bold.h3(style="color: #2f2d74") Reportar un siniestro
    .col-md-4: select.form-select(v-model="siniestro")
      option(disabled) Seleccionar
      option(:value="item" v-for="(item, i) in listaSiniestros") {{ item.item }}
    .col-md-12.mt-4: p(style="color: #2f2d74") Para poder registrar la solicitud toma en cuenta las siguientes recomendaciones:
    .col-md-12: ul 
      li(style="color: #2f2d74") Es importante estar al corriente con los pago(s).
      li(style="color: #2f2d74") Contar con documentación actualizada.
      li(style="color: #2f2d74") Escaneos a color y en formato PDF.
    .col-md-12.mt-4(v-if="mostrarDesempeno"): p(style="color: #2f2d74") El seguro de desempleo aplica en caso de:
    .col-md-12(v-if="mostrarDesempeno"): ul 
      li(style="color: #2f2d74") Ser trabajador asalariado
      li(style="color: #2f2d74") Sea un despido injustificado
      li(style="color: #2f2d74") Su mensualidad del mes en que se perdió el empleo fuera pagada en tiempo y forma como estipula su contrato.
    .col-md-12: Seguro-Vida(v-if="mostrarVida" :Siniestro="siniestro.documentos")
    .col-md-12: Seguro-Invalidez(v-if="mostrarInvalidez" :Siniestro="siniestro.documentos")
    .col-md-12: Seguro-Desempleo(v-if="mostrarDesempeno" :Siniestro="siniestro.documentos")
    .col-md-12: Seguro-Danios(v-if="mostrarDanios" :Danio="siniestro")
</template>
<script lang="ts" setup>
import { ref, reactive, toRefs, toRaw, onMounted, computed, watch } from "vue";
import SeguroVida from "./Siniestros/SeguroVida.vue";
import SeguroInvalidez from "./Siniestros/SeguroInvalidez.vue";
import SeguroDesempleo from "./Siniestros/SeguroDesempleo.vue";
import SeguroDanios from "./Siniestros/SeguroDanios.vue";
import { Cliente, Core } from "@/servicios/api";
import * as utilidades from "@/utilidades";
import api from "@/servicios/apiCliente";
import { useRouter } from "vue-router";
//#endregion Data
const $router = useRouter();
const tipoSeguro = ref("Seguro de Vida");
const listaSiniestros = ref([] as Cliente.Cliente.Siniestro.ListaSiniestroconDocumentos[]);
const siniestro = ref({} as Cliente.Cliente.Siniestro.ListaSiniestroconDocumentos);
const mostrarVida = ref(false);
const mostrarInvalidez = ref(false);
const mostrarDesempeno = ref(false);
const mostrarDanios = ref(false);
//#endregion
//#endregion Watch
watch(siniestro, async (item: Cliente.Cliente.Siniestro.ListaSiniestroconDocumentos) => {
  mostrarInvalidez.value = item.tipo == Core.Enums.TipoSinestro.INVALIDEZ_TOTAL_PERMANENTE ? true : false;
  mostrarVida.value = item.tipo == Core.Enums.TipoSinestro.FALLECIMIENTO ? true : false;
  mostrarDesempeno.value = item.tipo == Core.Enums.TipoSinestro.DESEMPLEO_1A_MENSUALIDAD ? true : false;
  mostrarDanios.value = item.tipo == Core.Enums.TipoSinestro.DAÑOS ? true : false;
  tipoSeguro.value =
    item.tipo! == Core.Enums.TipoSinestro.FALLECIMIENTO
      ? "SEGURO DE VIDA"
      : item.tipo == Core.Enums.TipoSinestro.INVALIDEZ_TOTAL_PERMANENTE
      ? "SEGURO DE INVALIDEZ"
      : item.tipo == Core.Enums.TipoSinestro.DAÑOS
      ? "SEGURO DE DAÑOS"
      : "SEGURO DE DESEMPLEO";
  //}
});
//#endregion
//#endregion Computed
//#endregion

onMounted(async () => {
  let siniestroId = ($router.currentRoute.value.query["siniestroid"] as string) || "";
  let resp = (await api.Cliente.Cliente.SiniestroApi.ObtenerSiniestrosCliente(siniestroId!)).data!;
  listaSiniestros.value = resp;
  listaSiniestros.value ??= [];
  if (listaSiniestros.value.length > 0) siniestro.value = listaSiniestros.value[0];
});
</script>
