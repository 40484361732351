<template lang="pug">
.d-flex.flex-column.p-3.w-100.g-0
  HeaderForm
  Personales(ref="formP")
  DomicilioParticular(ref="formDP")
  DomicilioTrabajo(ref="formDT")
  .d-flex.justify-content-end
    button.btn.btn-primary.text-white(style="width: 100px" @click="siguiente" :disabled="store.datos_figura[store.getFigura].estudioBloqueado")
      | Siguiente
</template>
<script lang="ts">
import { defineAsyncComponent, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useStoreTitular } from "../storeTitular";
import { useStore } from "@/store";
import { TipoBootstrap } from "@/interfaces";
import { storeToRefs } from "pinia";

export default {
  components: {
    DomicilioParticular: defineAsyncComponent(() => import("../components/DatosGenerales/DomicilioParticular.vue")),
    DomicilioTrabajo: defineAsyncComponent(() => import("../components/DatosGenerales/DomicilioTrabajo.vue")),
    Personales: defineAsyncComponent(() => import("../components/DatosGenerales/Personales.vue")),
    HeaderForm: defineAsyncComponent(() => import("../components/HeaderForm.vue"))
  },
  props: ["figura"],
  setup(props) {
    const router = useRouter(); //Navegar hacia un lugar
    const store = useStoreTitular();
    const $store = useStore();
    const formP = ref();
    const formDP = ref();
    const formDT = ref();

    /**
     * Controlar que si todos son ok
     * entonces redireccionar a al siguiente seccion
     */
    const siguiente = () => {
      formP.value.validar();
      formDP.value.validar();
      formDT.value.validar();
      //Validar ruta para determinar el camino correcto segun la figura

      if (
        store.$state.formulario_datos_generales_personales &&
        store.$state.formulario_datos_generales_domicilio_particular &&
        store.$state.formulario_datos_generales_domicilio_trabajo
      ) {
        store.formulario_datos_generales.completado = true;
        store.guardarFigura(store.$state.datos_figura[store.figuraActual]);
        if (store.tieneSesion) router.push({ name: "ingresos" });
        else router.push({ name: "ingresos", query: { operacionId: store.operacionTitulacionId } });
      } else {
        store.formulario_datos_generales.completado = false;
        $store.agregarToast({
          tipo: TipoBootstrap.DANGER,
          body: "Llenar todos los campos correctamente.",
          mensaje: "Texto"
        });
      }
    };
    return {
      siguiente,
      formP,
      formDP,
      formDT,
      store
    };
  }
};
</script>

<style scoped>
.form-label {
  font-size: 14px;
  font-weight: 600;
}
</style>
