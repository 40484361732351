<template>
  <div>
    <router-view />
    <hir-toast />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
// eslint-disable-next-line
export default defineComponent({});
</script>
