<template lang="pug">
div
  mixin toast(propiedad)
    .toast.hide.fade(
      v-for=`(t, i) in toasts` + propiedad
      :key="t.id"
      :class="obtenerClaseBG(t) + ' ' + obtenerClaseText(t)"
      :ref="'toast_' + t.id.toString().substring(2)"
    ): .d-flex
      .toast-body(v-html="t.body" style="max-width: calc(100% - 26px); max-height: calc(100vh - 173px); overflow: hidden")
      a.me-2.m-auto(href="#" data-bs-dismiss="toast" title="Cerrar"): em.fas.fa-times.text-white
  .top-0.end-0.position-fixed.toast-container.p-3
    +toast("TR")
  .position-fixed.toast-container.p-3.top-50.start-50.translate-middle
    +toast("MD")
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { TipoBootstrap } from "@/interfaces";
import { EstadoRespuesta } from "@/servicios/api";
import { useStore } from "@/store";
import { mapStores } from "pinia";
import type { Toast } from "@/store";
import * as bootstrap from "bootstrap";

interface HTMLToast extends Toast {
  evento: boolean;
}

const dc = defineComponent({
  data() {
    return {
      toasts: [] as HTMLToast[]
    };
  },
  computed: {
    ...mapStores(useStore),
    preToasts(): Toast[] {
      return this.baseStore.toasts;
    },
    toastsTR(): HTMLToast[] {
      return this.toasts
        .filter(
          (t) =>
            [TipoBootstrap.PRIMARY, TipoBootstrap.SECONDARY, TipoBootstrap.SUCCESS, TipoBootstrap.INFO].includes(
              t.tipo as TipoBootstrap
            ) || [EstadoRespuesta.OK, EstadoRespuesta.INFO].includes(t.tipo as EstadoRespuesta)
        )
        .map((v) => {
          v.body = this.limpiarN(v.body);
          return v;
        });
    },
    toastsMD(): HTMLToast[] {
      return this.toasts
        .filter(
          (t) =>
            [TipoBootstrap.DANGER, TipoBootstrap.WARNING].includes(t.tipo as TipoBootstrap) ||
            [EstadoRespuesta.ERROR, EstadoRespuesta.ALERT, EstadoRespuesta.NO_AUTORIZADO].includes(
              t.tipo as EstadoRespuesta
            )
        )
        .map((v) => {
          v.body = this.limpiarN(v.body);
          return v;
        });
    }
  },
  watch: {
    preToasts: {
      deep: true,
      // Cada que se agrega un valor a los toasts:
      async handler(val: Toast[]) {
        if (!val) return;
        this.toasts = val.map((t) => t as HTMLToast);
        await this.$nextTick();
        for (let t of this.toasts.filter((a) => !a.evento)) {
          let el = (this.$refs["toast_" + t.id!.toString().substring(2)] as HTMLElement[])[0];
          let t2 = new bootstrap.Toast(el, { delay: t.delay });
          t2.show();
          el.addEventListener(
            "hidden.bs.toast",
            () => {
              this.baseStore.removerToast(t.id!);
            },
            { once: true }
          );
          t.evento = true;
        }
      }
    }
  },
  methods: {
    obtenerClaseBG(toast: Toast) {
      if (toast.tipo == TipoBootstrap.PRIMARY) return "bg-primary";
      if (toast.tipo == TipoBootstrap.SECONDARY) return "bg-secondary";
      if (toast.tipo == TipoBootstrap.SUCCESS || toast.tipo == EstadoRespuesta.OK) return "bg-success";
      if (
        toast.tipo == TipoBootstrap.WARNING ||
        toast.tipo == EstadoRespuesta.ALERT ||
        toast.tipo == EstadoRespuesta.NO_AUTORIZADO
      )
        return "bg-warning";
      if (toast.tipo == TipoBootstrap.DANGER || toast.tipo == EstadoRespuesta.ERROR) return "bg-danger";
      if (toast.tipo == TipoBootstrap.INFO || toast.tipo == EstadoRespuesta.INFO) return "bg-info";
    },
    obtenerClaseText(toast: Toast) {
      if (this.obtenerClaseBG(toast) == "bg-warning") return "";
      return "text-white";
    },
    obtenerClaseBtn(toast: Toast) {
      if (this.obtenerClaseBG(toast) == "bg-warning") return "";
      return "btn-close-white";
    },
    limpiarN(s: string) {
      return s.replace(/\r?\n/g, "<br>");
    }
  }
});

export default dc;
</script>
