import api from "@/servicios/apiCliente";
import { defineStore, storeToRefs } from "pinia";
import { TipoBootstrap } from "@/interfaces";
import { useStore } from "@/store";
import { Axios } from "axios";

declare interface tipo_ingreso {
  estudioSocioeconomicoId: String;
  tipoFuenteIngreso: String;
  actividadEconomica: null;
  nombreEmpresa: String;
  giroEmpresa: Number;
  telefono: String;
  extension: String;
  email: String;
  puesto: String;
  antiguedad: Number;
  nombreJefe: String;
  telefonoJefe: String;
  ingresoBruto: Number; //monto de la renta
  impuestos: Number;
  calle: String;
  noExterior: String;
  noInterior: String;
  codigoPostal: String;
  coloniaId: Number;
  municipioAlcaldia: String;
  esSocio: Boolean;
  descripcion: String; //explieque brevemente
  descripcion2: String; //descripcion2 en consesiones
  tipoPension: Number;
}
declare interface tipo_ingreso_apoyo {
  estudioSocioeconomicoId: String;
  numero: Number;
  esCotitular: Boolean;
  tipoFuenteIngreso: String;
  actividadEconomica: null;
  nombreEmpresa: String;
  giroEmpresa: Number;
  telefono: String;
  extension: String;
  email: String;
  puesto: String;
  antiguedad: Number;
  nombreJefe: String;
  telefonoJefe: String;
  ingresoBruto: Number; //monto de la renta
  impuestos: Number;
  calle: String;
  noExterior: String;
  noInterior: String;
  codigoPostal: String;
  coloniaId: Number;
  municipioAlcaldia: String;
  esSocio: Boolean;
  descripcion: String; //explieque brevemente
  descripcion2: String; //descripcion2 en consesiones
  tipoPension: Number;
}
declare interface figura_apoyo {
  estudioSocioeconomicoId: String;
  numero: Number | any; // preguntar
  figura: String; // Figura
  version: Number;
  estudioBloqueado: true;
  nombre: String;
  apellidoPaterno: String;
  apellidoMaterno: String;
  fechaNacimiento: String;
  rfc: String;
  curp: String;
  calle: String;
  noExterior: String;
  noInterior: String;
  codigoPostal: String;
  coloniaId: Number;
  municipioAlcaldia: String;
  telefonoParticular: String;
  telefonoCelular: String;
  emailPersonal: String;
  telefonoTrabajo: String;
  emailTrabajo: String;
  calleTrabajo: String;
  noExteriorTrabajo: String;
  noInteriorTrabajo: String;
  codigoPostalTrabajo: String;
  coloniaIdTrabajo: Number;
  municipioAlcaldiaTrabajo: String;
  numeroDependientes: Number;
  estadoCivil: String;
  regimenMatrimonial: String;
  nombreConyuge: String;
  apellidoPaternoConyuge: String;
  apellidoMaternoConyuge: String;
  clienteRadicaExtranjero: Boolean;
  clienteDobleNacionalidad: Boolean;
  ingresosExtranjeros: Boolean;
  profesion: Number;
  numeroEmpresasTrabaja: Number;
  comprobarIngresos: Boolean | any;
  pagoMensualPrestamo: Number;
  pagoTarjetaCredito: Number;
  pagoRenta: Number;
  pagoColegiaturas: Number;
  pagoGastoFamiliar: Number;
  otrosEgresos: Number;
  tipoViviendaActual: String;
  nombreArrendador: String;
  nombreArrendador2: String;
  inmuebleDescripcion: String;
  inmuebleValorAproxPatrimonio: Number;
  inmuebleGravadoPatrimonio: Boolean | null;
  automovilDescripcion: String;
  automovilValorAproxPatrimonio: Number;
  pagandoAutomovilPatrimonio: Boolean;
  observacionesGenerales: String;
}
declare interface tipos_ingresos_apoyo_objeto {
  figura: any;
  tipos_ingresos_empleado_por_nomina: [];
  tipos_ingresos_negocio_propio: [];
  tipos_ingresos_actividad_empresarial: [];
  tipos_ingresos_arrendamiento: [];
  tipos_ingresos_concesiones: [];
  tipos_ingresos_pension: [];
  tipos_ingresos_conyuge: [];
  tipos_ingresos_otros: [];
}
export const useStoreTitular = defineStore("titular", {
  state: () => ({
    /* Mi figura actual */
    figuraActual: 0,
    indexPosicionFiguraTipoTrabajo: 0,
    operacionTitulacionId: "",
    estudioSocioeconomicoId: "",
    cargandoDatosFiguraDeApoyo: true,
    tieneSesion: false,
    /**Condierar estudio estudioSocioeconomicoId  */

    /** Obtener cliente desde api en consulta  */
    datos_figura: [
      {
        estudioSocioeconomicoId: "",
        clienteId: 0,
        version: 0,
        estudioBloqueado: true,
        montoAdjudicado: 0,
        mensualida: 0,
        proyecto: null,
        tieneContratoPreventa: null,
        valorPactado: 0,
        inmuebleGravado: true,
        usoInmueble: "",
        nombreDesarrollo: "",
        nombreContactoDesarrollo: "",
        telefonoDesarrollo: "",
        correoDesarrollo: "",
        valorProyecto: 0,
        tipoConstruccion: "",
        tienePermisoConstrunccion: true,
        vendedorEsPersonaFisica: true,
        nombreVendedor: "",
        apellidoPaternoVendedor: "",
        apellidoMaternoVendedor: "",
        telefonoVendedor: "",
        correoVendedor: "",
        estadoCivilVendedor: "SOLTERO",
        regimenMatrimonialVendedor: "SOCIEDAD_CONYUGAL",
        nombreConyugeVendedor: "",
        apellidoPaternoConyugeVendedor: "",
        apellidoMaternoConyugeVendedor: "",
        nombre: "",
        apellidoPaterno: "",
        apellidoMaterno: "",
        fechaNacimiento: "",
        rfc: "",
        curp: "",
        calle: "",
        noExterior: "",
        noInterior: "",
        codigoPostal: "",
        coloniaId: 0,
        municipioAlcaldia: "",
        telefonoParticular: "",
        telefonoCelular: "",
        emailPersonal: "",
        telefonoTrabajo: "",
        emailTrabajo: "",
        calleTrabajo: "",
        noExteriorTrabajo: "",
        noInteriorTrabajo: "",
        codigoPostalTrabajo: "",
        coloniaIdTrabajo: 0,
        municipioAlcaldiaTrabajo: "",
        numeroDependientes: 0,
        estadoCivil: "",
        regimenMatrimonial: "",
        nombreConyuge: "",
        apellidoPaternoConyuge: "",
        apellidoMaternoConyuge: "",
        clienteRadicaExtranjero: true,
        clienteDobleNacionalidad: true,
        ingresosExtranjeros: true,
        profesion: 0,
        numeroEmpresasTrabaja: 0,
        comprobarIngresos: null,
        pagoMensualPrestamo: 0,
        pagoTarjetaCredito: 0,
        pagoRenta: 0,
        pagoColegiaturas: 0,
        pagoGastoFamiliar: 0,
        otrosEgresos: 0,
        mensualidadHirCasa: 0,
        tipoViviendaActual: "PROPIA",
        nombreArrendador: null,
        nombreArrendador2: "",
        telefonoArrendador: null,
        institucionFinanciera: null,
        inmuebleDescripcion: "",
        inmuebleValorAproxPatrimonio: 0,
        inmuebleGravadoPatrimonio: null,
        automovilDescripcion: "",
        automovilValorAproxPatrimonio: 0,
        pagandoAutomovilPatrimonio: null,
        observacionesGenerales: ""
      }
    ] as any,

    tipos_ingreso_global_figuras_apoyo: [] as any,
    seccion_ingreso_actual: "",
    // tipos_ingresos_empleado_por_nomina: [] as any,
    // tipos_ingresos_negocio_propio: [] as any,
    // tipos_ingresos_actividad_empresarial: [] as any,
    // tipos_ingresos_arrendamiento: [] as any,
    // tipos_ingresos_concesiones: [] as any,
    // tipos_ingresos_pension: [] as any,
    // tipos_ingresos_conyuge: [] as any,
    // tipos_ingresos_otros: [] as any,

    tipos_ingreso: [] as any,
    formulario_datos_generales: { completado: false },
    formulario_datos_generales_personales: false,
    formulario_datos_generales_domicilio_particular: false,
    formulario_datos_generales_domicilio_trabajo: false,
    formulario_ingresos: { completado: false },
    formulario_gastos: { completado: false },
    formulario_patrimonio: { completado: false },
    formulario_uso_financiamiento: { completado: false },
    formulario_vendedor: { completado: false },
    formulario_observaciones: { completado: false },
    figura_titular: { completado: false }
  }),
  getters: {
    getDatosFigura: state => state.datos_figura,
    getTiposIngreso: state => state.tipos_ingreso.length,
    getTipoActualSeleccionado: state => state.seccion_ingreso_actual,
    getFigura: state => state.figuraActual,
    getDatosFiguras: state => state.datos_figura,
    getPosicionFiguraActualTiposTrabajo: state =>
      state.tipos_ingreso_global_figuras_apoyo.findIndex((e: any) => {
        return e.figura == state.figuraActual;
      }),
    getLenghtMegaArregloGlobal: state => state.tipos_ingreso_global_figuras_apoyo.length,
    getLongitudIngresoEmpleadoPorNomina: state =>
      state.tipos_ingreso_global_figuras_apoyo.find(e => {
        return e.figura == state.figuraActual;
      }),
    getArregloGlobal(state) {
      return state.tipos_ingreso_global_figuras_apoyo.find((obj: any) => obj.figura == state.figuraActual);
    }
  },
  actions: {
    asignarValoresGastos() {
      if (this.datos_figura[this.getFigura].pagoMensualPrestamo == null)
        this.datos_figura[this.getFigura].pagoMensualPrestamo = 0;
      if (this.datos_figura[this.getFigura].pagoTarjetaCredito == null)
        this.datos_figura[this.getFigura].pagoTarjetaCredito = 0;
      if (this.datos_figura[this.getFigura].pagoRenta == null) this.datos_figura[this.getFigura].pagoRenta = 0;
      if (this.datos_figura[this.getFigura].pagoColegiaturas == null)
        this.datos_figura[this.getFigura].pagoColegiaturas = 0;
      if (this.datos_figura[this.getFigura].pagoGastoFamiliar == null)
        this.datos_figura[this.getFigura].pagoGastoFamiliar = 0;
      if (this.datos_figura[this.getFigura].otrosEgresos == null) this.datos_figura[this.getFigura].otrosEgresos = 0;
      if (this.datos_figura[this.getFigura].mensualidadHirCasa == undefined)
        this.datos_figura[this.getFigura].mensualidadHirCasa = 0;
    },
    convertirArreglo() {
      const objetoPre = [
        ...this.tipos_ingreso_global_figuras_apoyo[this.getPosicionFiguraActualTiposTrabajo]
          .tipos_ingresos_empleado_por_nomina,
        ...this.tipos_ingreso_global_figuras_apoyo[this.getPosicionFiguraActualTiposTrabajo]
          .tipos_ingresos_negocio_propio,
        ...this.tipos_ingreso_global_figuras_apoyo[this.getPosicionFiguraActualTiposTrabajo]
          .tipos_ingresos_actividad_empresarial,
        ...this.tipos_ingreso_global_figuras_apoyo[this.getPosicionFiguraActualTiposTrabajo]
          .tipos_ingresos_arrendamiento,
        ...this.tipos_ingreso_global_figuras_apoyo[this.getPosicionFiguraActualTiposTrabajo].tipos_ingresos_concesiones,
        ...this.tipos_ingreso_global_figuras_apoyo[this.getPosicionFiguraActualTiposTrabajo].tipos_ingresos_pension,
        ...this.tipos_ingreso_global_figuras_apoyo[this.getPosicionFiguraActualTiposTrabajo].tipos_ingresos_conyuge,
        ...this.tipos_ingreso_global_figuras_apoyo[this.getPosicionFiguraActualTiposTrabajo].tipos_ingresos_otros
      ];
      this.$state.tipos_ingreso = objetoPre;
    },
    async guardarTiposDeingreso() {
      if (this.figuraActual == 0) {
        try {
          this.convertirArreglo();
          await api.Cliente.Cliente.EstudioSocioEconomicoApi.GuardarIngresos(this.$state.tipos_ingreso);
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          this.convertirArreglo();
          await api.Cliente.Cliente.EstudioSocioEconomicoApi.GuardarIngresosFiguraApoyo(this.$state.tipos_ingreso);
        } catch (error) {
          console.log(error);
        }
      }
    },
    async guardarFigura(datos: any) {
      if (this.figuraActual == 0) {
        try {
          await api.Cliente.Cliente.EstudioSocioEconomicoApi.Guardar(datos);
          this.guardarTiposDeingreso();
          useStore().agregarToast({
            tipo: TipoBootstrap.SUCCESS,
            body: "Datos guardados correctamente."
          });
        } catch (error) {
          useStore().agregarToast({
            tipo: TipoBootstrap.DANGER,

            body: `Error: ${error}`
          });
        }
      } else {
        try {
          await api.Cliente.Cliente.EstudioSocioEconomicoApi.GuardarFiguraApoyo(datos);
          this.guardarTiposDeingreso();
          useStore().agregarToast({
            tipo: TipoBootstrap.SUCCESS,
            body: `Datos guardados correctamente.`
          });
        } catch (error) {
          useStore().agregarToast({
            tipo: TipoBootstrap.DANGER,
            body: `Error: ${error}`
          });
        }
      }
    },
    async ObtenerOperacionesAtivasSesion() {
      try {
        const { data } = await api.Cliente.Cliente.OperacionTitulacionApi.ObtenerOperacionesActivas(); //Obtiene operaciones activas
        this.$state.operacionTitulacionId = data[0].operacionTitulacionId || "";
        setTimeout(() => {
          this.segundoToken(this.$state.operacionTitulacionId);
        }, 500);
        this.$state.tieneSesion = true;
      } catch (error) {
        console.log(error);
      }
    },

    async ObtenerOperacionesAtivas(operacionId: any) {
      try {
        this.$state.operacionTitulacionId = operacionId;
        setTimeout(() => {
          this.segundoToken(this.$state.operacionTitulacionId);
        }, 500);
      } catch (error) {
        console.log(error);
      }
    },
    async segundoToken(token: any) {
      const { data } = await api.Cliente.Cliente.EstudioSocioEconomicoApi.Obtener(token);
      this.$state.estudioSocioeconomicoId = data.estudioSocioeconomicoId || "";
      this.ObtenerDatosDeFigura();
    },
    cambiarTipoActual(tipo: string) {
      this.$state.seccion_ingreso_actual = tipo;
    },
    async ObtenerDatosDeFigura() {
      try {
        const { data } = await api.Cliente.Cliente.EstudioSocioEconomicoApi.Obtener(this.$state.operacionTitulacionId);
        this.$state.datos_figura[0] = { ...data };
        if (this.datos_figura[0].fechaNacimiento) {
          const fechaFormateada = this.datos_figura[0].fechaNacimiento;
          this.datos_figura[0].fechaNacimiento = fechaFormateada.split("T")[0];
        }
      } catch (error) {
        console.log(error);
      }

      try {
        const { data } = await api.Cliente.Cliente.EstudioSocioEconomicoApi.ObtenerFigurasApoyo(
          this.$state.estudioSocioeconomicoId
        );
        let cotitularTemporal, obligadoTemporal, garanteTemporal;
        for (let index = 0; index < data.length; index++) {
          if (data[index].figura == "CoTitular") {
            cotitularTemporal = data[index];
            this.$state.datos_figura[index + 1] = { ...cotitularTemporal };
            if (this.datos_figura[index + 1].fechaNacimiento) {
              const fechaFormateada = this.datos_figura[index + 1].fechaNacimiento;
              this.datos_figura[index + 1].fechaNacimiento = fechaFormateada.split("T")[0];
            }
          } else if (data[index].figura == "ObligadoSolidario") {
            obligadoTemporal = data[index];
            this.$state.datos_figura[index + 1] = { ...obligadoTemporal };
            if (this.datos_figura[index + 1].fechaNacimiento) {
              const fechaFormateada = this.datos_figura[index + 1].fechaNacimiento;
              this.datos_figura[index + 1].fechaNacimiento = fechaFormateada.split("T")[0];
            }
          } else {
            garanteTemporal = data[index];
            this.$state.datos_figura[index + 1] = { ...garanteTemporal };
            if (this.datos_figura[index + 1].fechaNacimiento) {
              const fechaFormateada = this.datos_figura[index + 1].fechaNacimiento;
              this.datos_figura[index + 1].fechaNacimiento = fechaFormateada.split("T")[0];
            }
          }
        }
      } catch (error) {
        console.log(error);
      }

      this.ObtenerDatosTiposIngreso();
      this.$state.cargandoDatosFiguraDeApoyo = false;
    },
    async ObtenerDatosTiposIngreso() {
      const tipos_ingresos_apoyo_objeto: tipos_ingresos_apoyo_objeto = {
        figura: this.$state.figuraActual,
        tipos_ingresos_empleado_por_nomina: [],
        tipos_ingresos_negocio_propio: [],
        tipos_ingresos_actividad_empresarial: [],
        tipos_ingresos_arrendamiento: [],
        tipos_ingresos_concesiones: [],
        tipos_ingresos_pension: [],
        tipos_ingresos_conyuge: [],
        tipos_ingresos_otros: []
      };
      const existeObjetoDeFigura = this.$state.tipos_ingreso_global_figuras_apoyo.some(
        e => e.figura == this.$state.figuraActual
      );
      if (!existeObjetoDeFigura) {
        this.$state.tipos_ingreso_global_figuras_apoyo.push(tipos_ingresos_apoyo_objeto);
      }

      if (this.$state.figuraActual == 0) {
        try {
          const { data } = await api.Cliente.Cliente.EstudioSocioEconomicoApi.ObtenerIngresos(
            this.$state.estudioSocioeconomicoId
          );

          for (let index = 0; index < data.length; index++) {
            const element = data[index];
            //Determinar donde se coloca cada arreglo asignar al arraelo de tipo que le correpsodne
            if (data[index].tipoFuenteIngreso == "EMPLEADO_POR_NOMINA") {
              tipos_ingresos_apoyo_objeto.tipos_ingresos_empleado_por_nomina.push(data[index]);
            } else if (data[index].tipoFuenteIngreso == "NEGOCIO_PROPIO") {
              tipos_ingresos_apoyo_objeto.tipos_ingresos_negocio_propio.push(data[index]);
            } else if (data[index].tipoFuenteIngreso == "ACTIVIDAD_EMPRESARIAL") {
              tipos_ingresos_apoyo_objeto.tipos_ingresos_actividad_empresarial.push(data[index]);
            } else if (data[index].tipoFuenteIngreso == "ARRENDAMIENTO") {
              tipos_ingresos_apoyo_objeto.tipos_ingresos_arrendamiento.push(data[index]);
            } else if (data[index].tipoFuenteIngreso == "CONCESIONES") {
              tipos_ingresos_apoyo_objeto.tipos_ingresos_concesiones.push(data[index]);
            } else if (data[index].tipoFuenteIngreso == "PENSION") {
              tipos_ingresos_apoyo_objeto.tipos_ingresos_pension.push(data[index]);
            } else if (data[index].tipoFuenteIngreso == "CONYUGE") {
              tipos_ingresos_apoyo_objeto.tipos_ingresos_conyuge.push(data[index]);
            } else if (data[index].tipoFuenteIngreso == "OTROS") {
              tipos_ingresos_apoyo_objeto.tipos_ingresos_otros.push(data[index]);
            } else {
              console.log("no entra en ningun tipo para agregar");
            }
          }
        } catch (error) {
          console.log("error", error);
        }
      } else {
        try {
          const { data } = await api.Cliente.Cliente.EstudioSocioEconomicoApi.ObtenerIngresosFiguraApoyo(
            this.$state.estudioSocioeconomicoId,
            this.$state.figuraActual
          );
          if (data.length > 0) {
            for (let index = 0; index < data.length; index++) {
              if (data[index].tipoFuenteIngreso == "EMPLEADO_POR_NOMINA") {
                tipos_ingresos_apoyo_objeto.tipos_ingresos_empleado_por_nomina.push(data[index]);
              } else if (data[index].tipoFuenteIngreso == "NEGOCIO_PROPIO") {
                tipos_ingresos_apoyo_objeto.tipos_ingresos_negocio_propio.push(data[index]);
              } else if (data[index].tipoFuenteIngreso == "ACTIVIDAD_EMPRESARIAL") {
                tipos_ingresos_apoyo_objeto.tipos_ingresos_actividad_empresarial.push(data[index]);
              } else if (data[index].tipoFuenteIngreso == "ARRENDAMIENTO") {
                tipos_ingresos_apoyo_objeto.tipos_ingresos_arrendamiento.push(data[index]);
              } else if (data[index].tipoFuenteIngreso == "CONCESIONES") {
                tipos_ingresos_apoyo_objeto.tipos_ingresos_concesiones.push(data[index]);
              } else if (data[index].tipoFuenteIngreso == "PENSION") {
                tipos_ingresos_apoyo_objeto.tipos_ingresos_pension.push(data[index]);
              } else if (data[index].tipoFuenteIngreso == "CONYUGE") {
                tipos_ingresos_apoyo_objeto.tipos_ingresos_conyuge.push(data[index]);
              } else if (data[index].tipoFuenteIngreso == "OTROS") {
                tipos_ingresos_apoyo_objeto.tipos_ingresos_otros.push(data[index]);
              } else {
                console.log("no entra en ningun tipo para agregar");
              }
            }
          } else {
            console.log("No hay datos para esta figura ", data);
          }
        } catch (error) {
          console.log("error", error);
        }
      }
    },
    /** Region Tipos de trabajo */
    AgregarOtroTipoIngreso(tipo: any, figuraActual: Number) {
      this.$state.seccion_ingreso_actual = tipo;
      /**
       * Buscar si ya existe un arreglo para la figura en cuestión
       * Si existe buscarlo obtener su posición en el mega arreglo
       * Si no existe agregarlo
       */
      const existeObjetoDeFigura = this.$state.tipos_ingreso_global_figuras_apoyo.some(e => e.figura == figuraActual);
      const posicionObjetoDeFigura = this.$state.tipos_ingreso_global_figuras_apoyo.findIndex(e => {
        return e.figura == figuraActual;
      });
      const tipo_ingreso: tipo_ingreso = {
        estudioSocioeconomicoId: this.$state.estudioSocioeconomicoId,
        tipoFuenteIngreso: tipo,
        actividadEconomica: null,
        nombreEmpresa: "",
        giroEmpresa: 0,
        telefono: "",
        extension: "",
        email: "",
        puesto: "",
        antiguedad: 0,
        nombreJefe: "",
        telefonoJefe: "",
        ingresoBruto: 0,
        impuestos: 0,
        calle: "",
        noExterior: "",
        noInterior: "",
        codigoPostal: "",
        coloniaId: 0,
        municipioAlcaldia: "",
        esSocio: true,
        descripcion: "",
        descripcion2: "",
        tipoPension: 0
      };
      const tipo_ingreso_apoyo: tipo_ingreso_apoyo = {
        estudioSocioeconomicoId: this.$state.estudioSocioeconomicoId,
        numero: this.figuraActual,
        esCotitular: true,
        tipoFuenteIngreso: tipo,
        actividadEconomica: null,
        nombreEmpresa: "",
        giroEmpresa: 0,
        telefono: "",
        extension: "",
        email: "",
        puesto: "",
        antiguedad: 0,
        nombreJefe: "",
        telefonoJefe: "",
        ingresoBruto: 0,
        impuestos: 0,
        calle: "",
        noExterior: "",
        noInterior: "",
        codigoPostal: "",
        coloniaId: 0,
        municipioAlcaldia: "",
        esSocio: true,
        descripcion: "",
        descripcion2: "",
        tipoPension: 0
      };
      const tipos_ingresos_apoyo_objeto: tipos_ingresos_apoyo_objeto = {
        figura: figuraActual,
        tipos_ingresos_empleado_por_nomina: [],
        tipos_ingresos_negocio_propio: [],
        tipos_ingresos_actividad_empresarial: [],
        tipos_ingresos_arrendamiento: [],
        tipos_ingresos_concesiones: [],
        tipos_ingresos_pension: [],
        tipos_ingresos_conyuge: [],
        tipos_ingresos_otros: []
      };
      //Buscar si existe un objeto perteneciente a la figura
      if (existeObjetoDeFigura) {
        if (this.figuraActual == 0) {
          if (tipo == "EMPLEADO_POR_NOMINA") {
            this.$state.tipos_ingreso_global_figuras_apoyo[
              posicionObjetoDeFigura
            ].tipos_ingresos_empleado_por_nomina.push(tipo_ingreso);
          } else if (tipo == "NEGOCIO_PROPIO") {
            this.$state.tipos_ingreso_global_figuras_apoyo[posicionObjetoDeFigura].tipos_ingresos_negocio_propio.push(
              tipo_ingreso
            );
          } else if (tipo == "ACTIVIDAD_EMPRESARIAL") {
            this.$state.tipos_ingreso_global_figuras_apoyo[
              posicionObjetoDeFigura
            ].tipos_ingresos_actividad_empresarial.push(tipo_ingreso);
          } else if (tipo == "ARRENDAMIENTO") {
            this.$state.tipos_ingreso_global_figuras_apoyo[posicionObjetoDeFigura].tipos_ingresos_arrendamiento.push(
              tipo_ingreso
            );
          } else if (tipo == "CONCESIONES") {
            this.$state.tipos_ingreso_global_figuras_apoyo[posicionObjetoDeFigura].tipos_ingresos_concesiones.push(
              tipo_ingreso
            );
          } else if (tipo == "PENSION") {
            this.$state.tipos_ingreso_global_figuras_apoyo[posicionObjetoDeFigura].tipos_ingresos_pension.push(
              tipo_ingreso
            );
          } else if (tipo == "CONYUGE") {
            this.$state.tipos_ingreso_global_figuras_apoyo[posicionObjetoDeFigura].tipos_ingresos_conyuge.push(
              tipo_ingreso
            );
          } else if (tipo == "OTROS") {
            this.$state.tipos_ingreso_global_figuras_apoyo[posicionObjetoDeFigura].tipos_ingresos_otros.push(
              tipo_ingreso
            );
          } else {
            console.log("error no se encontro ");
          }
        } else {
          // Insertar objeto del tipo de ingreso en su arreglo correspondiente
          if (tipo == "EMPLEADO_POR_NOMINA") {
            this.$state.tipos_ingreso_global_figuras_apoyo[
              posicionObjetoDeFigura
            ].tipos_ingresos_empleado_por_nomina.push(tipo_ingreso_apoyo);
          } else if (tipo == "NEGOCIO_PROPIO") {
            this.$state.tipos_ingreso_global_figuras_apoyo[posicionObjetoDeFigura].tipos_ingresos_negocio_propio.push(
              tipo_ingreso_apoyo
            );
          } else if (tipo == "ACTIVIDAD_EMPRESARIAL") {
            this.$state.tipos_ingreso_global_figuras_apoyo[
              posicionObjetoDeFigura
            ].tipos_ingresos_actividad_empresarial.push(tipo_ingreso_apoyo);
          } else if (tipo == "ARRENDAMIENTO") {
            this.$state.tipos_ingreso_global_figuras_apoyo[posicionObjetoDeFigura].tipos_ingresos_arrendamiento.push(
              tipo_ingreso_apoyo
            );
          } else if (tipo == "CONCESIONES") {
            this.$state.tipos_ingreso_global_figuras_apoyo[posicionObjetoDeFigura].tipos_ingresos_concesiones.push(
              tipo_ingreso_apoyo
            );
          } else if (tipo == "PENSION") {
            this.$state.tipos_ingreso_global_figuras_apoyo[posicionObjetoDeFigura].tipos_ingresos_pension.push(
              tipo_ingreso_apoyo
            );
          } else if (tipo == "CONYUGE") {
            this.$state.tipos_ingreso_global_figuras_apoyo[posicionObjetoDeFigura].tipos_ingresos_conyuge.push(
              tipo_ingreso_apoyo
            );
          } else if (tipo == "OTROS") {
            this.$state.tipos_ingreso_global_figuras_apoyo[posicionObjetoDeFigura].tipos_ingresos_otros.push(
              tipo_ingreso_apoyo
            );
          } else {
            console.log("error no se encontro ");
          }
        }
      } else {
        //Determinar para donde se va agregar con el tipo y agregarlo al arreglo correspondiente
        if (this.figuraActual == 0) {
          if (tipo == "EMPLEADO_POR_NOMINA") {
            this.$state.tipos_ingreso_global_figuras_apoyo.push(tipos_ingresos_apoyo_objeto);
            tipos_ingresos_apoyo_objeto.tipos_ingresos_empleado_por_nomina.push(tipo_ingreso);
          } else if (tipo == "NEGOCIO_PROPIO") {
            this.$state.tipos_ingreso_global_figuras_apoyo.push(tipos_ingresos_apoyo_objeto);
            tipos_ingresos_apoyo_objeto.tipos_ingresos_negocio_propio.push(tipo_ingreso);
          } else if (tipo == "ACTIVIDAD_EMPRESARIAL") {
            this.$state.tipos_ingreso_global_figuras_apoyo.push(tipos_ingresos_apoyo_objeto);
            tipos_ingresos_apoyo_objeto.tipos_ingresos_actividad_empresarial.push(tipo_ingreso);
          } else if (tipo == "ARRENDAMIENTO") {
            this.$state.tipos_ingreso_global_figuras_apoyo.push(tipos_ingresos_apoyo_objeto);
            tipos_ingresos_apoyo_objeto.tipos_ingresos_arrendamiento.push(tipo_ingreso);
          } else if (tipo == "CONCESIONES") {
            this.$state.tipos_ingreso_global_figuras_apoyo.push(tipos_ingresos_apoyo_objeto);
            tipos_ingresos_apoyo_objeto.tipos_ingresos_concesiones.push(tipo_ingreso);
          } else if (tipo == "PENSION") {
            this.$state.tipos_ingreso_global_figuras_apoyo.push(tipos_ingresos_apoyo_objeto);
            tipos_ingresos_apoyo_objeto.tipos_ingresos_pension.push(tipo_ingreso);
          } else if (tipo == "CONYUGE") {
            this.$state.tipos_ingreso_global_figuras_apoyo.push(tipos_ingresos_apoyo_objeto);
            tipos_ingresos_apoyo_objeto.tipos_ingresos_conyuge.push(tipo_ingreso);
          } else if (tipo == "OTROS") {
            this.$state.tipos_ingreso_global_figuras_apoyo.push(tipos_ingresos_apoyo_objeto);
            tipos_ingresos_apoyo_objeto.tipos_ingresos_otros.push(tipo_ingreso);
          } else {
            console.log("No se encontro el tipo ");
          }
        } else {
          // Insertar objeto del tipo de ingreso en su arreglo correspondiente
          if (tipo == "EMPLEADO_POR_NOMINA") {
            this.$state.tipos_ingreso_global_figuras_apoyo.push(tipos_ingresos_apoyo_objeto);
            tipos_ingresos_apoyo_objeto.tipos_ingresos_empleado_por_nomina.push(tipo_ingreso_apoyo);
          } else if (tipo == "NEGOCIO_PROPIO") {
            this.$state.tipos_ingreso_global_figuras_apoyo.push(tipos_ingresos_apoyo_objeto);
            tipos_ingresos_apoyo_objeto.tipos_ingresos_negocio_propio.push(tipo_ingreso_apoyo);
          } else if (tipo == "ACTIVIDAD_EMPRESARIAL") {
            this.$state.tipos_ingreso_global_figuras_apoyo.push(tipos_ingresos_apoyo_objeto);
            tipos_ingresos_apoyo_objeto.tipos_ingresos_actividad_empresarial.push(tipo_ingreso_apoyo);
          } else if (tipo == "ARRENDAMIENTO") {
            this.$state.tipos_ingreso_global_figuras_apoyo.push(tipos_ingresos_apoyo_objeto);
            tipos_ingresos_apoyo_objeto.tipos_ingresos_arrendamiento.push(tipo_ingreso_apoyo);
          } else if (tipo == "CONCESIONES") {
            this.$state.tipos_ingreso_global_figuras_apoyo.push(tipos_ingresos_apoyo_objeto);
            tipos_ingresos_apoyo_objeto.tipos_ingresos_concesiones.push(tipo_ingreso_apoyo);
          } else if (tipo == "PENSION") {
            this.$state.tipos_ingreso_global_figuras_apoyo.push(tipos_ingresos_apoyo_objeto);
            tipos_ingresos_apoyo_objeto.tipos_ingresos_pension.push(tipo_ingreso_apoyo);
          } else if (tipo == "CONYUGE") {
            this.$state.tipos_ingreso_global_figuras_apoyo.push(tipos_ingresos_apoyo_objeto);
            tipos_ingresos_apoyo_objeto.tipos_ingresos_conyuge.push(tipo_ingreso_apoyo);
          } else if (tipo == "OTROS") {
            this.$state.tipos_ingreso_global_figuras_apoyo.push(tipos_ingresos_apoyo_objeto);
            tipos_ingresos_apoyo_objeto.tipos_ingresos_otros.push(tipo_ingreso_apoyo);
          } else {
            console.log("No se encontro el tipo ");
          }
        }
      }
    },
    RemoverTipoIngreso(tipo: any) {
      if (tipo == "EMPLEADO_POR_NOMINA") {
        this.getArregloGlobal.tipos_ingresos_empleado_por_nomina = [];
      } else if (tipo == "NEGOCIO_PROPIO") {
        this.getArregloGlobal.tipos_ingresos_negocio_propio = [];
      } else if (tipo == "ACTIVIDAD_EMPRESARIAL") {
        this.getArregloGlobal.tipos_ingresos_actividad_empresarial = [];
      } else if (tipo == "ARRENDAMIENTO") {
        this.getArregloGlobal.tipos_ingresos_arrendamiento = [];
      } else if (tipo == "CONCESIONES") {
        this.getArregloGlobal.tipos_ingresos_concesiones = [];
      } else if (tipo == "PENSION") {
        this.getArregloGlobal.tipos_ingresos_pension = [];
      } else if (tipo == "CONYUGE") {
        this.getArregloGlobal.tipos_ingresos_conyuge = [];
      } else if (tipo == "OTROS") {
        this.getArregloGlobal.tipos_ingresos_otros = [];
      }
    },
    EliminarTipoIngreso(index: any) {
      /**
       * Detectar que persona es y que posición tiene
       * Detectar que tipo de ingreso es y hacia que arreglo va
       * Remover el index que traemos
       */
      if (this.getTipoActualSeleccionado == "EMPLEADO_POR_NOMINA") {
        this.tipos_ingreso_global_figuras_apoyo[
          this.getPosicionFiguraActualTiposTrabajo
        ].tipos_ingresos_empleado_por_nomina.splice(index, 1);
      } else if (this.getTipoActualSeleccionado == "NEGOCIO_PROPIO") {
        this.tipos_ingreso_global_figuras_apoyo[
          this.getPosicionFiguraActualTiposTrabajo
        ].tipos_ingresos_negocio_propio.splice(index, 1);
      } else if (this.getTipoActualSeleccionado == "ACTIVIDAD_EMPRESARIAL") {
        this.tipos_ingreso_global_figuras_apoyo[
          this.getPosicionFiguraActualTiposTrabajo
        ].tipos_ingresos_actividad_empresarial.splice(index, 1);
      } else if (this.getTipoActualSeleccionado == "ARRENDAMIENTO") {
        this.tipos_ingreso_global_figuras_apoyo[
          this.getPosicionFiguraActualTiposTrabajo
        ].tipos_ingresos_arrendamiento.splice(index, 1);
      } else if (this.getTipoActualSeleccionado == "CONCESIONES") {
        this.tipos_ingreso_global_figuras_apoyo[
          this.getPosicionFiguraActualTiposTrabajo
        ].tipos_ingresos_concesiones.splice(index, 1);
      } else if (this.getTipoActualSeleccionado == "PENSION") {
        this.tipos_ingreso_global_figuras_apoyo[this.getPosicionFiguraActualTiposTrabajo].tipos_ingresos_pension.splice(
          index,
          1
        );
      } else if (this.getTipoActualSeleccionado == "CONYUGE") {
        this.tipos_ingreso_global_figuras_apoyo[this.getPosicionFiguraActualTiposTrabajo].tipos_ingresos_conyuge.splice(
          index,
          1
        );
      } else if (this.getTipoActualSeleccionado == "OTROS") {
        this.tipos_ingreso_global_figuras_apoyo[this.getPosicionFiguraActualTiposTrabajo].tipos_ingresos_otros.splice(
          index,
          1
        );
      }
    }
  }
});
