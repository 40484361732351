<template lang="pug">
mixin contenidoTablaDocumentos(titulo, arregloDocumentos, entidad, os)
  thead
    tr: td.fw-bold.fs-5(colspan="3")= titulo
    tr
      td.fw-bold Documento
      td.fw-bold.text-center Observaciones
      td.fw-bold.text-center Acciones
      td.fw-bold.text-center Estatus
  tbody: tr(v-for=`doc in ` + arregloDocumentos)
    td(style="width: 70%")
      a(
        href="#"
        data-bs-toggle="modal"
        :data-bs-target="'#' + componentId + 'modalAyuda'"
        v-on:click="mostrarModalAyuda(doc.documentoTramiteTitulacion.nombre, doc)"
      )
        img(src="../../assets/Interrogacion.svg")
      span.text-capitalize(style="margin-left: 10px") {{ doc.documentoTramiteTitulacion.nombre }}
    //-td.text-center.a(data-bs-toggle='modal' data-bs-target='#documentosModal' v-on:click="pasarTitulo(doc.documentoTramiteTitulacion.nombre, doc.estudioSocioeconomicoDocumentoId,0)" class="pointer"): i(
      :class="doc.guardado ? 'fa fa-eye' : 'fa fa-upload'"
      )
    td.text-center(:style="!doc.guardado ? 'color: #94A3B0' : ''") {{ doc.comentario }}
    td.text-center
      a(
        href="#"
        v-if="doc.estatus == null || doc.estatus == 2 || doc.estatus == 0"
        @click.prevent=`seleccionarArchivo(doc, '` + entidad + `'` + (os ? `, i + 1` : ``) + `)`
        title="Cargar archivo"
      ): em.fas.fa-upload
      a(target="_blank" v-if="doc.archivo" :href=`obtenerUrlVerArchivo(doc, '` + entidad + `'` + (os ? `, i + 1` : ``) + `)` title="Ver archivo"): em.far.fa-eye
    td.text-center(v-html="obtenerEstadoDocumento(doc.estatus)" :class="obtenerClasesEstadoDocumento(doc.estatus)")

mixin tablaDocumentos(titulo, arregloDocumentos, entidad, vFor)
  if vFor
    template(v-for=`` + vFor): table.w-100.bg-white.table(v-if=arregloDocumentos + `.length`)
      +contenidoTablaDocumentos(titulo, arregloDocumentos, entidad, true)
  else
    table.w-100.bg-white.table(v-if=arregloDocumentos + `.length`)
      +contenidoTablaDocumentos(titulo, arregloDocumentos, entidad)

+tablaDocumentos("Checklist de Documentos Requeridos del Cliente", "props.cliente.documentos", "CLIENTE")
+tablaDocumentos("Checklist de Documentos Requeridos del Cónyuge", "props.cliente.documentosConyuge", "CONYUGE")
+tablaDocumentos("Checklist de Documentos Requeridos del Vendedor", "props.cliente.documentosVendedor", "VENDEDOR")
+tablaDocumentos("Checklist de Documentos Requeridos del Cónyuge del Vendedor", "props.cliente.documentosConyugeVendedor", "CONYUGE_VENDEDOR")
+tablaDocumentos("Checklist de Documentos Requeridos del Obligado Solidario {{ i + 1 }}", "docs", "OBLIGADO_SOLIDARIO", "(docs, i) in props.cliente.documentosOS")
+tablaDocumentos("Checklist de Documentos Requeridos del Cotitular {{ i + 1 }}", "docs", "COTITULAR", "(docs, i) in props.cliente.documentosCotitular")
+tablaDocumentos("Checklist de Documentos Requeridos del Garante Hipotecario", "props.cliente.documentosGaranteHipotecario", "GARANTE_HIPOTECARIO")

.modal.fade(tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" :id="componentId + 'modalAyuda'")
  .modal-dialog.modal-lg
    .modal-content
      .modal-header
        h5.modal-title
          img(src="../../assets/Interrogacion.svg")
          span &nbsp; Ayuda de Documento
          span.textHir1 &nbsp;{{ modalAyuda.titulo }}&nbsp;
          span de Checklist
        button.btn-close(type="button" data-bs-dismiss="modal" aria-label="Close")
      .modal-body
        div(v-if="modalAyuda.documento.value.especificacion")
          .fw-bold.fs-6 Especificaciones
          span {{ modalAyuda.documento.value.especificacion }}
          br
        div(v-if="modalAyuda.documento.value.descripcion")
          .fw-bold.fs-6 Descripción
          span {{ modalAyuda.documento.value.descripcion }}
          br
        div(v-if="modalAyuda.documento.value.dondeConseguir")
          .fw-bold.fs-6 ¿Dónde se consigue?
          span {{ modalAyuda.documento.value.dondeConseguir }}
          br
        div(v-if="modalAyuda.documento.value.mesesVigencia")
          .fw-bold.fs-6 Tiempo de Validéz
          span {{ modalAyuda.documento.value.mesesVigencia }} Meses
          br
        div(v-if="modalAyuda.documento.value.porqueNecesario")
          .fw-bold.fs-6 ¿Por qué es necesario?
          span {{ modalAyuda.documento.value.porqueNecesario }}
      .modal-footer
        button.btn.btn-primary.text-light.fw-bold(type="button" data-bs-dismiss="modal") Cerrar

.modal.fade(ref="modalCargarArchivoDom" data-bs-keyboard="false")
  .modal-dialog.modal-xl: .modal-content
    .modal-header
      h5.modal-title Vista previa del archivo
      button.btn-close(data-bs-dismiss="modal")
    .modal-body(style="height: calc(100vh - 202px); overflow: auto" v-if="!modalCargarArchivo.enviando.value")
      div Este es el documento elegido. Antes de enviar, confirma que es el correcto.
      .row
        .col-md-6
          iframe.w-100(v-if="modalCargarArchivoVerArchivo" :src="modalCargarArchivo.urlArchivo.value" style="height: calc(100vh - 338px)")
          p Archivo: {{ modalCargarArchivoNombreArchivo }}
          div Tamaño: {{ modalCargarArchivoPesoArchivo }}
        .col-md-6
          p.fw-bold Considera que:
          ul
            li El tamaño máximo admitido es 1 GB. Los documentos grandes tardarán en subir.
            li Es legible y la orientación es correcta.
            li Si el documento es de varias hojas, debes integrarlas todas en un solo archivo antes de enviarlo.
            li No se pueden subir archivos ejecutables.
          div En caso de que tengas algún inconveniente para cargarlo, contacta a tu asesor para ayudarte a resolverlo.
    .modal-body(v-if="modalCargarArchivo.enviando.value")
      p.text-center.fw-bold(v-if="!modalCargarArchivo.respuesta.value") El documento se está enviando.
      .progress.my-3(v-if="!modalCargarArchivo.respuesta.value")
        .progress-bar.progress-bar-striped.progress-bar-animated(:style="{ width: modalCargarArchivo.progreso.value + '%' }")
      .fw-bold.text-center(v-if="modalCargarArchivo.respuesta.value && modalCargarArchivo.respuesta.value.estado == 'OK'")
        p.text-success: i.fas.fa-circle-check.fa-2xl
        p Documento enviado
      .fw-bold.text-center(v-if="modalCargarArchivo.respuesta.value && modalCargarArchivo.respuesta.value.estado != 'OK'") 
        p.text-danger: i.fas.fa-circle-xmark.fa-2xl
        p {{ modalCargarArchivo.respuesta.value.mensaje }}
      div El documento entra a etapa de revisión. En caso de que requieras hacer alguna modificación, por favor contacta a tu asesor.
    .modal-footer
      button.btn.btn-primary(v-if="!modalCargarArchivo.enviando.value" @click.prevent="cargarArchivo()") Enviar
      button.btn.btn-secondary(v-if="!modalCargarArchivo.enviando.value" data-bs-dismiss="modal") Cancelar
input.d-none(ref="inputFile" @change="mostrarModalCargarArchivo()" type="file")
</template>

<script lang="ts" setup>
import type { Toast } from "@/store";
import type { ClienteDocumentos } from "../../composables/EstudioSocioeconomico";
import type { Respuesta } from "@/servicios/api";
import type { CancelTokenSource } from "axios";
import { useStore } from "@/store";
import { useRouter } from "vue-router";
import * as utilidades from "@/utilidades";
import * as bootstrap from "bootstrap";
import api from "@/servicios/apiCliente";
import axios from "axios";
import { TipoBootstrap } from "@/interfaces";
import type { Core } from "@/servicios/api";
import { withDefaults, toRefs, reactive, computed, onMounted, nextTick, ref } from "vue";

const componentId = utilidades.generarId();
const $router = useRouter();
const $store = useStore();

interface Props {
  cliente: ClienteDocumentos;
}

const props = withDefaults(defineProps<Props>(), {
  cliente: () => ({
    documentos: [],
    documentosOS: [],
    documentosConyuge: [],
    documentosCotitular: [],
    documentosVendedor: [],
    documentosGaranteHipotecario: [],
    documentosConyugeVendedor: []
  })
});

const modalAyuda = toRefs(
  reactive({
    titulo: "",
    documento: {} as Core.Models.EstudioSocioeconomicoDocumento
  })
);
const modalCargarArchivo = toRefs(
  reactive({
    documento: {} as Core.Models.EstudioSocioeconomicoDocumento,
    numeroObligadoSolidario: 0 as undefined | number,
    estudioSocioeconomicoEntidad: null as null | Core.Enums.EstudioSocioeconomicoEntidad,
    urlArchivo: "about:blank",
    archivo: null as null | File,
    progreso: 0,
    enviando: false,
    respuesta: null as null | Respuesta,
    tokenCancelacion: null as null | CancelTokenSource
  })
);

const modalCargarArchivoNombreArchivo = computed((): string => {
  if (modalCargarArchivo.archivo.value == null) return "";
  return modalCargarArchivo.archivo.value.name;
});
const modalCargarArchivoPesoArchivo = computed((): string => {
  if (modalCargarArchivo.archivo.value == null) return "";
  let peso = modalCargarArchivo.archivo.value.size / 1024;
  let formater = new Intl.NumberFormat("es-MX", { style: "decimal", maximumFractionDigits: 2 });
  if (peso < 1024) return formater.format(peso) + " kB";
  return formater.format(peso / 1024) + " MB";
});
const modalCargarArchivoVerArchivo = computed((): boolean => {
  if (modalCargarArchivo.archivo.value == null) return false;
  return /^(application\/pdf|image\/.+|video\/.+|audio\/.+)/g.test(modalCargarArchivo.archivo.value.type);
});

const modalCargarArchivoDom = ref(null as HTMLDivElement | null);
const inputFile = ref(null as HTMLInputElement | null);

onMounted(async () => {
  await nextTick();
  (modalCargarArchivoDom.value as HTMLDivElement).addEventListener("hide.bs.modal", () => {
    window.URL.revokeObjectURL(modalCargarArchivo.urlArchivo.value);
    modalCargarArchivo.archivo.value = null;
    modalCargarArchivo.urlArchivo.value = "about:blank";
    modalCargarArchivo.progreso.value = 0;
    modalCargarArchivo.enviando.value = false;
    modalCargarArchivo.respuesta.value = null;
    if (modalCargarArchivo.tokenCancelacion.value)
      modalCargarArchivo.tokenCancelacion.value.cancel("Cancelado por el usuario");
  });
});

function mostrarModalAyuda(nombre: string, documento: Core.Models.EstudioSocioeconomicoDocumento) {
  modalAyuda.titulo.value = nombre;
  modalAyuda.documento.value = documento;
}
function seleccionarArchivo(
  documento: Core.Models.EstudioSocioeconomicoDocumento,
  entidad: Core.Enums.EstudioSocioeconomicoEntidad,
  nOS: undefined | number
) {
  modalCargarArchivo.documento.value = documento;
  modalCargarArchivo.estudioSocioeconomicoEntidad.value = entidad;
  modalCargarArchivo.numeroObligadoSolidario.value = nOS;
  inputFile.value!.click();
}
function mostrarModalCargarArchivo() {
  let archivos = inputFile.value!.files;
  if (archivos?.length) {
    modalCargarArchivo.archivo.value = archivos[0];
    modalCargarArchivo.urlArchivo.value = window.URL.createObjectURL(archivos[0]);
    let instancia = bootstrap.Modal.getInstance(modalCargarArchivoDom.value!);
    if (!instancia)
      instancia = new bootstrap.Modal(modalCargarArchivoDom.value!, {
        keyboard: false,
        backdrop: "static"
      });
    instancia.show();
  }
  inputFile.value!.value = "";
}
async function cargarArchivo() {
  if (modalCargarArchivo.archivo.value!.size / 1024 / 1024 > 1024) {
    $store.agregarToast({
      tipo: TipoBootstrap.WARNING,
      body: "El archivo pesa más de 1 GB."
    });
    bootstrap.Modal.getInstance(modalCargarArchivoDom.value!)?.hide();
  }
  modalCargarArchivo.enviando.value = true;
  modalCargarArchivo.documento.value.estatus = -1;
  modalCargarArchivo.tokenCancelacion.value = axios.CancelToken.source();
  let token = ($router.currentRoute.value.query["estudioId"] as string) ?? "";
  let res = await api.Cliente.Cliente.EscrituracionApi.GuardarDocumento(
    modalCargarArchivo.archivo.value!,
    modalCargarArchivo.documento.value.documentoTramiteTitulacionId,
    modalCargarArchivo.numeroObligadoSolidario.value ?? 0,
    token,
    modalCargarArchivo.estudioSocioeconomicoEntidad.value!,
    {
      onUploadProgress: (progressEvent: ProgressEvent) => {
        if (progressEvent.lengthComputable)
          modalCargarArchivo.progreso.value = (progressEvent.loaded / progressEvent.total) * 100;
      },
      cancelToken: modalCargarArchivo.tokenCancelacion.value.token
    }
  );
  modalCargarArchivo.respuesta.value = res.data;
}
function obtenerUrlVerArchivo(
  documento: Core.Models.EstudioSocioeconomicoDocumento,
  entidad: Core.Enums.EstudioSocioeconomicoEntidad,
  nOS: undefined | number
) {
  const url = new URL(
    document.baseURI.replace(/\/$/g, "") + api.Cliente.Cliente.EscrituracionApi.ObtenerDocumentoUrl,
    window.location.protocol + "//" + window.location.host
  );
  const searchParams = new URLSearchParams(url.search);
  if ($router.currentRoute.value.query["estudioId"])
    searchParams.set("token", $router.currentRoute.value.query["estudioId"] as string);
  if (nOS != undefined) searchParams.set("obligadoSolidarioNumero", String(nOS));
  searchParams.set("estudioSocioeconomicoEntidad", entidad);
  searchParams.set("documentoTramiteTitulacionId", String(documento.documentoTramiteTitulacionId));
  url.search = searchParams.toString();
  return url.toString();
}
function obtenerEstadoDocumento(estatus: null | number): string {
  if (estatus == null || estatus == 0) return `<em class="far fa-circle me-2"></em>Sin cargar`;
  if (estatus == 1) return `<em class="fas fa-check-circle me-2"></em>Aceptado`;
  if (estatus == 2) return `<em class="fas fa-times-circle me-2"></em>Rechazado`;
  if (estatus == 3) return `<em class="fas fa-circle me-2"></em>No necesario`;
  return `<em class="far fa-circle me-2"></em>Pendiente de validar`;
}
function obtenerClasesEstadoDocumento(estatus: null | number): string {
  if (estatus == 1) return "text-success";
  if (estatus == 2) return "text-danger";
  return "";
}
</script>
