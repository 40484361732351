import $ from "jquery";
// @ts-ignore
import DT from "datatables.net/js/jquery.dataTables";
// @ts-ignore
import DTBS from "datatables.net-bs5/js/dataTables.bootstrap5";
import "datatables.net-bs5/css/dataTables.bootstrap5.css";

(DT as any)(window, $);
(DTBS as any)(window, $);

$.extend(true, ($.fn as any).dataTable.defaults, {
  language: {
    aria: {
      sortAscending: "Activar para ordenar la columna de manera ascendente",
      sortDescending: "Activar para ordenar la columna de manera descendente"
    },
    autoFill: {
      cancel: "Cancelar",
      fill: "Rellene todas las celdas con <i>%d</i>",
      fillHorizontal: "Rellenar celdas horizontalmente",
      fillVertical: "Rellenar celdas verticalmente"
    },
    buttons: {
      collection: "Colección",
      colvis: "Visibilidad",
      colvisRestore: "Restaurar visibilidad",
      copy: "Copiar",
      copyKeys:
        "Presione ctrl o \u2318 + C para copiar los datos de la tabla al portapapeles del sistema. <br /> <br /> Para cancelar, haga clic en este mensaje o presione escape.",
      copySuccess: {
        "1": "Copiada 1 fila al portapapeles",
        _: "Copiadas %d fila al portapapeles"
      },
      copyTitle: "Copiar al portapapeles",
      csv: "CSV",
      excel: "Excel",
      pageLength: {
        "-1": "Mostrar todas las filas",
        _: "Mostrar %d filas"
      },
      pdf: "PDF",
      print: "Imprimir"
    },
    infoEmpty: "Mostrando registros del 0 al 0 de un total de 0 registros",
    infoFiltered: "(filtrado de un total de _MAX_ registros)",
    infoThousands: ",",
    lengthMenu: "Mostrar _MENU_ registros",
    loadingRecords: "Cargando...",
    paginate: {
      first: "Primero",
      last: "Último",
      next: "Siguiente",
      previous: "Anterior"
    },
    processing: "Procesando...",
    search: "Buscar:",
    searchBuilder: {
      add: "Añadir condición",
      button: {
        "0": "Constructor de búsqueda",
        _: "Constructor de búsqueda (%d)"
      },
      clearAll: "Borrar todo",
      condition: "Condición",
      deleteTitle: "Eliminar regla de filtrado",
      leftTitle: "Criterios anulados",
      logicAnd: "Y",
      logicOr: "O",
      rightTitle: "Criterios de sangría",
      title: {
        "0": "Constructor de búsqueda",
        _: "Constructor de búsqueda (%d)"
      },
      value: "Valor",
      conditions: {
        date: {
          after: "Después",
          before: "Antes",
          between: "Entre",
          empty: "Vacío",
          equals: "Igual a",
          not: "Diferente de",
          notBetween: "No entre",
          notEmpty: "No vacío"
        },
        number: {
          between: "Entre",
          empty: "Vacío",
          equals: "Igual a",
          gt: "Mayor a",
          gte: "Mayor o igual a",
          lt: "Menor que",
          lte: "Menor o igual a",
          not: "Diferente de",
          notBetween: "No entre",
          notEmpty: "No vacío"
        },
        string: {
          contains: "Contiene",
          empty: "Vacío",
          endsWith: "Termina con",
          equals: "Igual a",
          not: "Diferente de",
          startsWith: "Inicia con",
          notEmpty: "No vacío"
        },
        array: {
          equals: "Igual a",
          empty: "Vacío",
          contains: "Contiene",
          not: "Diferente",
          notEmpty: "No vacío",
          without: "Sin"
        }
      },
      data: "Datos"
    },
    searchPanes: {
      clearMessage: "Borrar todo",
      collapse: {
        "0": "Paneles de búsqueda",
        _: "Paneles de búsqueda (%d)"
      },
      count: "{total}",
      emptyPanes: "Sin paneles de búsqueda",
      loadMessage: "Cargando paneles de búsqueda",
      title: "Filtros Activos - %d",
      countFiltered: "{shown} ({total})"
    },
    select: {
      cells: {
        "1": "1 celda seleccionada",
        _: "$d celdas seleccionadas"
      },
      columns: {
        "1": "1 columna seleccionada",
        _: "%d columnas seleccionadas"
      }
    },
    thousands: ",",
    datetime: {
      previous: "Anterior",
      hours: "Horas",
      minutes: "Minutos",
      seconds: "Segundos",
      unknown: "-",
      amPm: ["am", "pm"],
      next: "Siguiente",
      months: {
        "0": "Enero",
        "1": "Febrero",
        "10": "Noviembre",
        "11": "Diciembre",
        "2": "Marzo",
        "3": "Abril",
        "4": "Mayo",
        "5": "Junio",
        "6": "Julio",
        "7": "Agosto",
        "8": "Septiembre",
        "9": "Octubre"
      },
      weekdays: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"]
    },
    editor: {
      close: "Cerrar",
      create: {
        button: "Nuevo",
        title: "Crear Nuevo Registro",
        submit: "Crear"
      },
      edit: {
        button: "Editar",
        title: "Editar Registro",
        submit: "Actualizar"
      },
      remove: {
        button: "Eliminar",
        title: "Eliminar Registro",
        submit: "Eliminar",
        confirm: {
          _: "¿Está seguro que desea eliminar %d filas?",
          "1": "¿Está seguro que desea eliminar 1 fila?"
        }
      },
      multi: {
        title: "Múltiples Valores",
        restore: "Deshacer Cambios",
        noMulti: "Este registro puede ser editado individualmente, pero no como parte de un grupo.",
        info: "Los elementos seleccionados contienen diferentes valores para este registro. Para editar y establecer todos los elementos de este registro con el mismo valor, haga click o toque aquí, de lo contrario conservarán sus valores individuales."
      },
      error: {
        system: 'Ha ocurrido un error en el sistema (<a target="\\" rel="\\ nofollow" href="\\"> Más información</a>).'
      }
    },
    decimal: ".",
    emptyTable: "No hay datos disponibles en la tabla",
    info: "Mostrando de _START_ al _END_ de  _TOTAL_ registros",
    zeroRecords: "No se encontraron coincidencias"
  }
});
