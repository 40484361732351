<template lang="pug">
select.w-100(ref="select2")
  option(v-if="etiqueta" :selected="modelValue === undefined" value="" disabled) {{ etiqueta }}
</template>

<script lang="ts">
import "@/sass/select2.scss";
import $ from "jquery";
import S2 from "select2";
import { defineComponent } from "vue";
import type { PropType } from "vue";
import type { DataFormat } from "select2";

(S2 as any)(window, $);

/**
 * Soporta los mismos parámetros de configuración que Select2, los tipos válidos para el v-model son Number y string
 * Soporta una lista de opciones que cambie externamente desde el componente.
 */
export default defineComponent({
  props: {
    etiqueta: { type: String, default: "Seleccionar una opción..." },
    modelValue: { type: [Number, String, Object] as PropType<number | string | Object>, default: undefined },
    data: { type: Array as () => DataFormat[], default: () => [] }
  },
  emits: ["update:modelValue"],
  watch: {
    modelValue(value) {
      let select = this.$el as HTMLSelectElement;
      $(select).val(value).trigger("change");
    },
    data(value) {
      $(this.$el)
        .empty()
        .select2({ data: value, width: "100%" })
        .val(this.modelValue as any)
        .trigger("change");
    }
  },
  async mounted() {
    var vm = this;
    let select = this.$el as HTMLSelectElement;
    await this.$nextTick();
    $(select)
      .select2({ data: this.data, width: "100%" })
      .val(this.modelValue as any)
      .trigger("change")
      // emit event on change.
      .on("change", function () {
        if (!isNaN(vm.modelValue as any)) vm.$emit("update:modelValue", Number(this.value));
        else vm.$emit("update:modelValue", this.value);
      });
  },
  beforeUnmount() {
    let select = this.$el as HTMLSelectElement;
    $(select).off().select2("destroy");
  }
});
</script>
