export interface DocumentoComprobacionIngresos {
  nombre: string;
  requerido: boolean;
}

export interface IngresoGeneral {
  monto: number;
  concepto: string;
  esSocio: boolean | undefined;
}

export interface IngresoNomina {
  empresa: string;
  actividad: string;
  telefono: string;
  extension: string;
  correo: string;
  puesto: string;
  otroPuesto: string;
  antiguedad: number;
  nombreJefe: string;
  telefonoJefe: string;
  extensionJefe: string;
  sueldoBruto: number;
  isrImss: number;
  sueldoNeto: number;
}

export function generarComprobacionIngresos() {
  return {
    cantidadNomina: 0,
    nomina: [] as IngresoNomina[],
    numeroNominaActual: 0,
    cantidadNegocioPropio: 0,
    negocioPropio: [] as IngresoGeneral[],
    cantidadActividadEmpresarial: 0,
    actividadEmpresarial: [] as IngresoGeneral[],
    cantidadPension: 0,
    pension: [] as IngresoGeneral[],
    cantidadArrendamiento: 0,
    arrendamiento: [] as IngresoGeneral[],
    cantidadConcesion: 0,
    concesion: [] as IngresoGeneral[],
    cantidadOtro: 0,
    otro: [] as IngresoGeneral[],
    sumaTotal: 0,
    sueldoNetoTotal: 0,
    documentosNomina: undefined as DocumentoComprobacionIngresos[] | undefined,
    documentosActividadEmpresarial: undefined as DocumentoComprobacionIngresos[] | undefined,
    documentosPension: undefined as DocumentoComprobacionIngresos[] | undefined,
    documentosNegocioPropio: undefined as DocumentoComprobacionIngresos[] | undefined,
    documentosArrendamiento: undefined as DocumentoComprobacionIngresos[] | undefined,
    documentosConcesion: undefined as DocumentoComprobacionIngresos[] | undefined,
    documentosOtro: undefined as DocumentoComprobacionIngresos[] | undefined
  };
}

export type ComprobacionIngresos = ReturnType<typeof generarComprobacionIngresos>;
