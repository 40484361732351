<template lang="pug">
router-view
</template>

<script>
import { watch, watchEffect, ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStoreTitular } from "../storeTitular";
/**
 * Router : Navegación crear
 * Route : Rutas obtener
 */
export default {
  props: ["figura"],

  setup(props) {
    // const router = useRouter();
    // console.log("Router", router);
    const asignarFigura = ref();
    const mifigura = ref(props.figura);
    const route = useRoute();
    const router = useRouter();
    const store = useStoreTitular();
    const numero = ref();

    watch(route, () => {
      if (route.params.figura == undefined) return;
      if (route.query.operacionId) {
        asignarFiguraInicial();
        store.operacionTitulacionId = route.query.operacionId;
        store.segundoToken(route.query.operacionId);
      } else {
        router.replace({ query: {} });
        asignarFiguraInicial();
        store.ObtenerOperacionesAtivasSesion();
      }
    });

    const asignarFiguraInicial = () => {
      store.figuraActual = route.params.numero;
    };

    return {};
  }
};
</script>
