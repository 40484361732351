<template lang="pug">
.card: .card-body
  .row
    .col-md-12: p.fw-bold.h3(style="color: #2f2d74") Cargar Documentación
    .col-md-12: .row
      .col-md-8.mt-2: p(style="color: #2f2d74") Descarga y llena el&#32;
        a.text-primary.fw-bold.text-decoration-underline(href="cliente/cliente/pdf/formato/ReclamacionVida.pdf" target="_ blank") Formato de Reclamación Vida&#32;
        span con firma autógrafa
      .col-md-2: label.btn.btn-primary.text-white
        span.fas.fa-upload &#32;
        input(type="file" style="display: none" v-on:change="guardarDocuemento($event, 0)")
        | Cargar
      .col-md-2: label.gb-white.text-muted(v-if="mostrarEnlace(0)")
        span.fas.fa-eye &#32;
        a.text-muted.text-decoration-none(:href="mostrarDocumento(0)" target="_blank") Ver
      .col-md-8.mt-2: p(style="color: #2f2d74") Copia certificada del acta de defunción
      .col-md-2: label.btn.btn-primary.text-white
        span.fas.fa-upload &#32;
        input(type="file" style="display: none" v-on:change="guardarDocuemento($event, 1)")
        | Cargar
      .col-md-2: label.gb-white.text-muted(v-if="mostrarEnlace(1)")
        span.fas.fa-eye &#32;
        a.text-muted.text-decoration-none(:href="mostrarDocumento(1)" target="_blank") Ver
      .col-md-8.mt-2: p(style="color: #2f2d74") Copia certificada del acta de nacimiento del asegurado y beneficiarios
      .col-md-2: label.btn.btn-primary.text-white
        span.fas.fa-upload &#32;
        input(type="file" style="display: none" v-on:change="guardarDocuemento($event, 2)")
        | Cargar
      .col-md-2: label.gb-white.text-muted(v-if="mostrarEnlace(2)")
        span.fas.fa-eye &#32;
        a.text-muted.text-decoration-none(:href="mostrarDocumento(2)" target="_blank") Ver
      .col-md-8.mt-2: p(style="color: #2f2d74") Copia simple del certificado de defunción
      .col-md-2: label.btn.btn-primary.text-white
        span.fas.fa-upload &#32;
        input(type="file" style="display: none" v-on:change="guardarDocuemento($event, 3)")
        | Cargar
      .col-md-2: label.gb-white.text-muted(v-if="mostrarEnlace(3)")
        span.fas.fa-eye &#32;
        a.text-muted.text-decoration-none(:href="mostrarDocumento(3)" target="_blank") Ver
      .col-md-8.mt-2: p(style="color: #2f2d74") Copia de identificación oficial del asegurado y beneficiarios
      .col-md-2: label.btn.btn-primary.text-white
        span.fas.fa-upload &#32;
        input(type="file" style="display: none" v-on:change="guardarDocuemento($event, 4)")
        | Cargar
      .col-md-2: label.gb-white.text-muted(v-if="mostrarEnlace(4)")
        span.fas.fa-eye &#32;
        a.text-muted.text-decoration-none(:href="mostrarDocumento(4)" target="_blank") Ver
      .col-md-8.mt-2: p(style="color: #2f2d74") Copia de comprobante de domicilio del asegurado y beneficiarios
      .col-md-2: label.btn.btn-primary.text-white
        span.fas.fa-upload &#32;
        input(type="file" style="display: none" v-on:change="guardarDocuemento($event, 5)")
        | Cargar
      .col-md-2: label.gb-white.text-muted(v-if="mostrarEnlace(5)")
        span.fas.fa-eye &#32;
        a.text-muted.text-decoration-none(:href="mostrarDocumento(5)" target="_blank") Ver
      .col-md-8.mt-2: p(style="color: #2f2d74") Copia certificada del acta de matrimonio(En Caso de Aplicar)
      .col-md-2: label.btn.btn-primary.text-white
        span.fas.fa-upload &#32;
        input(type="file" style="display: none" v-on:change="guardarDocuemento($event, 6)")
        | Cargar
      .col-md-2: label.gb-white.text-muted(v-if="mostrarEnlace(6)")
        span.fas.fa-eye &#32;
        a.text-muted.text-decoration-none(:href="mostrarDocumento(6)" target="_blank") Ver
      .col-md-8.mt-2
        .form-check.form-check-inline
          input.form-check-input(type="checkbox" :id="componentId + 'menorEdad'" :name="componentId + 'menorEdad'" :value="1" v-model="opcionales")
          label.form-check-label(:for="componentId + 'menorEdad'" style="color: #2f2d74") El Beneficiario es menor de edad
        p(style="color: #2f2d74" v-if="mostrarMenor") Carta del tutor legal solicitando el pago en representación del menor
      .col-md-2: label.btn.btn-primary.text-white(v-if="mostrarMenor")
        span.fas.fa-upload &#32;
        input(type="file" style="display: none" v-on:change="guardarDocuemento($event, 7)")
        | Cargar
      .col-md-2: label.gb-white.text-muted(v-if="mostrarEnlace(7)")
        span.fas.fa-eye &#32;
        a.text-muted.text-decoration-none(:href="mostrarDocumento(7)" target="_blank") Ver
      .col-md-8.mt-2
        .form-check.form-check-inline
          input.form-check-input(
            type="checkbox"
            :id="componentId + 'muerteAccidental'"
            :name="componentId + 'muerteAccidental'"
            :value="2"
            v-model="opcionales"
          )
          label.form-check-label(:for="componentId + 'muerteAccidental'" style="color: #2f2d74") Muerte Accidental
        p(style="color: #2f2d74" v-if="mostrarMuerte") Actuaciones completas ante el ministerio público con reconocimiento de cuerpo, necropsia y estudios toxicológicos.
      .col-md-2.mt-2: label.btn.btn-primary.text-white(v-if="mostrarMuerte")
        span.fas.fa-upload &#32;
        input(type="file" style="display: none" @change="guardarDocuemento($event, 8)")
        | Cargar
      .col-md-2: label.gb-white.text-muted(v-if="mostrarEnlace(8)")
        span.fas.fa-eye &#32;
        a.text-muted.text-decoration-none(:href="mostrarDocumento(8)" target="_blank") Ver
    .col-md-5
    .col-md-2: p.text-center.btn.btn-primary.bg-primary.text-white(@click="enviarNotificacion()") Enviar Documentos
    .col-md-5
    Spinner-Loader#spinner(name="spinner")
</template>
<script lang="ts" setup>
import { ref, onMounted, watch, nextTick } from "vue";
import * as utilidades from "@/utilidades";
import api from "@/servicios/apiCliente";
import { useStore } from "@/store";
import type { Cliente } from "@/servicios/api";
const $store = useStore();
const componentId = utilidades.generarId();
const mostrarMenor = ref(false);
const mostrarMuerte = ref(false);
const opcionales = ref([] as number[]);
const documentosVida = ref([] as Cliente.Cliente.Siniestro.ListaDocumentos[]);
//#endregion Props
interface Props {
  Siniestro: Cliente.Cliente.Siniestro.ListaDocumentos[];
}
const props = withDefaults(defineProps<Props>(), {
  Siniestro: () => []
});
watch(opcionales, () => {
  mostrarMenor.value = opcionales.value.includes(1);
  mostrarMuerte.value = opcionales.value.includes(2);
});
function mostrarDocumento(index: number) {
  if (!documentosVida.value.length) return "";
  let nombre = documentosVida.value[index].nombreDocumento!;
  return (
    (import.meta.env.BASE_URL || "").replace(/\/$/g, "") +
    api.Cliente.Cliente.SiniestroApi.ObtenerDocumentosSiniestrosUrl +
    "?ruta=" +
    encodeURIComponent(nombre)
  );
}
function mostrarEnlace(index: number) {
  if (!documentosVida.value.length) return false;
  return documentosVida.value[index].nombreDocumento != null;
}
function mostrarOpcionales() {
  opcionales.value = [];
  mostrarMenor.value = false;
  mostrarMuerte.value = false;
  if (documentosVida.value[7].nombreDocumento != null) {
    opcionales.value.push(1);
    mostrarMenor.value = true;
  }
  if (documentosVida.value[8].nombreDocumento != null) {
    opcionales.value.push(2);
    mostrarMuerte.value = true;
  }
}
async function guardarDocuemento(event: Event, index: number) {
  let div = document.querySelector("#spinner") as HTMLDivElement;
  div.style.display = "block";
  let file = (event.target as HTMLInputElement).files![0] as File;
  let documentoSiniestroId = documentosVida.value[index].documentoSiniestroId!;
  let resp = await api.Cliente.Cliente.SiniestroApi.GuardarDocumentoSiniestro(file, documentoSiniestroId);
  documentosVida.value = resp.data.data.documentos!;
  mostrarOpcionales();
  div.style.display = "none";
  $store.agregarToast(resp.data);
}
async function enviarNotificacion() {
  let div = document.querySelector("#spinner") as HTMLDivElement;
  div.style.display = "block";
  let resp = await api.Cliente.Cliente.SiniestroApi.NotificarCambios(
    documentosVida.value[0].documentoSiniestroId!,
    {} as Cliente.Cliente.Siniestro.SiniestrosDañosPortalCliente
  );
  div.style.display = "none";
  $store.agregarToast(resp.data);
}
onMounted(async () => {
  documentosVida.value = props.Siniestro;
  mostrarOpcionales();
  let div = document.querySelector("#spinner") as HTMLDivElement;
  div.style.display = "none";
  await nextTick();
});
</script>
