<template lang="pug">
.container 
  //Spinner
  h6.text-danger.mb-3 Documentos faltantes por cargar: {{ documentosRechazados.reduce((prev, curr) => prev + curr) + documentosAsignados.reduce((prev, curr) => prev + curr) }}
  p(v-if="documentosPorEntidades.length == 0") El asesor en breve indicará los documentos necesarios para tú trámite
  .text-center.text-primary(v-if="!jsonCargado")
    .spinner-border(role="status")
      span.sr-only Cargando Checklist...
  #checklist.accordion(v-else)
    .accordion-item(v-for="(entidad, x) in documentosPorEntidades" :key="entidad")
      h2.accordion-header(:id="'panelsStayOpen-heading-' + entidad.entidad")
        button.accordion-button(
          type="button"
          data-bs-toggle="collapse"
          :data-bs-target="'#panelsStayOpen-' + entidad.entidad"
          aria-expanded="true"
          :aria-controls="'#panelsStayOpen-' + entidad.entidad"
        ) 
          p.font-weight-bold.text-secondary.mb-0.d-flex.align-items-center
            i.fa-solid.fa-angle-right.me-3.text-primary(:id="'flecha-' + entidad.entidad")
            .figura-collapse.me-5.text-secondary
              p.text-secondary.mb-0 {{ asignarEtiquetaEntidades(entidad.entidad) }}
          .contadores.w-100.d-flex.align-items-center.justify-content-end.text-secondary
            p.contador-title
            | Aceptado
            .grey.d-flex.align-items-center.justify-content-center.ms-2
              | {{ documentosAceptados[x] ? documentosAceptados[x] : 0 }}
            p.ms-3.contador-title
            | Rechazado
            .grey.d-flex.align-items-center.justify-content-center.ms-2
              | {{ documentosRechazados[x] ? documentosRechazados[x] : 0 }}
            p.ms-3.contador-title
            | Asignado
            .grey.d-flex.align-items-center.justify-content-center.ms-2
              | {{ documentosAsignados[x] ? documentosAsignados[x] : 0 }}
      .accordion-collapse.collapse(:aria-labelledby="'panelsStayOpen-heading-' + entidad.entidad" :id="'panelsStayOpen-' + entidad.entidad")
        .accordion-body.py-2(v-for="categoria in entidad.documentosPorCategoria" :key="categoria")
          strong.text-secondary {{ asignarEtiquetaCategoria(categoria.categoria) }}
          br
          .inner-documento.d-flex.justify-content-between.text-secondary.align-items-center.my-1(v-for="documento in categoria.documentos" :key="documento")
            .left
              | {{ documento.nombre }}
            .center.inner-options.center.d-flex.justify-content-between.align-items-center.text-center.ps-1
              button.btn.btn-link(
                :id="'spinner-' + documento.documentoTramiteTitulacionId"
                type="button"
                :class="documento.guardado || documento.estatus == 0 ? `text-primary spinner-${documento.documentoTramiteTitulacionId}` : `text-info spinner-${documento.documentoTramiteTitulacionId}`"
                @click="observarDocumentoTitular(entidad.entidad, documento.documentoTramiteTitulacionId, categoria.categoria)"
                :disabled="documento.estatus == 0 || documento.estatus == 1 || documento.estatus == 2 ? false : true"
              )
                i.fa-solid.fa-eye.me-2
                | Ver
              button.btn.btn-link.text-primary.spinner-hide(type="button" disabled="" :class="'spinner-' + documento.documentoTramiteTitulacionId")
                span.spinner-border.spinner-border-sm(role="status" aria-hidden="true")
                |
              // Button trigger modal
              button.btn.btn-link.me-3(
                type="button"
                data-bs-toggle="modal"
                :data-bs-target="'#' + componentId + 'modalChecklist'"
                v-if="documento.estatus == 2 || documento.estatus == 4"
                :class="documento.guardado || documento.guardado == null || documento.estatus == 2 || documento.estatus == 4 ? 'text-primary' : 'text-info'"
                :disabled="documento.estatus == 2 || documento.estatus == 4 ? false : true"
                @click="parametrosAcargar(documento.documentoTramiteTitulacionId, categoria.categoria, entidad.entidad, documento.comentario)"
              )
                i.fa-solid.fa-arrow-up-from-bracket.me-2
                | Cargar
            .right.d-flex.justify-content-between.align-items-center.text-center.pe-5
              .text-primary.rounded-pill.py-1.px-3(href="" :class="documento.estatus == 1 ? 'verde' : documento.estatus == 2 ? 'rojo' : ' '") 
                | {{ documento.estatus == 1 ? "Aceptado" : documento.estatus == 2 ? "Rechazado" : "" }}
              button.btn.btn-link.text-danger.d-flex.justify-content-between.align-items-center.text-center(
                type="button"
                data-bs-toggle="modal"
                :data-bs-target="'#' + componentId + 'modalComentario'"
                v-if="documento.comentario && documento.estatus !== 1 && documento.estatus !== 0"
                @click="parametrosAcargar(documento.documentoTramiteTitulacionId, categoria.categoria, entidad.entidad, documento.comentario)"
              )
                i.fa-solid.fa-message.fs-5.me-2

// Modal
.modal.fade(tabindex="-1" aria-labelledby="modalChecklistLabel" aria-hidden="true" :id="componentId + 'modalChecklist'")
  .modal-dialog.modal-dialog-centered
    .modal-content
      .modal-header
        .modal-title 
          h6 Cargar documento
      .modal-body
        input(type="file" name="" accept="image/*,.pdf,.zip" :disabled="storeDocumentos.cargando" :id="componentId + 'archivoUsuario'")
      .modal-footer
        button.btn.btn-outline-primary(
          type="button"
          data-bs-dismiss="modal"
          @click="funcionConsumirJSON(estudioSocioeconomicoId)"
          :disabled="storeDocumentos.cargando"
        ) Cerrar
        button.btn.btn-primary.text-white(
          v-if="!storeDocumentos.cargando"
          type="button"
          @click="guardarDocumento(documentoAcargar, categoriaAcargar, entidadAcargar)"
        ) Cargar
        button.btn.btn-primary.text-white(v-else type="button" disabled="")
          span.spinner-border.spinner-border-sm(role="status" aria-hidden="true")
          |
          | Cargando...
.modal.fade(tabindex="-1" aria-labelledby="modalComentarioLabel" aria-hidden="true" :id="componentId + 'modalComentario'")
  .modal-dialog
    .modal-content
      .modal-header
      .modal-body
        | {{ comentarioAcargar }}
      .modal-footer
        button.btn.btn-outline-primary(type="button" data-bs-dismiss="modal") Cerrar
</template>

<script lang="ts">
import { ref, watch } from "vue";
import api from "@/servicios/apiCliente";
import * as bootstrap from "bootstrap";
import { useStoreDocumentos } from "@/Aplicaciones/Cliente/Areas/Cliente/storeDocumentos";
import { useStoreTitular } from "@/Aplicaciones/Cliente/Areas/Cliente/storeTitular";
import { useRoute, useRouter } from "vue-router";
import * as utilidades from "@/utilidades";
import { useStore } from "@/store";
import { TipoBootstrap } from "@/interfaces";

export default {
  props: ["datos"],

  methods: {
    asignarEtiquetaEntidades(entidad) {
      let texto = "";
      this.entidadesConAcento.forEach(element => {
        if (element.nombre == entidad) {
          texto = element.texto;
        }
      });
      return texto;
    },
    asignarEtiquetaCategoria(categoria) {
      let texto = "";
      this.categoriasConAcento.forEach(element => {
        if (element.nombre == categoria) {
          texto = element.texto;
        }
      });
      return texto;
    }
  },

  methods: {
    asignarEtiquetaEntidades(entidad) {
      let texto = "";
      this.entidadesConAcento.forEach(element => {
        if (element.nombre == entidad) {
          texto = element.texto;
        }
      });
      return texto;
    },
    asignarEtiquetaCategoria(categoria) {
      let texto = "";
      this.categoriasConAcento.forEach(element => {
        if (element.nombre == categoria) {
          texto = element.texto;
        }
      });
      return texto;
    }
  },
  setup(props) {
    //VARIABLES
    const componentId = ref(utilidades.generarId());

    const misDatos = ref(props.datos);
    const figuraObtenida = ref({});
    const documentosTotales = ref([]);
    const documentosAceptados = ref([0]);
    const documentosRechazados = ref([0]);
    const documentosOpcionales = ref([]);
    const documentosAsignados = ref([0]);
    const documentosPorEntidades = ref({});
    const entidadesConAcento = ref([
      { nombre: "CONYUGE", texto: "Cónyuge" },
      { nombre: "CLIENTE_INMUEBLE_GARANTIA", texto: "Cliente inmueble garantía" },
      { nombre: "CLIENTE_PERSONA_MORAL", texto: "Cliente persona moral" },
      { nombre: "CONYUGE_VENDEDOR", texto: "Cónyugue vendedor" },
      { nombre: "CLIENTE", texto: "Cliente" },
      { nombre: "VENDEDOR_INMUEBLE_GARANTIA", texto: "Vendedor inmueble garantía" },
      { nombre: "OBLIGADO_SOLIDARIO", texto: "Obligado Solidario" },
      { nombre: "COTITULAR", texto: "Cotitular" },
      { nombre: "GARANTE_HIPOTECARIO", texto: "Garante hipotecario" },
      { nombre: "CONYUGE_GARANTE_HIPOTECARIO", texto: "Cónyugue garante hipotecario" },
      { nombre: "VENDEDOR", texto: "Vendedor" },
      { nombre: "CLIENTE_PERSONA_MORAL_APODERADO", texto: "Cliente personal moral apoderado" },
      { nombre: "CLIENTE_PERSONA_MORAL_REPRESENTANTE_LEGAL", texto: "Cliente persona moral representante legal" },
      { nombre: "VENDEDOR_PERSONA_MORAL", texto: "Vendedor personal moral" },
      { nombre: "VENDEDOR_PERSONA_MORAL_REPRESENTANTE_LEGAL", texto: "Vendedor persona moral representante legal" },
      { nombre: "COTITULAR_CONYUGE", texto: "Cotitular cónyugue" }
    ]);
    const categoriasConAcento = ref([
      { nombre: "COMPROBANTE_DE_IDENTFICACION_PERSONA_FISICA", texto: "Comprobante de indentificación persona física" },
      { nombre: "DOCUMENTOS_GENERALES_DE_PERSONA_FISICA", texto: "Documentos generales de persona física" },
      { nombre: "DOCUMENTOS_DEL_INMUEBLE", texto: "Documentos del inmueble" },
      { nombre: "COMPROBANTE_DE_INGRESOS", texto: "Comprobante de ingresos" },
      { nombre: "BURO_DE_CREDITO", texto: "Buró de crédito" },
      { nombre: "FORMATO_INTERNO", texto: "Formato interno" },
      { nombre: "COMPROBANTE_DE_PAGO", texto: "Comprobante de pago" },
      { nombre: "DOCUMENTOS_GENERALES_DE_PERSONA_MORAL", texto: "Documentos generales de persona moral" },
      { nombre: "OTROS", texto: "Otros" }
    ]);
    const estudioSocioeconomicoId = ref("");
    const documentoAcargar = ref("");
    const categoriaAcargar = ref("");
    const comentarioAcargar = ref("");
    const entidadAcargar = ref("");
    const cargandoDocumento = ref(false);
    const jsonCargado = ref(false);
    const storeDocumentos = useStoreDocumentos();
    const storeTitular = useStoreTitular();
    const route = useRoute();
    const router = useRouter();

    //METODOS

    const parametrosAcargar = (docuID, categoria, entidad, comentario) => {
      documentoAcargar.value = docuID;
      categoriaAcargar.value = categoria;
      entidadAcargar.value = entidad;
      comentarioAcargar.value = comentario;
    };

    const observarDocumentoTitular = async (entidad, docID, categoria) => {
      const target = document.getElementsByClassName(`spinner-${docID}`);
      target[0].classList.toggle("spinner-hide");
      target[1].classList.toggle("spinner-show");

      const documento = await api.Cliente.Cliente.EstudioSocioEconomicoApi.ObtenerDocumento(
        storeTitular.estudioSocioeconomicoId,
        storeTitular.figuraActual,
        entidad,
        docID,
        categoria
      );

      const url = URL.createObjectURL(documento.data);
      window.open(url);
      URL.revokeObjectURL(url);
      target[0].classList.toggle("spinner-hide");
      target[1].classList.toggle("spinner-show");
    };

    const cerrarModal = () => {
      const miModal = document.getElementById(`${componentId.value}modalChecklist`);
      const modal = bootstrap.Modal.getInstance(miModal);
      modal.hide();
    };

    const guardarDocumento = async (docID, categoria, entidad) => {
      const documento = document.getElementById(`${componentId.value}archivoUsuario`).files;
      if (
        (documento[0].type == "application/pdf" ||
          documento[0].type == "image/jpeg" ||
          documento[0].type == "application/zip" ||
          documento[0].type == "zip" ||
          documento[0].type == "application/x-zip" ||
          documento[0].type == "application/x-zip-compressed" ||
          documento[0].type == "image/png") &&
        documento[0].size <= 100000000
      ) {
        cargandoDocumento.value = storeDocumentos.cargando;
        await storeDocumentos.cargarDocumento(
          documento[0],
          docID,
          storeTitular.figuraActual,
          storeTitular.estudioSocioeconomicoId,
          categoria,
          entidad
        );

        cerrarModal();
        document.getElementById(`${componentId.value}archivoUsuario`).value = "";
        cargandoDocumento.value = storeDocumentos.cargando;
        iniciarChecklist();
      } else {
        cargandoDocumento.value = storeDocumentos.cargando;
        cerrarModal();
        useStore().agregarToast({
          tipo: TipoBootstrap.DANGER,
          body: "El archivo no es compatible."
        });
      }
    };

    const iniciarChecklist = async () => {
      await funcionConsumirJSON(storeTitular.estudioSocioeconomicoId);
    };

    const funcionConsumirJSON = async estudioSocioeconomicoId => {
      let data;
      if (storeTitular.figuraActual == 0) {
        await storeDocumentos.consumirDocumentos();
        data = storeDocumentos.datos;
      } else {
        const objFiguras = await api.Cliente.Cliente.EstudioSocioEconomicoApi.ObtenerDocumentosFigura(
          estudioSocioeconomicoId,
          storeTitular.figuraActual
        );
        data = objFiguras.data;
      }

      jsonCargado.value = true;
      colocarContador(data);
    };

    const colocarContador = data => {
      documentosPorEntidades.value = data;
      documentosAceptados.value = [0];
      documentosRechazados.value = [0];
      documentosAsignados.value = [0];
      let indexGuardado = 0;
      let sumaObjetos = 0;
      let guardados = 0;
      let pendientes = 0;
      let opcionales = 0;
      let asignados = 0;
      let indexCounter = 0;
      documentosPorEntidades.value.forEach((entidad, index) => {
        if (indexGuardado == index) {
          entidad.documentosPorCategoria.forEach(categoria => {
            sumaObjetos = sumaObjetos + categoria.documentos.length;
            categoria.documentos.forEach(element => {
              if (indexCounter == index) {
                if (element.estatus == 1) {
                  guardados = guardados + 1;
                  documentosAceptados.value[indexCounter] = guardados;
                } else if (element.estatus == 2) {
                  pendientes = pendientes + 1;
                  documentosRechazados.value[indexCounter] = pendientes;
                } else if (element.estatus == 3) {
                  opcionales = opcionales + 1;
                  documentosOpcionales.value[indexCounter] = opcionales;
                } else if (element.estatus == 4) {
                  asignados = asignados + 1;
                  documentosAsignados.value[indexCounter] = asignados;
                }
              }
            });
          });

          documentosTotales.value[index] = sumaObjetos;
          sumaObjetos = 0;
          indexCounter = indexCounter + 1;
          guardados = 0;
          pendientes = 0;
          opcionales = 0;
          asignados = 0;
        } else {
          jsonCargado.value = false;
          entidad.documentosPorCategoria.forEach(categoria => {
            sumaObjetos = sumaObjetos + categoria.documentos.length;
          });
          indexGuardado = index;
        }
        indexGuardado = indexGuardado + 1;
      });
    };

    iniciarChecklist();

    watch(route, () => {
      documentosAceptados.value = [0];
      documentosRechazados.value = [0];
      documentosAsignados.value = [0];
      iniciarChecklist();
    });

    return {
      componentId,
      guardarDocumento,
      observarDocumentoTitular,
      funcionConsumirJSON,
      iniciarChecklist,
      colocarContador,
      misDatos,
      figuraObtenida,
      documentosTotales,
      documentosAceptados,
      documentosRechazados,
      documentosOpcionales,
      documentosAsignados,
      estudioSocioeconomicoId,
      documentosPorEntidades,
      entidadesConAcento,
      categoriasConAcento,
      parametrosAcargar,
      documentoAcargar,
      categoriaAcargar,
      entidadAcargar,
      cerrarModal,
      cargandoDocumento,
      jsonCargado,
      storeDocumentos,
      comentarioAcargar,
      storeTitular,
      route,
      router,
      estudioSocioeconomicoId,
      documentosPorEntidades,
      entidadesConAcento,
      categoriasConAcento,
      parametrosAcargar,
      documentoAcargar,
      categoriaAcargar,
      entidadAcargar,
      cerrarModal,
      cargandoDocumento,
      jsonCargado,
      storeDocumentos,
      comentarioAcargar,
      storeTitular,
      route,
      router
    };
  }
};
</script>

<style scoped>
input[type="file"] {
  width: 100%;
}

a,
button {
  text-decoration: none;
}
.rojo {
  color: #f42222 !important;
  border: 2px solid #f42222;
  background: #fddede;
  font-size: 12px;
  font-weight: 600;
  font-size: 12px;
  font-weight: 600;
}
.verde {
  color: #7cc465 !important;
  border: 2px solid #7cc465;
  background: #ebf6e8;
  font-size: 12px;
  font-weight: 600;
  font-size: 12px;
  font-weight: 600;
}

.azul {
  color: blue !important;
}
.amarillo {
  color: yellow !important;
}

.fa-angle-right,
.fa-angle-down {
  font-size: 1.5rem;
}
.grey {
  background: #eceff2 !important;
  height: 28px;
  width: 46px;
}
button.accordion-button {
  font-weight: 500;
}
.accordion-button::after {
  background-image: none !important;
}
.inner-options a {
  transition: all 0.3s ease;
}
/* .inner-options a:hover {
  transform: scale(1.1);
} */
.center {
  width: 25%;
}
.right {
  width: 25%;
}
.left {
  width: 50%;
}

#comentarioChecklist {
  font-size: 12px;
}

.spinner-hide {
  display: none;
}

.spinner-show {
  display: block;
}

.contadores {
  font-size: 13px;
}

.accordion-item {
  border: 1px solid #a1b5d8;
}
.accordion-item {
  outline: none !important;
  box-shadow: none !important;
}
button.accordion-button {
  border: none !important;
  box-shadow: none !important;
  background-color: none !important;
}

.accordion-button:not(.collapsed) {
  background-color: inherit !important;
}
p.font-weight-bold.text-secondary.mb-0.d-flex.align-items-center {
  width: 50%;
}

a.router-link-exact-active {
  color: #ffa000;
  font-weight: bold;
}
</style>
