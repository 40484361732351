<template lang="pug">
.container(:class="{ 'was-validated': formularioValidado }")
  .row.pt-4(v-if="mostrarHeaderFooter")
    .col-md-6: .card: .card-body
      .row
        .col-md-5
          b.form-label Cliente
          br
          span.text-primary.fw-bold {{ cliente.nombreCliente.value }}
        .col-md-4
          b.form-label Lugar
          br
          span.text-primary.fw-bold Ciudad de México
        .col-md-3
          b.form-label Fecha
          br
          span.text-primary.fw-bold {{ fechaActual }}
    .col-md-6: .card: .card-body
      .row
        .col-4.text-center
          b.form-label.font-weight-bold Ingresos
        .col-4.text-center
          b.form-label.font-weight-bold Egresos
        .col-4.text-center
          b.form-label.font-weight-bold Remanente
      .row
        .col-4.text-center
          span.text-primary.fw-bold {{ filtros.filtroDivisa(obligadoSolidarioTotalIngresos) }}
        .col-4.text-center
          span.text-primary.fw-bold {{ filtros.filtroDivisa(obligadoSolidarioTotalEgresos) }}
        .col-4.text-center
          span.text-primary.fw-bold {{ filtros.filtroDivisa(obligadoSolidarioTotalIngresos - obligadoSolidarioTotalEgresos) }}
  .row(v-if="header"): .col-md-12: p.mt-2.mb-0.fs-5.fw-bold.text-primary {{ header }}
  .row.mt-4(v-if="header"): .col-md-12.col-sm-12: .card: .card-body
    .row
      .col-4.text-center
        b.form-label.font-weight-bold Ingresos
      .col-4.text-center
        b.form-label.font-weight-bold Egresos
      .col-4.text-center
        b.form-label.font-weight-bold Remanente
    .row
      .col-4.text-center
        span.text-primary.fw-bold {{ filtros.filtroDivisa(obligadoSolidarioTotalIngresos) }}
      .col-4.text-center
        span.text-primary.fw-bold {{ filtros.filtroDivisa(obligadoSolidarioTotalEgresos) }}
      .col-4.text-center
        span.text-primary.fw-bold {{ filtros.filtroDivisa(obligadoSolidarioTotalIngresos - obligadoSolidarioTotalEgresos) }}
  .row: .col.pt-4.pb-4
    .accordion.shadow.mb-4(:id="componentId + 'acordionDatosObligadoSolidario'")
      .accordion-item
        h2.accordion-header: button.accordion-button.collapsed.fw-bold(data-bs-toggle="collapse" :data-bs-target="'#' + componentId + 'datosObligadoSolidario'") Datos Generales
        .accordion-collapse.collapse(:id="componentId + 'datosObligadoSolidario'" :data-bs-parent="'#' + componentId + 'acordionDatosObligadoSolidario'"): .accordion-body
          .row
            .col-md-3
              label.form-label Nombre:
              input.form-control(
                :form="componentId + 'form'"
                type="text"
                pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+"
                v-model="estudioSocioeconomicoObligadoSolidario.nombre.value"
              )
              .invalid-feedback Nombre invalido.
            .col-md-3
              label.form-label Apellido Paterno:
              input.form-control(
                :form="componentId + 'form'"
                type="text"
                pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+"
                v-model="estudioSocioeconomicoObligadoSolidario.apellidoPaterno.value"
              )
              .invalid-feedback Apellido Paterno invalido.
            .col-md-3
              label.form-label Apellido Materno:
              input.form-control(
                :form="componentId + 'form'"
                type="text"
                pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+"
                v-model="estudioSocioeconomicoObligadoSolidario.apellidoMeterno.value"
              )
              .invalid-feedback Apellido Materno invalido.
            .col-md-3
              label.form-label Parentesco:
              //-input.form-control(
                v-model="estudioSocioeconomicoObligadoSolidario.parentezco"
                :form="componentId + 'form'"
                type="text"
                pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+").invalid-feedback Parentesco invalido.
              select.form-select(v-model="estudioSocioeconomicoObligadoSolidario.parentezco.value")
                option(value="PADRE") PADRE/MADRE
                option(value="HIJO") HIJO/HIJA
                option(value="AMIGO") CONOCIDO
                option(value="OTRO") OTRO FAMILIAR
          .row
            .col-md-3
              label.form-label Nacionalidad:
              hir-select2(
                :form="componentId + 'form'"
                :data="catalogos.paises.value.map(a => ({ id: a.paisId, text: (a.gentilicio || '').trim() || a.descripcion }))"
                v-model="estudioSocioeconomicoObligadoSolidario.paisNacionalidadId.value"
              )
            //-.col-md-3
              label.form-label C.P.:
              input.form-control(:form="componentId + 'form'" v-model="estudioSocioeconomicoObligadoSolidario.codigoPostal" type="text" pattern="[0-9]{5}")
              .invalid-feedback Colocar un código postal valido.
            //-.col-md-3
              label.form-label Municipio o Alcaldía:
              select.form-select(v-model="estudioSocioeconomicoObligadoSolidario.coloniaId")
                option(v-for="colonia in catalogos.colonias" :value="colonia.coloniaId") {{ colonia.colonia }}
            //-.col-md-3
              label.form-label Estado de Nacimiento:
              input.form-control(
                :form="componentId + 'form'"
                type="text"
                pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+"
                v-model="estudioSocioeconomicoObligadoSolidario.estadoNacimiento"
              )
              .invalid-feedback Estado de Nacimiento invalido.
              //- input.form-control(
                :form="componentId + 'form'"
                type="text"
                pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+"
                v-model="estudioSocioeconomicoObligadoSolidario.municipioAlcaldia").invalid-feedback Mucipio o Alcaldía invalido.
            .col-md-3 
              label.form-label Estado Civil:
              select.form-select(v-model="estudioSocioeconomicoObligadoSolidario.estadoCivil.value")
                option(v-for="ecivil in catalogos.estadoCivil.value" :value="ecivil") {{ ecivil.replace(/_/g, " ") }}
            .col-md-3
              label.form-label Régimen Matrimonial:
              select.form-select(v-model="estudioSocioeconomicoObligadoSolidario.regimenMatrimonial.value" :disabled="regimenMatrimonial")
                option(v-for="regimen in catalogos.regimenMatrimonial.value" :value="regimen") {{ regimen.replace(/_/g, " ") }}
            .col-md-3
              label.form-label RFC:
              input.form-control(
                type="text"
                pattern="[a-zA-Z,Ñ,&]{3,4}[0-9]{2}[0-1][0-9][0-3][0-9][a-zA-Z,0-9]?[a-zA-Z,0-9]?[0-9,a-zA-Z]?"
                v-model="estudioSocioeconomicoObligadoSolidario.rfc.value"
              )
              .invalid-feedback RFC invalido.
            .col-md-3
              label.form-label Fecha de Nacimiento:
              input.form-control(
                :form="componentId + 'form'"
                type="date"
                v-model="estudioSocioeconomicoObligadoSolidario.fechaNacimiento.value"
                min="1900-01-01"
                :max="fechaIso"
              )
              .invalid-feedback Fecha de Nacimiento invalida.
          .row.mt-2: .col-md-12.text-end
            .form.text-center.mt-2: button.btn.btn-primary.text-light.fw-bold(:form="componentId + 'form'") Guardar
    .accordion.shadow.mb-4(:id="componentId + 'acordionDomicilioObligadoSolidario'")
      .accordion-item
        h2.accordion-header: button.accordion-button.collapsed.fw-bold(
          data-bs-toggle="collapse"
          :data-bs-target="'#' + componentId + 'domicilioObligadoSolidario'"
        ) Domicilio Actual
        .accordion-collapse.collapse(
          :id="componentId + 'domicilioObligadoSolidario'"
          :data-bs-parent="'#' + componentId + 'acordionDomicilioObligadoSolidario'"
        ): .accordion-body
          .row
            .col-md-3
              label.form-label Calle:
              input.form-control(
                :form="componentId + 'form'"
                type="text"
                pattern="[0-9a-zA-ZñÑéÉáÁíÍóÓúÓ ]+"
                v-model="estudioSocioeconomicoObligadoSolidario.calle.value"
              )
              .invalid-feedback Calle invalida.
            .col-md-3
              label.form-label No. Exterior:
              input.form-control(
                :form="componentId + 'form'"
                type="text"
                pattern="^[a-zA-Z0-9 ]+"
                v-model="estudioSocioeconomicoObligadoSolidario.numExt.value"
              )
              .invalid-feedback No. Exterior inválido.
            .col-md-3
              label.form-label CP:
              input.form-control(
                :form="componentId + 'form'"
                type="text"
                pattern="^[0-9]{5}"
                v-model="estudioSocioeconomicoObligadoSolidario.cp.value"
                maxlength="5"
              )
              .invalid-feedback Codigo postal invalido.
            .col-md-3
              label.form-label Colonia:
              //- input.form-control(
                :form="componentId + 'form'"
                type="text"
                pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+"
                v-model="estudioSocioeconomicoObligadoSolidario.municipioAlcaldiaDocumento"
                )
                .invalid-feedback Municipio / Alcaldía invalido.
              select.form-select(v-model="estudioSocioeconomicoObligadoSolidario.coloniaIdOS.value")
                option(v-for="colonia in catalogos.coloniasOS.value" :value="colonia.coloniaId") {{ colonia.colonia }}
                .invalid-feedback Ingrese un codigo postal.
            //-.col-md-3
              label.form-label Ciudad:
              input.form-control(
                :form="componentId + 'form'"
                type="text"
                pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+"
                v-model="estudioSocioeconomicoObligadoSolidario.ciudad"
              )
              .invalid-feedback Ciudad invalida.
            .col-md-3
              label.form-label Municipio / Alcaldía:
              input.form-control(
                :form="componentId + 'form'"
                type="text"
                pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+"
                v-model="estudioSocioeconomicoObligadoSolidario.municipioAlcaldia.value"
              )
              .invalid-feedback Colonia invalida.
            .col-md-3
              label.form-label Estado:
              input.form-control(
                :form="componentId + 'form'"
                type="text"
                pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+"
                v-model="estudioSocioeconomicoObligadoSolidario.estadoDomicilio.value"
              )
              .invalid-feedback Estado invalido.
            .col-md-3
              label.form-label Tel. Particular:
              input.form-control(
                :form="componentId + 'form'"
                type="tel"
                pattern="[0-9]{10}"
                v-model="estudioSocioeconomicoObligadoSolidario.telParticular.value"
                maxlength="10"
              )
              .invalid-feedback Teléfono a 10 dígitos.
            .col-md-3
              label.form-label Tel. Celular:
              input.form-control(
                :form="componentId + 'form'"
                type="tel"
                pattern="[0-9]{10}"
                v-model="estudioSocioeconomicoObligadoSolidario.telCelular.value"
                maxlength="10"
              )
              .invalid-feedback Teléfono a 10 dígitos.
            .col-md-3
              label.form-label Correo Electrónico:
              input.form-control(
                :form="componentId + 'form'"
                type="email"
                pattern="[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?"
                v-model="estudioSocioeconomicoObligadoSolidario.correoElectronico.value"
              )
              .invalid-feedback Correo Electrónico invalido.
            .col-md-3
              label.form-label No. Dependientes Económicos:
              input.form-control(
                :form="componentId + 'form'"
                v-model="estudioSocioeconomicoObligadoSolidario.numDependientesEconomicos.value"
                type="text"
                pattern="^[0-9]+"
                min="0"
                maxlength="3"
                max="100"
              )
              .invalid-feedback No. Dependeintes Económicos invalido.
          .row.mt-2: .col-md-12.text-end
            .form.text-center.mt-2: button.btn.btn-primary.text-light.fw-bold(:form="componentId + 'form'") Guardar
    .accordion.shadow.mb-4(:id="componentId + 'acordionDatosVivienda'")
      .accordion-item
        h2.accordion-header: button.accordion-button.collapsed.fw-bold(data-bs-toggle="collapse" :data-bs-target="'#' + componentId + 'datosVivienda'") Datos de Vivienda
        .accordion-collapse.collapse(:id="componentId + 'datosVivienda'" :data-bs-parent="'#' + componentId + 'acordionDatosVivienda'"): .accordion-body
          .row
            .col-md-4
              label.form-label Actualmente su vivienda es:
              select.form-select(v-model="estudioSocioeconomicoObligadoSolidario.vivienda.value")
                option(:value="0") Propio
                option(:value="2") Familiar
                option(:value="3") Hipotecada
                option(:value="1") Rentada
            .col-md-4
              label.form-label Nombre del Arrendador:
              input.form-control(
                :form="componentId + 'form'"
                type="text"
                pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+"
                v-model="estudioSocioeconomicoObligadoSolidario.nombreArrendador.value"
                :required="requeridoViviendaActual"
              )
              .invalid-feedback Nombre del Arrendador invalido.
            .col-md-4
              label.form-label Teléfono del del Arrendador:
              input.form-control(
                :form="componentId + 'form'"
                type="tel"
                pattern="[0-9]{10}"
                maxlength="10"
                v-model="estudioSocioeconomicoObligadoSolidario.telFijoArrendador.value"
                :required="requeridoViviendaActual"
              )
              .invalid-feedback Teléfono a 10 dígitos.
            //- .col-md-3
              label.form-label Cel del Arrendador:
              input.form-control(
                :form="componentId + 'form'"
                type="tel"
                pattern="[0-9]{10}"
                v-model="estudioSocioeconomicoObligadoSolidario.telCelArrendador"
              )
              .invalid-feedback Tel. Cel del Arrendador invalido.
          .row.mt-2: .col-md-12.text-end
            .form.text-center.mt-2: button.btn.btn-primary.text-light.fw-bold(:form="componentId + 'form'") Guardar
    //-.accordion.shadow.mb-4(:id="this.componentId + 'acordionOcupacion'")
      .accordion-item
        h2.accordion-header: button.accordion-button.collapsed.fw-bold(data-bs-toggle="collapse" :data-bs-target="'#' + this.componentId + 'datosOcupacion'") Ocupación
        .accordion-collapse.collapse(:id="this.componentId + 'datosOcupacion'" :data-bs-parent="'#' + this.componentId + 'acordionOcupacion'"): .accordion-body
          .row
            .col-md-3
              label.form-label Ocupación Actual:
              select.form-select(v-model="estudioSocioeconomicoObligadoSolidario.ocupacionActual")
                option(value="1") Asalariado (Empleado)
                option(value="2") Profesional Independiente
                option(value="5") Arrendador o Inversionista
                option(value="4") Personas Físicas con Actividad Empresarial o RIF
                option(value="3") Jubilado o Pensionado
            .col-md-3
              label.form-label Especificación de Otro:
              input.form-control(
                :form="componentId + 'form'"
                type="text"
                pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+"
                v-model="estudioSocioeconomicoObligadoSolidario.otraOcupacion"
              )
              .invalid-feedback Especificación de Otro invalido.
            .col-md-3
              label.form-label Nombre de la Empresa:
              input.form-control(
                :form="componentId + 'form'"
                type="text"
                pattern="[0-9a-zA-ZñÑéÉáÁíÍóÓúÓ ]+"
                v-model="estudioSocioeconomicoObligadoSolidario.nombreEmpresa"
              )
              .invalid-feedback Nombre de la Empresa invalida.
            .col-md-3
              label.form-label Actividad de la Empresa:
              input.form-control(
                :form="componentId + 'form'"
                type="text"
                pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+"
                v-model="estudioSocioeconomicoObligadoSolidario.actividadEmpresa"
              )
              .invalid-feedback Actividad de la Empresa invalida.
          .row
            .col-md-3
              label.form-label Tel. de la Empresa:
              input.form-control(:form="componentId + 'form'" type="tel" pattern="[0-9]{10}" v-model="estudioSocioeconomicoObligadoSolidario.telEmpresa")
              .invalid-feedback Tel. de la Empresa invalido.
            .col-md-3
              label.form-label Extensión de la Empresa:
              input.form-control(:form="componentId + 'form'" type="text" pattern="^[0-9]+" v-model="estudioSocioeconomicoObligadoSolidario.numExtEmpresa")
              .invalid-feedback Extensión de la Empresa invalido.
            .col-md-3
              label.form-label Correo Electrónico del Trabajo:
              input.form-control(
                :form="componentId + 'form'"
                type="email"
                pattern="[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}"
                v-model="estudioSocioeconomicoObligadoSolidario.correoEmpresa"
              )
              .invalid-feedback Correo Electrónico del Trabajo invalido.
            .col-md-3
              label.form-label Puesto que Desempeña:
              input.form-control(type="text" pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+" v-model="estudioSocioeconomicoObligadoSolidario.puesto")
              .invalid-feedback Puesto que Desempeña invalido.
          .row
            .col-md-3
              label.form-label Nombre del Jefe Inmediato:
              input.form-control(
                :form="componentId + 'form'"
                type="text"
                pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+"
                v-model="estudioSocioeconomicoObligadoSolidario.nombreJefeDirecto"
              )
              .invalid-feedback Nombre del Jefe Inmediato invalido.
            .col-md-3
              label.form-label Profesión:
              select.form-select(v-model="estudioSocioeconomicoObligadoSolidario.profesion")
                option(v-for="prof in catalogos.profesiones" :value="prof.profesionId" :key="prof.profesionId") {{ prof.descripcion }}
            //- .col-md-3
              label.form-label Antigüedad Laboral (años):
              input.form-control(
                :form="componentId + 'form'"
                type="text"
                pattern="^[0-9]+"
                min="0"
                v-model="estudioSocioeconomicoObligadoSolidario.antiguedadAnios"
              )
              .invalid-feedback Antigüedad Laboral (años) invalido.
            .col-md-3
              label.form-label Antigüedad Laboral (meses):
              input.form-control(
                :form="componentId + 'form'"
                type="text"
                pattern="^[0-9]+"
                min="0"
                v-model="estudioSocioeconomicoObligadoSolidario.antiguedadMeses"
              )
              .invalid-feedback Antigüedad Laboral (meses) invalido.
          .row.mt-2: .col-md-12.text-end
            button.btn.btn-primary.text-light.fw-bold(:form="componentId + 'form'") Guardar
    informacion-laboral(
      :estudio-socioeconomico="estudioSocioeconomicoObligadoSolidario"
      :es-comprobacion-ingresos="esComprobacionIngresos"
      :comp-id="componentId"
      :catalogos="catalogos"
      @guardar="guardarEstudioSocioeconomico()"
    )
    //-.accordion.shadow.mb-4(:id="this.componentId + 'acordion4'")
      .accordion-item
        h2.accordion-header: button.accordion-button.collapsed.fw-bold(data-bs-toggle="collapse" :data-bs-target="'#' + this.componentId + 'ingresos'") Ingresos (En moneda nacional)
        .accordion-collapse.collapse(:id="this.componentId + 'ingresos'" :data-bs-parent="'#' + this.componentId + 'acordion4'"): .accordion-body
          //-.row
            .col-md-3
              label.form-label Sueldo Nominal Bruto:
              //-input.form-control(
                :form="componentId + 'form'"
                v-model="estudioSocioeconomicoObligadoSolidario.sueldoNominalBruto"
                type="text"
                pattern="^[0-9]+([.][0-9]+)?"
                min="0"
                max="999999999"
                step="any")
              autonumeric.form-control(:form="formId" v-model="estudioSocioeconomicoObligadoSolidario.sueldoNominalBruto" :configuracion="{ emptyInputBehavior: 'min', maximumValue: '99999999', minimumValue: '0', currencySymbol: '$ ' }")
            .col-md-3
              label.form-label ISR e IMSS:
              //- input.form-control(
                :form="componentId + 'form'"
                v-model="estudioSocioeconomicoObligadoSolidario.isrImss"
                type="text"
                pattern="^[0-9]+([.][0-9]+)?"
                min="0"
                m
                ax="999999999"
                step="any").invalid-feedback ISR e IMSS invalido.
              autonumeric.form-control(:form="formId" v-model="estudioSocioeconomicoObligadoSolidario.isrImss" :configuracion="{ emptyInputBehavior: 'min', maximumValue: '99999999', minimumValue: '0', currencySymbol: '$ ' }")
            .col-md-3
              label.form-label Sueldo Nominal Neto:
              //- input.form-control(v-model="estudioSocioeconomicoSueldoNominalNeto" readonly)
              autonumeric.form-control(:form="formId" v-model="estudioSocioeconomicoSueldoNominalNeto" :configuracion="{ emptyInputBehavior: 'min', maximumValue: '99999999', minimumValue: '0', currencySymbol: '$ ' }", readonly)
            .col-md-3
              label.form-label Gratificaciones:
              //- input.form-control(
                :form="componentId + 'form'"
                v-model="estudioSocioeconomicoObligadoSolidario.gratificaciones"
                type="text"
                pattern="^[0-9]+([.][0-9]+)?"
                min="0"
                max="999999999"
                step="any").invalid-feedback Gratificaciones invalidas.
              autonumeric.form-control(:form="formId" v-model="estudioSocioeconomicoObligadoSolidario.gratificaciones" :configuracion="{ emptyInputBehavior: 'min', maximumValue: '99999999', minimumValue: '0', currencySymbol: '$ ' }",)

          //-.row
            .col-md-3
              label.form-label Rentas:
              //- input.form-control(
                :form="componentId + 'form'"
                v-model="estudioSocioeconomicoObligadoSolidario.rentas"
                type="text"
                pattern="^[0-9]+([.][0-9]+)?"
                min="0"
                max="999999999"
                step="any").invalid-feedback Rentas invalidas.
              autonumeric.form-control(:form="formId" v-model="estudioSocioeconomicoObligadoSolidario.rentas" :configuracion="{ emptyInputBehavior: 'min', maximumValue: '99999999', minimumValue: '0', currencySymbol: '$ ' }",)
            .col-md-3
              label.form-label Comisiones:
              //- input.form-control(
                :form="componentId + 'form'"
                v-model="estudioSocioeconomicoObligadoSolidario.comisiones"
                type="text"
                pattern="^[0-9]+([.][0-9]+)?"
                min="0"
                max="999999999"
                step="any").invalid-feedback Comisiones invalidas.
              autonumeric.form-control(:form="formId" v-model="estudioSocioeconomicoObligadoSolidario.comisiones" :configuracion="{ emptyInputBehavior: 'min', maximumValue: '99999999', minimumValue: '0', currencySymbol: '$ ' }",)
            .col-md-3
              label.form-label Ingresos del Cónyuge:
              //- input.form-control(type="text" pattern="^[0-9]+([.][0-9]+)?" v-model="estudioSocioeconomicoObligadoSolidario.ingresosConyuge").invalid-feedback Ingresos del Cónyuge invalido.
              autonumeric.form-control(:form="formId" v-model="estudioSocioeconomicoObligadoSolidario.ingresosConyuge" :configuracion="{ emptyInputBehavior: 'min', maximumValue: '99999999', minimumValue: '0', currencySymbol: '$ ' }",)
            .col-md-3
              label.form-label Otros Ingresos:
              //- input.form-control(
                :form="componentId + 'form'"
                type="text"
                pattern="^[0-9]+([.][0-9]+)?"
                v-model="estudioSocioeconomicoObligadoSolidario.otrosIngresos").invalid-feedback Otros Ingresos invalido.
              autonumeric.form-control(:form="formId" v-model="estudioSocioeconomicoObligadoSolidario.otrosIngresos" :configuracion="{ emptyInputBehavior: 'min', maximumValue: '99999999', minimumValue: '0', currencySymbol: '$ ' }",)
          //-.row
            .col-md-3
              label.form-label Puede Comprar Ingresos:
              select.form-select
                option(:value="true") Si
                option(:value="false") No
            .col-md-3
              label.form-label Total de Ingresos Comprobables:
              //- input.form-control(
                :form="componentId + 'form'"
                type="text"
                pattern="^[0-9]+([.][0-9]+)?"
                v-model="estudioSocioeconomicoObligadoSolidario.totalIngresosComprobabes")
                .invalid-feedback Total de Ingresos Comprobables invalido.
              autonumeric.form-control(:form="formId" v-model="estudioSocioeconomicoObligadoSolidario.totalIngresosComprobabes" :configuracion="{ emptyInputBehavior: 'min', maximumValue: '99999999', minimumValue: '0', currencySymbol: '$ ' }",)
            .col-md-3
              label.form-label Total de Ingresos:
              //- input.form-control(readonly v-model="estudioSocioeconomicoTotalIngresos")
              autonumeric.form-control(:form="formId" v-model="estudioSocioeconomicoTotalIngresos" :configuracion="{ emptyInputBehavior: 'min', maximumValue: '99999999', minimumValue: '0', currencySymbol: '$ ' }",readonly)
          //-.row.mt-2: .col-md-12.text-end
            button.btn.btn-primary.text-light.fw-bold(:form="componentId + 'form'") Guardar
    .accordion.shadow.mb-4(:id="componentId + 'acordion6'")
      .accordion-item
        h2.accordion-header: button.accordion-button.collapsed.fw-bold(data-bs-toggle="collapse" :data-bs-target="'#' + componentId + 'gastos'") Gastos (En moneda nacional)
        .accordion-collapse.collapse(:id="componentId + 'gastos'" :data-bs-parent="'#' + componentId + 'acordion6'"): .accordion-body
          .row
            .col-md-3
              label.form-label Monto de los Préstamos:
              //-input.form-control(
                :form="componentId + 'form'"
                type="text"
                pattern="^[0-9]+([.][0-9]+)?"
                min="0"
                v-model="estudioSocioeconomicoObligadoSolidario.pagoMensualCreditos"
                )
                .invalid-feedback Monto de los Préstamos invalido.
              hir-autonumeric.form-control(
                :form="componentId + 'form'"
                v-model="estudioSocioeconomicoObligadoSolidario.pagoMensualCreditos.value"
                :configuracion="{ emptyInputBehavior: 'min', maximumValue: '99999999', minimumValue: '0', currencySymbol: '$ ' }"
              )
            .col-md-3
              label.form-label Monto de Deuda Tarjeta(s) de Crédito:
              //-input.form-control(
                :form="componentId + 'form'"
                type="text"
                pattern="^[0-9]+([.][0-9]+)?"
                min="0"
                v-model="estudioSocioeconomicoObligadoSolidario.tarjetaCredito"
                )
                .invalid-feedback Monto de Deuda Tarjeta(s) de Crédito invalido.
              hir-autonumeric.form-control(
                :form="componentId + 'form'"
                v-model="estudioSocioeconomicoObligadoSolidario.tarjetaCredito.value"
                :configuracion="{ emptyInputBehavior: 'min', maximumValue: '99999999', minimumValue: '0', currencySymbol: '$ ' }"
              )
            .col-md-3
              label.form-label Renta:
              //-input.form-control(
                :form="componentId + 'form'"
                type="text"
                pattern="^[0-9]+([.][0-9]+)?"
                min="0"
                v-model="estudioSocioeconomicoObligadoSolidario.renta"
                )
                .invalid-feedback Renta invalido.
              hir-autonumeric.form-control(
                :form="componentId + 'form'"
                v-model="estudioSocioeconomicoObligadoSolidario.renta.value"
                :configuracion="{ emptyInputBehavior: 'min', maximumValue: '99999999', minimumValue: '0', currencySymbol: '$ ' }"
              )
            .col-md-3
              label.form-label Colegiaturas:
              //-input.form-control(
                :form="componentId + 'form'"
                type="text"
                pattern="^[0-9]+([.][0-9]+)?"
                min="0"
                v-model="estudioSocioeconomicoObligadoSolidario.colegiaturas"
                )
                .invalid-feedback Colegiaturas invalido.
              hir-autonumeric.form-control(
                :form="componentId + 'form'"
                v-model="estudioSocioeconomicoObligadoSolidario.colegiaturas.value"
                :configuracion="{ emptyInputBehavior: 'min', maximumValue: '99999999', minimumValue: '0', currencySymbol: '$ ' }"
              )
          .row
            .col-md-3
              label.form-label Gasto Familiar:
              //- input.form-control(
                :form="componentId + 'form'"
                type="text"
                pattern="^[0-9]+([.][0-9]+)?"
                min="0"
                v-model="estudioSocioeconomicoObligadoSolidario.gastoFamiliar"
                )
                .invalid-feedback Gasto Familiar invalido.
              hir-autonumeric.form-control(
                :form="componentId + 'form'"
                v-model="estudioSocioeconomicoObligadoSolidario.gastoFamiliar.value"
                :configuracion="{ emptyInputBehavior: 'min', maximumValue: '99999999', minimumValue: '0', currencySymbol: '$ ' }"
              )
            .col-md-3
              label.form-label Otros Egresos:
              //- input.form-control(
                :form="componentId + 'form'"
                type="text"
                pattern="^[0-9]+([.][0-9]+)?"
                min="0"
                v-model="estudioSocioeconomicoObligadoSolidario.otrosEgresos"
                )
                .invalid-feedback Otros Egresos invalido.
              hir-autonumeric.form-control(
                :form="componentId + 'form'"
                v-model="estudioSocioeconomicoObligadoSolidario.otrosEgresos.value"
                :configuracion="{ emptyInputBehavior: 'min', maximumValue: '99999999', minimumValue: '0', currencySymbol: '$ ' }"
              )
            .col-md-3
              label.form-label Total Egresos:
              //-input.form-control(v-model="estudioSocioeconomicoTotalEgresos" readonly)
              hir-autonumeric.form-control(
                :form="componentId + 'form'"
                v-model="estudioSocioeconomicoTotalEgresos"
                :configuracion="{ emptyInputBehavior: 'min', maximumValue: '99999999', minimumValue: '0', currencySymbol: '$ ' }"
                readonly
              )
          .row.mt-2: .col-md-12.text-end
            .form.text-center.mt-2: button.btn.btn-primary.text-light.fw-bold(:form="componentId + 'form'") Guardar
    .accordion.shadow.mb-4(:id="componentId + 'acordion5'")
      .accordion-item
        h2.accordion-header: button.accordion-button.collapsed.fw-bold(data-bs-toggle="collapse" :data-bs-target="'#' + componentId + 'viviendaActual'") Propiedades
        .accordion-collapse.collapse(:id="componentId + 'viviendaActual'" :data-bs-parent="'#' + componentId + 'acordion5'"): .accordion-body
          .row
            .col-md-3
              label.form-label Relación Patrimonial Inmueble:
              input.form-control(
                :form="componentId + 'form'"
                type="text"
                pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+"
                v-model="estudioSocioeconomicoObligadoSolidario.relacionInmueble.value"
              )
              .invalid-feedback Relación Patrimonial Inmueble invalido.
            .col-md-3
              label.form-label Cantidad de Inmuebles:
              input.form-control(
                :form="componentId + 'form'"
                type="text"
                pattern="^[0-9]{1,2}"
                min="0"
                max="99"
                size="2"
                minlength="1"
                maxlength="2"
                v-model="estudioSocioeconomicoObligadoSolidario.cantidadInmueble.value"
              )
              .invalid-feedback Cantidad de Inmuebles invalido.
            .col-md-3
              label.form-label Valor Aproximado de Inmueble:
              //- input.form-control(
                :form="componentId + 'form'"
                type="text"
                pattern="^[0-9]+([.][0-9]+)?"
                v-model="estudioSocioeconomicoObligadoSolidario.valorAproximadoInmueble"
                )
                .invalid-feedback Valor Aproximado de Inmueble invalido.
              hir-autonumeric.form-control(
                :form="componentId"
                v-model="estudioSocioeconomicoObligadoSolidario.valorAproximadoInmueble.value"
                :configuracion="{ emptyInputBehavior: 'min', maximumValue: '99999999', minimumValue: '0', currencySymbol: '$ ' }"
              )
            .col-md-3
              label.form-label ¿Está Gravado?:
              select.form-select(v-model="estudioSocioeconomicoObligadoSolidario.gravadoInmueble.value")
                option(:value="true") Si
                option(:value="false") No
          .row
            .col-md-3
              label.form-label Relación Patrimonial Automóviles:
              input.form-control(
                :form="componentId + 'form'"
                type="text"
                pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+"
                v-model="estudioSocioeconomicoObligadoSolidario.relacionPatrimonialAutomoviles.value"
              )
              .invalid-feedback Relación Patrimonial Automóviles invalido.
            .col-md-3
              label.form-label Cantidad de Automóviles:
              input.form-control(
                :form="componentId + 'form'"
                type="text"
                pattern="^[0-9]{1,2}"
                min="0"
                max="99"
                size="2"
                minlength="1"
                maxlength="2"
                v-model="estudioSocioeconomicoObligadoSolidario.cantidadAutomoviles.value"
              )
              .invalid-feedback Cantidad de Automóviles invalido.
            .col-md-3
              label.form-label Valor Aproximado Automóviles:
              //- input.form-control(
                :form="componentId + 'form'"
                type="text"
                pattern="^[0-9]+([.][0-9]+)?"
                v-model="estudioSocioeconomicoObligadoSolidario.valorAproximadoAutomoviles"
                )
                .invalid-feedback Valor Aproximado Automóviles invalido.
              hir-autonumeric.form-control(
                :form="componentId"
                v-model="estudioSocioeconomicoObligadoSolidario.valorAproximadoAutomoviles.value"
                :configuracion="{ emptyInputBehavior: 'min', maximumValue: '99999999', minimumValue: '0', currencySymbol: '$ ' }"
              )
            .col-md-3
              label.form-label ¿Está Gravado?:
              select.form-select(v-model="estudioSocioeconomicoObligadoSolidario.gravadoAutomoviles")
                option(:value="true") Si
                option(:value="false") No
              .invalid-feedback invalido.
          .row.mt-2: .col-md-12.text-end: form(ref="formDom" novalidate :id="componentId + 'form'" @submit.prevent="guardarEstudioSocioeconomico()")
            .form.text-center.mt-2: button.btn.btn-primary.text-light.fw-bold(:form="componentId + 'form'") Guardar
    .text-center 
      button.btn.btn-success(:form="componentId + 'form'" v-on:click="estudioSocioeconomicoObligadoSolidario.notificacionAscesor.value = true") Enviar Datos
</template>

<style lang="scss">
.accordion-item {
  background-color: #ffffff;
}
</style>

<script lang="ts" setup>
import * as utilidades from "@/utilidades";
import InformacionLaboral from "./EstudioSocioeconomico/InformacionLaboral.vue";
import { provide, ref, reactive, toRefs, computed, watch, toRaw, onMounted, nextTick } from "vue";
import { useStore } from "@/store";
import api from "@/servicios/apiCliente";
import { TipoBootstrap } from "@/interfaces";
import * as ESUtilidades from "../composables/EstudioSocioeconomico";
import { Cliente, Core } from "@/servicios/api";
import filtros from "@/composables/filtros";
import HirAutonumeric from "@/components/HirAutonumeric.vue";
import { useStore as useClienteClienteStore } from "../store";
import { useRouter } from "vue-router";
import { debounce as _debounce } from "lodash";

//#region Data
const store = useClienteClienteStore();
const $store = useStore();
const $router = useRouter();
const componentId = utilidades.generarId();
const formularioValidado = ref(false);
const fechaIso = new Date().toISOString().substring(0, 10);
const fechaActual = new Intl.DateTimeFormat("es-MX", {
  day: "2-digit",
  month: "2-digit",
  year: "numeric"
}).format(new Date());
const formDom = ref(null as HTMLFormElement | null);
const catalogos = toRefs(
  reactive({
    estadoCivil: Core.Models.EstadoCivil,
    regimenMatrimonial: Core.Models.RegimenMatrimonial,
    profesiones: [] as Core.Models.Profesion[],
    colonias: [] as Cliente.Cliente.ObtenerColoniasBody[],
    coloniasOS: [] as Cliente.Cliente.ObtenerColoniasBody[],
    paises: [] as Core.Models.Pais[]
  })
);
const cliente = toRefs(
  reactive({
    nombreCliente: ""
  })
);
const reactiveEstudioSocioeconomicoObligadoSolidario = reactive({
  estudioSocioeconomicoId: "",
  clienteId: 0,
  nombre: "" as string | undefined,
  apellidoPaterno: "" as string | undefined,
  apellidoMeterno: "" as string | undefined,
  parentezco: undefined as string | undefined,
  fechaNacimiento: "" as string | undefined,
  nacionalidad: "" as string | undefined,
  estadoNacimiento: "" as string | undefined,
  municipioAlcaldia: "" as string | undefined,
  estadoCivil: undefined as Core.Models.EstadoCivil | undefined,
  regimenMatrimnial: undefined as Core.Models.RegimenMatrimonial | undefined,
  rfc: "" as string | undefined,
  calle: "" as string | undefined,
  numExt: "" as string | undefined,
  colonia: "" as string | undefined,
  estadoDomicilio: "" as string | undefined,
  municipioAlcaldiaDocumento: "" as string | undefined,
  ciudad: "" as string | undefined,
  cp: "",
  telParticular: "",
  telCelular: "",
  correoElectronico: "" as string | undefined,
  numDependientesEconomicos: 0,
  vivienda: undefined as number | undefined,
  nombreArrendador: "",
  telFijoArrendador: "" as string | undefined,
  telCelArrendador: "" as string | undefined,
  ocupacionActual: "",
  ocupacionActualArray: [] as number[],
  otraOcupacion: "" as string | undefined,
  nombreEmpresa: "" as string | undefined,
  actividadEmpresa: "" as string | undefined,
  telEmpresa: "" as string | undefined,
  numExtEmpresa: 0,
  correoEmpresa: "",
  puesto: "" as string | undefined,
  nombreJefeDirecto: "",
  profesion: undefined as number | undefined,
  antiguedadAnios: 0,
  antiguedadMeses: 0,
  ingresosConyuge: 0,
  otrosIngresos: 0,
  puedeComprarIngresos: false,
  relacionInmueble: "",
  cantidadInmueble: 0,
  valorAproximadoInmueble: 0,
  gravadoInmueble: false,
  relacionPatrimonialAutomoviles: "",
  cantidadAutomoviles: 0,
  valorAproximadoAutomoviles: 0,
  gravadoAutomoviles: false,
  regimenMatrimonial: undefined as Core.Models.RegimenMatrimonial | undefined,
  esAvalObligadoSolidario: false,
  esDeclaradoMoratoriaSuspensionPagos: false,
  esDemandadoIncumplimientoObligacionesFinancieras: false,
  estadoCivilVendedor: undefined as Core.Models.EstadoCivil | undefined,
  regimenMatrimonialVendedor: undefined as Core.Models.RegimenMatrimonial | undefined,
  paisNacionalidadId: 0,
  sueldoNominalBruto: 0,
  isrImss: 0,
  honorarios: 0,
  gratificaciones: 0,
  rentas: 0,
  comisiones: 0,
  totalIngresosComprobabes: 0,
  pagoMensualCreditos: 0,
  tarjetaCredito: 0,
  renta: 0,
  colegiaturas: 0,
  gastoFamiliar: 0,
  otrosEgresos: 0,
  sueldoNominalNeto: 0.0,
  totalIngresos: 0,
  totalEgresos: 0,
  codigoPostal: "",
  coloniaId: 0,
  coloniaIdOS: 0,
  notificacionAscesor: false,
  ingresos: ""
});
const estudioSocioeconomicoObligadoSolidario = toRefs(reactiveEstudioSocioeconomicoObligadoSolidario);
const esComprobacionIngresos = ESUtilidades.generarObjetoIngresos();
provide("ComprobacionIngresos", esComprobacionIngresos);
const obligadoSolidarioTotalIngresos = ref(0.0);
const obligadoSolidarioTotalEgresos = ref(0.0);
const aIngresos = ref([] as string[]);
//#endregion

//#region Props
interface Props {
  mostrarHeaderFooter?: boolean;
  llave?: string;
  header?: string;
}

const props = withDefaults(defineProps<Props>(), {
  mostrarHeaderFooter: true,
  llave: "",
  header: ""
});
//#endregion

//#region Computed
const estudioSocioeconomicoSueldoNominalNeto = computed((): number => {
  let es = estudioSocioeconomicoObligadoSolidario;
  let r = (es.sueldoNominalBruto?.value ?? 0) - (es.isrImss?.value ?? 0);
  return isNaN(r) ? 0 : r;
});
const estudioSocioeconomicoTotalIngresos = computed((): number => {
  let es = estudioSocioeconomicoObligadoSolidario;
  let r =
    Number(estudioSocioeconomicoSueldoNominalNeto.value) +
    Number(es.honorarios.value) +
    Number(es.gratificaciones.value) +
    Number(es.rentas.value) +
    Number(es.comisiones.value) +
    Number(es.otrosIngresos.value);
  return isNaN(r) ? 0 : r;
});
const estudioSocioeconomicoTotalEgresos = computed((): number => {
  let es = estudioSocioeconomicoObligadoSolidario;
  let r =
    Number(es.pagoMensualCreditos.value) +
    Number(es.tarjetaCredito.value) +
    Number(es.renta.value) +
    Number(es.colegiaturas.value) +
    Number(es.gastoFamiliar.value) +
    Number(es.otrosEgresos.value);
  return isNaN(r) ? 0 : r;
});
const regimenMatrimonial = computed((): boolean => {
  let es = estudioSocioeconomicoObligadoSolidario;
  es.regimenMatrimonial.value = undefined;
  es.regimenMatrimnial.value = undefined;
  if (es.estadoCivil.value == "CASADO") return false;
  return true;
});
const requeridoViviendaActual = computed((): boolean => {
  let es = estudioSocioeconomicoObligadoSolidario;
  if (es.vivienda.value == 1) return true;
  return false;
});
//#endregion

//#region Watch
watch(
  estudioSocioeconomicoObligadoSolidario.codigoPostal,
  _debounce((val: string) => {
    if (val) api.Cliente.Cliente.EscrituracionApi.ObtenerColonias(val).then(v => (catalogos.colonias.value = v.data));
    else catalogos.colonias.value = [];
  }, 300)
);

watch(
  estudioSocioeconomicoObligadoSolidario.cp,
  _debounce((val: string) => {
    if (val) {
      api.Cliente.Cliente.EscrituracionApi.ObtenerColonias(val).then(v => {
        catalogos.coloniasOS.value = v.data;
        if (val.length == 5 && v.data.length == 0) {
          $store.agregarToast({
            tipo: TipoBootstrap.DANGER,
            body: "Codigo postal invalido."
          });
        }
      });
    } else {
      catalogos.coloniasOS.value = [];
    }
  }, 300)
);
watch(estudioSocioeconomicoObligadoSolidario.coloniaId, () => {
  if (estudioSocioeconomicoObligadoSolidario.coloniaId.value > 0) {
    for (const oCol in catalogos.colonias.value) {
      if (catalogos.colonias.value[oCol].coloniaId == estudioSocioeconomicoObligadoSolidario.coloniaId.value) {
        estudioSocioeconomicoObligadoSolidario.estadoNacimiento.value = catalogos.colonias.value[oCol].estado;
        break;
      }
    }
  }
});
watch(estudioSocioeconomicoObligadoSolidario.coloniaIdOS, () => {
  if (estudioSocioeconomicoObligadoSolidario.coloniaIdOS.value > 0) {
    for (const oCol in catalogos.coloniasOS.value) {
      if (catalogos.coloniasOS.value[oCol].coloniaId == estudioSocioeconomicoObligadoSolidario.coloniaIdOS.value) {
        estudioSocioeconomicoObligadoSolidario.coloniaIdOS.value = catalogos.coloniasOS.value[oCol].coloniaId;
        estudioSocioeconomicoObligadoSolidario.colonia.value = catalogos.coloniasOS.value[oCol].colonia;
        estudioSocioeconomicoObligadoSolidario.municipioAlcaldia.value = catalogos.coloniasOS.value[oCol].estado;
        estudioSocioeconomicoObligadoSolidario.ciudad.value = catalogos.coloniasOS.value[oCol].ciudad;
        break;
      }
    }
  }
});
//#endregion

//#region Methods
async function guardarEstudioSocioeconomico() {
  let valido = formDom.value!.checkValidity();
  formularioValidado.value = true;
  if (!valido) {
    $store.agregarToast({
      tipo: TipoBootstrap.DANGER,
      body: "Llenar todos los campos correctamente."
    });
    return;
  }

  estudioSocioeconomicoObligadoSolidario.sueldoNominalNeto.value = estudioSocioeconomicoSueldoNominalNeto.value;
  estudioSocioeconomicoObligadoSolidario.totalIngresos.value = estudioSocioeconomicoTotalIngresos.value;
  estudioSocioeconomicoObligadoSolidario.totalEgresos.value = estudioSocioeconomicoTotalEgresos.value;

  if (estudioSocioeconomicoObligadoSolidario.fechaNacimiento.value === "")
    estudioSocioeconomicoObligadoSolidario.fechaNacimiento.value = undefined;
  aIngresos.value = [];
  verificarConceptos(esComprobacionIngresos.nomina.value, 1, esComprobacionIngresos.cantidadNomina.value);
  verificarConceptos(esComprobacionIngresos.negocioPropio.value, 2, esComprobacionIngresos.cantidadNegocioPropio.value);
  verificarConceptos(esComprobacionIngresos.pension.value, 3, esComprobacionIngresos.cantidadPension.value);
  verificarConceptos(
    esComprobacionIngresos.actividadEmpresarial.value,
    4,
    esComprobacionIngresos.cantidadActividadEmpresarial.value
  );
  verificarConceptos(esComprobacionIngresos.arrendamiento.value, 5, esComprobacionIngresos.cantidadArrendamiento.value);
  verificarConceptos(esComprobacionIngresos.concesion.value, 6, esComprobacionIngresos.cantidadConcesion.value);
  verificarConceptos(esComprobacionIngresos.otro.value, 7, esComprobacionIngresos.cantidadOtro.value);
  estudioSocioeconomicoObligadoSolidario.ingresos.value = JSON.stringify(aIngresos.value);
  let b = estudioSocioeconomicoObligadoSolidario.ocupacionActual.value;
  estudioSocioeconomicoObligadoSolidario.ocupacionActual.value = (
    estudioSocioeconomicoObligadoSolidario.ocupacionActualArray.value || []
  ).join(",");
  /*Convertimos en JSON */
  sumarTodosConceptos();
  /*Gurdar Datos */
  let estudio = toRaw(reactiveEstudioSocioeconomicoObligadoSolidario) as Cliente.Cliente.ObligadoSolidario;
  let r = await api.Cliente.Cliente.EscrituracionApi.GuardarEstudioSocioeconomicoObligadoSolidario(estudio);
  estudioSocioeconomicoObligadoSolidario.ocupacionActual.value = b;
  $store.agregarToast({ tipo: r.data.estado, body: r.data.mensaje! });
}
function sumarDatos(value: { monto: number }[]): number {
  let suma = 0;
  suma = value.reduce((prev: number, curr: { monto: number }) => prev + curr.monto, 0) || 0;
  return suma;
}
function sumarTodosConceptos() {
  estudioSocioeconomicoObligadoSolidario.rentas.value = sumarDatos(esComprobacionIngresos.arrendamiento.value);
  estudioSocioeconomicoObligadoSolidario.otrosIngresos.value = sumarDatos(esComprobacionIngresos.otro.value);
  estudioSocioeconomicoObligadoSolidario.comisiones.value = sumarDatos(esComprobacionIngresos.concesion.value);
  estudioSocioeconomicoObligadoSolidario.sueldoNominalNeto.value =
    (esComprobacionIngresos.sueldoNetoTotal.value ?? 0) +
    sumarDatos(esComprobacionIngresos.pension.value ?? [{ monto: 0 }]) +
    sumarDatos(esComprobacionIngresos.negocioPropio.value ?? [{ monto: 0 }]) +
    sumarDatos(esComprobacionIngresos.actividadEmpresarial.value ?? [{ monto: 0 }]);
  obligadoSolidarioTotalIngresos.value =
    estudioSocioeconomicoObligadoSolidario.sueldoNominalNeto.value +
    estudioSocioeconomicoObligadoSolidario.rentas.value +
    estudioSocioeconomicoObligadoSolidario.otrosIngresos.value +
    estudioSocioeconomicoObligadoSolidario.comisiones.value;
  estudioSocioeconomicoObligadoSolidario.sueldoNominalNeto.value = obligadoSolidarioTotalIngresos.value;
  calcularEgresos();
}
function calcularEgresos() {
  obligadoSolidarioTotalEgresos.value =
    estudioSocioeconomicoObligadoSolidario.pagoMensualCreditos.value +
    estudioSocioeconomicoObligadoSolidario.tarjetaCredito.value +
    estudioSocioeconomicoObligadoSolidario.renta.value +
    estudioSocioeconomicoObligadoSolidario.colegiaturas.value +
    estudioSocioeconomicoObligadoSolidario.gastoFamiliar.value +
    estudioSocioeconomicoObligadoSolidario.otrosEgresos.value;
}
function verificarConceptos(
  arrayAdd: ESUtilidades.IngresoNomina[] | ESUtilidades.IngresoGeneral[],
  valueCurrent: number,
  cantidadCurrent: number
): void {
  if (estudioSocioeconomicoObligadoSolidario.ocupacionActualArray.value.includes(valueCurrent)) {
    let arregloLimpio = [];
    for (let i = 0; i < cantidadCurrent; i++) {
      arregloLimpio.push(arrayAdd[i]);
    }
    aIngresos.value.push(JSON.stringify(arregloLimpio));
  } else {
    arrayAdd = [];
    aIngresos.value.push(JSON.stringify(arrayAdd));
  }
}
//#endregion

onMounted(async () => {
  store.titulo = "Obligado Solidario";
  catalogos.profesiones.value = (await api.Cliente.Cliente.EscrituracionApi.ObtenerProfesiones()).data;
  catalogos.paises.value = (await api.Cliente.Cliente.EscrituracionApi.ObtenerPaises()).data;
  let llave = "";
  if ((props.llave || "").length) llave = props.llave;
  else llave = $router.currentRoute.value.query["estudioId"] as string;
  let d = (await api.Cliente.Cliente.EscrituracionApi.ObtenerDatosCliente(llave, true)).data;
  cliente.nombreCliente.value = d.nombre + " " + d.apellidoPaterno + " " + d.apellidoMaterno;
  let d2 = await api.Cliente.Cliente.EscrituracionApi.ObtenerDatosObligadoSolidario(llave);
  ESUtilidades.mapear(d2.data, estudioSocioeconomicoObligadoSolidario);
  estudioSocioeconomicoObligadoSolidario.estudioSocioeconomicoId!.value = llave;
  estudioSocioeconomicoObligadoSolidario.clienteId.value = d2.data.clienteId;
  /*Creamos el Arreglo de Ocupacion Actual */
  estudioSocioeconomicoObligadoSolidario.ocupacionActualArray.value = [];
  if (d2.data.ocupacionActual != null && d2.data.ocupacionActual != "")
    estudioSocioeconomicoObligadoSolidario.ocupacionActualArray.value = d2.data.ocupacionActual
      .split(",")
      .map((a: string) => Number(a));
  ESUtilidades.asignarIngresos(d2.data.ingresos, esComprobacionIngresos);
  nextTick(
    () =>
      (estudioSocioeconomicoObligadoSolidario.sueldoNominalNeto!.value = Number.parseFloat(
        (d2.data.sueldoNominalNeto ?? "0").toString()
      ))
  );
  calcularEgresos();
  /*Cargamos JSON de ingresos */
  formularioValidado.value = true;
});
</script>
