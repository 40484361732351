<template lang="pug">
.row
  .col-md-4
    p.fs-5.mb-2(style="color: #94a3b0") {{ etiquetaHeader }}
  .col-md-8
    hr
  .col-md-12
    .p-2.mb-2(style="border: 1px solid var(--bs-primary); color: #6f6f6f; background-color: #eb811326"): .row
      .col-md-3
        label.form-label Num. de {{ etiquetaConcepto }}
        hir-autonumeric.form-control(:form="formId" v-model.number="cantidad" :configuracion="{ maximumValue: '9', minimumValue: '0', decimalPlaces: 0 }")
        .invalid-feedback Num. de {{ etiquetaConcepto }} invalido.
      .col-md-9
        p Documentos requeridos:
        .documentos-requeridos
          .form-check.form-check-inline(v-for="doc in documentos")
            input.form-check-input(type="checkbox" :checked="doc.requerido" disabled)
            label.form-check-label {{ doc.nombre }}
  template(v-for="(empresa, i) in comprobacionIngresosGeneral")
    div(v-bind:class="[esPregunta ? 'col-md-5 mb-3' : 'col-md-9 mb-3']")
      label.form-check-label Especifique Concepto de {{ etiquetaConcepto }}:
      input.form-control(:form="formId" v-model.lazy="empresa.concepto" type="text" pattern="[0-9a-zA-ZñÑéÉáÁíÍóÓúÓ\\-#\\. ]+" required="true")
      .invalid-feedback Concepto de {{ etiquetaConcepto }} invalido.
    .col-md-4(v-if="esPregunta")
      label.form-check-label ¿Es socio o dueño de la empresa en la que labora?
      div
        .form-check.form-check-inline
          input.form-check-input(
            :id="componentId + 'SiDuenoPropietario' + i"
            type="radio"
            :value="true"
            :name="componentId + 'DuenoPropietario' + i"
            v-model="empresa.esSocio"
          )
          label.form-check-label(:for="componentId + 'SiDuenoPropietario' + i") Si
        .form-check.form-check-inline
          input.form-check-input(
            :id="componentId + 'NoDuenoPropietario' + i"
            type="radio"
            :value="false"
            :name="componentId + 'DuenoPropietario' + i"
            v-model="empresa.esSocio"
          )
          label.form-check-label(:for="componentId + 'NoDuenoPropietario' + i") No
    .col-md-3
      label.form-check-label Especifique Monto:
      hir-autonumeric.form-control(
        :form="formId"
        v-model="empresa.monto"
        :configuracion="{ emptyInputBehavior: 'min', maximumValue: '99999999', minimumValue: '0', currencySymbol: '$ ' }"
        required="true"
      )
      .invalid-feedback Especifique Monto invalido.
  .col-md-9.text-end
    br
    label.form-check-label Total de {{ etiquetaConcepto }}:
  .col-md-3
    br
    hir-autonumeric.form-control(v-model="total" readonly :configuracion="{ currencySymbol: '$ ' }")
  br
  br
  .form.text-center(ref="form" novalidate :id="componentId + 'form'"): button.btn.btn-primary.text-light.fw-bold.mt-4(@click.prevent="$emit('guardar')") Guardar
</template>

<script lang="ts" setup>
import { ref, computed, watch } from "vue";
import HirAutonumeric from "@/components/HirAutonumeric.vue";
import type {
  ComprobacionIngresos,
  DocumentoComprobacionIngresos,
  IngresoGeneral
} from "../../../composables/EstudioSocioeconomico";
import * as utilidades from "@/utilidades";

const componentId = utilidades.generarId();

interface Props {
  esComprobacionIngresos?: ComprobacionIngresos;
  formId: string;
  documentos: DocumentoComprobacionIngresos[];
  etiquetaConcepto: string;
  etiquetaHeader: string;
  propiedadCantidad: string;
  propiedadArreglo: string;
  esPregunta: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  esComprobacionIngresos: undefined,
  formId: "",
  documentos: () => [],
  etiquetaConcepto: "",
  etiquetaHeader: "",
  propiedadCantidad: "",
  propiedadArreglo: "",
  esPregunta: false
});

let cantidad = ref(0);

const total = computed((): number => comprobacionIngresosGeneral.value.reduce((prev, curr) => prev + curr.monto, 0));
const comprobacionIngresosGeneral = computed({
  get: (): IngresoGeneral[] => {
    if (!props.esComprobacionIngresos) return [];
    return (
      Object.entries(props.esComprobacionIngresos).find(
        (e) => e[0] == props.propiedadArreglo
      )![1] as typeof props.esComprobacionIngresos.actividadEmpresarial
    ).value;
  },
  set: (val) => {
    if (!props.esComprobacionIngresos) return;
    (
      Object.entries(props.esComprobacionIngresos).find(
        (e) => e[0] == props.propiedadArreglo
      )![1] as typeof props.esComprobacionIngresos.actividadEmpresarial
    ).value = val;
  }
});

watch(cantidad, (val, oldVal) => {
  if (!props.esComprobacionIngresos) return;
  let ci = comprobacionIngresosGeneral.value;
  if (isNaN(val) || val < 0) return;
  if (isNaN(oldVal) || oldVal < 0) oldVal = 0;
  if (val === 0) {
    comprobacionIngresosGeneral.value = [];
  } else if (val < oldVal && ci.length != val) {
    ci.splice(val - 1, oldVal - val);
  } else if (val > oldVal && val != ci.length) {
    ci.push(
      ...Array.from(Array(val - oldVal).keys()).map(() => ({
        monto: 0,
        concepto: "",
        esSocio: false
      }))
    );
  }
  (
    Object.entries(props.esComprobacionIngresos).find(
      (e) => e[0] == props.propiedadCantidad
    )![1] as typeof props.esComprobacionIngresos.cantidadActividadEmpresarial
  ).value = val;
});

if (props.esComprobacionIngresos) {
  let cic = (
    Object.entries(props.esComprobacionIngresos!).find(
      (e) => e[0] == props.propiedadCantidad
    )![1] as typeof props.esComprobacionIngresos.cantidadActividadEmpresarial
  ).value;
  if (cantidad.value < cic) cantidad.value = cic;
}
</script>

<style lang="scss">
.documentos-requeridos {
  .form-check-input:disabled ~ label {
    color: #6f6f6f;
    opacity: 1;
  }
}
</style>
