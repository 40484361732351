<template lang="pug">
.container.ff-poppins.pt-3: .row
  .col-md-12: .Principal(style="height: 500px")
    .row.justify-content-md-start
      .col-md-7.m-4: .card.mb-2(style="margin: 36% 5% 5% 5%; border-radius: 25px"): .card-body.bg-primary(style="border-radius: 25px")
        h2.text-white.text-justify.fw-bold.fs-2 En HIR Casa estamos más cerca de ti
        p.text-white(style="text-align: justify; font-size: 14px") Te invitamos a conocer información que será útil en la vida de tu financiamiento a través de nuestro portal, en donde podrás encontrar y realizar de una forma ágil los servicios que tenemos a tu disposición.
  .col-md-12: .row(style="margin: 0px 0px 2%")
    Tarjeta-Guia(:tarjeta="cards")
</template>

<script lang="ts" setup>
import * as bootstrap from "bootstrap";
//#region data
let modalAltaPortal = null as null | bootstrap.Modal;
let cards = [
  {
    titulo: "Alta portal cliente",
    imagen: "IAltaPortal.svg",
    descripcion: `
		<p>Obtener la información de tu financiamiento a través de nuestro sitio, es muy fácil.</p>
		<h6>¿Cómo hacerlo?</h6>
		<ul>
			<li><p>Debes ingresar a  <a href="https://hircasa.com.mx/">www.hircasa.com.mx  </a><a>en el apartado  </a><a href="login">Portal Cliente  </a><a>crea tu cuenta.</a></p>
			<li><p>Para la creación de tu usuario y contraseña lo único que necesitas es registrar tus datos.</p></li>
			<li><p>Considera que tu cuenta de correo electrónico debe ser el mismo que nos proporcionaste.</p></li></ul>
	</div>
  `
  },
  {
    titulo: "Recuperación y cambio de password",
    imagen: "IPassword.svg",
    descripcion: `

		<p>Si necesitas cambiar tu contraseña por alguna razón o bien no la recuerdas, el proceso para hacer una modificación es sencillo.</p>
		<h6>¿Cómo hacerlo?</h6>
		<ul>
			<li><p>Debes ingresar a  <a href="https://hircasa.com.mx/">www.hircasa.com.mx </a></p></li>
			<li><p>Tienes la opción <strong style="color: #494d64">“¿Has olvidado tu contraseña?</strong>”</p></li>
			<li><p>Te solicitará ingresar tu correo y posteriormente te llegará un link para recuperación.</p></li>
      <li><p>Accede para continuar el proceso estableciendo la contraseña que elijas.</p></li></ul>
	</div>
  `
  },
  {
    titulo: "Programa Recicla",
    imagen: "IProgramaRecicla.svg",
    descripcion: `

		<p>HIR Casa te invita a participar en su programa Recicla; nuestro objetivo es cuidar el medio ambiente, por eso promovemos el envío de estado de cuenta a través de correo electrónico para seguir contribuyendo como una Empresa Socialmente Responsable.</p>
		<h6>¿Cómo hacerlo?</h6>
		<ul>
			<li><p>Debes ingresar a  <a href="https://hircasa.com.mx/">www.hircasa.com.mx  </a></p></li>
			<li><p>Logueate usando tu usuario y contraseña.</p></li>
			<li><p>Puedes activar tu participación accediendo al mensaje de invitación que se muestra al iniciar tu sesión.</p></li>
      <li><p>Acepta los términos y condiciones.</p></li>
      <li><p>Da click para activar tu participación a esta campaña.</p></li></ul>
	</div>
  `
  },
  {
    titulo: "Descarga Estado de Cuenta",
    imagen: "IEstadoCuenta.svg",
    descripcion: `

		<p>Tienes una ruta rápida para descargar el estado de cuenta vigente en un solo archivo.</p>
		<h6>¿Cómo hacerlo?</h6>
		<ul>
			<li><p>Debes ingresar a  <a href="https://hircasa.com.mx/">www.hircasa.com.mx </a></p></li>
			<li><p>Logueate usando tu usuario y contraseña.</p></li>
			<li><p>Ve al apartado de Mi Financiamiento.</p></li>
      <li><p>Elige el mes que desees descargar (puedes visualizar tu estado de cuenta vigente o de 11 meses atrás).</p></li>
      <li><p>Da click en descarga tus estados de cuenta.</p></li></ul>
	</div>
  `
  },
  {
    titulo: "Aclaración de Pagos",
    imagen: "IAclaracionPagos.svg",
    descripcion: `

		<p>Puede solicitar una aclaración de pago desde el portal.</p>
		<h6>¿Cómo hacerlo?</h6>
		<ul>
			<li><p>Da clic en apartado de Aclaraciones.</p></li>
			<li><p>Elige tu tipo de aclaración.</p></li>
      <li><p>Realiza la descripción.</p></li>
      <li><p>Adjunta tus documentos.</p></li>
      <li><p>Envíala.</p></li></ul>
	</div>
  `
  },
  {
    titulo: "Consulta y/o actualiza tus datos",
    imagen: "IDatos.svg",
    descripcion: `

		<p>Puedes consultar o actualiza desde el sitio web <a href="https://hircasa.com.mx/">www.hircasa.com.mx  </a> los datos que tienes registrados.</p>
		<h6>¿Cómo hacerlo?</h6>
		<ul>
			<li><p>Logueate usando tu usuario y contraseña.</p></li>
			<li><p>Ve a Mi Perfil.</p></li>
    </ul>
    <p>Puedes consultar los datos generales proporcionados, tales como: </p>
    <p>-Nombre</p>
    <p>-Domicilio</p>
    <p>-Medios de contacto</p>
    <br>
    <p>Si así lo deseas puedas actualizar:</p>
    <p>-Domicilio</p>
    <p>-Medios de contacto</p>
    <p>-Password</p>
    <ul>
			<li><p>Da clic en el botón Editar información.</p></li>
			<li><p>Actualiza el dato deseado.</p></li>
      <li><p>Ingresa los documentos requeridos.</p></li>
    </ul>

	</div>
  `
  },
  {
    titulo: "Monto a pagar",
    imagen: "IMonto.svg",
    descripcion: `

		<p>Podrás visualizar el total que debes de cubrir de la(s) cuota(s) vigente(s) y si llegases a tener algún vencimiento.</p>
		<h6>¿Cómo hacerlo?</h6>
		<ul>
			<li><p>Debes ingresar a  <a href="https://hircasa.com.mx/">www.hircasa.com.mx  </a></p></li>
			<li><p>Logueate usando tu usuario y contraseña.</p></li>
			<li><p>Ve al apartado de <strong style="color: #494d64">Mi Financiamiento.</strong></p></li>
      <li><p>En el lado izquierdo de tu pantalla podrás vizualizar el monto total a pagar.</p></li>
    </ul>
	</div>
  `
  },
  {
    titulo: "Descuentos",
    imagen: "IDescuentos.svg",
    descripcion: `

		<p>Puede consultar el/los beneficio(s) de descuento(s) que tienes activos (si estos aplicaran).</p>
		<h6>¿Cómo hacerlo?</h6>
		<ul>
			<li><p>Debes ingresar a  <a href="https://hircasa.com.mx/">www.hircasa.com.mx  </a></p></li>
			<li><p>Logueate usando tu usuario y contraseña.</p></li>
			<li><p>Ve al apartado de <strong style="color: #494d64">Mi Financiamiento.</strong></p></li>
      <li><p>Da click en <strong style="color: #494d64">mis descuentos del mes.<strong></strong></p></li>
    </ul>
	</div>
  `
  },
  {
    titulo: "Seguimiento aclaración de pago",
    imagen: "IAclaracionPagos.svg",
    descripcion: `

		<p>En este apartado se puede visualizar el seguimiento de las aclaraciones que tengas por folio y estatus.</p>
		<h6>¿Cómo hacerlo?</h6>
		<ul>
			<li><p>Debes ingresar a  <a href="https://hircasa.com.mx/">www.hircasa.com.mx  </a></p></li>
			<li><p>Logueate usando tu usuario y contraseña.</p></li>
			<li><p>Ve al apartado de <strong style="color: #494d64">Mi Financiamiento.</strong></p></li>
      <li><p>Da click en <strong style="color: #494d64">seguimiento aclaración.</strong></p></li>
    </ul>
	</div>
  `
  },
  {
    titulo: "Resultados del evento de Adjudicación",
    imagen: "IAdjudicacion.svg",
    descripcion: `

		<p>Puedes consultar la publicación de todos los clientes que han adjudicado en el mes.</p>
		<h6>¿Cómo hacerlo?</h6>
		<ul>
			<li><p>Debes ingresar a  <a href="https://hircasa.com.mx/">www.hircasa.com.mx  </a></p></li>
			<li><p>Logueate usando tu usuario y contraseña.</p></li>
			<li><p>Ve al apartado de <strong style="color: #494d64">Adjudicaciones.</strong></p></li>
      <li><p>Selecciona el mes que deseas consultar (resultados de un año) o bien da click en la publicación del mes para visualizarlo desde la publicación del periódico.</p></li>
    </ul>
	</div>
  `
  },
  {
    titulo: "Calendario de Evento de Adjudicación",
    imagen: "ICalendario.svg",
    descripcion: `

		<p> Si quieres conocer las fechas de nuestros próximos eventos de adjudicación consulta el calenadario.</p>
		<h6>¿Cómo hacerlo?</h6>
		<ul>
			<li><p>Debes ingresar a  <a href="https://hircasa.com.mx/">www.hircasa.com.mx  </a></p></li>
			<li><p>Logueate usando tu usuario y contraseña.</p></li>
			<li><p>Ve al apartado de <strong style="color: #494d64">Adjudicaciones.</strong></p></li>
      <li><p>Verifica a través del calendario las fechas de nuestros eventos.</p></li>
    </ul>
	</div>
  `
  },
  {
    titulo: "Información complementaria",
    imagen: "IInformacion.svg",
    descripcion: `

		<p>De manera general, complementaria y gráfica te informamos los requisitos, entradas, documentos de cada uno de los trámites especificados, con la finalidad de facilitarte lo que desees realizar.</p>
		<h6>¿Cómo hacerlo?</h6>
		<ul>
			<li><p>Debes ingresar a  <a href="https://hircasa.com.mx/">www.hircasa.com.mx  </a></p></li>
			<li><p>Logueate usando tu usuario y contraseña.</p></li>
			<li><p>Ve al apartado de <strong style="color: #494d64">Material de apoyo.</strong></p></li>
      <li><p>Consulta la información de tu interés</p></li>
    </ul>
    <p><strong style="color: #494d64">Bienvenida. </strong>Información para utilizar tu financiamiento.</p>
    <p><strong style="color: #494d64">Pagos. </strong>Formas de pago.</p>
    <p><strong style="color: #494d64">Trámites. </strong>Los trámites que puedes realizar.</p>
    <p><strong style="color: #494d64">Liberación. </strong>Requisitos para liberar tu hipoteca.</p>
    <p><strong style="color: #494d64">Adjudicación. </strong>Métodos y estrategias de adjudicación.</p>
    <p><strong style="color: #494d64">Preventas. </strong>Conoce como adquirir un inmueble en preventa.</p>
    <p><strong style="color: #494d64">Liquidación. </strong>Valida como puedes liquidar de forma anticipada.</p>
    <p><strong style="color: #494d64">Titulación. </strong>Da seguimiento documental y administrativo a tu adjudicación.</p>
    <p><strong style="color: #494d64">Seguros. </strong>Conoce cada uno de los seguros y reporta un siniestro de ser necesario.</p>
	</div>
  `
  },
  {
    titulo: "Seguro de Vida",
    imagen: "ISeguroV.svg",
    descripcion: `

		<p>Te damos a conocer los detalles tu seguro vida.</p>
		<h6>¿Cómo hacerlo?</h6>
		<ul>
			<li><p>Debes ingresar a  <a href="https://hircasa.com.mx/">www.hircasa.com.mx </a></p></li>
			<li><p>Logueate usando tu usuario y contraseña.</p></li>
			<li><p>Consulta el apartado de Seguros para visualizar:</p></li>
    </ul>
    <p>- Estatus de tu solicitud.</p>
    <p>- Coberturas.</p>
    <p>- Prima del pago mensual (el monto se cambia de forma mensual basándonos en el factor de actualización).</p>
    <p>- Información de tu cotitular (si aplica).</p>
    <p>- Contratos.</p>
    <p>- Si tienes un reporte de siniestro activo podrás ingresar tu documentación.</p>
    <br>
	</div>
  `
  },
  {
    titulo: "Seguro de Daños",
    imagen: "ISDanios.svg",
    descripcion: `

		<p>Te damos a conocer los detalles de tu seguro daños.</p>
		<h6>¿Cómo hacerlo?</h6>
		<ul>
			<li><p>Debes ingresar a  <a href="https://hircasa.com.mx/">www.hircasa.com.mx  </a></p></li>
			<li><p>Logueate usando tu usuario y contraseña.</p></li>
			<li><p>Ve al apartado de <strong style="color: #494d64">Mi Financiamiento.</strong></p></li>
      <li><p>Consulta el apartado de Seguro de Daños para visualizar:</p></li>
    </ul>
    <p>- Datos de la aseguradora.</p>
    <p>- Coberturas.</p>
    <p>- Prima del pago mensual (el monto se cambia de forma mensual basándonos en el factor de actualización).</p>
    <p>- Garantías.</p>
    <p>- Si tienes un reporte de siniestro activo podrás ingresar tu documentación.</p>
	</div>
  `
  },
  {
    titulo: "Siniestros",
    imagen: "ISiniestros.svg",
    descripcion: `

		<p>Si tienes un reporte de siniestro activo podrás cargar tu documentación.</p>
		<h6>¿Cómo hacerlo?</h6>
		<ul>
			<li><p>Comunicate al área de seguros para reportar un siniestro, puedes hacerlo por medio del:</p>
        <p><strong style="color: #494d64">Tel. 55 5511 9910 ext. 6158 y 6161</strong></p>
        <p><strong style="color: #494d64">WA. 55.7665.9885 opción 4</strong></p>
        <p><strong style="color: #494d64">Mail. atencionseguros@hircasa.com.mx</strong></p></li>
      <li><p>Una vez que tengas tu reporte, ingresa al sitio <a href="https://hircasa.com.mx/">www.hircasa.com.mx  </a></p></li>
			<li><p>Logueate usando tu usuario y contraseña.</p></li>
			<li><p>Consulta el apartado de Siniestros para visualizar</strong></p></li>
      <li><p>Cargar tus documentos previamente escaneados a color y en formato PDF</p></li>
    </ul>
	</div>
  `
  }
];
//#endregion
//#region Metodos
function MostrarModalAltaPortalCliente() {
  modalAltaPortal?.show();
}
function CerrarModal() {
  modalAltaPortal?.hide();
}
//#endregion
</script>

<style lang="scss" scoped>
.Principal {
  background-image: url("../assets/Guia/img_principal_guiarapida\ 1.png");
  width: 100%;
  height: auto;
}

.Principal p {
  font-family: Poppins;
  font-style: normal;
  font-size: 18px;
  margin: 2% 2% 2% 2%;
}

.Principal h2 {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  margin: 2% 2% 2% 2%;
}

h4 {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 150%;
  text-align: center;
}

.iconop {
  height: 100px;
  width: auto;
}

.componenteModal img.iconop {
  height: 250px;
  width: auto;
}

.componenteModal h4 {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  color: #2f2d74;
  text-align: justify;
}

.componenteModal h6 {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #2f2d74;
}

.componenteModal p {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #6e7599;
}

.componenteModal ul {
  list-style-type: disc;
  color: #ffa000;
}

li label,
p {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #6e7599;
}
</style>
