<template lang="pug">
.container.ff-poppins.pt-3: .row
  .col-md-12: .Principal(style="height: 400px")
    .row.justify-content-md-start
      .col-md-12.mt-4
      .col-md-12.mt-4
      .col-md-4.m-4: .card.mb-2(style="border-radius: 25px"): .card-body.bg-primary(style="border-radius: 25px")
        p.text-white.text-center.fw-bold.fs-2 Preventas
        p.text-white(style="text-align: justify; font-size: 14px") Estás interesado en adquirir un inmueble que se encuentra en preventa. ¡Con nosotros puedes hacer realidad ese sueño!
        p.text-white(style="text-align: justify; font-size: 14px") El enganche que proporciones a la inmobiliaria se puede considerar como aportaciones para la adquisición de tu inmueble.
  .col-md-12: .mt-4.card(style="border-radius: 15px"): .card-body(style="border-radius: 15px"): .d-flex
    .flex-fill
      p.fs-2.fw-bold(style="color: #2f2d74") ¿Puedo adquirir un inmueble en preventa con mi Financiamiento?
      p.text-muted Claro, en este video encontrarás toda la información para adquirir ese inmueble que tanto deseas en Preventa.
    div <iframe width="560" height="315" src="https://www.youtube.com/embed/XlR7jZnpsps" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>	
  .col-md-12: .mt-4.card(style="border-radius: 15px"): .card-body(style="border-radius: 15px"): .row
    .col-md-4.m-4: img(src="../assets/img_preventa.png" style="margin: 10px auto; display: block")
    .col-md-6: .row
      .col-md-12: p.fs-2.fw-bold(style="color: #2f2d74") Es muy sencillo, deberemos dar los siguientes pasos:
      .col-md-12: .row
        .col-md-1: .Circulo: p.text-center.text-white 1
        .col-md-11: p.text-muted Se llevará a cabo una validación de tus capacidades de pago para poder realizar tu contratación (comprobantes de ingresos y revisaremos el buró de crédito).
      .col-md-12: .row
        .col-md-1: .Circulo: p.text-center.text-white 2
        .col-md-11: p.text-muted Deberás de compartirnos los comprobantes de pago a la inmobiliaria fehacientes (transferencias y/o depósitos) y avalúo bancario.
      .col-md-12: .row
        .col-md-1: .Circulo: p.text-center.text-white 3
        .col-md-11: p.text-muted En cuanto tu operación esté lista para fondeo, firmarás un “mutuo”&#32;
          a.btn-link(
            href="javascript:void();"
            data-bs-toggle="tooltip"
            title="El mutuo hipotecario es un préstamo hipotecario que se otorga para la adquisición, ampliación, reparación o construcción de una propiedad; compra de sitios, oficinas o locales comerciales; refinanciamiento de mutuos hipotecarios o préstamos para fines generales"
            data-bs-html="true"
          ): img(
            src="../assets/Interrogacion.svg"
          )
          | .&#32;Para que pueda ser fondeada tu operación.
      .col-md-12: .row
        .col-md-1: .Circulo: p.text-center.text-white 4
        .col-md-11: p.text-muted Una vez completado tu expediente y autorizada tu adjudicación, programaremos la firma de tu escritura en 15 días.
  .col-md-12: p.fs-2.fw-bold.text-center(style="color: #2f2d74") ¡Listo! ¡Eres dueño del hogar que siempre soñaste!
  .col-md-12: p.fs-2.fw-bold.text-center(style="color: #2f2d74") ¿Tienes alguna duda?&#32;
    span.text-primary.fw-bold Contáctanos
</template>

<script lang="ts" setup>
import { onMounted } from "vue";
import * as bootstrap from "bootstrap";

onMounted(() => {
  let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl);
  });
});
</script>

<style scoped>
.Principal {
  background-image: url("../assets/img_preventas_principal.jpg");
  width: 100%;
}
.Circulo {
  height: 25px;
  width: 25px;
  background-color: #fd7e14;
  border-radius: 50%;
  display: block;
}
</style>
