<template lang="pug">
.card: .card-body
  .row 
    .col-md-12: p.fw-bold.h3(style="color: #2f2d74") Cargar Documentación
    .col-md-8.mt-2: p(style="color: #2f2d74") Dictamen original de invalidez total o pérdidas orgánicas emitido por IMSS, ISSSTE, SSA o cualquier institución médica legalmente autorizada para ejercer la medicina con especialidad en medicina del trabajo.
    .col-md-2: label.btn.btn-primary.text-white
      span.fas.fa-upload &#32;
      input(type="file" style="display: none" v-on:change="guardarDocuemento($event, 0)")
      | Cargar
    .col-md-2: label.gb-white.text-muted(v-if="mostrarEnlace(0)")
      span.fas.fa-eye &#32;
      a.text-muted.text-decoration-none(:href="mostrarDocumento(0)" target="_blank") Ver
    .col-md-8.mt-2: p(style="color: #2f2d74") Historial clínico con todos los estudios realizados de laboratorio y gabinete
    .col-md-2: label.btn.btn-primary.text-white
      span.fas.fa-upload &#32;
      input(type="file" style="display: none" v-on:change="guardarDocuemento($event, 1)")
      | Cargar
    .col-md-2: label.gb-white.text-muted(v-if="mostrarEnlace(1)")
      span.fas.fa-eye &#32;
      a.text-muted.text-decoration-none(:href="mostrarDocumento(1)" target="_blank") Ver
    .col-md-8.mt-2: p(style="color: #2f2d74") Constancia patronal de la baja por invalidez
    .col-md-2: label.btn.btn-primary.text-white
      span.fas.fa-upload &#32;
      input(type="file" style="display: none" v-on:change="guardarDocuemento($event, 2)")
      | Cargar
    .col-md-2: label.gb-white.text-muted(v-if="mostrarEnlace(2)")
      span.fas.fa-eye &#32;
      a.text-muted.text-decoration-none(:href="mostrarDocumento(2)" target="_blank") Ver
    .col-md-8.mt-2: p(style="color: #2f2d74") Copia certificada del acta de nacimiento del asegurado
    .col-md-2: label.btn.btn-primary.text-white
      span.fas.fa-upload &#32;
      input(type="file" style="display: none" v-on:change="guardarDocuemento($event, 3)")
      | Cargar
    .col-md-2: label.gb-white.text-muted(v-if="mostrarEnlace(3)")
      span.fas.fa-eye &#32;
      a.text-muted.text-decoration-none(:href="mostrarDocumento(3)" target="_blank") Ver
    .col-md-8.mt-2: p(style="color: #2f2d74") Copia de identificación oficial del asegurado
    .col-md-2: label.btn.btn-primary.text-white
      span.fas.fa-upload &#32;
      input(type="file" style="display: none" v-on:change="guardarDocuemento($event, 4)")
      | Cargar
    .col-md-2: label.gb-white.text-muted(v-if="mostrarEnlace(4)")
      span.fas.fa-eye &#32;
      a.text-muted.text-decoration-none(:href="mostrarDocumento(4)" target="_blank") Ver
    .col-md-8.mt-2: p(style="color: #2f2d74") Copia de comprobante de domicilio del asegurado
    .col-md-2: label.btn.btn-primary.text-white
      span.fas.fa-upload &#32;
      input(type="file" style="display: none" v-on:change="guardarDocuemento($event, 5)")
      | Cargar
    .col-md-2: label.gb-white.text-muted(v-if="mostrarEnlace(5)")
      span.fas.fa-eye &#32;
      a.text-muted.text-decoration-none(:href="mostrarDocumento(5)" target="_blank") Ver
    .col-md-8.mt-2: a.text-primary.text-decoration-underline(href="cliente/cliente/pdf/formato/siniestro/invalidez-total.pdf" target="_blank") Formato de reclamación de siniestro
    .col-md-2: label.btn.btn-primary.text-white
      span.fas.fa-upload &#32;
      input(type="file" style="display: none" v-on:change="guardarDocuemento($event, 6)")
      | Cargar
    .col-md-2: label.gb-white.text-muted(v-if="mostrarEnlace(6)")
      span.fas.fa-eye &#32;
      a.text-muted.text-decoration-none(:href="mostrarDocumento(6)" target="_blank") Ver
    .col-md-12.mt-4: .row.justify-content-center
      .col-md-3: button.btn.bg-white.text-primary.border.border-primary Llamar Asesor
      .col-md-3: button.btn.btn-primary.text-white(@click.prevent="enviarNotificacion()") Enviar Información
    Spinner-Loader#spinner(name="spinner")
</template>
<script lang="ts" setup>
import { ref, onMounted, watch, nextTick } from "vue";
import api from "@/servicios/apiCliente";
import { useStore } from "@/store";
import { Cliente, Core, EstadoRespuesta } from "@/servicios/api";
const $store = useStore();
const mostrarMenor = ref(false);
const mostrarMuerte = ref(false);
const opcionales = ref([] as number[]);
const documentosVida = ref([] as Cliente.Cliente.Siniestro.ListaDocumentos[]);
const danios = ref({} as Cliente.Cliente.Siniestro.SiniestrosDañosPortalCliente);
//#endregion Props
interface Props {
  Siniestro: Cliente.Cliente.Siniestro.ListaDocumentos[];
}
const props = withDefaults(defineProps<Props>(), {
  Siniestro: () => []
});
watch(opcionales, () => {
  mostrarMenor.value = opcionales.value.includes(1);
  mostrarMuerte.value = opcionales.value.includes(2);
});
function mostrarDocumento(index: number) {
  if (!documentosVida.value.length) return "";
  let nombre = documentosVida.value[index].nombreDocumento;
  return (
    (import.meta.env.BASE_URL || "").replace(/\/$/g, "") +
    api.Cliente.Cliente.SiniestroApi.ObtenerDocumentosSiniestrosUrl +
    "?ruta=" +
    nombre
  );
}
function mostrarEnlace(index: number) {
  if (!documentosVida.value.length) return false;
  if (documentosVida.value[index].nombreDocumento == null) return false;
  return true;
}
async function guardarDocuemento(event: Event, index: number) {
  let div = document.querySelector("#spinner") as HTMLDivElement;
  div.style.display = "block";
  if (!documentosVida.value.length) {
    await CrearSiniestro();
  }
  let file = (event.target as HTMLInputElement).files![0] as File;
  let DocumentoSiniestro = documentosVida.value[index];
  let resp = await api.Cliente.Cliente.SiniestroApi.GuardarDocumentoSiniestro(
    file,
    DocumentoSiniestro.documentoSiniestroId!
  );
  documentosVida.value = resp.data.data.documentos!;
  div.style.display = "none";
  $store.agregarToast(resp.data);
}
async function CrearSiniestro() {
  let resp = await api.Cliente.Cliente.SiniestroApi.CrearSiniestro(Core.Enums.TipoSinestro.INVALIDEZ_TOTAL_PERMANENTE);
  if (resp.data.estado == EstadoRespuesta.OK) {
    let data = (resp.data.data as Cliente.Cliente.Siniestro.ListaSiniestroconDocumentos)!.documentos!;
    documentosVida.value = data;
  }
  $store.agregarToast(resp.data);
}
async function enviarNotificacion() {
  let div = document.querySelector("#spinner") as HTMLDivElement;
  div.style.display = "block";
  if (!documentosVida.value.length) {
    await CrearSiniestro();
  }
  let resp = await api.Cliente.Cliente.SiniestroApi.NotificarCambios(
    documentosVida.value[0].documentoSiniestroId!,
    danios.value!
  );
  div.style.display = "none";
  $store.agregarToast(resp.data);
}
onMounted(async () => {
  documentosVida.value = props.Siniestro;
  let div = document.querySelector("#spinner") as HTMLDivElement;
  div.style.display = "none";
  await nextTick();
});
</script>
