<template lang="pug">
.container.ff-poppins.pt-2(:class="{ 'was-validated': formularioValidado }")
  .row
    .col-md-5
      .p-3.fw-bold.text-secondary.text-white.fs-5(style="background: #5935df; border-radius: 8px 8px 0 0") RECUPERACIÓN DE CONTRASEÑA
      .card: .card-body
        .row.justify-content-center
          .col-10.mt-4
            router-link.fw-bold.text-primary.text-primary(:to="{ name: 'cliente-cliente-login' }") Regresar
          .col-10.mt-4
            label.form-label.fw-bold.fs-5(style="color: #2f2d74") Ingresa tu Correo Electrónico Registrado
            input.form-control(
              :form="componentId + 'form'"
              type="email"
              required
              v-model="LoginUser.correo.value"
              pattern="[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}"
              style="border-radius: 90px"
            )
            .invalid-feedback Correo Electrónico invalido.
          .col-8.text-center.mt-4
            form.text-center(ref="formDom" novalidate :id="componentId + 'form'" @submit.prevent="ActualizarPasswordCorreo()"): button.btn.btn-primary.text-white.px-5.fw-bold Enviar
            //-input(class="btn btn-primary text-white" type="submit" value="Enviar" @click="ActualizarPasswordCorreo()")
          .col-8.text-center.mt-4
            router-link.fw-bold.text-primary(:to="{ name: 'cliente-cliente-usuario-crear' }") ¿Necesitas Ayuda?
          //-.col-8.text-center.mt-4
            b.text-primary.text-success Se ha enviado una liga a correo@gmail.com con la información para recuperar tu acceso.
            br
            input.mt-2(class="btn btn-primary text-white" type="submit" value="Regresar")
    .col-md-6.offset-md-1
      img.img-fluid(src="../assets/bg.png" alt="Responsive image")
</template>

<script lang="ts" setup>
import { ref, toRefs, reactive, toRaw } from "vue";
import { TipoBootstrap } from "@/interfaces";
import api from "@/servicios/apiCliente";
import { EstadoRespuesta, Core } from "@/servicios/api";
import { useStore } from "@/store";
import { useRouter } from "vue-router";
import * as utilidades from "@/utilidades";

//#region Data
const $router = useRouter();
const $store = useStore();
const componentId = utilidades.generarId();
const formDom = ref(null as HTMLFormElement | null);
const formularioValidado = ref(true);
const reactiveLoginUser = reactive({
  correo: "",
  estatus: Core.Enums.EstatusAlta.Activo
} as Core.Models.AltaUsuario);
const LoginUser = toRefs(reactiveLoginUser);

//#endregion

//#region Methods
async function ActualizarPasswordCorreo() {
  let valido = formDom.value!.checkValidity();
  if (!valido) {
    $store.agregarToast({
      tipo: TipoBootstrap.DANGER,
      body: "Llenar todos los campos correctamente."
    });
    return;
  }
  let res = await api.Cliente.Cliente.HomeApi.SolicitudCambioContrasenia(toRaw(reactiveLoginUser));
  $store.agregarToast(res.data);
  if (res.data.estado == EstadoRespuesta.OK) $router.push({ name: "cliente-cliente-login" });
}
//#endregion
</script>
