<template lang="pug">
.d-flex.flex-column.p-3.w-100
  HeaderForm
  UsoDeFinanciamiento(ref="formUF")
  .d-flex.justify-content-between
    button.btn.btn-outline-primary(style="width: 100px" @click="anterior" :disabled="store.datos_figura[store.getFigura].estudioBloqueado")
      | Anterior
    button.btn.btn-primary.text-white(style="width: 100px" @click="siguiente" :disabled="store.datos_figura[store.getFigura].estudioBloqueado")
      | Siguiente
</template>
<script>
import { defineAsyncComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { useStoreTitular } from "../storeTitular";
import { useStore } from "@/store";
import { TipoBootstrap } from "@/interfaces";
import { storeToRefs } from "pinia";

export default {
  components: {
    UsoDeFinanciamiento: defineAsyncComponent(() => import("./UsoDeFinanciamiento/UsoDeFinanciamiento.vue")),
    HeaderForm: defineAsyncComponent(() => import("./HeaderForm.vue"))
  },
  props: ["figura"],
  setup() {
    //variables
    const router = useRouter(); //Navegar hacia un lugar
    const formUF = ref();
    const store = useStoreTitular();
    const $store = useStore();

    const { formulario_uso_financiamiento } = storeToRefs(store);

    //metodos
    const siguiente = () => {
      formUF.value.validar();

      //Validar ruta para determinar el camino correcto segun la figura
      if (store.$state.formulario_uso_financiamiento.completado) {
        store.guardarFigura(store.$state.datos_figura[store.figuraActual]);
        if (store.tieneSesion) router.push({ name: "vendedor" });
        else router.push({ name: "vendedor", query: { operacionId: store.operacionTitulacionId } });
      } else {
        $store.agregarToast({
          tipo: TipoBootstrap.DANGER,
          body: "Llenar todos los campos correctamente.",
          mensaje: "Texto"
        });
      }
    };
    return {
      siguiente,
      formUF,
      store,
      anterior: () => {
        if (store.tieneSesion) router.push({ name: "patrimonio" });
        else router.push({ name: "patrimonio", query: { operacionId: store.operacionTitulacionId } });
      }
    };
  }
};
</script>

<style></style>
