<template lang="pug">
.container.ff-poppins(:class="{ 'was-validated': formularioValidado }")
  .row.mt-2(style="margin-top: -27px")
    .col-md-5.mt-2
      .p-3.text-secondary.text-white.fs-5.fw-bold(style="background: #5935df; border-radius: 8px 8px 0 0") CREA TU CUENTA
      .card(style="border-radius: 0 0 8px 8px"): .card-body
        form(ref="formDom" novalidate :id="componentId + 'form'" @submit.prevent="crearUsuario()"): .row.justify-content-center
          .col-12.mt-2
            a.fw-bold.text-primary(href="/login" @click.prevent="$router.push({ name: 'cliente-cliente-login' })") Regresar
          .col-8.mt-2
            label.form-label.fw-bold(style="color: #2f2d74") No. Contrato
            input.form-control(
              :form="componentId + 'form'"
              type="text"
              v-model="LoginUser.numContrato.value"
              required
              pattern="[/0-9]+"
              style="border-radius: 90px"
              placeholder="Ejemplo: 01234567/89"
            )
            .invalid-feedback No. Contrato invalido.
          .col-8.mt-2
            label.form-label.fw-bold(style="color: #2f2d74") Correo Electrónico
            input.form-control(
              :form="componentId + 'form'"
              type="email"
              required
              v-model="LoginUser.correo.value"
              pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
              style="border-radius: 90px"
              placeholder="Ejemplo: usuario@dominio.com"
            )
            .invalid-feedback Correo Electrónico Invalido.
          .col-8.mt-2
            label.form-label.fw-bold(style="color: #2f2d74") Contraseña
            input.form-control(
              :form="componentId + 'form'"
              type="Password"
              required
              v-model="LoginUser.contrasenia.value"
              :pattern="patronPassword"
              style="border-radius: 90px"
            )
            .invalid-feedback Contraseña inválida.
          .col-8.mt-2
            label.form-label.fw-bold(style="color: #2f2d74") Confirmar Contraseña
            input.form-control(
              :form="componentId + 'form'"
              type="Password"
              v-model="RepPassword"
              required
              :pattern="patronPassword"
              style="border-radius: 90px"
            )
            .invalid-feedback Contraseña inválida.
          .col-8.text-center.mt-2
            button.btn.btn-primary.text-white.px-5.fw-bold Crear
            //-button(class="btn btn-primary text-white" type="submit" @click="crearUsuario()") CREAR
    .col-md-6.mt-2.offset-md-1
      img.img-fluid(src="../assets/bg.png" alt="Responsive image")
</template>

<script lang="ts" setup>
import * as utilidades from "@/utilidades";
import { ref, toRefs, reactive, toRaw, computed } from "vue";
import { TipoBootstrap } from "@/interfaces";
import api from "@/servicios/apiCliente";
import { Cliente, EstadoRespuesta } from "@/servicios/api";
import { useStore } from "@/store";
import { useRouter } from "vue-router";

const $router = useRouter();
const componentId = utilidades.generarId();
const patronPassword = "[^'\" ]+";

const $store = useStore();
const formDom = ref(null as HTMLFormElement | null);
const formularioValidado = ref(false);
const RepPassword = ref("");
const reactiveLoginUser = reactive({
  numContrato: "",
  correo: "",
  contrasenia: "",
  aceptarAvisoTerminos: false,
  canal: 1
});
const LoginUser = toRefs(reactiveLoginUser);
const CorreoUpperCase = computed(() => {
  return LoginUser.correo.value.toUpperCase();
});
async function crearUsuario() {
  formularioValidado.value = true;
  let valido = formDom.value!.checkValidity();
  if (!valido) {
    $store.agregarToast({
      tipo: TipoBootstrap.DANGER,
      body: "Llenar todos los campos correctamente."
    });
    return;
  }
  reactiveLoginUser.correo = CorreoUpperCase.value;
  if (RepPassword.value == LoginUser.contrasenia.value) {
    let res = await api.Cliente.Cliente.HomeApi.RegistrarUsuario(toRaw(reactiveLoginUser));
    $store.agregarToast(res.data);
    if (res.data.estado == EstadoRespuesta.OK) {
      gtag("event", "sign_up");
      $router.push({ name: "cliente-cliente-login" });
    }
  }
}
</script>
