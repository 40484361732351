<template lang="pug">
div
  .row
    .col-md-4.text-center.justify-content-center
      p.fs-5.mb-2(style="color: #94a3b0") Empleado con Salario por Nómina
    .col-md-8
      hr
  .row: .col-md-12
    .p-2.mb-2(style="border: 1px solid var(--bs-primary); color: #6f6f6f; background-color: #eb811326"): .row
      .col-md-3
        label.form-label Número de Empresas para las que labora:
        input.form-control(
          :form="props.formId"
          v-model.number.lazy="esComprobacionIngresos.cantidadNomina.value"
          type="number"
          pattern="^[0-9]+"
          min="0"
          max="5"
          step="any"
        )
        .invalid-feedback Núm. de Empresas para las que Labora invalido.
      .col-md-9
        p Documentos requeridos:
        .documentos-requeridos
          .form-check.form-check-inline(v-for="doc in esComprobacionIngresos.documentosNomina.value")
            input.form-check-input(type="checkbox" :checked="doc.requerido" disabled)
            label.form-check-label {{ doc.nombre }}
  template(v-if="nominaActual")
    .row
      .col-md-3
        label.form-label Empresa en la que Labora
        br
        input.form-control(:form="props.formId" type="text" pattern="[0-9a-zA-ZñÑéÉáÁíÍóÓúÓ ]+" v-model.lazy="nominaActual.empresa" required="true")
        .invalid-feedback Empresa en la que Labora invalido.
      .col-md-3
        label.form-label Giro de la Empresa
        input.form-control(v-model.lazy="nominaActual.actividad" :form="props.formId" type="text" pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+" required="true")
        .invalid-feedback Giro de la Empresa invalido.
      .col-md-3
        label.form-label Teléfono de la Empresa
        input.form-control(:form="props.formId" type="tel" pattern="[0-9]{10}" v-model.lazy="nominaActual.telefono" maxlength="10" required="true")
        .invalid-feedback Teléfono a 10 dígitos.
      .col-md-3
        label.form-label Extensión Telefónica
        input.form-control(
          :form="props.formId"
          type="text"
          pattern="^[0-9]+"
          min="0"
          max="999999999"
          maxlength="8"
          step="any"
          v-model.lazy="nominaActual.extension"
          required="true"
        )
        .invalid-feedback Extensión Telefónica invalido.
    .row
      .col-md-3
        label.form-label Correo Electrónico de Trabajo
        input.form-control(
          :form="props.formId"
          type="email"
          pattern="[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}"
          v-model.lazy="nominaActual.correo"
          required="true"
        )
        .invalid-feedback Correo Electrónico de Trabajo invalido.
      .col-md-3
        label.form-label Puesto que Desempeña
        input.form-control(:form="props.formId" type="text" pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+" v-model.lazy="nominaActual.puesto" required="true")
        .invalid-feedback Puesto que Desempeña invalido.
        //- select.form-select(v-model.lazy="nominaActual.puesto")
          option Ejecutivo
          option Técnico
          option Gerente
          option Director
      //- .col-md-3
        label.form-label Otro Puesto
        input.form-control(:form="props.formId" type="text" pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+" v-model.lazy="nominaActual.otroPuesto")
        .invalid-feedback Otro Puesto invalido.
      .col-md-3
        label.form-label Antigüedad Laboral (Meses)
        input.form-control(
          :form="props.formId"
          type="text"
          pattern="^[0-9]+"
          min="0"
          max="99999"
          maxlength="5"
          step="any"
          v-model.lazy="nominaActual.antiguedad"
          required="true"
        )
        .invalid-feedback Antigüedad Laboral (Meses) invalido.
      .col-md-3
    .row
      .col-md-3
        label.form-label Nombre del Jefe Inmediato
        input.form-control(:form="props.formId" type="text" pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+" v-model.lazy="nominaActual.nombreJefe")
        .invalid-feedback Nombre del Jefe Inmediato invalido.
      .col-md-3
        label.form-label Teléfono del Jefe Inmediato
        input.form-control(:form="props.formId" type="text" pattern="[0-9]{10}" v-model.lazy="nominaActual.telefonoJefe" maxlength="10")
        .invalid-feedback Teléfono del Jefe Inmediato invalido.
      .col-md-3
        label.form-label Extensión de Jefe Inmediato
        input.form-control(:form="props.formId" type="text" pattern="^[0-9]+" v-model.lazy="nominaActual.extensionJefe" maxlength="8")
        .invalid-feedback Extensión de Jefe Inmediato invalido.
      .col-md-3
        label.form-label Sueldo Nominal Bruto
        hir-autonumeric.form-control(
          :form="props.formId"
          :configuracion="{ emptyInputBehavior: 'min', maximumValue: '99999999', minimumValue: '0', currencySymbol: '$ ' }"
          v-model.number="nominaActual.sueldoBruto"
          required="true"
        )
        .invalid-feedback Sueldo Nominal Bruto invalido.
    .row
      .col-md-3
        label.form-label ISR e IMSS
        hir-autonumeric.form-control(
          :form="props.formId"
          :configuracion="{ emptyInputBehavior: 'min', maximumValue: '99999999', minimumValue: '0', currencySymbol: '$ ' }"
          v-model.number="nominaActual.isrImss"
          required="true"
        )
        .invalid-feedback ISR e IMSS invalido.
      .col-md-3
        label.form-label Sueldo Nominal Neto
        hir-autonumeric.form-control(
          :form="props.formId"
          :configuracion="{ currencySymbol: '$ ' }"
          readonly
          v-model="esComprobacionIngresosNominaActualSueldoNeto"
        )
        .invalid-feedback Sueldo Nominal Neto invalido.
  .col-md-12.text-center(v-if="esComprobacionIngresos.numeroNominaActual.value")
    div: label.form-label {{ esComprobacionIngresos.numeroNominaActual.value }} / {{ esComprobacionIngresos.cantidadNomina.value }}
  .col-md-3.text-end.offset-md-9
    label.form-label Total Sueldo Nominal Neto
    hir-autonumeric.form-control(readonly v-model="sueldoNetoTotal" :configuracion="{ currencySymbol: '$ ' }")
  .col-md-12.text-center(v-if="esComprobacionIngresos.numeroNominaActual.value")
    .text-center: .btn-group
      button.btn.btn-primary.text-white(v-if="esComprobacionIngresos.numeroNominaActual.value > 1" :form="formId" @click.prevent="anteriorNomina()") Empresa Anterior
      button.btn.btn-danger.text-white(:form="props.formId" @click.prevent="eliminarNomina()") Eliminar
      button.btn.btn-primary.text-white(
        v-if="esComprobacionIngresos.numeroNominaActual.value < esComprobacionIngresos.cantidadNomina.value"
        :form="props.formId"
        @click.prevent="siguienteNomina()"
      ) Siguiente Empresa
  br
  br
  .form.text-center(ref="form" novalidate :id="componentId + 'form'"): button.btn.btn-primary.text-light.fw-bold.mt-4(@click.prevent="$emit('guardar')") Guardar
</template>

<script lang="ts" setup>
import HirAutonumeric from "@/components/HirAutonumeric.vue";
import type { ComprobacionIngresos, IngresoNomina } from "../../../composables/EstudioSocioeconomico";
import { computed, inject, watch, ref, toRef, nextTick } from "vue";
import * as utilidades from "@/utilidades";

const componentId = utilidades.generarId();
const esComprobacionIngresos: ComprobacionIngresos = inject("ComprobacionIngresos")!;
const nominaActual = ref(undefined as IngresoNomina | undefined);

const esComprobacionIngresosNominaActualSueldoNeto = computed({
  get: () => nominaActual?.value?.sueldoNeto! ?? 0,
  set: () => {
    if (nominaActual?.value?.sueldoNeto == undefined) return;
    nominaActual.value.sueldoNeto = (nominaActual.value.sueldoBruto ?? 0) - (nominaActual.value.isrImss ?? 0);
  }
});

const sueldoNetoTotal = computed((): number => {
  return esComprobacionIngresos.nomina.value.reduce((prev, curr) => prev + (curr.sueldoNeto ?? 0), 0);
});

watch(
  esComprobacionIngresos.cantidadNomina,
  (val, oldVal) /* nosonar */ => {
    let ci = esComprobacionIngresos;
    if (val == undefined) return;
    if (isNaN(val) || val < 0) return;
    oldVal = utilidades.ifNaN(oldVal);
    if (oldVal! < 0) oldVal = 0;
    if (val === 0) ci.nomina.value = [];
    else if (val < oldVal! && ci.nomina.value.length != val) {
      ci.nomina.value.splice(val - 1, oldVal! - val);
    } else if (val > oldVal!) {
      let allData = ci.nomina.value.length == 0 ? 1 : ci.nomina.value.length;
      for (let i = 0; i < allData; i++) {
        ci.nomina.value.push(
          ...Array.from(Array(val - oldVal!).keys()).map(() => ({
            empresa: "",
            actividad: "",
            telefono: "",
            extension: "",
            correo: "",
            puesto: "",
            otroPuesto: "",
            antiguedad: 0,
            nombreJefe: "",
            telefonoJefe: "",
            extensionJefe: "",
            sueldoBruto: 0,
            isrImss: 0,
            sueldoNeto: 0
          }))
        );
      }
    }
    if (val === 0) {
      nominaActual.value = undefined;
      ci.numeroNominaActual.value = 0;
    } else if (!nominaActual.value) {
      nominaActual.value = ci.nomina.value[0];
      ci.numeroNominaActual.value = 1;
    } else if (val - ci.numeroNominaActual.value == 1) {
      nominaActual.value = ci.nomina.value[val - 1];
      ci.numeroNominaActual.value = val;
    }
  },
  { immediate: true }
);

watch(
  () => (nominaActual.value ? [toRef(nominaActual.value, "sueldoBruto"), toRef(nominaActual.value, "isrImss")] : []),
  () => {
    if (nominaActual.value?.sueldoNeto == undefined) return;
    nominaActual.value.sueldoNeto = (nominaActual.value.sueldoBruto ?? 0) - (nominaActual.value.isrImss ?? 0);
    esComprobacionIngresos.sueldoNetoTotal.value = sueldoNetoTotal.value;
  },
  { deep: true }
);

function siguienteNomina() {
  if (esComprobacionIngresos == undefined) return;
  let ci = esComprobacionIngresos;
  nominaActual.value = ci.nomina.value[ci.numeroNominaActual.value];
  ci.numeroNominaActual.value++;
}

function anteriorNomina() {
  if (esComprobacionIngresos == undefined) return;
  let ci = esComprobacionIngresos;
  ci.numeroNominaActual.value--;
  nominaActual.value = ci.nomina.value[ci.numeroNominaActual.value - 1];
}

async function eliminarNomina() {
  if (esComprobacionIngresos == undefined) return;
  let ci = esComprobacionIngresos;
  let indexof = ci.nomina.value.indexOf(nominaActual.value!);
  ci.nomina.value.splice(indexof, 1);
  ci.cantidadNomina.value = ci.nomina.value.length;
  if (ci.numeroNominaActual.value > ci.cantidadNomina.value) ci.numeroNominaActual.value--;
  if (ci.cantidadNomina.value == 0) {
    await nextTick();
    ci.cantidadNomina.value = 1;
  }
}

interface Props {
  formId: string;
}
const props = withDefaults(defineProps<Props>(), {
  formId: "form"
});
</script>

<style lang="scss">
.documentos-requeridos {
  .form-check-input:disabled ~ label {
    color: #6f6f6f;
    opacity: 1;
  }
}
</style>
