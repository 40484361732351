<template lang="pug">
.container.ff-poppins(:class="{ 'was-validated': formularioValidado }")
  .row.mt-2(style="margin-top: -27px")
    .col-md-5.mt-2
      .p-3.fw-bold.text-secondary.text-white.fs-5(style="background: #5935df; border-radius: 8px 8px 0 0") RECUPERACIÓN DE CONTRASEÑA
      .card(style="border-radius: 0 0 8px 8px"): .card-body
        .row.justify-content-center
          .col-8.mt-4
            label.form-label.fw-bold.fs-5(style="color: #2f2d74") Nueva Contraseña
            input.form-control(
              :form="componentId + 'form'"
              type="password"
              required
              v-model="actualizarCliente.passwordNueva.value"
              :pattern="patternPassword"
            )
            .invalid-feedback Contraseña Invalido.
          .col-8.mt-4
            label.form-label.fw-bold.fs-5(style="color: #2f2d74") Confirmar Nueva Contraseña
            input.form-control(:form="componentId + 'form'" type="password" required v-model="actualizarCliente.repPassword.value" :pattern="patternPassword")
            .invalid-feedback Contraseña Invalido.
          .col-8.text-center.mt-4
            form.text-center(ref="formDom" novalidate :id="componentId + 'form'" @submit.prevent="actualizarPassword()"): button.btn.btn-primary.text-white.px-5.fw-bold(
              style="border-radius: 20px; background: #ffa000"
            ) Confirmar
            //-input(class="btn btn-primary text-white" type="submit" value="Confirmar" @click="actualizarPassword()")
    .col-md-6.mt-2.offset-md-1
      img.img-fluid(src="../assets/bg.png" alt="Responsive image")
</template>

<script lang="ts" setup>
import { ref, toRefs, reactive, toRaw } from "vue";
import api from "@/servicios/apiCliente";
import { TipoBootstrap } from "@/interfaces";
import { Core, EstadoRespuesta, Cliente } from "@/servicios/api";
import { useRouter } from "vue-router";
import { useStore } from "@/store";
import * as utilidades from "@/utilidades";

//#region Data
const $router = useRouter();
const $store = useStore();
const patternPassword = "[^'\" ]+";
const componentId = utilidades.generarId();
const formDom = ref(null as HTMLFormElement | null);
const formularioValidado = ref(true);
const reactiveActualizarCliente = reactive({
  clienteId: 0,
  passwordActual: "",
  passwordNueva: "",
  repPassword: "",
  estatus: Core.Enums.EstatusSesion.Recuperacion_Automatica
} as Cliente.Cliente.ActualizarAltaCliente);
const actualizarCliente = toRefs(reactiveActualizarCliente);
const token = ref("");
//#endregion

//#region Methods
async function actualizarPassword() {
  let valido = formDom.value!.checkValidity();
  if (!valido) {
    $store.agregarToast({
      tipo: TipoBootstrap.DANGER,
      body: "Llenar todos los campos correctamente."
    });
    return;
  }
  actualizarCliente.estatus.value = Core.Enums.EstatusSesion.Recuperacion_Automatica;
  let res = await api.Cliente.Cliente.HomeApi.ActualizarContrasenia(token.value, toRaw(reactiveActualizarCliente));
  $store.agregarToast(res.data);
  if (res.data.estado == EstadoRespuesta.OK) $router.push({ name: "cliente-cliente-login" });
}
//#endregion

token.value = $router.currentRoute.value.query["token"] as string;
</script>
