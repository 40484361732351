<template lang="pug">
.container.ff-poppins.pt-3: .row
  .col-md-12: .Principal(style="height: 350px")
    .row.justify-content-md-start
      .col-md-12.mt-4
      .col-md-12.mt-4
      .col-md-4.m-4: .card.mb-2(style="border-radius: 25px"): .card-body.bg-primary(style="border-radius: 25px")
        p.text-white.text-center.fw-bold.HirH1 Liberación de Hipoteca
        p.text-white.HirH5(style="text-align: justify") ¡Felicidades! Ahora que tu financiamiento es momento de liberar la garantía registrada sobre tu inmueble.
  .col-md-12: p.HirH2.fw-bold.text-center.mt-4 En&#32;
    span.fw-bold.text-primary HIR Casa&#32;
    | estamos muy contentos porque hemos llegado juntos a la etapa final de tu financiamiento.
  .col-md-12: .mt-4.card(style="border-radius: 15px"): .card-body.p-5(style="border-radius: 15px"): .d-flex
    .flex-fill.pe-3
      p.fs-2.fw-bold(style="color: #2f2d74") Conoce toda la información para la liberación de tu hipoteca
      p.fs-5.mt-4(style="color: #2f2d74") Hemos preparado este video con toda la información para que conozcas paso a paso cómo realizar la liberación.
      p.fs-5.mt-4(style="color: #2f2d74") Recuerda que sí deseas asesoría específica puedes contactarnos
    div <iframe width="560" height="315" src="https://www.youtube.com/embed/DU_MMyBKRKk" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  .col-md-12: .row
    .col-md-6: .mt-4.card(style="border-radius: 15px; background-color: #2f2d74"): .card-body(style="border-radius: 15px"): .row
      .col-md-12: p.HirH3.fw-bold.text-center.text-white Te sugerimos tomar en cuenta los siguientes puntos.
      .col-md-12.mt-4
      .col-md-12
        ol.list-group(start="1" type="1")
          li.list-group-item.active.text-white.HirP(style="border-radius: 15px") 1.- Cotiza tu trámite.
          li.list-group-item.mt-2.text-white.HirP(style="border-radius: 15px; background-color: transparent; border-style: none") 2.- Proporciona tus datos.
          li.list-group-item.mt-2.text-white.HirP(style="border-radius: 15px; background-color: transparent; border-style: none") 3.- Seguimiento con notaria.
          li.list-group-item.mt-2.text-white.HirP(style="border-radius: 15px; background-color: transparent; border-style: none") 4.- Entrega
      .col-md-12.mt-4
    .col-md-6: .mt-4.card(style="border-radius: 15px"): .card-body(style="border-radius: 15px"): .row
      .col-md-12: img(src="../assets/i_cotizatramite.svg" style="margin: 10px auto; display: block" @click.prevent="mostrarPopUp()")
      //- Modal de Estados de Cuentas
      #NotariaModal.modal.fade(tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true")
        .modal-dialog.modal-dialog-centered.mw-100.w-75(role="document")
          .modal-content
            .modal-body
              p.m-2.text-center.h2(style="color: #34189a; font-weight: bold") Notarias
              .row.justify-content-md-center: .col-md-7: Slider-Carousel(
                :ImagenesProp="['img/notarias/01_notaria.png', 'img/notarias/02_notaria.png', 'img/notarias/03_notaria.png', 'img/notarias/04_notaria.png', 'img/notarias/05_notaria.png', 'img/notarias/06_notaria.png', 'img/notarias/07_notaria.png', 'img/notarias/08_notaria.png', 'img/notarias/09_notaria.png', 'img/notarias/10_notaria.png']"
              )
            .modal-footer
              button.btn.btn-primary.text-white.fw-bold(data-dismiss="modal" @click="ocultarPopUp()") Cerrar
      .col-md-12: p.text-center.text-muted.fs-5 Investiga con la Notaria de tu preferencia el costo del trámite de liberación.
      .col-md-12: p.text-center.text-muted.fs-5 En algunas localidades podemos recomendarte Notarias con las cuales tenemos convenio.
  .col-md-12.mt-4
</template>
<script lang="ts" setup>
import { ref, onMounted } from "vue";
import * as bootstrap from "bootstrap";
import SliderCarousel from "../components/SliderCarousel.vue";
let notarias = null as null | bootstrap.Modal;
function ocultarPopUp() {
  notarias?.hide();
}
function mostrarPopUp() {
  notarias?.show();
}
onMounted(async () => {
  notarias = new bootstrap.Modal(document.querySelector("#NotariaModal") as HTMLDivElement);
});
</script>
<style scoped>
.Principal {
  background-image: url("../assets/img_liberacion_principal.jpg");
  width: 100%;
}
.HirH1 {
  font-size: 40px;
  color: #2f2d74;
}
.HirH2 {
  font-size: 36px;
  color: #2f2d74;
}
.HirH3 {
  font-size: 28px;
  color: #2f2d74;
}
.HirH4 {
  font-size: 24px;
  color: #2f2d74;
}
.HirH5 {
  font-size: 18px;
  color: #2f2d74;
}
.HirH6 {
  font-size: 14px;
  color: #2f2d74;
}
.HirP {
  font-size: 20px;
  color: white;
}
</style>
