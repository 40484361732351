<template lang="pug">
div
  .container
    .row.mt-2(style="margin-top: -27px")
      .col-md-12
        .d-inline-block(
          style="background: #374066; color: #ffffff; padding: 15px 26px; border-top-left-radius: 11px; border-top-right-radius: 11px; margin-left: 26px"
        ): b.text.secondary Financiamiento
      .col-md-12(v-if="tieneComplementoEstadoCuenta != undefined && tieneComplementoEstadoCuenta"): .card: .card-body
        .row: .col: p.fw-bold.text-center
          span.text-muted PAGUE TODOS SUS CONTRATOS EN&nbsp;
          | UNA SOLA REFERENCIA
        .row
          .col-2
          .col-4.text-center
            p.text-muted TOTAL A PAGAR
            p.text-primary {{ filtros.filtroDivisa(totalPagar) }}
          .col-4.text-center
            p.text-muted DESCARGA TU COMPLEMENTO
            p.text-center: a.text-primary(
              style="font-size: 30px"
              @click="gt('event', 'descargar', { event_category: 'complemento-estado-cuenta', event_label: 'financiamiento', value: 1 })"
              :href="linkDescargaComplemento"
              target="_blank"
            ): i.fas.fa-download
          //- .col-3
            button.btn.btn-primary.text-white Solicita tu Domiciliación
      .col-md-12(v-if="tieneComplementoEstadoCuenta != undefined && !tieneComplementoEstadoCuenta"): .card: .card-body
        .row: .col: p.fw-bold.text-center
          span.text-muted DESCARGUE TODOS LOS ESTADOS DE CUENTA DE SUS CONTRATOS EN&nbsp;
          | UN SOLO ARCHIVO
        .row
          .col-2
          .col-4.text-center
            p.text-muted TOTAL A PAGAR
            p.text-primary {{ filtros.filtroDivisa(totalPagar) }}
          .col-4.text-center
            p.text-muted DESCARGA TUS ESTADOS DE CUENTA
            p.text-center: a.text-primary(
              style="font-size: 30px"
              @click="gt('event', 'descargar', { event_category: 'complemento-estado-cuenta', event_label: 'financiamiento', value: 1 })"
              :href="linkDescargaComplemento"
              target="_blank"
            ): i.fas.fa-download
          //- .col-3
            button.btn.btn-primary.text-white Solicita tu Domiciliación
    .row.mt-3
      .col-md-12: .card: .card-body.card-top(style="background: #5935df")
        .row
          .col-4
            .input-group
              label.input-group-text.bg-white(
                style="border-top-left-radius: 90px; border-bottom-left-radius: 90px; border-right: none"
                :for="componentId + 'BusquedaContrato'"
              ): em.fas.fa-search
              input.form-control(
                type="search"
                placeholder="Búsqueda por Contrato"
                v-model="filtroContrato"
                style="border-top-right-radius: 90px; border-bottom-right-radius: 90px; border-left: none"
                :id="componentId + 'BusquedaContrato'"
              )
          .col-8.text-end.text-white
            span.fw-bold.me-3 Agrupar por:
            .form-check.form-check-inline
              input.form-check-input(
                type="radio"
                :name="componentId + 'TipoAgrupacion'"
                :id="componentId + 'TipoAgrupacion0'"
                value="grupo"
                v-model="tipoAgrupacion"
              )
              label.form-check-label(:for="componentId + 'TipoAgrupacion0'") Grupo
            .form-check.form-check-inline
              input.form-check-input(
                type="radio"
                :name="componentId + 'TipoAgrupacion'"
                :id="componentId + 'TipoAgrupacion1'"
                value="etapa"
                v-model="tipoAgrupacion"
              )
              label.form-check-label(:for="componentId + 'TipoAgrupacion1'") Etapa de Contrato
            .form-check.form-check-inline
              input.form-check-input(
                type="radio"
                :name="componentId + 'TipoAgrupacion'"
                :id="componentId + 'TipoAgrupacion2'"
                value="garantia"
                v-model="tipoAgrupacion"
              )
              label.form-check-label(:for="componentId + 'TipoAgrupacion2'") Inmueble
            .dropdown.d-inline-block
              button.btn.btn-outline-white.dropdown-toggle.bg-white(data-bs-toggle="dropdown" style="border-radius: 90px") {{ (periodoSeleccionado || {}).texto }}
              ul.dropdown-menu
                li: a.dropdown-item(v-for="periodo in catalogos.meses.value" :key="periodo.texto" href="#" @click.prevent="seleccionarPeriodo(periodo)") {{ periodo.texto }}
      .col-md-12: .card: .card-body
        .row
          .col-6
            p.m-2.font-weight-bold Da click en el contrato para descargar tu Estado de Cuenta
          .col-3
            button.btn.btn-primary.text-white.fw-bold(href="#" style="border-radius: 16px; font-size: 14px" @click.prevent="abrirModalDescuento()") Mis Descuentos del Mes
          .col-3
            button.btn.btn-primary.text-white.fw-bold(href="#" style="border-radius: 16px; font-size: 14px" @click="mostrarTablaAclaraciones()") Seguimiento de Aclaraciones
            //- Modal Descuentos
            #descuentosModal.modal.fade(tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true")
              .modal-dialog.modal-dialog-centered.mw-100.w-75(role="document")
                .modal-content
                  .modal-header
                    h3#descuentosModalLabel.modal-title.m-2(style="color: #34189a; font-weight: bold") Mis descuentos
                  .modal-body
                    .row.text-center
                      .col-md-12: h4.m-2.font-weight-bold(style="color: #34189a; font-weight: bold") Total de descuentos
                    .row.mb-3.mt-3
                      .col-md-12.text-center
                        .card.mb-2(style="border-radius: 8px; border: none"): .card-body(style="background: #2C2666; padding: 16px 26px; color: white; border-radius: 12px")
                          .row
                            .col-md-12
                              p.text-white En este mes el ahorro acumulado de tus&#32;
                                span.fw-bold(style="color: #ffa000") {{ numContratos }} Contratos&#32;
                                | ha sido:
                            .col-md-12
                              p.h4.text-center.text-white(style="font-weight: bold") {{ filtros.filtroDivisa(utilidades.ifNaN(descuentosPago)) }}
                                
                    .row: .col-md-12.acordion-discount
                      .accordion.shadow.mb-4(:id="componentId + 'acordionDetalle'")
                        .accordion-item
                          h2.accordion-header.text-center: span.accordion-button.collapsed.fw-bold(
                            data-bs-toggle="collapse"
                            :data-bs-target="'#' + componentId + 'detalledescuentoscontrato'"
                          ): div(
                            style="margin-left: 34%"
                          ) Conoce a detalle tus descuentos aquí
                          .accordion-collapse.collapse(:id="componentId + 'detalledescuentoscontrato'" :data-bs-parent="'#' + componentId + 'acordionDetalle'"): .accordion-body
                            .row
                              .col-md-4.mb-3(v-for="descuento in descuentos" :key="descuento.NumeroContrato")
                                .card(style="border: none; box-shadow: 0px 0px 4px 4px #0000000D;")
                                  .row.m-0(style="background: #2C2666; border-radius: 8px 8px 0 0; border: none; color: white")
                                    .col-12
                                      p.text-center.m-0.mt-3.mb-2(style="font-weight: 600") Contrato: {{ descuento.NumeroContrato }}                           
                                  .row.m-3.align-items-center(style="color: #2C2666")(v-for="(d, index) in descuento.Descuentos" :key="index")
                                    .col-12
                                      .row(style="font-size: 11px;")
                                        .col-6.p-0
                                          p.m-0 Vencimiento: {{ filtros.formatoFecha(d.FechaVencimiento) }}
                                        .col-6.text-end.p-0
                                          p.m-0 No. Recibo {{ d.NumeroRecibo }}
                                    .col-6.p-0(style="font-weight: 600")
                                      p.m-0 {{ d.NombreMotivo }}
                                    .col-6.p-0.text-end(style="font-weight: 600")
                                      p.m-0 {{ filtros.filtroDivisa(d.Monto) }}                                                    
                    .row: .col-md-12.acordion-discount
                      .accordion.shadow.mb-4(:id="componentId + 'acordionVendedor'")
                        .accordion-item
                          h2.accordion-header.text-center: span.accordion-button.collapsed.fw-bold(
                            data-bs-toggle="collapse"
                            :data-bs-target="'#' + componentId + 'detalledescuentos'"
                          ): div(
                            style="margin-left: 35%"
                          ) ¿Cómo obtengo los descuentos?
                          .accordion-collapse.collapse(:id="componentId + 'detalledescuentos'" :data-bs-parent="'#' + componentId + 'acordionVendedor'"): .accordion-body
                            .row.justify-content-md-center.d-flex
                              .col-md-2: .card.mb-2(style="border-radius: 12px; border: 1px solid #94a3b0"): .card-body(
                                style="background-color: white; border-radius: 12px"
                              )
                                .row.justify-content-md-center
                                  .col-md-12: p.text-center.fw-bold(style="color: #302d74") Domiciliación Inicial 5%
                                  .col-md-12: p.mt-4
                                  .col-md-12: p.text-center(style="color: #444444") Lo obtienes al domiciliar el día que contrataste.
                                  .col-md-12: p.mt-4
                                  .col-md-12: br
                              .col-md-2: .card.mb-2(style="border-radius: 12px; border: 1px solid #94a3b0"): .card-body(
                                style="background-color: white; border-radius: 12px"
                              )
                                .row.justify-content-md-center
                                  .col-md-12: p.text-center.fw-bold(style="color: #302d74") Domiciliación Posterior 2.5%
                                  .col-md-12: p.text-center(style="color: #444444") Lo puedes obtener al domiciliar después de haber contratado.
                              .col-md-2: .card.mb-2(style="border-radius: 12px; border: 1px solid #94a3b0"): .card-body(
                                style="background-color: white; border-radius: 12px"
                              )
                                .row.justify-content-md-center
                                  .col-md-12: p.text-center.fw-bold(style="color: #302d74") Score de Buro de Credito 5%
                                  .col-md-12: p.mt-4
                                  .col-md-12: p.text-center(style="color: #444444") Lo obtienes al contratar o al escriturar.
                                  .col-md-12: p.mt-4
                                  .col-md-12: br
                              .col-md-2: .card.mb-2(style="border-radius: 12px; border: 1px solid #94a3b0"): .card-body(
                                style="background-color: white; border-radius: 12px"
                              )
                                .row.justify-content-md-center
                                  .col-md-12: p.text-center.fw-bold(style="color: #302d74") Pago Puntual Inicial 5%
                                  .col-md-12: p.text-center(style="color: #444444") Lo obtienes al realizar tus pagos puntualmente de forma continua (al 6º mes).
                              .col-md-2: .card.mb-2(style="border-radius: 12px; border: 1px solid #94a3b0"): .card-body(
                                style="background-color: white; border-radius: 12px"
                              )
                                .row.justify-content-md-center
                                  .col-md-12: p.text-center.fw-bold(style="color: #302d74") Pago Puntual Sostenido 5%
                                  .col-md-12: p.mt-4
                                  .col-md-12: p.text-center(style="color: #444444") Lo obtienes al mantener tus pagos puntuales (al 13º mes).
                                  .col-md-12: p.mt-4
                              .col-md-2: .card.mb-2(style="border-radius: 12px; border: 1px solid #94a3b0"): .card-body(
                                style="background-color: white; border-radius: 12px"
                              )
                                .row.justify-content-md-center
                                  .col-md-12: p.text-center.fw-bold(style="color: #302d74") Referidos 5%
                                  .col-md-12: p.text-center(style="color: #444444") Lo obtienes en todos tus contratos cuando tu referido firma con nosotros (por 12º mes).
                              .col-md-12: p.text-primary.fw-bold * Aplica Restricciones.
                  .modal-footer
                    button.btn.text-white.fw-bold(data-dismiss="modal" @click="ocultarPopUp()")(style="background-color: #2C2666") Cerrar
            #tablaAclaraciones.modal.fade(tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true")
              .modal-dialog.modal-dialog-centered.mw-100.w-75(role="document")
                .modal-content
                  .modal-header
                    h3#tablaAclaracionesLabel.modal-title.m-2(style="color: #34189a; font-weight: bold") Seguimiento de Aclaraciones
                  .modal-body
                    .row(style="text-align: center")
                      table.table.table-striped(style="padding: 4px")
                        thead
                          tr 
                            th Folio
                            th Contratos Involucrados
                            th Tipo de Aclaración
                            th Estatus
                        tbody
                          tr(v-for="(item, i) in listaAclaraciones" :key="i")
                            td.fw-bold {{ item.aclaracionId }}
                            td {{ item.numeroContrato }}
                            td {{ obtenertipoAclaracion(item.tipoAclaracionId) }}
                            td.fw-bold(
                              v-if="item.fechaTerminacion?.slice(0, 10) == '2000-01-01' ? (estatus = 'En proceso') : (estatus = 'Finalizado')"
                              :style="item.fechaTerminacion?.slice(0, 10) == '2000-01-01' ? 'color: green' : 'color: red'"
                            ) {{ estatus }}
                  .modal-footer
                    button.btn.btn-primary.text-white.fw-bold(data-dismiss="modal" @click="ocultarTabla()") Cerrar
        template(v-for="(grupo, i) in catalogosContratosGrupos")
          .row: .col.text-end
            span(v-if="tipoAgrupacion == 'grupo'") GRUPO {{ grupo }}
            span(v-if="tipoAgrupacion == 'etapa'") ETAPA DE CONTRATO {{ grupo }}
            template(v-if="tipoAgrupacion == 'garantia'")
              .input-group.me-2.d-inline-flex.w-auto(style="border-radius: 90px; background-color: #94a3b0; min-width: 200px" v-if="grupo != '-1'")
                label.input-group-text.border-none.bg-transparent.text-white(:for="componentId + 'inputAliasGarantia' + i"): i.fas.fa-pen
                input.form-control.text-white.ph-text-white.bg-transparent.border-none(
                  :id="componentId + 'inputAliasGarantia' + i"
                  :ref="el => inputGarantiaAliasRef(el, i)"
                  type="text"
                  :value="obtenerAliasGarantia(grupo)"
                  placeholder="Agregar Alias"
                  style="font-weight: 600"
                  maxlength="150"
                  :pattern="utilidades.patronAlfaNumEspacio"
                  @change.prevent="actualizarAliasGarantia(grupo, i)"
                )
              span(style="color: #94a3b0; font-weight: 600") {{ obtenerProductoContratado(grupo) }}
            a.d-inline-block.text-center.text-decoration-none.ms-2(href="#" @click.prevent="obtenerManualConsumidor(grupo)" v-if="tipoAgrupacion == 'grupo'") Manual<br><em class="fas fa-file-pdf text-primary"></em>
            //- a.d-inline-block.text-center.text-decoration-none.ms-2(href="#") Contrato<br><em class="fas fa-file-pdf text-primary"></em>
          hr
          .row(style="margin-bottom: 5rem")
            .col-md-2(v-for="contrato in obtenerContratosGrupo(grupo)")
              .card: a.card-body.d-block.text-decoration-none.btn.btn-outline-primary(
                :href="contrato.linkEstadoCuentaContrato[(periodoSeleccionado || {}).i || 0]"
                :title="'Descargar estado de cuenta - ' + (periodoSeleccionado || {}).texto"
                target="_blank"
              )
                p.text-center {{ contrato.numeroContrato }}
                p 
                  | {{ filtros.filtroDivisa(contrato.totalPagar) }}
                  em.fas.fa-circle.ms-2(
                    :class="{ 'text-success': contrato.totalPagar == 0, 'text-danger': contrato.vencido, 'text-warning': !contrato.vencido && contrato.totalPagar != 0 }"
                  )
          .row(v-if="mostrarTabla" style="text-align: center")
            .col(style="text-align: center; margin-bottom: 30px")
              h2 Seguimiento de Aclaraciones
            .row
              table.table.table-striped(style="padding: 4px")
                thead
                  tr 
                    th Folio
                    th Contratos Involucrados
                    th Tipo de Aclaración
                    th Estatus
                tbody
                  tr(v-for="(item, i) in listaAclaraciones" :key="i")
                    td.fw-bold {{ item.aclaracionId }}
                    td {{ item.numeroContrato }}
                    td {{ obtenertipoAclaracion(item.tipoAclaracionId) }}
                    td.fw-bold(
                      v-if="item.fechaTerminacion?.slice(0, 10) == '2000-01-01' ? (estatus = 'En proceso') : (estatus = 'Finalizado')"
                      :style="item.fechaTerminacion?.slice(0, 10) == '2000-01-01' ? 'color: green' : 'color: red'"
                    ) {{ estatus }}
</template>

<style lang="sass" scoped>
.card-top
  border-top-left-radius: 8px
  border-top-right-radius: 8px

.acordion-discount .accordion-button:not(.collapsed) 
  color: white
  background-color:  #2C2666

.acordion-discount .accordion-button:not(.collapsed)::after 
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0id2hpdGUiIGNsYXNzPSJiaSBiLWNoZXZyb24tZG93biIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICA8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xLjY0NiA0LjY0NmEuNS41IDAgMCAxIC43MDggMEw4IDEwLjI5M2w1LjY0Ni01LjY0N2EuNS41IDAgMCAxIC43MDguNzA4bC02IDZhLjUuNSAwIDAgMS0uNzA4IDBsLTYtNmEuNS41IDAgMCAxIDAtLjcwOHoiLz4KPC9zdmc+')
  background-size: 1.3em
  background-repeat: no-repeat
  background-position: center
  content: ""
</style>

<script lang="ts" setup>
import { toRefs, reactive, computed, ref, watch, onMounted, nextTick } from "vue";
import api from "@/servicios/apiCliente";
import { DateTime } from "luxon";
import { Cliente } from "@/servicios/api";
import * as utilidades from "@/utilidades";
import filtros from "@/composables/filtros";
import * as bootstrap from "bootstrap";
import { useStore } from "@/store";
import { TipoBootstrap } from "@/interfaces";
import type * as RuntimeCore from "@vue/runtime-core";

interface Periodo {
  i: number;
  texto: string;
}

//#region Data
const $store = useStore();
const componentId = utilidades.generarId();
const tieneComplementoEstadoCuenta = ref(undefined as boolean | undefined);
const totalPagar = ref(0 as number | undefined);
const linkDescargaComplemento = ref("" as string | undefined);
const nombreCliente = "";
const catalogos = toRefs(
  reactive({
    meses: [] as Periodo[],
    contratos: [] as Cliente.Financiamiento.InformacionFinanciamientoContrato[]
  })
);
const periodoSeleccionado = ref(null as Periodo | null);
const filtroContrato = ref("");
const tipoAgrupacion = ref("grupo");
const tabla = null as any;
const descuentos = ref();
const pagoMes = ref(0);
const descuentosPago = ref(0);
const numContratos = ref(0);
const descuentoDomInicial = ref(0);
const descuentoPostDomi = ref(0);
const descuentoScore = ref(0);
const descuentoPPI = ref(0);
const descuentoPPS = ref(0);
const descuentoRef = ref(0);
const totalContratos = ref(0);
let modalDescuentos = null as null | bootstrap.Modal;
const inputGarantiaAlias = ref([] as (Element | HTMLInputElement)[]);
const inputGarantiaAliasRef = (el: Element | RuntimeCore.ComponentPublicInstance | null, i: number) => {
  if (el) inputGarantiaAlias.value[i] = el as Element;
};
const listaAclaraciones = ref([] as Cliente.Cliente.TablaAclaracion[] | undefined);
const estatus = ref("");
const tipoAclaracionFor = ref("");
const contrato = ref("");
const mostrarTabla = ref(false);
let modalAclaraciones = null as null | bootstrap.Modal;
//#endregion

//#region Computed
const catalogosContratosGrupos = computed((): string[] => {
  if (!catalogos.contratos.value.length) return [] as string[];
  return utilidades
    .arrayDistinct(catalogos.contratos.value, (a: any, b: any) => a[tipoAgrupacion.value] == b[tipoAgrupacion.value])
    .map((a: any) => a[tipoAgrupacion.value] || "");
});
//#endregion

//#region Watch
watch(periodoSeleccionado, async (val: Periodo | null) => {
  if (val) {
    let contratos = (await api.Cliente.Cliente.FinanciamientoApi.ObtenerContratos(1)).data;
    if (contratos.length) catalogos.contratos.value = contratos;
  }
});
//#endregion

//#region Methods
function ocultarPopUp() {
  modalDescuentos?.hide();
}
function ocultarTabla() {
  modalAclaraciones?.hide();
}
function abrirModalDescuento() {
  modalDescuentos?.show();
}
function mostrarTablaAclaraciones() {
  modalAclaraciones?.show();
}
function seleccionarPeriodo(periodo: Periodo) {
  periodoSeleccionado.value = periodo;
}
function obtenerContratosGrupo(grupo: string) {
  if (grupo.length == 3) grupo = "0" + grupo;
  return catalogos.contratos.value.filter(
    a =>
      ((tipoAgrupacion.value == "grupo" && a.numeroContrato?.startsWith(grupo)) ||
        (tipoAgrupacion.value == "etapa" && a.etapa == grupo) ||
        (tipoAgrupacion.value == "garantia" && a.garantia.toString() == grupo)) &&
      (filtroContrato.value == "" ||
        (tipoAgrupacion.value == "grupo" && a.numeroContrato?.includes(filtroContrato.value)) ||
        (tipoAgrupacion.value == "etapa" && a.etapa?.includes(filtroContrato.value)) ||
        (tipoAgrupacion.value == "garantia" && a.garantia.toString()?.includes(filtroContrato.value)))
  );
}
async function obtenerManualConsumidor(grupo: string) {
  let res = await api.Cliente.Cliente.FinanciamientoApi.ObtenerPdfManualConsumidor(
    catalogos.contratos.value.find(a => a.grupo == grupo)?.contratoId ?? 0
  );
  utilidades.descargarBlob(res.data, "Manual del consumidor.pdf");
}
function obtenerProductoContratado(grupo: string) {
  if (tipoAgrupacion.value != "garantia") return "";
  return catalogos.contratos.value.find(a => a.garantia.toString() == grupo)?.productoContratado?.replace(/_/g, " ");
}
function obtenerAliasGarantia(grupo: string) {
  if (tipoAgrupacion.value != "garantia") return "";
  return catalogos.contratos.value.find(a => a.garantia.toString() == grupo)?.aliasGarantia;
}
async function actualizarAliasGarantia(grupo: string, i: number) {
  let contratoLocal = catalogos.contratos.value.find(a => a.garantia.toString() == grupo);
  if (contratoLocal) {
    if (!inputGarantiaAlias.value[i].validity.valid) {
      $store.agregarToast({
        tipo: TipoBootstrap.WARNING,
        body: "Carácteres inválidos"
      });
      contratoLocal.aliasGarantia = "";
    } else {
      contratoLocal.aliasGarantia = inputGarantiaAlias.value[i].value.trim();
    }
    if (contratoLocal.aliasGarantia) {
      let res = (
        await api.Cliente.Cliente.FinanciamientoApi.ActualizarAliasGarantia(Number(grupo), contratoLocal.aliasGarantia)
      ).data;
      $store.agregarToast(res);
      contratoLocal.aliasGarantia = res.data;
    }
  }
}

async function obtenerAclaracion() {
  let aclaracion = (await api.Cliente.Cliente.AclaracionApi.ObtenerAclaraciones()).data;
  listaAclaraciones.value = aclaracion;
}

function obtenertipoAclaracion(id: number) {
  if (id == 1) return "Dupliqué mi pago";
  else if (id == 2) return "No aparece mi pago";
  else if (id == 3) return "No reconozco un pago";
  else if (id == 4) return "Excedí mi pago";
  else return "Tengo dudas de mi pago";
}

const gt = gtag;
//#endregion

onMounted(async () => {
  let fecha = DateTime.now().setLocale("es-MX");
  let formato: Intl.DateTimeFormatOptions = {
    month: "long",
    year: "numeric"
  };
  for (let i = 0; i < 13; i++) {
    catalogos.meses.value.push({ i, texto: fecha.toLocaleString(formato) });
    fecha = fecha.minus({ months: 1 });
  }
  periodoSeleccionado.value = catalogos.meses.value[0];
  api.Cliente.Cliente.FinanciamientoApi.ObtenerDescuentosUltimoMesTodosContratos().then( a => {
    descuentos.value = JSON.parse(a.data.data);
    numContratos.value = descuentos.value
      .map(item => item.NumeroContrato)
      .filter((value, index, self) => self.indexOf(value) === index).length;
    descuentos.value.forEach(contrato => {
        contrato.Descuentos.forEach(descuento => {
            descuentosPago.value += descuento.Monto;
        });
    });
  });
  api.Cliente.Cliente.FinanciamientoApi.ObtenerInformacion(1).then(a => {
    let informacionFinanciamiento = a.data;
    if (informacionFinanciamiento.linkDescargaComplemento) {
      totalPagar.value = informacionFinanciamiento.totalPagar;
      tieneComplementoEstadoCuenta.value = informacionFinanciamiento.tieneComplementoEstadoCuenta;
      linkDescargaComplemento.value = informacionFinanciamiento.linkDescargaComplemento;
    }
  });
  obtenerAclaracion();
  await nextTick();
  modalDescuentos = new bootstrap.Modal(document.querySelector("#descuentosModal") as HTMLDivElement);
  modalAclaraciones = new bootstrap.Modal(document.querySelector("#tablaAclaraciones") as HTMLDivElement);
});
</script>
