function initializeGenesys() {
    const g = window;
    const e = 'Genesys';
    const n = 'https://apps.usw2.pure.cloud/genesys-bootstrap/genesys.min.js';
    const es = {
      environment: 'prod-usw2',
      deploymentId: '9822f2a3-4996-49f5-8be5-21bf880220d5'
    };
  
    g['_genesysJs'] = e;
    g[e] = g[e] || function () {
      (g[e].q = g[e].q || []).push(arguments);
    };
    g[e].t = 1 * new Date();
    g[e].c = es;
  
    const ys = document.createElement('script');
    ys.classList.add('bg-success')
    ys.async = 1;
    ys.src = n;
    ys.charset = 'utf-8';
    document.head.appendChild(ys);
    ys.click()
  }

  export default initializeGenesys