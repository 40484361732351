<template lang="pug">
.container.ff-poppins.pb-4(:class="{ 'was-validated': formularioValidado }")
  generar-pin(:MediosContactos="mediosContactos" ref="GeneradorPin" @pin-validado="getValidacion")
  .row(style="margin: 50px auto 20px auto"): h1.fw-bold Domiciliación
  .row(style="background-color: white; border-radius: 10px; box-shadow: 4px 4.5px 10px -6px black" v-if="mostrarFormulario")
    .col-md-5(style="margin: 0 auto; padding: 40px 40px")
      .row 
        h5.fw-bold Contratos Domiciliados
        .row(style="padding: 20px 40px")
          .col-md-7
            .row(style="margin: 0 0 10px 0")
              h5.fw-bold Contrato
            .row(v-for="(contrato, index) in listaContratos" :key="index")
              .col-md-10(style="padding: 0 0 0 25px")
                p(v-if="contrato.esDomiciliado != null") {{ contrato.numeroContrato }}
          .col-md-5
            .row(style="margin: 0 0 10px -13px")
              h5.fw-bold Cuota*
            .row(v-for="(contrato, index) in listaContratos" :key="index")
              .col-md-12
                p(v-if="contrato.esDomiciliado != null") {{ filtros.filtroDivisa(contrato.montoCoutaVigente) }} mxn
      h5.fw-bold Selecciona los contratos a domiciliar
      .row(style="padding: 20px 40px")
        .col-md-7
          .row(style="margin: 0 0 10px 0")
            h5.fw-bold Contrato
          .row(v-for="(contrato, index) in listaContratos" :key="index")
            .col-md-1(v-if="contrato.esDomiciliado == null")
              input(type="checkbox" :id="contrato.contratoId.toString()" :value="contrato.contratoId")
            .col-md-10(v-if="contrato.esDomiciliado == null")
              p {{ contrato.numeroContrato }}
        .col-md-5
          .row(style="margin: 0 0 10px -13px")
            h5.fw-bold Cuota*
          .row(v-for="(contrato, index) in listaContratos" :key="index")
            .col-md-12(v-if="contrato.esDomiciliado == null")
              p {{ filtros.filtroDivisa(contrato.montoCoutaVigente) }} mxn
      .row 
        p(style="font-size: 15px") *Cuota vigente hasta dd/mm/aaaa
    .col-md-1(style="border-right: 1px solid black; height: 300px; margin: 40px 0 0 0")
    .col-md-6
      .row(style="padding: 40px 0 5px 20px") 
        h5.fw-bold Selecciona si es la cuenta del titular o de un tercero
      .row(style="padding: 0 20px") 
        .col-md-4(style="margin: 0 -40px 0 0")
          button(
            v-on:click="obtenerDatosCliente()"
            style="border-radius: 15px; padding: 10px; background-color: white"
            :style="cuentaSeleccionada == true ? 'background-color: rgb(89, 53, 223); color: white;' : 'border: 1px solid black;'"
          ) Cuenta Titular
        .col-md-4
          button(
            v-on:click="obtenerDatosTercero()"
            style="background-color: white; border-radius: 15px; padding: 10px 20px"
            :style="cuentaSeleccionada == false ? 'background-color: rgb(89, 53, 223); color: white;' : 'border: 1px solid black;'"
          ) Cuenta Tercero
        .col-md-4
      .row(style="padding: 70px 0 10px 20px" v-if="cuentaSeleccionada == 'cliente'")
        h5.fw-bold Ingresa los datos bancarios
      .row(style="padding: 0 20px" v-if="cuentaSeleccionada == 'cliente'") 
        .col-md-6
          p(style="color: #2f2d74") Nombre(s) *
          input.form-control(type="text" v-model="nombre" :form="componentId + 'form'" pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+")
        .col-md-6
          p(style="color: #2f2d74") Apellido Paterno *
          input.form-control(type="text" v-model="apellidoPaterno" :form="componentId + 'form'" pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+")
        .col-md-6
          p(style="color: #2f2d74") Apellido Materno *
          input.form-control(type="text" v-model="apellidoMaterno" :form="componentId + 'form'" pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+")
      .row(style="padding: 20px 20px" v-if="cuentaSeleccionada == 'cliente'") 
        .col-md-6
          p(style="color: #2f2d74") Teléfono de contacto *
          input.form-control(type="tel" v-model="telefonoTercero" pattern="[0-9]{10}")
        .col-md-6
          p(style="color: #2f2d74") Banco *
          select.form-select(v-model="datosCliente.banco.bancoId" :form="componentId + 'form'"): option(v-for="banco in bancos" :value="banco.bancoId") {{ banco.nombre }}
        .col-md-6
          p(style="color: #2f2d74") Clabe *
          input.form-control(type="text" v-model="datosCliente.clabe" :form="componentId + 'form'" pattern="[0-9]+")
      .row.mt-3(style="margin: 0 0 0 10px" v-if="cuentaSeleccionada == 'cliente'") 
        .col-md-5(style="margin: 0 20px 0 10px")
          .row: h6 Identificación oficial*
          .row
            input#IDtercero(type="file" v-on:change="obtenerArchivoID($event)" hidden)
            label(for="IDtercero" style="background-color: rgb(224, 231, 234); border-radius: 10px; padding: 5px 20px") Arrastra tus archivos aqui o Búscalos
          .row(style="text-align: center; margin: 10px 0 0 0")
            a.fw-bold(v-if="motsrarNombreArchivoID == false ? true : false" :href="visualizarArchivo(archivoID)" target="_blank") {{ archivoID.name }}
        .col-md-5
          .row: h6 Estado de cuenta bancario*
          .row
            input#Edotercero(type="file" v-on:change="obtenerArchivoEdo($event)" hidden)
            label(for="Edotercero" style="background-color: rgb(224, 231, 234); border-radius: 10px; padding: 5px 20px") Arrastra tus archivos aqui o Búscalos
          .row(style="text-align: center; margin: 10px 0 0 0")
            a.fw-bold(v-if="motsrarNombreArchivoEdo == false ? true : false" :href="visualizarArchivo(archivoEdo)" target="_blank") {{ archivoEdo.name }}
      .row.mt-3(style="margin: 0 0 0 10px" v-if="cuentaSeleccionada == 'tercero'")
        .col-md-6
          p(style="color: #2f2d74") Nombre de Tercero:*
          input.form-control(type="text" v-model="apellidoPaterno" :form="componentId + 'form'" pattern="[a-zA-ZñÑéÉáÁíÍóÓúÓ ]+")
        .col-md-6 
          p(style="color: #2f2d74") Teléfono de contacto*
          input.form-control(type="text" v-model="telefonoTercero" pattern="[0-9]{10}")
        .col-md-6
          p(style="color: #2f2d74") Banco*
          select.form-select(v-model="datosCliente.banco.bancoId" :form="componentId + 'form'")
            option(v-for="banco in bancos" :value="banco.bancoId") {{ banco.nombre }}
        .col-md-6
          p(style="color: #2f2d74") Clabe*
          input.form-control(type="text" v-model="datosCliente.clabe" :form="componentId + 'form'" pattern="[0-9]+")
        .col-md-5(style="margin: 0 20px 0 10px")
          .row: h6 Identificación oficial del tercero*
          .row
            input#IDtercero(type="file" v-on:change="obtenerArchivoIdTercero($event)" hidden)
            label(for="IDtercero" style="background-color: rgb(224, 231, 234); border-radius: 10px; padding: 5px 20px") Arrastra tus archivos aqui o Búscalos
          .row(style="text-align: center; margin: 10px 0 0 0")
            a.fw-bold(v-if="motsrarNombreArchivoIDTercero == false ? true : false" :href="visualizarArchivo(archivoIDTercero)" target="_blank") {{ archivoIDTercero.name }}
        .col-md-5
          .row: h6 Estado de cuenta bancario del tercero*
          .row
            input#Edotercero(type="file" v-on:change="obtenerArchivoEdoTercero($event)" hidden)
            label(for="Edotercero" style="background-color: rgb(224, 231, 234); border-radius: 10px; padding: 5px 20px") Arrastra tus archivos aqui o Búscalos
          .row(style="text-align: center; margin: 10px 0 0 0")
            a.fw-bold(v-if="motsrarNombreArchivoEdoTercero == false ? true : false" :href="visualizarArchivo(archivoEdoTercero)" target="_blank") {{ archivoEdoTercero.name }}
      .row.mt-3(style="margin: 0 0 0 10px" v-if="cuentaSeleccionada == 'tercero'") 
        .col-md-5(style="margin: 0 20px 0 10px")
          .row: h6 Descarga el formato de domiciliación*
            h6 Llena los datos
          .row: a.fw-bold(
            href="Documentos/ATC-FO-145FORMATOUNICOPARADOMICILIACIONDEMENSUALIDADESAHIRCASAV1.doc"
            style="background-color: rgb(89, 53, 223); text-decoration: none; color: white; border-radius: 10px; padding: 5px 20px"
          ) Descarga formato de domiciliación
        .col-md-5.mt-4
          .row: h6 Carga el formato de domiciliación*
          .row
            input#CargaTercero(type="file" v-on:change="cargaFormato($event)" hidden)
            label(for="CargaTercero" style="background-color: rgb(224, 231, 234); border-radius: 10px; padding: 5px 20px") Arrastra tus archivos aqui o Búscalos
          .row(style="text-align: center; margin: 10px 0 0 0")
            a.fw-bold(v-if="motsrarNombreArchivoFormato == false ? true : false" :href="visualizarArchivo(formato)" target="_blank") {{ formato.name }}
      .row(style="margin: 40px 0 40px 0") 
        .col-md-4
        .col-md-3
          button(
            style="width: 100%; border-radius: 10px; border: 1px solid rgb(235, 129, 19); color: rgb(235, 129, 19); padding: 8px 30px; background-color: white"
          ) Cancelar
        .col-md-5
          button.fw-bold(
            :form="componentId + 'form'"
            style="width: 100%; border-radius: 10px; padding: 8px 60px; background-color: rgb(235, 129, 19); color: white; border: none"
            v-on:click="capturaDatosDomiciliacion()"
          ) Continuar
  .card(v-if="!mostrarFormulario"): .card-body
    .row
      .col-md-12
        Spinner-Loader#spinner(name="spinner" style="width: 100%; display: none")
        .row: .col-md-12
          h6.fw-bold Resumen de los contratos a domiciliar
        .row 
          .col-md-3(style="background-color: rgb(89, 53, 223); border-radius: 10px; color: white; text-align: center; padding: 20px 0; margin: 20px 0 0 10px")
            .row(style="text-align: center") 
              h6 Monto a domiciliar
            .row(style="margin: 0 0 20px 0") 
              h6.fw-bold {{ filtros.filtroDivisa(informacionContratosGuardados.sumaCuotas) }} mxn
            .row 
              h6 {{ (nombre || "") + " " + (apellidoPaterno || "") + " " + (apellidoMaterno || "") }}
            .row 
              h6.fw-bold {{ datosCliente.clabe }}
            .row 
              h6.fw-bold {{ datosCliente.banco.nombre }}
          .col-md-1
          .col-md-7(style="margin: 20px")
            .row 
              h6.fw-bold Próxima aplicación del pago 03 / Enero /2022
            .row(style="margin: 10px 0 0 -10px") 
              .col-md-4
                .row(style="margin: 0 0 10px -15px")
                  h6.fw-bold Contrato
                .row(v-for="contrato in informacionContratosGuardados.numeroContrato")
                  p {{ contrato }}
              .col-md-4
                .row(style="margin: 0 0 10px -15px")
                  h6.fw-bold Cuota *
                .row(v-for="cuota in informacionContratosGuardados.cuota") 
                  p {{ filtros.filtroDivisa(cuota) }} mxn
              .col-md-4
                .row(style="margin: 0 0 10px -15px")
                  h6.fw-bold Adeudo
                .row(v-for="cuota in informacionContratosGuardados.cuota") 
                  p {{ filtros.filtroDivisa(0) }} mxn
          .row(style="margin: 40px 0 40px 0") 
            .col-md-7
            .col-md-2
              button(
                style="width: 100%; border-radius: 10px; border: 1px solid rgb(235, 129, 19); color: rgb(235, 129, 19); padding: 8px 30px; background-color: white"
                v-on:click="regresarFormularioDomiciliacion()"
              ) Cancelar
            .col-md-3
              button.fw-bold(
                :form="componentId + 'form'"
                style="width: 100%; border-radius: 10px; padding: 8px 60px; background-color: rgb(235, 129, 19); color: white; border: none"
                v-on:click="guardarDatosDomiciliacion()"
              ) Continuar
</template>

<script lang="ts" setup>
import { Cliente, Core } from "@/servicios/api";
import api from "@/servicios/apiCliente";
import { onMounted, reactive, ref } from "vue";
import * as utilidades from "@/utilidades";
import { useStore } from "@/store";
import filtros from "@/composables/filtros";
import $, { isEmptyObject } from "jquery";
import GenerarPin from "../components/GeneradorPin.vue";
import * as bootstrap from "bootstrap";
interface MediosContacto {
  valor: string;
  tipo: number;
}
interface Archivos {
  nombreArchivo: File;
  tipoDocDomiciliacion: Core.Enums.TipoDocumentoDomiciliacion;
}
const $store = useStore();
const archivosDomiciliacion = ref([] as Archivos[]);
const mediosContactos = ref([] as MediosContacto[]);
const datosCliente = ref({} as Cliente.Cliente.BodyDomiciliacion);
const bancoId = ref(1);
const bancos = ref([] as Cliente.Cliente.BodyBanco[]);
const nombre = ref("" as string | undefined);
const nombreTercero = ref("");
const apellidoPaterno = ref("" as string | undefined);
const apellidoMaterno = ref("" as string | undefined);
const cuentaSeleccionada = ref(false);
const componentId = utilidades.generarId();
const form = ref(null as HTMLFormElement | null);
const formularioValidado = ref(false);
const listaContratos = ref([] as Cliente.Financiamiento.InformacionFinanciamientoContrato[]);
const mostrarFormulario = ref(true);
const contratosGuardados = ref([] as Cliente.Financiamiento.InformacionFinanciamientoContrato[]);
const archivoID = ref({} as File);
const archivoEdo = ref({} as File);
const archivoIDTercero = ref({} as File);
const archivoEdoTercero = ref({} as File);
const formato = ref({} as File);
const motsrarNombreArchivoID = ref(true);
const motsrarNombreArchivoEdo = ref(true);
const motsrarNombreArchivoIDTercero = ref(true);
const motsrarNombreArchivoEdoTercero = ref(true);
const motsrarNombreArchivoFormato = ref(true);
const informacionContratosGuardados = reactive({
  numeroContrato: [] as string[],
  cuota: [] as number[],
  sumaCuotas: 0
});
const telefonoTercero = ref("");
const validarPin = ref(false);
const contratosDomiciliados = ref<boolean>()
async function getValidacion(value: boolean) {
  validarPin.value = value;
  let archivosEnviar = [] as Archivos[];
  if (validarPin.value) {
    if (cuentaSeleccionada.value != "tercero") {
      datosCliente.value.nombreTercero = "";
      archivosEnviar = archivosDomiciliacion.value.filter((elemento) => {
        elemento.tipoDocDomiciliacion == Core.Enums.TipoDocumentoDomiciliacion.Identificacion_Oficial_Tercero ||
          Core.Enums.TipoDocumentoDomiciliacion.Estado_Cuenta_Tercero ||
          Core.Enums.TipoDocumentoDomiciliacion.Formato_Domiciliacion_Tercero;
        return elemento;
      });
    } else {
      archivosEnviar = archivosDomiciliacion.value.filter((elemento) => {
        elemento.tipoDocDomiciliacion == Core.Enums.TipoDocumentoDomiciliacion.Estado_Cuenta_Cliente ||
          Core.Enums.TipoDocumentoDomiciliacion.Identificacion_Oficial_Cliente;
        return elemento;
      });
    }
    let div = document.getElementById("spinner") as HTMLDivElement;
    div.style.display = "block";
    datosCliente.value.cuentaDomiciliacionId = 0;
    let respuesta = await api.Cliente.Cliente.DomiciliacionApi.GuardarDatosDomiciliacion(datosCliente.value);
    $store.agregarToast(respuesta.data);
    let id = respuesta.data.data as string;
    archivosEnviar.forEach(async (x) => {
      let resp = await api.Cliente.Cliente.DomiciliacionApi.GuardarDocumentosDomiciliacion(
        x.nombreArchivo,
        id,
        x.tipoDocDomiciliacion
      );

      $store.agregarToast(resp.data);
    });
    contratosGuardados.value.forEach(async (x) => {
      await api.Cliente.Cliente.DomiciliacionApi.GuardarDomiciliacionContrato(x);
    });
    let respNotificaciones = await api.Cliente.Cliente.DomiciliacionApi.NotificarUsuarios();
    $store.agregarToast(respNotificaciones.data);
    bootstrap.Modal.getOrCreateInstance(document.querySelector("#codigoModal") as HTMLDivElement)?.hide();
    div.style.display = "none";
  }
}
async function obtenerDatosCliente() {
  cuentaSeleccionada.value = true;
  let informacionCliente = (await api.Cliente.Cliente.HomeApi.ObtenerInformacion()).data;
  nombre.value = informacionCliente.nombre;
  apellidoPaterno.value = informacionCliente.apellidoPaterno!;
  apellidoMaterno.value = informacionCliente.apellidoMaterno!;
  mediosContactos.value = [];
  if (informacionCliente.cliente.telefono3 != null)
    mediosContactos.value.push({ valor: informacionCliente.cliente.telefono3!, tipo: 0 });
  if (informacionCliente.cliente.email1 != null)
    mediosContactos.value.push({ valor: informacionCliente.cliente.email1!, tipo: 1 });
}
function obtenerDatosTercero() {
  cuentaSeleccionada.value = "tercero";
}

function obtenerArchivoIdTercero(event: any) {
  let file = event.target.files[0];
  archivoIDTercero.value = file;
  let nuevoDocDomi = {} as Archivos;
  nuevoDocDomi.nombreArchivo = file;
  nuevoDocDomi.tipoDocDomiciliacion = Core.Enums.TipoDocumentoDomiciliacion.Identificacion_Oficial_Tercero;
  archivosDomiciliacion.value.push(nuevoDocDomi);
  motsrarNombreArchivoIDTercero.value = isEmptyObject(archivoIDTercero.value);
}
function obtenerArchivoID(event: any) {
  let file = event.target.files[0];
  archivoID.value = file;
  let nuevoDocDomi = {} as Archivos;
  nuevoDocDomi.nombreArchivo = file;
  nuevoDocDomi.tipoDocDomiciliacion = Core.Enums.TipoDocumentoDomiciliacion.Identificacion_Oficial_Cliente;
  archivosDomiciliacion.value.push(nuevoDocDomi);
  motsrarNombreArchivoID.value = isEmptyObject(archivoID.value);
}
function visualizarArchivo(archivo: Blob) {
  let leer = URL.createObjectURL(archivo);
  return leer;
}
function obtenerArchivoEdoTercero(event: any) {
  let file = event.target.files[0];
  archivoEdoTercero.value = file;
  let nuevoDocDomi = {} as Archivos;
  nuevoDocDomi.nombreArchivo = file;
  nuevoDocDomi.tipoDocDomiciliacion = Core.Enums.TipoDocumentoDomiciliacion.Estado_Cuenta_Tercero;
  archivosDomiciliacion.value.push(nuevoDocDomi);
  motsrarNombreArchivoEdoTercero.value = isEmptyObject(archivoEdoTercero.value);
}
function obtenerArchivoEdo(event: any) {
  let file = event.target.files[0];
  archivoEdo.value = file;
  let nuevoDocDomi = {} as Archivos;
  nuevoDocDomi.nombreArchivo = file;
  nuevoDocDomi.tipoDocDomiciliacion = Core.Enums.TipoDocumentoDomiciliacion.Estado_Cuenta_Cliente;
  archivosDomiciliacion.value.push(nuevoDocDomi);
  motsrarNombreArchivoEdo.value = isEmptyObject(archivoEdo.value);
}
function cargaFormato(event: any) {
  let file = event.target.files[0];
  formato.value = file;
  let nuevoDocDomi = {} as Archivos;
  nuevoDocDomi.nombreArchivo = file;
  nuevoDocDomi.tipoDocDomiciliacion = Core.Enums.TipoDocumentoDomiciliacion.Formato_Domiciliacion_Tercero;
  motsrarNombreArchivoFormato.value = isEmptyObject(formato.value);
}
function capturaDatosDomiciliacion() {
  datosCliente.value.telefonoTercero = "";
  datosCliente.value.nombreTercero = "";

  bancos.value.forEach((x: any) => {
    if (x.bancoId == datosCliente.value.banco.bancoId) datosCliente.value.banco.nombre = x.nombre;
  });

  listaContratos.value.forEach((x) => {
    if ($("#" + x.contratoId).prop("checked")) {
      informacionContratosGuardados.numeroContrato.push(x.numeroContrato || "");
      informacionContratosGuardados.cuota.push(x.montoCuotaVigente!);
      informacionContratosGuardados.sumaCuotas += x.montoCuotaVigente!;
      contratosGuardados.value.push(x);
    }
  });

  mostrarFormulario.value = false;
}
function regresarFormularioDomiciliacion() {
  informacionContratosGuardados.numeroContrato = [];
  informacionContratosGuardados.cuota = [];
  contratosGuardados.value = [];
  mostrarFormulario.value = true;
}
async function guardarDatosDomiciliacion() {
  bootstrap.Modal.getOrCreateInstance(document.querySelector("#mediosContactoModal") as HTMLDivElement)?.show();
}
onMounted(async () => {
  formularioValidado.value = true;
  datosCliente.value = (await api.Cliente.Cliente.DomiciliacionApi.InformacionDomiciliacion()).data;
  bancos.value = (await api.Cliente.Cliente.DomiciliacionApi.ObtenerBancos()).data;
  listaContratos.value = (await api.Cliente.Cliente.FinanciamientoApi.ObtenerContratos(1)).data;
  listaContratos.value = listaContratos.value.filter((e) => {
    return e.esDomiciliado != Cliente.Financiamiento.TipoDomiciliacion.Proceso;
  });
  listaContratos.value.forEach((x) => {
    if (x.esDomiciliado == Cliente.Financiamiento.TipoDomiciliacion.Domiciliado) contratosDomiciliados.value = true;
  });
});
</script>
