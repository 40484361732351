<template lang="pug">
.accordion.shadow.mb-4(:id="compId + 'acordion3'")
  .accordion-item: template(v-if="estudioSocioeconomico")
    h2.accordion-header: button.accordion-button.collapsed.fw-bold(data-bs-toggle="collapse" :data-bs-target="'#' + compId + 'informacionLaboral'") Información Laboral
    .accordion-collapse.collapse(:id="compId + 'informacionLaboral'" :data-bs-parent="'#' + compId + 'acordion3'"): .accordion-body
      .row
        .col-md-3
          label.form-label Profesión:
          select.form-select(v-model="estudioSocioeconomico.profesion.value" v-if="catalogos")
            option(v-for="prof in catalogos.profesiones.value" :value="prof.profesionId" :key="prof.profesionId") {{ prof.descripcion }}
        .col-md-9
          label.form-label Ocupación Actual:
          .mt-2
            .form-check.form-check-inline.me-4
              input.form-check-input(
                type="checkbox"
                v-model="estudioSocioeconomico.ocupacionActualArray.value"
                :value="1"
                :id="compId + 'inputOcupacionActual1'"
              )
              label.form-check-lable(:for="compId + 'inputOcupacionActual1'") Empleado con Salario por Nómina
            .form-check.form-check-inline.me-4
              input.form-check-input(
                type="checkbox"
                v-model="estudioSocioeconomico.ocupacionActualArray.value"
                :value="2"
                :id="compId + 'inputOcupacionActual2'"
              )
              label.form-check-lable(:for="compId + 'inputOcupacionActual2'") Negocio Propio
            .form-check.form-check-inline.me-4
              input.form-check-input(
                type="checkbox"
                v-model="estudioSocioeconomico.ocupacionActualArray.value"
                :value="4"
                :id="compId + 'inputOcupacionActual3'"
              )
              label.form-check-lable(:for="compId + 'inputOcupacionActual3'") Actividad Empresarial
            .form-check.form-check-inline.me-4
              input.form-check-input(
                type="checkbox"
                v-model="estudioSocioeconomico.ocupacionActualArray.value"
                :value="5"
                :id="compId + 'inputOcupacionActual4'"
              )
              label.form-check-lable(:for="compId + 'inputOcupacionActual4'") Arrendamiento
            .form-check.form-check-inline.me-4
              input.form-check-input(
                type="checkbox"
                v-model="estudioSocioeconomico.ocupacionActualArray.value"
                :value="6"
                :id="compId + 'inputOcupacionActual5'"
              )
              label.form-check-lable(:for="compId + 'inputOcupacionActual5'") Concesiones
            .form-check.form-check-inline.me-4
              input.form-check-input(
                type="checkbox"
                v-model="estudioSocioeconomico.ocupacionActualArray.value"
                :value="7"
                :id="compId + 'inputOcupacionActual6'"
              )
              label.form-check-lable(:for="compId + 'inputOcupacionActual6'") Otros
            .form-check.form-check-inline.me-4
              input.form-check-input(
                type="checkbox"
                v-model="estudioSocioeconomico.ocupacionActualArray.value"
                :value="3"
                :id="compId + 'inputOcupacionActual7'"
              )
              label.form-check-lable(:for="compId + 'inputOcupacionActual7'") Pensionado
      template(v-if="estudioSocioeconomico.ocupacionActual != undefined") 
        informacion-laboral-nomina(v-if="estudioSocioeconomico.ocupacionActualArray.value.includes(1)" :form-id="compId + 'form'" @guardar="$emit('guardar')")
        informacion-laboral-general(
          v-if="estudioSocioeconomico.ocupacionActualArray.value.includes(4)"
          :es-comprobacion-ingresos="esComprobacionIngresos"
          :form-id="compId + 'form'"
          etiqueta-concepto="Proyectos Comprobables"
          etiqueta-header="Actividad Empresarial"
          propiedad-cantidad="cantidadActividadEmpresarial"
          propiedad-arreglo="actividadEmpresarial"
          :documentos="esComprobacionIngresos.documentosActividadEmpresarial.value"
          :es-pregunta="true"
          @guardar="$emit('guardar')"
        )
        informacion-laboral-general(
          v-if="estudioSocioeconomico.ocupacionActualArray.value.includes(3)"
          :es-comprobacion-ingresos="esComprobacionIngresos"
          :form-id="compId + 'form'"
          etiqueta-concepto="Pensiones Comprobables"
          etiqueta-header="Pensionado"
          propiedad-cantidad="cantidadPension"
          propiedad-arreglo="pension"
          :es-pregunta="false"
          :documentos="esComprobacionIngresos.documentosPension.value"
          @guardar="$emit('guardar')"
        )
        informacion-laboral-general(
          v-if="estudioSocioeconomico.ocupacionActualArray.value.includes(2)"
          :es-comprobacion-ingresos="esComprobacionIngresos"
          :form-id="compId + 'form'"
          etiqueta-concepto="Negocios Propios Comprobables"
          etiqueta-header="Negocio Propio"
          propiedad-cantidad="cantidadNegocioPropio"
          propiedad-arreglo="negocioPropio"
          :es-pregunta="false"
          :documentos="esComprobacionIngresos.documentosNegocioPropio.value"
          @guardar="$emit('guardar')"
        )
        informacion-laboral-general(
          v-if="estudioSocioeconomico.ocupacionActualArray.value.includes(5)"
          :es-comprobacion-ingresos="esComprobacionIngresos"
          :form-id="compId + 'form'"
          etiqueta-concepto="Inmuebles Arrendados Comp."
          etiqueta-header="Arrendamiento"
          propiedad-cantidad="cantidadArrendamiento"
          propiedad-arreglo="arrendamiento"
          :es-pregunta="false"
          :documentos="esComprobacionIngresos.documentosArrendamiento.value"
          @guardar="$emit('guardar')"
        )
        informacion-laboral-general(
          v-if="estudioSocioeconomico.ocupacionActualArray.value.includes(6)"
          :es-comprobacion-ingresos="esComprobacionIngresos"
          :form-id="compId + 'form'"
          etiqueta-concepto="Concesiones Comprobables"
          etiqueta-header="Concesiones"
          propiedad-cantidad="cantidadConcesion"
          propiedad-arreglo="concesion"
          :es-pregunta="false"
          :documentos="esComprobacionIngresos.documentosConcesion.value"
          @guardar="$emit('guardar')"
        )
        informacion-laboral-general(
          v-if="estudioSocioeconomico.ocupacionActualArray.value.includes(7)"
          :es-comprobacion-ingresos="esComprobacionIngresos"
          :form-id="compId + 'form'"
          etiqueta-concepto="Otros Ingresos"
          etiqueta-header="Otros"
          propiedad-cantidad="cantidadOtro"
          propiedad-arreglo="otro"
          :es-pregunta="false"
          :documentos="esComprobacionIngresos.documentosOtro.value"
          @guardar="$emit('guardar')"
        )
</template>

<script lang="ts" setup>
import { inject, watch, reactive, toRefs } from "vue";
import type { ToRefs } from "vue";
import InformacionLaboralGeneral from "./InformacionLaboral/CCGeneral.vue";
import InformacionLaboralNomina from "./InformacionLaboral/CCNomina.vue";
import { Core } from "@/servicios/api";
import type {
  EstudioSocioeconomico,
  EstudioSocioeconomicoObligadoSolidario,
  ComprobacionIngresos
} from "../../composables/EstudioSocioeconomico";

interface Props {
  estudioSocioeconomico: ToRefs<EstudioSocioeconomico | EstudioSocioeconomicoObligadoSolidario>;
  compId: string;
  catalogos: ToRefs<{ profesiones: Core.Models.Profesion[] }>;
}
const props = withDefaults(defineProps<Props>(), {
  estudioSocioeconomico: () =>
    toRefs(
      reactive({
        estudioSocioeconomicoId: "",
        ocupacionActualArray: [],
        clienteId: 0,
        esAvalObligadoSolidario: false,
        esDeclaradoMoratoriaSuspensionPagos: false,
        esDemandadoIncumplimientoObligacionesFinancieras: false,
        paisNacionalidadId: 0,
        version: 0,
        trabajaConyuge: false,
        notificacionAscesor: false
      })
    ),
  compId: "",
  catalogos: () => toRefs(reactive({ profesiones: [] }))
});

const esComprobacionIngresos: ComprobacionIngresos = inject("ComprobacionIngresos")!;

watch(
  props.estudioSocioeconomico!.ocupacionActualArray,
  (val, _) => {
    if (val == undefined) return;
    if (val.includes(1) && esComprobacionIngresos.cantidadNomina.value == 0) {
      esComprobacionIngresos.cantidadNomina.value = 1;
    } else if (val.includes(2) && esComprobacionIngresos.cantidadNegocioPropio.value == 0) {
      esComprobacionIngresos.cantidadNegocioPropio.value = 1;
    } else if (val.includes(3) && esComprobacionIngresos.cantidadPension.value == 0) {
      esComprobacionIngresos.cantidadPension.value = 1;
    } else if (val.includes(4) && esComprobacionIngresos.cantidadActividadEmpresarial.value == 0) {
      esComprobacionIngresos.cantidadActividadEmpresarial.value = 1;
    } else if (val.includes(5) && esComprobacionIngresos.cantidadArrendamiento.value == 0) {
      esComprobacionIngresos.cantidadArrendamiento.value = 1;
    } else if (val.includes(6) && esComprobacionIngresos.cantidadConcesion.value == 0) {
      esComprobacionIngresos.cantidadConcesion.value = 1;
    } else if (val.includes(7) && esComprobacionIngresos.cantidadOtro.value == 0) {
      esComprobacionIngresos.cantidadOtro.value = 1;
    }
  },
  { deep: true }
);

{
  let ci = esComprobacionIngresos;
  if (!ci.documentosNomina.value)
    ci.documentosNomina.value = [
      { nombre: "Recibos de Nómina (3 últimos meses)", requerido: true },
      { nombre: "Estado de Cuenta de Depósitos de Nómina", requerido: true },
      { nombre: "Carta Laboral", requerido: true }
    ];
  if (!!ci.documentosActividadEmpresarial.value)
    ci.documentosActividadEmpresarial.value = [
      { nombre: "Estados de Cuenta Personales", requerido: true },
      { nombre: "Declaraciones Anuales/Mensuales", requerido: true },
      { nombre: "Recibos de honorarios", requerido: true },
      { nombre: "Constancia de Declaración Fiscal", requerido: true },
      { nombre: "Carta bajo protesta decir verdad", requerido: true },
      { nombre: "Licencia de funcionamiento", requerido: true },
      { nombre: "Registro de marca (en su caso)", requerido: true }
    ];
  if (!ci.documentosPension.value)
    ci.documentosPension.value = [
      { nombre: "Estados de Cuenta de Pensión", requerido: true },
      { nombre: "Recibo de Pensión", requerido: true },
      { nombre: "Resolución de Otorgamiento de Pensión", requerido: true }
    ];
  if (!ci.documentosNegocioPropio.value)
    ci.documentosNegocioPropio.value = [
      { nombre: "Estados Financieros", requerido: true },
      { nombre: "Declaraciones Anuales/Mensuales", requerido: true },
      { nombre: "Estados de Cuenta Personales", requerido: true },
      { nombre: "Estados de Cuentas de TPV", requerido: true },
      { nombre: "Constancia de Situación Fiscal", requerido: true },
      { nombre: "Licencia de Funcionamiento", requerido: true },
      { nombre: "Acta Constitutiva", requerido: true }
    ];
  if (!ci.documentosArrendamiento.value)
    ci.documentosArrendamiento.value = [
      { nombre: "Contratos de Arrendamiento", requerido: true },
      { nombre: "Estados de Cuenta de Arrendamiento", requerido: true },
      { nombre: "Declaraciones Anuales/Mensuales", requerido: true },
      { nombre: "Escritura de los Inmuebles", requerido: true }
    ];
  if (!ci.documentosConcesion.value)
    ci.documentosConcesion.value = [
      { nombre: "Documento de Concesión", requerido: true },
      { nombre: "Estados de Cuenta Personales", requerido: true }
    ];
  if (!ci.documentosOtro.value)
    ci.documentosOtro.value = [
      { nombre: "Carta bajo Protesta de decir la verdad", requerido: true },
      { nombre: "Estudio Socioeconómico (con costo para el cliente)", requerido: true }
    ];
}
</script>
