<template lang="pug">
.container.ff-poppins.pb-4(:class="{ 'was-validated': formularioValidado }")
  .row.mt-2
    .col-md-6.mt-2(style="margin-left: -50px")
      .row: .col-md-12
        p.h1.fw-bold(style="color: #34189a") Seguro de Daños
          p.h5(style="color: #6e7599; margin: 50px 0 40px 0") Para garantizar el cumplimiento del compromiso adquirido con HIR Casa,&#32;
            span.h5.fw-bold(style="color: #6e7599") estás protegido &#32;
            | con un moderno Plan de Seguro.
      .row: .col-md-12
        p.h1.fw-bold(v-if="seguro == 'ZURICH COMPAÑÍA DE SEGUROS SA'" style="color: #34189a") Cobertura
        .row.text-center.mt-3.mb-4
          .col
            img(src="../assets/i_hogar_cobertura.svg" alt="Incendio")
            p.py-1.fw-bold(v-if="seguro == 'ZURICH COMPAÑÍA DE SEGUROS SA'" style="color: #34189a") Hogar
            a.fw-bold(
              style="width: 200px; text-align: center; text-decoration: none; margin: auto; padding: 10px; border-radius: 15px; color: white; background-color: #eb8113; border: none"
              href="cliente/cliente/pdf/infografia_hogar.pdf"
              target="_blank"
            ) Conoce más
          .col
            img(src="../assets/i_oficinas_cobertura.svg" alt="Incendio")
            p.py-1.fw-bold(v-if="seguro == 'ZURICH COMPAÑÍA DE SEGUROS SA'" style="color: #34189a") Locales comerciales y oficinas
            a.fw-bold(
              style="width: 200px; text-align: center; text-decoration: none; margin: auto; padding: 10px; border-radius: 15px; color: white; background-color: #eb8113; border: none"
              href="cliente/cliente/pdf/infografia_oficinas.pdf"
              target="_blank"
            ) Conoce más

    .col-md-6.mt-2(v-if="seguro == 'ZURICH COMPAÑÍA DE SEGUROS SA'" style="margin-left: 50px"): p.h1.fw-bold(style="color: #34189a") Mis Seguros
      .row: .col.pt-4.pb-4
        template(v-for="(item, i) in garantias" :key="item")
          .accordion.mb-4.shadow(:id="componentId + 'acordion'")
            .accordion-item
              h2.accordion-header: button.accordion-button.fw-bold(data-bs-toggle="collapse" :data-bs-target="'#' + componentId + 'segurodanios' + i"): p.text-muted {{ item.alias }}
              .accordion-collapse.collapse.show(:id="componentId + 'segurodanios' + i" :data-bs-parent="'#' + componentId + 'acordion'"): .accordion-body: .row
                .col-md-6
                  p.text-muted(style="font-size: 14px") Tipo de Inmueble:
                  p(style="font-size: 12px") {{ item.productoContratado.toString().replace(/_/g, " ") }}
                .col-md-6
                  p.text-muted(style="font-size: 14px") Fecha de Escrituración:
                  p(style="font-size: 12px") {{ DateTime.fromISO(item.fechaAvaluo || "").toFormat("dd/MM/yyyy") }}
                .col-md-6
                  p.text-muted(style="font-size: 14px") Dirección:
                  p(style="font-size: 12px") {{ item.calle }}
                .col-md-6
                  p.text-muted(style="font-size: 14px") Estás asegurado con:
                  p(style="font-size: 12px") {{ item.aseguradora }}
                .col-md-6
                  p.text-muted(style="font-size: 14px") Porcentaje:
                  p(style="font-size: 12px") {{ item.porcentaje }} %
                .col-md-6
                  p.text-muted(style="font-size: 14px") Monto:
                  p(style="font-size: 12px") {{ filtros.filtroDivisa(item.monto) }}
                .col-md-12
                  a.btn.btn-primary.text-white(
                    v-if="item.ruta != ''"
                    :href="ObtenerCertificadoDanios(item.ruta || '')"
                    :title="'Descargar Certificado de Daños - ' + item.ruta"
                    target="_blank"
                  ) Descargar Certificado Daños
</template>

<style lang="sass">
.modal-overlay
  position: absolute
  top: 0
  left: 0
  bottom: 0
  right: 0
  z-index: 100
  height: 160%
  width: 100%
  background: rgba(0,0,0,0.4)
</style>

<script lang="ts" setup>
import { ref, onMounted, nextTick } from "vue";
import api from "@/servicios/apiCliente";
import { DateTime } from "luxon";
import { Cliente } from "@/servicios/api";
import * as utilidades from "@/utilidades";
import filtros from "@/composables/filtros";
const garantias = ref([] as Cliente.Financiamiento.GarantiaAdministradaDetalle[]);

const componentId = utilidades.generarId();
const formularioValidado = true;
const seguro = ref("" as string | undefined);

onMounted(async () => {
  api.Cliente.Cliente.SeguroApi.AgruparGarantias().then(resp => {
    garantias.value = resp.data as Cliente.Financiamiento.GarantiaAdministradaDetalle[];
    seguro.value = garantias.value[0].aseguradora;
  });
  await nextTick();
});
//# Metodos
function ObtenerCertificadoDanios(path: string): string {
  let URL =
    (import.meta.env.BASE_URL || "").replace(/\/$/g, "") + api.Cliente.Cliente.SeguroApi.ObtenerArchivoUrl + "?ruta=";
  return URL + path;
}
</script>

