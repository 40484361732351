import { defineStore } from "pinia";
import { useStoreTitular } from "@/Aplicaciones/Cliente/Areas/Cliente/storeTitular";
import api from "@/servicios/apiCliente";

export const useStoreDocumentos = defineStore("documentos", {
  state: () => ({
    cargando: false,
    datos: null
  }),
  getters: {},
  actions: {
    async cargarDocumento(documento, documentoID, figuraActual, estudioSocioeconomicoId, categoria, entidad) {
      this.cargando = true;
      const storeTitular = useStoreTitular();
      try {
        await api.Cliente.Cliente.EstudioSocioEconomicoApi.GuardarDocumento(
          documento,
          documentoID,
          figuraActual,
          storeTitular.estudioSocioeconomicoId,
          categoria,
          entidad
        );

        this.cargando = false;
      } catch (error) {
        this.cargando = false;
        console.log("error: " + error);
      }
    },
    async consumirDocumentos() {
      const storeTitular = useStoreTitular();
      const resp = await api.Cliente.Cliente.EstudioSocioEconomicoApi.ObtenerDocumentos(
        storeTitular.estudioSocioeconomicoId
      );
      this.datos = resp.data;
    }
  }
});
